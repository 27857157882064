import React from 'react';
import { Switch } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import PanelRoute from './PanelRoute';
import ExternalAuth from './ExternalAuth';
import InstitutionalRoute from './InstitutionalRoute';

import LoginPage from '../pages/Login';
import ConfirmIdentity from '../pages/ConfirmIdentity';
import ExtractInCSV from '../pages/ExtractInCSV';

import PanelStartPage from '../pages/panel/PanelStart';
import PanelCashInTed from '../pages/panel/PanelCashInTed';
import PanelHistoryPage from '../pages/panel/PanelHistory';
import PanelExtractPage from '../pages/panel/PanelExtract';
import PanelWalletPage from '../pages/panel/PanelWallet';
import PanelDepositPage from '../pages/panel/PanelDeposit';
import PanelCashOut from '../pages/panel/PanelCashOut';

import PanelHistoryDetails from '../pages/panel/PanelHistoryDetails';
import PanelExtractDetails from '../pages/panel/PanelExtractDetails';
import PublicExtractDetails from '../pages/PublicExtractDetails';

import ROUTES from '../data/routes';
import PageNotFound from '../pages/PageNotFound';
import AccessDenied from '../pages/AccessDenied';
import PanelCashInBoleto from '../pages/panel/PanelCashInBoleto';
import PanelConfiguration from '../pages/panel/PanelConfiguration';

import InsitutionalHome from '../pages/institutional/InsitutionalHome';
import InstitutionalAbout from '../pages/institutional/InsitutionalAbout';
import InstitutionalMap from '../pages/institutional/InsitutionalMap';
import InstitutionalTerms from '../pages/institutional/InsitutionalTerms';
import InstitutionalFaq from '../pages/institutional/InstitutionalFaq';
import PanelFaq from '../pages/panel/PanelFaq';
import SupportEmail from '../pages/support/SupportEmail';
import SupportOuvidoria from '../pages/support/SupportOuvidoria';
import PanelAbout from '../pages/panel/PanelAbout';
import PanelTerms from '../pages/panel/PanelTerms';
import PanelCashinBanks from '../pages/panel/PanelCashinBanks';
// import PanelCredit from '../pages/panel/PanelCredit';
// import PanelCreditApproved from '../pages/panel/PanelCreditApproved';
import PanelInformIncome from '../pages/panel/PanelInformIncome';
import ReportOmbudsman from '../components/institutional/ReportOmbudsman';
import PanelDeleteAccount from '../pages/panel/PanelDeleteAccount';

const Content = () => (
  <Switch>

    <InstitutionalRoute
      path={ROUTES.HOME}
      component={InsitutionalHome}
      title={`${I18n.t('panel.startPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact

    />

    <InstitutionalRoute
      path={ROUTES.ABOUT_US}
      component={InstitutionalAbout}
      title={`${I18n.t('panel.startPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <InstitutionalRoute
      path={ROUTES.TERMS}
      component={InstitutionalTerms}
      title={`${I18n.t('panel.startPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <InstitutionalRoute
      path={ROUTES.MAP}
      component={InstitutionalMap}
      title={`${I18n.t('panel.startPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <InstitutionalRoute
      path={ROUTES.FAQ}
      component={InstitutionalFaq}
      title={`${I18n.t('panel.startPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PublicRoute
      path={ROUTES.REPORT_OMBUDSMAN}
      component={ReportOmbudsman}
      title={`${I18n.t('public.reportOmbudsman')} - ${I18n.t('application.projectName')}`}
    />

    <PublicRoute
      path={ROUTES.PUBLIC_EXTRACT}
      component={PublicExtractDetails}
      title={`${I18n.t('public.extractDetail')} - ${I18n.t('application.projectName')}`}
      noHeader
    />

    <PublicRoute
      path={ROUTES.LOGIN}
      component={LoginPage}
      title={`${I18n.t('login.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
      noHeader
    />

    <PrivateRoute
      path={ROUTES.PANEL_EXTRACT_CSV}
      component={ExtractInCSV}
      noHeader
    />

    <PrivateRoute
      path={ROUTES.CONFIRM_IDENTITY}
      component={ConfirmIdentity}
      title={`${I18n.t('confirmIdentity.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
      noHeader
    />

    <PanelRoute
      path={ROUTES.PANEL_START}
      component={PanelStartPage}
      title={`${I18n.t('panel.startPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
      showHeaderBalanceWeb={false}
      showHeaderBalanceMobile
    />

    <PanelRoute
      path={ROUTES.PANEL_HISTORY}
      component={PanelHistoryPage}
      title={`${I18n.t('panel.historyPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PanelRoute
      path={ROUTES.PANEL_HISTORY_DETAILS}
      component={PanelHistoryDetails}
      title={`${I18n.t('panel.historyPage.pageTitle')} - ${I18n.t('application.projectName')}`}
    />

    <PanelRoute
      path={ROUTES.PANEL_EXTRACT}
      component={PanelExtractPage}
      title={`${I18n.t('extractPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
      showHeaderBalanceMobile
    />

    <PanelRoute
      path={ROUTES.PANEL_EXTRACT_DETAILS}
      component={PanelExtractDetails}
      title={`${I18n.t('extractPage.pageTitle')} - ${I18n.t('application.projectName')}`}
    />

    <PanelRoute
      path={ROUTES.PANEL_ADD_MONEY}
      component={PanelDepositPage}
      title={`${I18n.t('panel.depositPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PanelRoute
      path={ROUTES.PANEL_ADD_MONEY_TRANSFER}
      component={PanelCashInTed}
      title={`${I18n.t('panel.depositPage.pageTitle')} - ${I18n.t('application.projectName')}`}
    />

    <PanelRoute
      path={ROUTES.PANEL_ADD_MONEY_CHOOSE_BANKS}
      component={PanelCashinBanks}
      title={`${I18n.t('panel.depositPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PanelRoute
      path={ROUTES.PANEL_ADD_MONEY_BILLET}
      component={PanelStartPage}
      title={`${I18n.t('panel.depositPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PanelRoute
      path={ROUTES.PANEL_WITHDRAW_MONEY}
      component={PanelCashOut}
      title={`${I18n.t('cashOut.title')} - ${I18n.t('application.projectName')}`}
      exact
      showHeaderBalanceMobile={false}
    />

    <PanelRoute
      path={ROUTES.PANEL_WALLET}
      component={PanelWalletPage}
      title={`${I18n.t('walletPage.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PanelRoute
      path={ROUTES.PANEL_SETTINGS}
      component={PanelConfiguration}
      title={`${I18n.t('configuration.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PanelRoute
      path={ROUTES.PANEL_DELETE_ACCOUNT}
      component={PanelDeleteAccount}
      title={`${I18n.t('configuration.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PanelRoute
      path={ROUTES.PANEL_FAQ}
      component={PanelFaq}
      title={`${I18n.t('support.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PanelRoute
      path={ROUTES.PANEL_FAQ_EMAIL}
      component={SupportEmail}
      title={`${I18n.t('support.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PanelRoute
      path={ROUTES.PANEL_FAQ_OUVIDORIA}
      component={SupportOuvidoria}
      title={`${I18n.t('support.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    {/* <PanelRoute
      path={ROUTES.PANEL_CREDIT}
      component={PanelCredit}
      title="Crédito"
      exact
    />

    <PanelRoute
      path={ROUTES.PANEL_CREDIT_APPROVED}
      component={PanelCreditApproved}
      title={`${I18n.t('panel.credit.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    /> */}

    <PanelRoute
      path={ROUTES.PANEL_INFORM_INCOME}
      component={PanelInformIncome}
      title={`${I18n.t('panel.informIncome.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PrivateRoute
      path={ROUTES.PANEL_TERMS}
      component={PanelTerms}
      title={`${I18n.t('support.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <PrivateRoute
      path={ROUTES.PANEL_ABOUT}
      component={PanelAbout}
      title={`${I18n.t('support.pageTitle')} - ${I18n.t('application.projectName')}`}
      exact
    />

    <ExternalAuth
      path={ROUTES.EXTERNAL_AUTH}
    />

    <PublicRoute
      path={ROUTES.ACCESS_DENIED}
      title={`${I18n.t('accessDenied.pageTitle')} - ${I18n.t('application.projectName')}`}
      component={AccessDenied}
    />

    <PublicRoute
      title={`${I18n.t('pageNotFound.pageTitle')} - ${I18n.t('application.projectName')}`}
      component={PageNotFound}
    />

  </Switch>
);
export default Content;
