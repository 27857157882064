export default {
  header: {
    basic: 'NzI0NjE0ZjBjODU4ZmQ1ZDNmNDYwZjYxZTRlNWE3YzM6ZTBjMmU4YjE4MmRiZjQ5Nzg1ZDY4MjU1Zjc4YWQzOTY2Nzg1OTM1MzFlMGI4Y2Y0YThhZTllZDg3YTc2NTM2OA==', // eslint-disable-line
    platform: 'web',
    version: '1.0.0',
  },
  timezone: 'America/Sao_Paulo',
  language: 'pt-br',
  termsAndPolitics: 'https://s4pay.mblabs.com.br/termos',
  about: 'https://s4pay.mblabs.com.br/sobre',
  limitValueCashInBoleto: 100000,
  urlAdiq: {
    localhost: 'https://card.stage.s4pay.com.br/',
    'stage.superbidpay.com.br': 'https://card.stage.s4pay.com.br/',
    'stage.s4pay.com.br': 'https://card.stage.s4pay.com.br/',
    'superbidpay.com.br': 'https://card.s4pay.com.br/',
    'www2.s4pay.com.br': 'https://card.s4pay.com.br/',
    'www.s4pay.com.br': 'https://card.s4pay.com.br/',
    's4pay.com.br': 'https://card.s4pay.com.br/'
  },
  supportPhone: '11 2586-7866',
  simulateCredit: 'https://fin.stage.s4pay.com.br/'
};
