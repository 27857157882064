import RestService from '../services/rest';

async function authenticate(username, password, secondFactorType, dateDevice) {
  return RestService.postRest('auth', {
    username,
    password,
    secondFactorType,
    dateDevice
  });
}

async function twoFactorCode(twoFactorCode) {
  return RestService.putAuthenticated('auth/two-factor', {
    twoFactorCode,
  });
}

async function resendTwoFactor(secondFactorType) {
  return RestService.putAuthenticated('auth/resend-two-factor', {
    secondFactorType,
  });
}

async function sendTwoFactor(secondFactorType) {
  return RestService.putAuthenticated('auth/send-two-factor', {
    secondFactorType,
  });
}

export default {
  authenticate,
  twoFactorCode,
  resendTwoFactor,
  sendTwoFactor,
};
