/* eslint-disable max-len */
import React from "react"

const IcMoneyAddColored = ({ size = 24, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="11.5"
        cy="11.5586"
        r="9"
        stroke="#7800A1"
      />
      <circle
        cx="11.5"
        cy="11.5586"
        r="8.5"
        stroke="#CD72FF"
        strokeOpacity="0.6"
      />
      <path
        d="M22 21.1758H2.33081V21.9417H22V21.1758Z"
        fill="#CD72FF"
      />
      <path
        d="M12.5626 16.3388H10.9374C10.2912 16.3379 9.6718 16.0808 9.2149 15.6239C8.758 15.167 8.50091 14.5476 8.5 13.9014V13.0892H10.1252V13.9014C10.1255 14.1168 10.2111 14.3232 10.3633 14.4755C10.5156 14.6277 10.7221 14.7134 10.9374 14.7136H12.5626C12.7779 14.7134 12.9844 14.6277 13.1367 14.4755C13.2889 14.3232 13.3745 14.1168 13.3748 13.9014V13.4472C13.3759 13.2759 13.3228 13.1087 13.2229 12.9695C13.123 12.8304 12.9816 12.7265 12.819 12.6728L10.1665 11.7884C9.68145 11.6275 9.25936 11.3179 8.96022 10.9035C8.66107 10.4892 8.50005 9.9911 8.5 9.48004V9.02577C8.50091 8.37962 8.758 7.76018 9.2149 7.30328C9.6718 6.84638 10.2912 6.58929 10.9374 6.58838H12.5626C13.2088 6.58929 13.8282 6.84638 14.2851 7.30328C14.742 7.76018 14.9991 8.37962 15 9.02577V9.83795H13.3748V9.02577C13.3745 8.81044 13.2889 8.60399 13.1367 8.45173C12.9844 8.29946 12.7779 8.21382 12.5626 8.2136H10.9374C10.7221 8.21382 10.5156 8.29946 10.3633 8.45173C10.2111 8.60399 10.1255 8.81044 10.1252 9.02577V9.48004C10.1241 9.65131 10.1772 9.81852 10.2771 9.95766C10.377 10.0968 10.5184 10.2007 10.681 10.2544L13.3335 11.1388C13.8191 11.2999 14.2416 11.61 14.5408 12.0251C14.84 12.4401 15.0007 12.9389 15 13.4506V13.9014C14.9991 14.5476 14.742 15.167 14.2851 15.6239C13.8282 16.0808 13.2088 16.3379 12.5626 16.3388Z"
        fill="#7800A1"
      />
      <path
        d="M12.4043 5.55859H11.5439V7.27931H12.4043V5.55859Z"
        fill="#7800A1"
      />
      <path
        d="M12.4044 15.0237H11.5441V16.7444H12.4044V15.0237Z"
        fill="#7800A1"
      />
    </svg>
  )
}

export default IcMoneyAddColored