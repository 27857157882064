import { CreditOfferActions } from "../actions";

const defaultState = {
  creditOfferlist: null,
  creditOfferProposal: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CreditOfferActions.ACTION_LIST_CREDIT_OFFER:
      return {
        ...state,
        creditOfferlist: action.creditOfferlist
      };
    case CreditOfferActions.ACTION_SET_CREDIT_OFFER_PROPOSAL:
      return {
        ...state,
        creditOfferProposal: action.creditOfferProposal
      }
    default:
      return state;
  }
};

export function getCreditOffer(state) {
  return state.creditOffer.creditOfferlist;
}

export function getCreditOfferProposal(state) {
  return state.creditOffer.creditOfferProposal;
}

