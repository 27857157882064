import React from 'react';
import { NavLink } from 'react-router-dom';
import ROUTES from '../../data/routes';
import SidebarRoutes from '../../data/sidebar';
import ArrowRight from '../../svg/Arrow-right.svg';

const PanelSidebar = () => {
  return (
    <aside className="sidebar col-lg-2 p-0 mobile-hidden">

      <div className="widget widget-dashboard">
        <ul className="sidebar__menu">
          <li
            className="sidebar__menu__item"
          >
            {
              SidebarRoutes.links.map((link, linkIndex) => (
                <NavLink
                  // eslint-disable-next-line react/no-array-index-key
                  key={(linkIndex + 1)}
                  exact={(link.url === ROUTES.PANEL_START)}
                  className="sidebar__menu__item__link"
                  activeClassName="sidebar__menu__item__link--active"
                  to={link.url}
                >
                  <span className="sidebar__menu__item__link__icon">
                    {link.icon}
                  </span>
                  <span className="sidebar__menu__item__link__name">
                    {link.name}
                  </span>
                </NavLink>
              ))
            }
          </li>
        </ul>
      </div>
      <div className='sidebar__menu__last'>
        <a
          href={ROUTES.SUPERBID_MARKETPLACE}
          className='sidebar__menu__last__link'
        >
          <span className='sidebar__menu__final'>
            Superbid Exchange
          </span>
          <ArrowRight />
        </a>
      </div>
    </aside>
  );
}


export default PanelSidebar;
