import React from 'react';
import { connect } from 'react-redux';
import { UserSelectors } from '../../app/store/reducers';

export const ProfilePhoto = ({ user, isBigger }) => (
  <div className="profile-photo">
    <div
      className={`${isBigger ? 'profile-photo__configuration' : 'profile-photo__container'}`}
      style={{
        backgroundImage: `url(${user && user.photoUrl || "/assets/images/ic_user.png"})`,
      }}
    />
  </div>
);

const mapStateToProps = state => ({
  user: UserSelectors.getUser(state),
});

export default connect(
  mapStateToProps,
  null,
)(ProfilePhoto);
