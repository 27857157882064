/* eslint-disable max-len */
import * as React from "react"

const IcSettings = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.783 18.333H9.208a1.4 1.4 0 0 1-1.4-1.4v-.908a.283.283 0 0 0-.183-.242.316.316 0 0 0-.342 0l-.616.667a1.39 1.39 0 0 1-1.975 0L3.55 15.333a1.384 1.384 0 0 1-.417-.991c0-.378.15-.74.417-1.009l.617-.616a.283.283 0 0 0 0-.309c-.05-.125-.134-.216-.25-.216h-.85a1.409 1.409 0 0 1-1.4-1.409V9.208a1.4 1.4 0 0 1 1.4-1.4h.908a.283.283 0 0 0 .242-.183.317.317 0 0 0 0-.342l-.667-.616a1.392 1.392 0 0 1 0-1.975L4.667 3.55a1.375 1.375 0 0 1 .983-.417 1.433 1.433 0 0 1 1.017.417l.616.617a.283.283 0 0 0 .309 0c.125-.05.216-.134.216-.25v-.85a1.408 1.408 0 0 1 1.409-1.4h1.616a1.4 1.4 0 0 1 1.4 1.4v.908a.283.283 0 0 0 .184.242.317.317 0 0 0 .341 0l.575-.667a1.39 1.39 0 0 1 1.975 0l1.142 1.117a1.394 1.394 0 0 1 .417.991 1.359 1.359 0 0 1-.417 1.009l-.617.616a.283.283 0 0 0 0 .309c.05.125.134.216.25.216h.909a1.408 1.408 0 0 1 1.341 1.409v1.616a1.4 1.4 0 0 1-1.4 1.4h-.908a.283.283 0 0 0-.242.184.283.283 0 0 0 0 .308l.642.642a1.393 1.393 0 0 1 0 1.975l-1.092 1.108a1.374 1.374 0 0 1-.983.417 1.433 1.433 0 0 1-.992-.417l-.641-.617a.283.283 0 0 0-.309 0c-.125.05-.216.134-.216.25v.909a1.408 1.408 0 0 1-1.409 1.341Zm-1.308-1.666h1.05v-.642a1.94 1.94 0 0 1 1.217-1.783 1.966 1.966 0 0 1 2.158.391l.45.45.733-.733-.45-.458a1.95 1.95 0 0 1-.4-2.134 1.942 1.942 0 0 1 1.784-1.208h.65V9.475h-.642a1.942 1.942 0 0 1-1.783-1.217 1.967 1.967 0 0 1 .391-2.158l.45-.45-.733-.733-.458.45a1.991 1.991 0 0 1-3.334-1.392v-.642H9.475v.642a1.942 1.942 0 0 1-1.217 1.783A1.967 1.967 0 0 1 6.1 5.367l-.45-.45-.733.733.45.458a1.992 1.992 0 0 1-1.392 3.334h-.642v1.05h.642a1.941 1.941 0 0 1 1.783 1.216 1.966 1.966 0 0 1-.391 2.159l-.45.45.733.733.458-.45a1.992 1.992 0 0 1 3.334 1.392l.033.675Z"
      fill="currentColor"
    />
    <path
      d="M10 12.917a2.916 2.916 0 1 1 0-5.833 2.916 2.916 0 0 1 0 5.833Zm0-4.167a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
    />
  </svg>
)

export default IcSettings
