import { addLoading, removeLoading } from './loading';
import SupportRequests from '../../server/support';

export const createEmail = (message) => async (dispatch) => {
	dispatch(addLoading());
	try {
		const supportEmail = await SupportRequests.createEmail(message);
		return supportEmail;
	} catch (err) {
		throw err;
	} finally {
		dispatch(removeLoading());
	}
};

export const createOuvidoria = (message, protocol) => async (dispatch) => {
	dispatch(addLoading());
	try {
		const supportOuvidoria = await SupportRequests.createOuvidoria(message, protocol);
		return supportOuvidoria;
	} catch (err) {
		throw err;
	} finally {
		dispatch(removeLoading());
	}
};
