import * as AuthActions from './auth';
import * as AccountActions from './account';
import * as UserActions from './user';
import * as LoadingActions from './loading';
import * as UtilsActions from './utils';
import * as CashInActions from './cashin';
import * as BankTransferActions from './bank-transfer';
import * as CashOutActions from './cash-out';
import * as PaymentActions from './paymentOrder';
import * as CreditCardActions from './credit-card';
import * as SupportActions from './support';
import * as ConnectedDevicesAction from './connected-devices';
import * as SettingsActions from './settings';
import * as CreditOfferActions from './credit-offers';
import * as UserInformIncomeActions from './inform-income';

export {
	AuthActions,
	AccountActions,
	UserActions,
	LoadingActions,
	CashInActions,
	UtilsActions,
	BankTransferActions,
	CashOutActions,
	PaymentActions,
	CreditCardActions,
	SupportActions,
	ConnectedDevicesAction,
	SettingsActions,
	CreditOfferActions,
	UserInformIncomeActions
}
