import { I18n } from 'react-redux-i18n';
import { browserName, osName, isMobile, mobileVendor } from 'react-device-detect';
import AuthRequests from '../../server/auth';
import ConnectedDevicesRequests from '../../server/connected-devices';
import AuthService from '../../services/auth';
import getDeviceId from '../../utils/storage';

import { addLoading, removeLoading } from './loading';
import * as UserActions from './user';

export const ACTION_AUTH_LOGIN = 'LOGIN';
export const ACTION_AUTH_LOGOUT = 'LOGOUT';

export const resetAuthentication = () => ({
  type: ACTION_AUTH_LOGOUT,
});

export const saveAuthentication = authData => ({
  type: ACTION_AUTH_LOGIN,
  auth: authData,
});

export const cleanAuth = () => async (dispatch) => {
  dispatch(resetAuthentication());
  dispatch(UserActions.cleanUser());
  AuthService.reset();
};

export const authenticate = (username, password, secondFactorType) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let computerName = null;

    if (isMobile) {
      computerName = mobileVendor;
    } else {
      computerName = osName;
    }

    const deviceId = getDeviceId();

    const dateDevice = {
      deviceId,
      computerName,
      operationSystem: osName,
      browser: browserName,
    };

    const auth = await AuthRequests.authenticate(username, password, secondFactorType, dateDevice);
    AuthService.create(auth);
    dispatch(saveAuthentication(auth));
    dispatch(UserActions.saveUser(auth.user));
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error === 'user_not_found') {
      throw new Error(I18n.t('error.userInvalid'));
    }
    if (err.response && err.response.status && err.response.status === 429) {
      throw new Error(I18n.t('error.loginManyRequestError'));
    }
    throw new Error(I18n.t('error.loginUnexpectedError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const authenticateSDK = (token) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let computerName = null;

    if (isMobile) {
      computerName = mobileVendor;
    } else {
      computerName = osName;
    }

    const deviceId = getDeviceId();
    const dateDevice = {
      deviceId,
      computerName,
      operationSystem: osName,
      browser: browserName,
    };

    AuthService.create({ token });
    dispatch(saveAuthentication({ token }));
    dispatch(UserActions.getUser());
    await ConnectedDevicesRequests.saveDevice(token, dateDevice);
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

export const twoFactorConfirmCode = code => async (dispatch) => {
  dispatch(addLoading());
  try {
    const auth = await AuthRequests.twoFactorCode(code);
    AuthService.create(auth);
    dispatch(saveAuthentication(auth));
    await dispatch(UserActions.getUser());
  } catch (err) {
    throw new Error(I18n.t('error.codeInvalid'));
  } finally {
    dispatch(removeLoading());
  }
};

export const resendTwoFactor = secondFactorType => async (dispatch) => {
  dispatch(addLoading());
  try {
    const { user } = await AuthRequests.resendTwoFactor(secondFactorType);
    dispatch(UserActions.saveUser(user));
  } finally {
    dispatch(removeLoading());
  }
};

export const sendTwoFactor = secondFactorType => async (dispatch) => {
  dispatch(addLoading());
  try {
    const { user } = await AuthRequests.sendTwoFactor(secondFactorType);
    dispatch(UserActions.saveUser(user));
  } finally {
    dispatch(removeLoading());
  }
};

export const logout = () => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanAuth());
  dispatch(removeLoading());
};
