import Incomes from '../../server/incomes';
import { addLoading, removeLoading } from './loading';

export const ACTION_SAVE_INCOMES = "ACTION_SAVE_INCOMES";

export const saveIncomes = incomes => ({
    type: ACTION_SAVE_INCOMES,
    payload: {
        incomes
    }
});

export const getIncomes = () => async dispatch => {
    try {
        dispatch(addLoading());

        const incomes = await Incomes.getUserInformIncomes();

        dispatch(saveIncomes(incomes));

        dispatch(removeLoading())
    } catch (error) {
        dispatch(removeLoading());
        throw error
    }
}