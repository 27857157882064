import React, { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { I18n } from 'react-redux-i18n';

import 'dayjs/locale/pt-br';
import ReactTooltip from "react-tooltip";
import { useTheme } from "styled-components";
import Icon from "../elements/Icon";
import InfoIcon from '../../svg/ic_info_outline.svg';

const ExtractCard = ({ extract, balance, date, type = 'D', customDescription = ""}) => {
  const {colors} = useTheme();
  const transaction = extract;

  const types = useMemo(() => ({
    C: {
      title: "Entrada de dinheiro",
      icon: "arrow-forward-outline",
      color: "#59AC7C"
    },
    D: {
      title: "Saída de dinheiro",
      icon: "arrow-back-outline",
      color: "#BE00FF"
    },
    S: {
      title: "Saldo",
      icon: "briefcase-outline",
      color: "#BE00FF"
    },
    F: {
      title: date ? "Transação agendada" : "Transação aguardando validação de conta",
      icon: date ? "calendar-outline" : "alert-triangle-outline",
      color: date ? colors.brand.primary : colors.feedback.warning,
    }
    }), [date]);


  const handleDate = useCallback(() => {
    const isToday = dayjs(date).isSame(dayjs(), 'day');

    return isToday ? I18n.t('date.today') : dayjs(date).locale('pt-br').format("DD MMM").toLocaleUpperCase()
  }, [date]);

  const handleTitle = useCallback(() => {
    let title = types[type].title;

    if (transaction && transaction.comment) {
      if (Object.keys(transaction.comment).length > 0 && transaction.comment.customDescription) {
        title = transaction.comment.customDescription
      } else {
        title = transaction.comment
      }
    }

    if(transaction && transaction.counterpart && (transaction.historyCode === 1201 || transaction.historyCode === 1301 || transaction.historyCode === 1211)){
      title = transaction.counterpart.name;
    }

    return title
  }, [type, transaction]);

  const handleCustomDescription = useCallback(() => {
    let description = ''

    if (transaction && transaction.comment) {
      if (Object.keys(transaction.comment).length > 0 && transaction.comment.customDescription) {
        description = transaction.comment.customDescription
      } else {
        description = transaction.comment
      }
    }
    if(transaction && transaction.counterpart && transaction.counterpart.name){
      return description;
    }
    else {
      return ''
    }

  },[transaction])

  const handleValue = useCallback(() => {
    let value = balance || 0;

    if (type === "D") {
      value = balance * -1
    }

    return value.toLocaleString('pt-br', { style: 'currency', currency: "BRL" })
  }, [balance])

  return (
    <div className="extract-card">
      <div
        className="extract-card"
        id="extract-card-desktop"
      >
        <div className="extract-card__icon" title={types[type].title} > 
          <Icon
            icon={types[type].icon}
            color={types[type].color}
          />
        </div>
        <div className="extract-card__content">
          <div>
          <h6 className="extract-card__content__title">
            {handleTitle()}
            <span>
              {type === 'F' && (
              <InfoIcon
                style={{ marginBottom: '2px', marginLeft: '18px' }}
                data-tip="React-tooltip"
              />
)}
            </span>
          </h6>
          <ReactTooltip
            backgroundColor="#66696F"
            className="extract-card__tooltip"
            place="right"
            type="dark"
            effect="solid"
          >
            {date ?
              <span>Transações agendadas após as 15h serão agendadas para o próximo dia útil</span>
                  : (
                    <span>Essa transação será realizada após a validação dos dados bancários da conta de destino. 
                      Isso acontecerá automaticamente, em até 24hrs. 
                      caso a conta não seja validada essa transação será cancelada.
                    </span>
                )}
          </ReactTooltip>
          <p className="extract-card__content__description">
            {transaction && transaction.description && transaction.description}
          </p>
          <p className="extract-card__content_description">
            {transaction && transaction.counterpart && transaction.counterpart.taxIdentifier && transaction.counterpart.taxIdentifier.taxId}
          </p>
          <p className="extract-card__content__date">{date && handleDate()}</p>
        </div>
        <div>
          <p>{handleCustomDescription()}</p>
        </div>
        </div>
        <p
          className="extract-card__content__value"
          style={{ color: type === 'C' ? '#59AC7C' : "#66696F" }}
        >
          {handleValue()}
        </p>
      </div>
      <div
        className="extract-card"
        id="extract-card-mobile"
      >
        <div className="extract-card__icon">
          <Icon
            icon={types[type].icon}
            color={types[type].color}
          />
        </div>
        <div className="extract-card__content">
          <h6 className="extract-card__content__title">
            {handleTitle()}
          </h6>
          <p className="extract-card__content__description">
            {transaction ? transaction.description && transaction.description : customDescription}
          </p>
          <p
            className="extract-card__content__value"
            style={{ color: type === 'C' ? '#59AC7C' : "#66696F" }}
          >
            {handleValue()}
          </p>
        </div>
        <p className="extract-card__content__date">{date && handleDate()}</p>
      </div>
    </div>
  )
}

export default ExtractCard;