import { I18n } from "react-redux-i18n"


const TranslateDescription = value => {
    switch(value){

        case 'COMMISSION_AUCTIONEER': 
        return I18n.t('paymentDescription.commision_auctioner');

        case 'ADMINISTRATIVE_RATE':
            return I18n.t('paymentDescription.administrative_rate');

        case 'FEE_PLATAFORM':
            return I18n.t('paymentDescription.fee_platform');

        case 'BUYERS_PREMIUM':
            return I18n.t('paymentDescription.buyers_premium');
    }
}



export {
    // eslint-disable-next-line import/prefer-default-export
    TranslateDescription
}