import styled from "styled-components";
import { color, typography } from "styled-system";

export const Menu = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.colors.background.default};
    top: calc(100% + 16px);
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;

    z-index: 999;
    border-radius: 8px;
    box-shadow: 4px 4px 80px rgba(11, 47, 99, 0.24);
    gap:16px;
`;

export const Link = styled.a`
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none !important;
    
    svg {
        color: ${({ theme }) => theme.colors.text.dark};
    }

    &:hover {
        svg {
            color:${({ theme }) => theme.colors.brand.primary};
        }
    }
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;

    svg {
        color: ${({ theme }) => theme.colors.text.dark};
    }
    
    &:hover {
        svg {
            color:${({ theme }) => theme.colors.brand.primary};
        }
    }
    
    background: transparent;
    border: none;
    cursor: pointer;
`;

export const Text = styled.p`
    margin-bottom: 0;
    flex: 1;
    text-decoration: none;

    &:hover{
        color:${({ theme }) => theme.colors.brand.primary};
    }
    
    ${typography};
    ${color};
`;