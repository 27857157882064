import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import BackPage from '../../svg/panel/panelExtract/ic_keyboard_backspace.svg';
import ROUTES from '../../data/routes'
import HistoryDetails from '../../components/history/HistoryDetails';

const PanelHistoryDetails = () => (
  <div className="panel">
    <div className="panel__head mobile-hidden">
      <div className="row">
        <div className="col-8">
          <h3 className="panel__head__title">{I18n.t('panel.historyDetailsPage.pageTitle')}</h3>

          <Link
            to={ROUTES.PANEL_HISTORY}
            className="panel__head__back link-no-style"
          >
            <BackPage className="panel__head__ic_keyboard_backspace" />
            <span className="panel__head__back__text">{I18n.t('panel.historyDetailsPage.backPageText')}</span>
          </Link>

        </div>
      </div>
    </div>

    <div className="panel__container">
      <div className="row m-0">
        <div className="col-lg-8 col-12 p-0">
          <HistoryDetails />
        </div>
      </div>
    </div>

  </div>
);

export default PanelHistoryDetails;
