import React from 'react';
import { toast } from "react-toastify";
import { Translate } from 'react-redux-i18n';
import { addLoading, removeLoading } from './loading';
import BankTransferRequests from '../../server/bank-transfer';

export const ACTION_LIST_BANK_TRANSFER = 'ACTION_LIST_BANK_TRANSFER';
export const ACTION_CLEAN_USER = 'CLEAN_USER';

export const saveList = list => ({
  type: ACTION_LIST_BANK_TRANSFER,
  bankTransferList: list,
});

export const createBankTransfer = (bankTransfer) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let data = await BankTransferRequests.create(bankTransfer);
    dispatch(getAllBankTransfer());
    return data;  
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const getAllBankTransfer = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const list = await BankTransferRequests.getAll();
    dispatch(saveList(list))
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};


export const deleteBankTransfer = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await BankTransferRequests.deleteById(id);
    dispatch(getAllBankTransfer());
  } catch (err) {
    toast.warning(
      <Translate value="toastMessage.validationExclusionError" />, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000
    })
  } finally {
    dispatch(removeLoading());
  }
}
