import React from 'react';
import { Translate } from 'react-redux-i18n';

const AccessDenied = () => (
  <main className="main access-denied">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <div className="access-denied__icon">
            <img
              src="/assets/images/ic_page_not_found.jpg"
              alt="access denied"
            />
          </div>
          <h1 className="access-denied__title">
            <Translate value="accessDenied.title" />
          </h1>
          <p className="access-denied__description">
            <Translate value="accessDenied.description" /><br />
            <Translate value="accessDenied.descriptionTwo" />
          </p>
        </div>
      </div>
    </div>
  </main>
);

export default AccessDenied;
