/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as eva from 'eva-icons';
import { Link } from 'react-router-dom';
import { UserActions, AuthActions } from '../../../app/store/actions';
import Button from '../../elements/Button';
import routes from '../../../data/routes';

const DeleteAccountModal = ({ onCancel }) => {

    useEffect(() => {
        eva.replace();
      }, []);
    

  return (
    <div className="update-modal__deleteModal">
      <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
        <button
          type="button"
          style={{padding: '0px'}}
          className="update-modal__close-button"
          onClick={onCancel}
        >
          <i
            data-eva="close-outline"
          />
        </button>
      </div>

      <header>
        <h5>Deseja encerrar sua conta em Superbid Pay e Superbid Exchange?</h5>
      </header>
      <div className='update-modal__deleteModal__textContainer'>
        <span className='update-modal__deleteModal__text'>
          Ao encerrar sua conta na Superbid Pay, você perderá seu acesso as plataformas Superbid Exchange e Superbid Pay. 
          Lembrando que sua conta de pagamento é <span className='update-modal__deleteModal__highlight'>gratuita</span> e 
          <span className='update-modal__deleteModal__highlight'> não possui taxas de manutenção</span>.
        </span>
        <span className='update-modal__deleteModal__text'>
          Ao continuar sua conta será bloqueada para verificação de pendências e não será possível se habilitar nem dar novos lances.
          Após encerrar sua conta seu histórico de compras ficará indisponível.
        </span>
        <span className='update-modal__deleteModal__text'>
          Para voltar a utilizar nossos serviços será necessário criar uma nova conta.
        </span>
      </div>
      <footer className="update-modal__deleteModal__buttonContainer">
        <Link to={routes.PANEL_DELETE_ACCOUNT}>
          <Button
            style={{textTransform: 'none', width: '213px'}}
          >
            Continuar
          </Button>
        </Link>
        <Button
          variant="secondary"
          onClick={onCancel}
          style={{marginTop: '15px'}}
        >
          Voltar
        </Button>
      </footer>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  changeEmail: (email) => dispatch(UserActions.changeEmail(email)),
  sendCode: (twoFactorAuthenticate) => dispatch(AuthActions.sendTwoFactor(twoFactorAuthenticate))
});

export default connect(
  null,
  mapDispatchToProps,
)(DeleteAccountModal);
