import React, { Fragment } from 'react';
import ChevronRightIcon from '../../../svg/institutional/ic_chevron_right.svg';
import ChevronLeftIcon from '../../../svg/institutional/ic_chevron_left.svg';

const FaqList = ({ topicTitle, topicQuestions, selectQuestion, current, resetQuestion }) => (
  <Fragment>
    <div className="faq__topic">
      <div className="faq__topic__title">
        {current && (
          <ChevronLeftIcon
            className="faq__topic__title__icon"
            onClick={resetQuestion}
          />
        )}
        {topicTitle}
      </div>
      <div className="faq__topics__list">
        {topicQuestions && topicQuestions.length > 0 && (
          topicQuestions.map((item) => (
            <button
              key={item.id}
              type="button"
              className={`faq__topics__list__box
              ${current === item.id ? 'faq__topics__list__box--selected' : ''}`}
              onClick={() => { selectQuestion(item) }}
            >
              <div className="row">
                <div className="col-10">
                  <div className={`faq__topics__list__box__title
                  ${current === item.id ? 'faq__topics__list__title--selected' : ''}`}
                  >
                    {item.title}
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <ChevronRightIcon />
                </div>
              </div>
            </button>
          ))
        )}
      </div>
    </div>
  </Fragment>
)

export default FaqList;
