import React from "react";
import Icon from "../elements/Icon"
import { Button, Link, Text } from "./styles";

const HeaderMenuButton = ({ title, icon, link, onClick, customIconProvider, ...props }) => {
  if (onClick) {
    return (
      <Button
        type="button"
        onClick={onClick}
        {...props}
      >
        <Icon
          provider={customIconProvider}
          icon={icon}
          size={20}
          mr={0}
        />
        <Text
          textAlign="left"
          color="text.dark"
        >
          {title}
        </Text>
      </Button>
    )
  }

  return (
    <Link
      href={link}
      {...props}
    >
      <Icon
        provider={customIconProvider}
        icon={icon}
        size={20}
        mr={0}
      />
      <Text
        textAlign="left"
        color="text.dark"
      >
        {title}
      </Text>
    </Link>
  )
}

export default HeaderMenuButton;