import React from 'react';
import styled from 'styled-components';
import { border, color, flexbox, layout, space } from 'styled-system';

const Component = styled.hr`
 ${space};
 ${color};
 ${border};
 ${layout};
 ${flexbox}
`;

const Divider = ({ children, ...props }) => {
  return (
    <Component
      margin="0px"
      {...props}
    >
      {children}
    </Component>
  )
}

export default Divider;