import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import ROUTES from '../../data/routes';
import IconAddMoney from '../../svg/ic_attach_money_white.svg';
import IconWithdrawMoney from '../../svg/ic_withdraw_money.svg';

const CashOperationButton = () => {
  return (
    <div className="cash-button__wrapper">
      <div className="cash-button__cash-in">
        <Link
          className="link-no-style"
          to={ROUTES.PANEL_ADD_MONEY}
        >
          <button
            type="button"
            className="panel__card__action__button transparent-button with-transition text-left"
          >
            <IconAddMoney />
            <span className="cash-button__text cash-button__text--white">
              {I18n.t('whiteCard.deposit')}<br />
              {I18n.t('whiteCard.money')}
            </span>
          </button>
        </Link>
      </div>
      <div className="cash-button__cash-out">
        <Link
          className="link-no-style"
          to={ROUTES.PANEL_WITHDRAW_MONEY}
        >
          <button
            type="button"
            className="panel__card__action__button transparent-button with-transition text-left"
          >
            <IconWithdrawMoney />
            <span className="cash-button__text pl-2">
              {I18n.t('whiteCard.withdrawal')}<br />
              {I18n.t('whiteCard.money')}
            </span>
          </button>
        </Link>
      </div>
    </div>
  )
}

export default CashOperationButton;
