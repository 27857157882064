/* eslint-disable max-len */
import React from "react"

const LogoSbPay = ({width, height}) => {
    return (
      <svg
        width={width ? width : '143'}
        height={height ? height : '43'}
        viewBox="0 0 143 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M117.463 42.5123C117.269 42.5123 117.122 42.4574 117.018 42.3482C116.915 42.2391 116.863 42.0874 116.863 41.8934V36.4386C116.863 36.2388 116.916 36.0871 117.023 35.9841C117.129 35.8811 117.279 35.8293 117.473 35.8293H119.732C120.456 35.8293 121.015 36.0106 121.409 36.3729C121.803 36.7355 122 37.2421 122 37.8914C122 38.5416 121.803 39.0488 121.409 39.4145C121.015 39.7802 120.456 39.9627 119.732 39.9627H118.063V41.8934C118.063 42.0874 118.013 42.2391 117.913 42.3482C117.813 42.4574 117.663 42.5123 117.463 42.5123M118.063 39.0165H119.544C119.969 39.0165 120.291 38.9209 120.509 38.7306C120.728 38.5398 120.838 38.2605 120.838 37.8914C120.838 37.5229 120.728 37.2452 120.509 37.0574C120.291 36.8699 119.969 36.7761 119.544 36.7761H118.063V39.0165"
          fill="#BE00FF"
        />
        <path
          d="M123.357 42.5123C123.206 42.5123 123.086 42.4783 122.996 42.4093C122.906 42.3405 122.851 42.2483 122.832 42.1328C122.813 42.0168 122.835 41.8876 122.898 41.7436L125.371 36.2606C125.453 36.0793 125.551 35.9496 125.667 35.8718C125.783 35.7935 125.915 35.7547 126.065 35.7547C126.21 35.7547 126.339 35.7935 126.454 35.8718C126.57 35.9496 126.668 36.0793 126.75 36.2606L129.234 41.7436C129.302 41.8876 129.327 42.0186 129.309 42.137C129.29 42.256 129.237 42.3482 129.149 42.4139C129.061 42.4796 128.946 42.5123 128.802 42.5123C128.627 42.5123 128.492 42.47 128.395 42.3859C128.298 42.3017 128.211 42.1722 128.137 41.9971L127.528 40.5812L128.034 40.9096H124.079L124.584 40.5812L123.985 41.9971C123.903 42.1784 123.819 42.3094 123.732 42.3905C123.644 42.4716 123.519 42.5123 123.357 42.5123M126.047 37.132L124.734 40.2538L124.491 39.9535H127.621L127.387 40.2538L126.065 37.132H126.047Z"
          fill="#BE00FF"
        />
        <path
          d="M132.512 42.5123C132.317 42.5123 132.169 42.4574 132.066 42.3482C131.963 42.2391 131.912 42.0843 131.912 41.8842V39.0347L132.165 39.7568L129.906 36.5795C129.825 36.4669 129.788 36.3467 129.798 36.2187C129.807 36.0908 129.859 35.981 129.953 35.8904C130.047 35.7997 130.178 35.7547 130.346 35.7547C130.471 35.7547 130.585 35.7856 130.688 35.8481C130.792 35.911 130.89 36.0075 130.983 36.1385L132.662 38.5194H132.399L134.086 36.1385C134.186 36.0017 134.283 35.903 134.377 35.8435C134.47 35.784 134.586 35.7547 134.723 35.7547C134.886 35.7547 135.012 35.7966 135.103 35.8811C135.194 35.9653 135.24 36.0698 135.243 36.1947C135.247 36.3202 135.202 36.4512 135.108 36.5887L132.868 39.7568L133.111 39.0347V41.8842C133.111 42.3026 132.911 42.5123 132.512 42.5123"
          fill="#BE00FF"
        />
        <path
          d="M5.88284 -3.0914e-06C2.63371 -3.0914e-06 0 2.63402 0 5.88315V24.2923C0 27.5421 2.63371 30.1758 5.88284 30.1758H137.116C140.366 30.1758 143 27.5421 143 24.2923V5.88315C143 2.63402 140.366 -3.0914e-06 137.116 -3.0914e-06H5.88284Z"
          fill="#BE00FF"
        />
        <path
          d="M106.37 13.6678H98.354V11.1009H106.37C107.078 11.1009 107.654 11.6766 107.654 12.3845C107.654 13.0918 107.078 13.6678 106.37 13.6678M106.37 19.0489H98.354V16.4828H106.37C107.078 16.4828 107.654 17.0581 107.654 17.7657C107.654 18.4736 107.078 19.0489 106.37 19.0489ZM110.448 12.0562C110.448 9.98973 108.766 8.30872 106.699 8.30872H96.5836C96.0192 8.30872 95.5598 8.76755 95.5598 9.33144V20.8184C95.5598 21.383 96.0192 21.8414 96.5836 21.8414H106.699C108.766 21.8414 110.448 20.1602 110.448 18.0938C110.448 16.8891 109.88 15.7807 108.914 15.075C109.88 14.3692 110.448 13.2614 110.448 12.0562Z"
          fill="white"
        />
        <path
          d="M90.2769 13.1584C90.2769 14.1231 89.4916 14.9082 88.5262 14.9082H80.9764V11.1009H88.5262C89.4916 11.1009 90.2769 11.8859 90.2769 12.8507V13.1584ZM88.6944 8.30872H79.206C78.6416 8.30872 78.1823 8.76755 78.1823 9.33175V20.7956C78.1823 21.3599 78.6416 21.8186 79.206 21.8186H79.9527C80.5171 21.8186 80.9764 21.3599 80.9764 20.7956V17.7006H85.8876L89.9139 21.2948C90.3085 21.6472 90.8176 21.8414 91.3465 21.8414H92.045C92.4728 21.8414 92.8485 21.5831 93.0009 21.1829C93.154 20.783 93.0459 20.3406 92.727 20.0551L89.8989 17.5304C91.7569 17.0001 93.0703 15.2893 93.0703 13.3258V12.6833C93.0703 10.271 91.1065 8.30872 88.6944 8.30872Z"
          fill="white"
        />
        <path
          d="M131.842 15.9154C131.842 17.631 130.447 19.0267 128.731 19.0267H122.542V11.1009H128.731C130.447 11.1009 131.842 12.4964 131.842 14.2116V15.9154ZM128.743 8.30872H120.772C120.208 8.30872 119.748 8.76755 119.748 9.33175V20.7956C119.748 21.3599 120.208 21.8186 120.772 21.8186H128.743C131.992 21.8186 134.636 19.1763 134.636 15.9277V14.1993C134.636 10.9511 131.992 8.30872 128.743 8.30872Z"
          fill="white"
        />
        <path
          d="M115.471 8.30841H114.724C114.16 8.30841 113.701 8.76723 113.701 9.33144V20.7956C113.701 21.3596 114.16 21.8186 114.724 21.8186H115.471C116.035 21.8186 116.495 21.3596 116.495 20.7956V9.33144C116.495 8.76723 116.035 8.30841 115.471 8.30841"
          fill="white"
        />
        <path
          d="M74.5917 8.30841H61.7511C61.1867 8.30841 60.7279 8.76723 60.7279 9.33144V20.7956C60.7279 21.3596 61.1867 21.8186 61.7511 21.8186H74.5917C75.156 21.8186 75.6154 21.3596 75.6154 20.7956V20.0491C75.6154 19.4853 75.156 19.0264 74.5917 19.0264H63.5215V16.46H71.3752C71.9388 16.46 72.3982 16.0008 72.3982 15.4369V14.6905C72.3982 14.1265 71.9388 13.6678 71.3752 13.6678H63.5215V11.1009H74.5917C75.156 11.1009 75.6154 10.6422 75.6154 10.0779V9.33144C75.6154 8.76723 75.156 8.30841 74.5917 8.30841"
          fill="white"
        />
        <path
          d="M55.367 13.1239C55.367 14.0893 54.582 14.8739 53.617 14.8739H46.0664V11.1009H53.617C54.582 11.1009 55.367 11.8859 55.367 12.8507V13.1239ZM53.7847 8.3084H44.2963C43.7323 8.3084 43.2732 8.76755 43.2732 9.33143V20.7959C43.2732 21.3599 43.7323 21.8189 44.2963 21.8189H45.043C45.6072 21.8189 46.0664 21.3599 46.0664 20.7959V17.7004H53.7847C56.197 17.7004 58.1601 15.7381 58.1601 13.3258V12.6833C58.1601 10.271 56.197 8.3084 53.7847 8.3084"
          fill="white"
        />
        <path
          d="M39.6828 8.30841H38.936C38.3718 8.30841 37.9124 8.76723 37.9124 9.33144V15.9154C37.9124 17.6307 36.5169 19.0264 34.8007 19.0264H31.6901C29.9927 19.0264 28.6114 17.6458 28.6114 15.9487V9.33144C28.6114 8.76723 28.1523 8.30841 27.5877 8.30841H26.8416C26.2777 8.30841 25.8186 8.76723 25.8186 9.33144V15.9277C25.8186 19.176 28.4619 21.8186 31.711 21.8186H34.8134C38.0622 21.8186 40.7062 19.176 40.7062 15.9277V9.33144C40.7062 8.76723 40.2467 8.30841 39.6828 8.30841Z"
          fill="white"
        />
        <path
          d="M22.2278 8.30841H11.4755C9.75971 8.30841 8.36397 9.70423 8.36397 11.4194V13.349C8.36397 15.0645 9.75971 16.46 11.4755 16.46H19.4856C20.0218 16.46 20.4577 16.8959 20.4577 17.4324V18.0543C20.4577 18.5902 20.0218 19.0264 19.4856 19.0264H9.38724C8.82272 19.0264 8.36397 19.4853 8.36397 20.0491V20.7956C8.36397 21.3596 8.82272 21.8186 9.38724 21.8186H20.1392C21.8553 21.8186 23.2508 20.4229 23.2508 18.7079V16.7781C23.2508 15.0632 21.8553 13.6678 20.1392 13.6678H12.1296C11.5931 13.6678 11.157 13.2318 11.157 12.6953V12.0731C11.157 11.5372 11.5931 11.1009 12.1296 11.1009H22.2278C22.7923 11.1009 23.2508 10.6422 23.2508 10.0779V9.33144C23.2508 8.76723 22.7923 8.30841 22.2278 8.30841"
          fill="white"
        />
        <path
          d="M15.8187 6.93547C16.5886 6.93547 17.2126 6.31112 17.2126 5.54178C17.2126 4.77196 16.5886 4.14816 15.8187 4.14816C15.0491 4.14816 14.4244 4.77196 14.4244 5.54178C14.4244 6.31112 15.0491 6.93547 15.8187 6.93547Z"
          fill="white"
        />
        <path
          d="M15.8187 23.208C15.0491 23.208 14.4244 23.8317 14.4244 24.6013C14.4244 25.3709 15.0491 25.9949 15.8187 25.9949C16.5886 25.9949 17.2126 25.3709 17.2126 24.6013C17.2126 23.8317 16.5886 23.208 15.8187 23.208Z"
          fill="white"
        />
        <title>Logo Superbid Pay</title>
      </svg>

    )
}


export default LogoSbPay