import { UserActions } from "../actions";

export default (state = null, action) => {

  switch (action.type) {
    case UserActions.ACTION_SAVE_USER:
      return {
        ...state,
        ...action.user,
      };
    case UserActions.ACTION_UPDATE_ADDRESS:
      return {
        ...state,
        ...action.user,
        address: action.address
      };
    case UserActions.ACTION_UPDATE_PHONE:
      return {
        ...state,
        ...action.user,
      };
    case UserActions.ACTION_UPDATE_EMAIL:
      return {
        ...state,
        ...action.user,
      };
    case UserActions.ACTION_UPDATE_DOCUMENT_USER:
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
};

export function getUser(state) {
  return state.user;
}
