import 'normalize.css/normalize.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components'

import AppRouter from './routers/AppRouter';
import configureStore from './app/store/configureStore';
import initialization from './app/initialization';

import theme from './styles/theme';

import './styles/styles.scss';

const store = configureStore();

const jsx = (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  </Provider>
);

initialization(store);

ReactDOM.render(jsx, document.getElementById('app'));
