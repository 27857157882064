import RestService from '../services/rest';

async function getAll() {
  return RestService.getAuthenticated('bank-transfer');
}


async function getById(id) {
  return RestService.getAuthenticated(`bank-transfer/${id}`);
}

async function create(bankTransfer) {
  return RestService.postAuthenticated('bank-transfer', bankTransfer);
}

async function deleteById(id) {
  return RestService.deleteAuthenticated(`bank-transfer/${id}`);
}

export default {
  create,
  getAll,
  getById,
  deleteById,
};
