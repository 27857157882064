import React from 'react';
import Modal from 'react-modal';
import IcClose from '../icons/IcClose';
import IconError from '../../../public/assets/images/error.svg';
import Divider from '../elements/Divider';
import routes from '../../data/routes';

const ScheduledValidationModal = ({ isOpen, onClose, handleCashout, history }) => {

  const handleConfirmCashout = () => {
      onClose();
      handleCashout();
  }

  Modal.setAppElement('#app');


 
  const customStyles = {
    overlay: {
      background: 'rgb(40, 51, 74, 0.5)',
    },

    content: {
      overflow: 'hidden',
      top: '0',
        left: '0',
      right: '0',
      bottom: '0',
      padding: '0',
      margin: 'auto',
      height: '350px',
      width: '436px',
      background: '#fff',
      border: 'none',
      position: 'absolute',
      textAlign: 'start',
      zIndex: '100'
    },
    months: {
      fontWeight: 'bold',
    }
  }
    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          style={customStyles}
          onRequestClose={onClose}
        >
          <div className='credit__modal'>
            <IcClose
              style={{position: 'absolute', top: '32px', right: '24px', cursor: 'pointer'}}
              onClick={onClose}
            />
            <IconError style={{alignSelf: 'start'}} />
            <div>
              <span
                className='credit__item-title'
                style={{fontWeight: 'bold', fontSize: '20px'}}
              >Transação excede o saldo atual
              </span>
              <Divider 
                mt="4px"
                width="50px"
                border="1px solid"
                borderColor="#DAA1FF"
              />
            </div>
            <span
              className='credit__medium-text'
              style={{color: '#313234', fontSize: '14px'}}
            >Já existe um agendamento pendente e ao realizar esta nova transação seu saldo poderá 
              ser insuficiente e a operação cancelada.
              <br />
              <br />
              Gostaria de agendar esta transação?
            </span>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0px 20px'}}>
              <button
                type='submit'
                className='credit__cancel-button'
                onClick={() => history.push(routes.PANEL_START)}
              >Cancelar
              </button>
              <button 
                className='credit__purpleButton' 
                type="submit"
                onClick={handleConfirmCashout}
              >Agendar
              </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
  )
}

export default ScheduledValidationModal;
