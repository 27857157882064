import React, { useCallback, useMemo } from "react";
import { getBrandImageUrl } from "../../helpers/card";

const FlipedCard = ({ number, holder, expiration, cvv, cardIsFlipped }) => {
  const brand = useMemo(() => getBrandImageUrl(number), [number])

  const useMask = useCallback((mask, value, maskChar = "*") => {
    let finalValue = mask;

    if (value) {
      for (let index = 0; index < value.length; index++) {
        if (mask[index] === maskChar) {
          finalValue = finalValue.replace(finalValue[index], value[index]);
        }
      }

    }

    return finalValue;
  }, [])

  return (
    <div
      className={`flipped-card ${cardIsFlipped && "flipped-card__flip"}`}
    >
      <div className="flipped-card__front">
        {brand ? (
          <img
            src={brand}
            alt="card-logo"
          />
        ) : <div className="flipped-card__front__empty" />}

        <div className="flipped-card__front__content">
          <p className="flipped-card__front__content__number">
            {useMask("**** **** **** ****", number)}
          </p>
          <div className="flipped-card__front__content__data">
            <p>{holder.toLocaleUpperCase() || "NOME"}</p>
            <p>{useMask("**/**", expiration)}</p>
          </div>
        </div>
      </div>

      <div className="flipped-card__back">
        <hr />
        <p>{useMask("***", cvv)}</p>
      </div>
    </div>

  )
}

export default FlipedCard;