import * as React from "react"

const IcChevronFoward = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8 18.351 1.203 1.296L16 12.324 9.203 5 8 6.296l5.594 6.028L8 18.35Z"
      fill="currentColor"
    />
  </svg>
)

export default IcChevronFoward
