export default {
  HOME: '/',
  LOGIN: '/login',
  CONFIRM_IDENTITY: '/confirmar-identidade',
  PAGE_NOT_FOUND: '/erro/404',
  ACCESS_DENIED: '/erro/403',

  EXTERNAL_AUTH: '/external-auth',

  PUBLIC_EXTRACT: '/extrato-publico/',

  PANEL_START: '/painel',
  PANEL_HISTORY: '/painel/historico',
  PANEL_EXTRACT: '/painel/extrato',
  PANEL_EXTRACT_CSV: '/painel/extrato-csv',
  PANEL_WALLET: '/painel/carteira',
  PANEL_ADD_MONEY: '/painel/adicionar-dinheiro',
  PANEL_ADD_MONEY_CHOOSE_BANKS: '/painel/adicionar-dinheiro/bancos',
  PANEL_WITHDRAW_MONEY: '/painel/retirar-dinheiro',
  PANEL_SETTINGS: '/painel/configuracoes',
  PANEL_DELETE_ACCOUNT: '/painel/configuracoes/encerrar-conta',
  PANEL_FAQ: '/painel/ajuda',
  PANEL_CREDIT: '/painel/credito',
  PANEL_CREDIT_APPROVED: '/painel/credito/oferta',
  PANEL_INFORM_INCOME: '/panel/informe-rendimento',

  PANEL_FAQ_EMAIL: '/painel/ajuda/email',
  PANEL_FAQ_OUVIDORIA: '/painel/ajuda/ouvidoria',

  PANEL_ADD_MONEY_BILLET: '/painel/adicionar-dinheiro/boleto',

  PANEL_ADD_MONEY_TRANSFER: '/painel/adicionar-dinheiro/transferencia/',
  PANEL_TED: '/painel/adicionar-dinheiro/transferencia/',

  PANEL_EXTRACT_DETAILS: '/painel/extrato/:id',
  PANEL_HISTORY_DETAILS: '/painel/historico/:id',

  PANEL_ABOUT: '/painel/sobre',
  PANEL_TERMS: '/painel/termos',

  HELP: '/painel/ajuda',

  ABOUT_US: '/sobre',
  TERMS: '/termos',
  MAP: '/mapa',
  FAQ: '/ajuda',
  OMBUDSMAN: 'https://www.superbid.net/ouvidoria',
  HELP_SUPERBID: 'https://www.superbid.net/ajuda',
  REPORT_OMBUDSMAN: '/relatorio-ouvidoria',

  // eslint-disable-next-line max-len
  CREATE_ACCOUNT: 'https://accounts.superbid.net/signup/?response_type=token&redirect_uri=https%3A%2F%2Fwww.superbid.net%2F&authorization_uri=https://www.superbid.net/authorization/?authorization_login=userdata&client_id=dzqC3VodSoXukD45BQKg3NQU6-faststore&language=pt-BR&portal_id=2',
  // eslint-disable-next-line max-len
  FORGOT_PASSWORD: 'https://accounts.superbid.net/forgot-password/?response_type=token&redirect_uri=https%3A%2F%2Fwww.superbid.net%2F&authorization_uri=https://www.superbid.net/authorization/?authorization_login=userdata&client_id=dzqC3VodSoXukD45BQKg3NQU6-faststore&language=pt-BR&portal_id=2',
  SUPERBID_MARKETPLACE: 'https://www.superbid.net/',
};
