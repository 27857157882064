import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../data/routes';
import ArrowBackIcon from '../../svg/institutional/ic_arrow_back.svg';

const SupportHeader = ({ title }) => {
  return (
    <Fragment>
      <div className="support-header">
        <div className="support-header__title">
          {title || "como podemos ajudar?"}
        </div>
        <div className="support-header__link">
          <Link
            to={ROUTES.PANEL_FAQ}
            className="support-header__link--no-underline"
          >
            <ArrowBackIcon />
            Voltar
          </Link>
        </div>
      </div>
    </Fragment>
  )
}

export default SupportHeader;
