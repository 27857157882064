/* eslint-disable max-len */
import * as React from "react"

const IcCoin = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={16}
      cy={16}
      r={14.658}
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M17.79 24.05h-2.737a4.11 4.11 0 0 1-4.106-4.104v-1.368h2.738v1.368a1.37 1.37 0 0 0 1.367 1.368h2.738a1.37 1.37 0 0 0 1.367-1.368v-.765a1.363 1.363 0 0 0-.936-1.304l-4.467-1.49a4.096 4.096 0 0 1-2.807-3.888v-.765a4.11 4.11 0 0 1 4.106-4.105h2.737a4.11 4.11 0 0 1 4.105 4.105v1.368h-2.738v-1.368a1.369 1.369 0 0 0-1.367-1.368h-2.737a1.37 1.37 0 0 0-1.368 1.368v.765a1.363 1.363 0 0 0 .936 1.304l4.467 1.49a4.096 4.096 0 0 1 2.807 3.893v.76a4.112 4.112 0 0 1-4.105 4.105Z"
      fill="currentColor"
    />
    <path
      d="M17.523 5.895h-1.449v2.898h1.45V5.895ZM17.523 23.836h-1.449v1.5h1.45v-1.5Z"
      fill="currentColor"
    />
  </svg>
)

export default IcCoin
