import React, { Fragment } from 'react';
import { TranslateDescription } from '../../helpers/descriptionTranslate';
import MoneyValue from '../moneyValue/MoneyValue';

const OrderItem = ({ description, price }) => (
  <Fragment>
    <div className="order__item">
      <div className="order__item__description">
        <div className="order__item__description__name">
          {TranslateDescription(description) || description}
        </div>
      </div>
      <div className="order__item__price">
        <MoneyValue value={price} />
      </div>
    </div>
  </Fragment>
)

export default OrderItem;
