import { CreditCardActions } from "../actions";

const defaultState = {
  creditCardList: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CreditCardActions.ACTION_LIST_CREDIT_CARD:
      return {
        ...state,
        creditCardList: action.creditCardList
      };
    default:
      return state;
  }
};

export function getCreditCards(state) {
  return state.creditCard.creditCardList;
}
