import React from 'react';
import Close from '../../svg/ic_close.svg';
import HeaderLeft from '../header/HeaderLeft';
import MobileNavigation from './MobileNavigation';
import ExitIcon from '../../svg/sidebar/ic_exit.svg';

const MobileSidebar = ({ closeSidebar }) => {

  const logout = () => {
    localStorage.removeItem('auth');
    location.reload();
  }

  return (
    <React.Fragment>
      <div className="mobile__sidebar__wrapper">
        <div className="mobile__sidebar__user">
          <HeaderLeft closeSidebar={closeSidebar} />
          <ExitIcon
            className="mobile__sidebar__icon"
            onClick={logout}
          />
        </div>
        <div className="mobile__sidebar__menu">
          <MobileNavigation closeSidebar={closeSidebar} />
        </div>
      </div>
      <div className="mobile__sidebar__overlay">
        <Close onClick={closeSidebar} />
      </div>
    </React.Fragment>
  )
}

export default MobileSidebar;
