
import { addLoading, removeLoading } from './loading';
import CreditRequests from "../../server/credit-data";

export const ACTION_LIST_CREDIT_OFFER = 'ACTION_LIST_CREDIT_OFFER';
export const ACTION_SET_CREDIT_OFFER_PROPOSAL = 'ACTION_SET_CREDIT_OFFER_PROPOSAL';

export const saveList = list => ({
  type: ACTION_LIST_CREDIT_OFFER,
  creditOfferlist: list,
});

export const setOfferProposal = creditOfferProposal => ({
  type: ACTION_SET_CREDIT_OFFER_PROPOSAL,
  creditOfferProposal,
})

export const getAllCreditOffer = (id, filter) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const list = await CreditRequests.listCreditOffers(id, filter);

    dispatch(saveList(list))
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

export const getCreditOfferProposalByLoanId = (id) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const creditOfferProposal = await CreditRequests.getCreditOfferProposalByLoanId(id);

    dispatch(setOfferProposal(creditOfferProposal));
    dispatch(removeLoading());
  } catch (error) {
    dispatch(removeLoading());
  }
}


export const cancelCreditOffer = (id,text) => async (dispatch) => {
  try {
    dispatch(addLoading());
    await CreditRequests.cancelCreditOffer(id,text);
    await dispatch(getAllCreditOffer());
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};