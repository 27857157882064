import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import Wallet from '../../../svg/institutional/ic_wallet.svg';
import WalletSecurity from '../../../svg/institutional/ic_wallet_security.svg';
import Cellphone from '../../../svg/institutional/ic_cellphone.svg';


const AdvantagesSection = () => {
  return (
    <Fragment>
      <div className="institutional__section">
        <div className="institutional__section__title">
          {I18n.t('institucional.advantagesSection.benefits')}
        </div>
        <div className="advantages__section">
          <div className="advantages__section__image mobile-hidden">
            <img
              alt=""
              src="/assets/images/mockup_iphone.png"
              className="advantages__section__image__single"
            />
          </div>
          <div className="advantages__section__list">
            <div className="advantages__section__list__single">
              <div>
                <Wallet />
              </div>
              <div className="advantages__section__list__single__content">
                <div className="advantages__section__list__single__content__description">
                  {I18n.t('institucional.advantagesSection.contentWallet')}
                </div>
                {/* <hr
                  className="advantages__section__list__single__content__link"
                /> */}
              </div>
            </div>
            <div className="advantages__section__list__single">
              <div>
                <Cellphone />
              </div>
              <div className="advantages__section__list__single__content">
                <div className="advantages__section__list__single__content__description">
                  {I18n.t('institucional.advantagesSection.contentCellphone')}
                </div>
                {/* <hr
                  className="advantages__section__list__single__content__link"
                /> */}
              </div>
            </div>
            <div className="advantages__section__list__single">
              <div>
                <WalletSecurity />
              </div>
              <div className="advantages__section__list__single__content">
                <div className="advantages__section__list__single__content__description">
                  {I18n.t('institucional.advantagesSection.contentWalletSecurity')}
                </div>
                {/* <hr
                  className="advantages__section__list__single__content__link"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AdvantagesSection;
