import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from '../components/header/Header';
import { AuthSelectors } from '../app/store/reducers';
import MobileSidebarWrapper from '../components/mobile/MobileSidebarWrapper';

export const PrivateRoute = ({
  isStarted,
  isAuthenticated,
  component: Component,
  noHeader,
  title,
  ...rest
}) => {
  return (
    <React.Fragment>

      <div className="mobile-hidden">
        {!noHeader && <Header />}
      </div>

      {!noHeader && <MobileSidebarWrapper />}
      
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Route
        {...rest}
        component={props => (
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        )}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: AuthSelectors.getIsAuthenticated(state),
});

export default connect(mapStateToProps)(PrivateRoute);
