import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { AccountActions } from "../app/store/actions";

class ExtractInCSV extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectUrl: '',
    }
  }

  componentDidMount = async () => {
    try {
      const { location, extractFinanceCSV } = this.props;
      let params = queryString.parse(location.search);
      await extractFinanceCSV(params.startAt, params.endAt);
      let redirectUrl = '/painel';
      this.setState({ redirectUrl });
    } catch (err) {
      this.setState({ redirectUrl: '/painel' });
    }
  }

   returnUrl = () => {
    if (this.state.redirectUrl) {
      return <Redirect to={this.state.redirectUrl} />;
    }
  }

  render() {
    return (
      <div>
        {this.returnUrl()}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  extractFinanceCSV: (startAt, endAt) => dispatch(AccountActions.extractFinanceCSV(startAt, endAt)),
});

export default connect(null, mapDispatchToProps)(withRouter(ExtractInCSV));
