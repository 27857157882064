import moment from 'moment';

export const dateFromNow = (date) => {
  if (moment(date).isSame(moment(), 'day')) return moment(date).fromNow();
  return (moment(date).format('L'));
}

export const formatDate = (date, format) =>
  (format ? moment(date).format(`${format}`) : moment(date).format('DD/MM/YYYY'));

export const dateWithMonth = (date) => (moment(date).format('DD / MMM / YYYY').toUpperCase());
