import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import thunk from 'redux-thunk';

import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import loadingReducer from './reducers/loading';
import cashInReducer from './reducers/cashin';
import utilsReducer from './reducers/utils';
import bankTransferReducer from './reducers/bank-transfer';
import connectedDevicesReducer from './reducers/connected-devices';
import accountReducer from './reducers/account';
import cashOutReducer from './reducers/cash-out';
import paymentReducer from './reducers/paymentOrder';
import creditCardReducer from './reducers/credit-card';
import settingsReducer from './reducers/settings';
import creditOfferReducer from './reducers/credit-data';
import informIncomeReducer from './reducers/inform-income';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      user: userReducer,
      i18n: i18nReducer,
      loading: loadingReducer,
      account: accountReducer,
      cashIn: cashInReducer,
      utils: utilsReducer,
      bankTransfer: bankTransferReducer,
      connectedDevices: connectedDevicesReducer,
      cashOut: cashOutReducer,
      payment: paymentReducer,
      creditCard: creditCardReducer,
      settings: settingsReducer,
      creditOffer: creditOfferReducer,
      informIncome: informIncomeReducer
    }),
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};
