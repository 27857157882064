import React, { useEffect, useMemo } from 'react';
import { osName, isMobile, mobileVendor } from 'react-device-detect';
import { I18n } from 'react-redux-i18n';

import * as eva from 'eva-icons';

const DeviceItemCard = ({ id, computerName, browser, operationSystem, onRemove }) => {
  useEffect(() => {
    eva.replace();
  }, [])

  const computer = useMemo(() => {
    if (isMobile) {
      return mobileVendor
    }
    return osName
  }, []);

  return (
    <div className="device-item">
      <div className="device-item__header">
        <h6>{isMobile ? 'Celular' : 'Computador'}</h6>
        {computer !== computerName && (
          <i
            title="Excluir dispositivo"
            aria-hidden
            data-eva="trash-outline"
            onClick={() => { onRemove(id) }}
          />
        )}
      </div>

      <hr />

      <div className="device-item__content">
        <p><b>{I18n.t('configuration.devicesList.nameComputer')}: </b>{computerName}</p>
        <p><b>{I18n.t('configuration.devicesList.browser')}: </b>{browser}</p>
        <p><b>{I18n.t('configuration.devicesList.operationSystem')}: </b>{operationSystem}</p>
      </div>
    </div>
  )
}

export default DeviceItemCard;