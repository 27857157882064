import React, { useCallback } from "react";
import { I18n } from 'react-redux-i18n'
import IcChevronFoward from "../icons/IcChevronFoward";

const EmptyAccount = ({ onClickAdd }) => {
  const translate = useCallback((path) => {
    return I18n.t(`emptyAccount.${path}`)
  }, []);

  return (
    <div className="empty-state">
      <img
        src="/assets/images/pig.svg"
        alt="pig-coin"
      />
      <div className="empty-state__content">
        <h3>{translate("title")}</h3>
        <p>{translate("description")}</p>
        {onClickAdd && (
          <button
            type="button"
            onClick={onClickAdd}
          >
            {translate("addButton")}
            <IcChevronFoward />
          </button>
        )}
      </div>
    </div>
  )
}


export default EmptyAccount;