import dayjs from 'dayjs';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import ROUTES from '../../data/routes';
import InstitutionalFooterLinks from './InstitutionalFooterLinks';

const InstitutionalFooter = () => {
  return (
    <React.Fragment>
      <div className="institutional-footer">
        <div className="institutional-footer__create">
          <div className="institutional-footer__create__text">
            {I18n.t('institucional.footer.content')}
          </div>
          <a
            className="institutional-footer__create__button"
            href={ROUTES.CREATE_ACCOUNT}
          >
            {I18n.t('institucional.footer.signUp')}
          </a>
        </div>
        <div className="institutional-footer__ombudsman">
            <p className="institutional-footer__ombudsman__title">{I18n.t('institucional.footer.ombudsman')}</p>
            <p className="institutional-footer__ombudsman__subtitle">{I18n.t('institucional.footer.disclaimerOmbudsman')}</p>
        </div>
        <div className="institutional-footer__navigation">
          <InstitutionalFooterLinks />
          <div className="institutional-footer__copyright">
            <div>Copyright &copy; Superbid Pay {dayjs().format('YYYY')}</div>
            <div>
              {I18n.t('institucional.footer.rightsReserved')}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InstitutionalFooter;
