export const TermsAndPoliticsEnum = {
  TERMS_AND_POLITICS: 1,
  SECURITY_POLICY: 2,
  TERMS_OF_USE: 3,
  PRIVACY_POLICY: 4,
};

export const TermsAndPoliticsMap = new Map([
  [TermsAndPoliticsEnum.TERMS_OF_USE, 'Termos de Uso'],
  // [TermsAndPoliticsEnum.TERMS_AND_POLITICS, 'Termos & Políticas'],
  // [TermsAndPoliticsEnum.SECURITY_POLICY, 'Política de Segurança'],
  [TermsAndPoliticsEnum.PRIVACY_POLICY, 'Política de Privacidade'],
]);
