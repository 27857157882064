import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import CashInTed from '../../components/cashIn/CashInTed';
import { CashInActions } from '../../app/store/actions';
import { CashInSelectors } from '../../app/store/reducers';

const PanelCashInTed = ({ getCashInTed, cashInTed }) => {
  useEffect(() => {
    const tedId = location.pathname[location.pathname.length - 1];

    getCashInTed(tedId)
  }, [])

  return (
    <div className="content-container">
      <div className="content-container__view">
        <div className="mobile-hidden">
          <h3 className="content-container__head__title">
            <b>
              {I18n.t('cashIn.transfer.title')}
            </b>
          </h3>
          <h6 className="content-container__head__description">
            {I18n.t('cashIn.transfer.titleOne')}
          </h6>
        </div>

        <CashInTed
          bank={cashInTed && cashInTed.bank}
          agency={cashInTed && cashInTed.branch}
          account={cashInTed && cashInTed.account}
          type={cashInTed && cashInTed.accountType}
          name={cashInTed && cashInTed.accountName}
          document={cashInTed && cashInTed.accountCnpj}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  cashInTed: CashInSelectors.getDataTed(state)
});

const mapDispatchToProps = dispatch => ({
  getCashInTed: (tedId) => dispatch(CashInActions.getCashInTed(tedId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelCashInTed);
