/* eslint-disable max-len */
import React from "react"

const IcWalletColored = ({ size = 24, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.65612 9.93761H3.94241C3.80109 9.94009 3.66072 9.91408 3.52967 9.86114C3.39862 9.8082 3.27957 9.72942 3.17963 9.62947C3.07969 9.52953 3.0009 9.41049 2.94796 9.27944C2.89502 9.14839 2.86902 9.00802 2.8715 8.8667V19.5725C2.8692 19.7137 2.89533 19.8539 2.94835 19.9848C3.00137 20.1157 3.08019 20.2346 3.18011 20.3344C3.28004 20.4342 3.39902 20.5129 3.52998 20.5658C3.66094 20.6186 3.8012 20.6446 3.94241 20.6421H4.65612V9.93761Z"
        fill="#CD72FF"
      />
      <path
        d="M16.9065 3H16.2523V4.96257H16.9065V3Z"
        fill="#7800A1"
      />
      <path
        d="M18.7588 3.73245L17.8302 5.34082L18.4483 5.69768L19.3769 4.08931L18.7588 3.73245Z"
        fill="#7800A1"
      />
      <path
        d="M20.6167 5.32992L19.0083 6.25854L19.3652 6.87663L20.9735 5.94801L20.6167 5.32992Z"
        fill="#7800A1"
      />
      <path
        d="M21.4857 7.57935H20.1774V8.23353H21.4857V7.57935Z"
        fill="#7800A1"
      />
      <path
        d="M2.87152 8.86746C2.86886 9.00871 2.8947 9.14905 2.9475 9.28009C3.00029 9.41114 3.07896 9.5302 3.1788 9.63016C3.27864 9.73012 3.3976 9.80893 3.52858 9.86189C3.65956 9.91484 3.79987 9.94085 3.94112 9.93837H17.5031V7.79655H3.94112C3.79987 7.79406 3.65956 7.82007 3.52858 7.87303C3.3976 7.92598 3.27864 8.00479 3.1788 8.10475C3.07896 8.20471 3.00029 8.32377 2.9475 8.45482C2.8947 8.58586 2.86886 8.7262 2.87152 8.86746Z"
        fill="#CD72FF"
      />
      <path
        d="M17.8596 9.93763H17.1458V8.15301H3.94038C3.84615 8.15095 3.75248 8.16801 3.66503 8.20316C3.57758 8.23832 3.49816 8.29084 3.43158 8.35754C3.36499 8.42425 3.31262 8.50377 3.27762 8.59128C3.24263 8.6788 3.22574 8.7725 3.22797 8.86672H2.51425C2.51215 8.6788 2.54759 8.49234 2.6185 8.31829C2.68941 8.14425 2.79436 7.98611 2.92719 7.85316C3.06002 7.7202 3.21806 7.61511 3.39205 7.54404C3.56603 7.47297 3.75245 7.43736 3.94038 7.43928H17.8596V9.93763Z"
        fill="#7800A1"
      />
      <path
        d="M15.7499 9.93889L10.7244 4.91211L5.67798 9.93889"
        fill="#CD72FF"
      />
      <path
        d="M5.92987 10.1902L5.42615 9.68449L10.7251 4.40649L16.0024 9.68514L15.498 10.1895L10.7225 5.41395L5.92987 10.1902Z"
        fill="#7800A1"
      />
      <path
        d="M13.2201 9.93704L11.4819 8.1897C11.3938 8.30723 11.2795 8.40263 11.1482 8.46834C11.0168 8.53405 10.8719 8.56825 10.725 8.56825C10.5781 8.56825 10.4333 8.53405 10.3019 8.46834C10.1705 8.40263 10.0562 8.30723 9.96812 8.1897L8.22144 9.93638"
        fill="#CD72FF"
      />
      <path
        d="M8.47398 10.1896L7.9696 9.68524L9.96815 7.68604L10.2207 7.93855C10.2756 8.02223 10.3505 8.09093 10.4386 8.13848C10.5267 8.18602 10.6253 8.21091 10.7254 8.21091C10.8255 8.21091 10.924 8.18602 11.0121 8.13848C11.1002 8.09093 11.1751 8.02223 11.2301 7.93855L11.4832 7.68604L13.4739 9.6872L12.9676 10.1903L11.463 8.67517C11.252 8.83734 10.9934 8.92526 10.7273 8.92526C10.4613 8.92526 10.2026 8.83734 9.9917 8.67517L8.47398 10.1896Z"
        fill="#7800A1"
      />
      <path
        d="M19.6442 20.9999H3.94364C3.75546 21.0022 3.56873 20.9669 3.39441 20.896C3.22009 20.8251 3.06169 20.72 2.92852 20.587C2.79536 20.4541 2.69012 20.2958 2.61897 20.1216C2.54783 19.9474 2.51222 19.7607 2.51424 19.5725V8.8667H3.22796C3.22582 8.96101 3.24281 9.05478 3.27792 9.14235C3.31303 9.22991 3.36552 9.30945 3.43223 9.37616C3.49894 9.44286 3.57847 9.49536 3.66603 9.53047C3.7536 9.56557 3.84737 9.58257 3.94168 9.58042H19.6422L19.6442 20.9999ZM3.22796 10.1097V19.5731C3.22582 19.6675 3.24281 19.7612 3.27792 19.8488C3.31303 19.9364 3.36552 20.0159 3.43223 20.0826C3.49894 20.1493 3.57847 20.2018 3.66603 20.2369C3.7536 20.272 3.84737 20.289 3.94168 20.2869H18.9298V10.2941H3.94168C3.69182 10.2954 3.4459 10.2319 3.22796 10.1097Z"
        fill="#7800A1"
      />
      <path
        d="M19.6443 17.0748H14.4696C13.9491 17.0748 13.45 16.868 13.0819 16.5C12.7138 16.1319 12.5071 15.6327 12.5071 15.1122C12.5071 14.5917 12.7138 14.0925 13.0819 13.7245C13.45 13.3564 13.9491 13.1497 14.4696 13.1497H19.6443C19.8335 13.1498 20.0148 13.2251 20.1485 13.3589C20.2822 13.4928 20.3573 13.6742 20.3573 13.8634V16.3617C20.3572 16.5508 20.282 16.7321 20.1483 16.8657C20.0146 16.9994 19.8333 17.0746 19.6443 17.0748Z"
        fill="#CD72FF"
      />
      <path
        d="M19.6443 17.432H14.4697C13.8544 17.432 13.2644 17.1876 12.8293 16.7525C12.3943 16.3175 12.1499 15.7275 12.1499 15.1122C12.1499 14.497 12.3943 13.907 12.8293 13.4719C13.2644 13.0369 13.8544 12.7925 14.4697 12.7925H19.6443C19.9282 12.7928 20.2004 12.9058 20.4011 13.1065C20.6019 13.3073 20.7148 13.5795 20.7152 13.8634V16.3617C20.7147 16.6455 20.6017 16.9176 20.4009 17.1182C20.2002 17.3188 19.9281 17.4316 19.6443 17.432ZM14.4697 13.5069C14.0437 13.5069 13.6352 13.6761 13.334 13.9772C13.0328 14.2784 12.8636 14.6869 12.8636 15.1129C12.8636 15.5388 13.0328 15.9473 13.334 16.2485C13.6352 16.5497 14.0437 16.7189 14.4697 16.7189H19.6443C19.6912 16.7189 19.7376 16.7097 19.781 16.6917C19.8243 16.6738 19.8637 16.6475 19.8969 16.6143C19.93 16.5811 19.9563 16.5418 19.9743 16.4984C19.9922 16.4551 20.0015 16.4086 20.0015 16.3617V13.8634C20.0015 13.8165 19.9922 13.77 19.9743 13.7267C19.9563 13.6834 19.93 13.644 19.8969 13.6108C19.8637 13.5776 19.8243 13.5513 19.781 13.5334C19.7376 13.5154 19.6912 13.5062 19.6443 13.5062L14.4697 13.5069Z"
        fill="#7800A1"
      />
      <path
        d="M13.9344 15.1123C13.9344 14.9358 13.9868 14.7633 14.0848 14.6165C14.1829 14.4698 14.3222 14.3554 14.4853 14.2879C14.6483 14.2204 14.8278 14.2027 15.0008 14.2371C15.1739 14.2715 15.3329 14.3565 15.4577 14.4813C15.5825 14.6061 15.6675 14.7651 15.7019 14.9382C15.7364 15.1113 15.7187 15.2907 15.6511 15.4538C15.5836 15.6168 15.4692 15.7562 15.3225 15.8542C15.1758 15.9523 15.0032 16.0046 14.8268 16.0046C14.5901 16.0046 14.3631 15.9106 14.1958 15.7432C14.0285 15.5759 13.9344 15.3489 13.9344 15.1123Z"
        fill="white"
      />
      <path
        d="M14.8261 16.3612C14.5791 16.3612 14.3377 16.288 14.1323 16.1507C13.9269 16.0135 13.7669 15.8185 13.6723 15.5903C13.5778 15.3621 13.5531 15.111 13.6013 14.8687C13.6495 14.6265 13.7684 14.404 13.943 14.2293C14.1177 14.0547 14.3402 13.9357 14.5825 13.8875C14.8247 13.8393 15.0758 13.8641 15.304 13.9586C15.5322 14.0531 15.7273 14.2132 15.8645 14.4185C16.0017 14.6239 16.075 14.8654 16.075 15.1124C16.075 15.4436 15.9434 15.7612 15.7092 15.9954C15.475 16.2296 15.1573 16.3612 14.8261 16.3612ZM14.8261 14.5766C14.7203 14.5766 14.6168 14.608 14.5288 14.6668C14.4408 14.7256 14.3722 14.8092 14.3317 14.9069C14.2912 15.0047 14.2806 15.1123 14.3013 15.2161C14.3219 15.3199 14.3729 15.4153 14.4477 15.4901C14.5226 15.5649 14.6179 15.6159 14.7217 15.6366C14.8255 15.6572 14.9331 15.6466 15.0309 15.6061C15.1287 15.5656 15.2123 15.497 15.2711 15.409C15.3299 15.321 15.3612 15.2176 15.3612 15.1117C15.3612 14.9698 15.3049 14.8337 15.2045 14.7333C15.1042 14.633 14.968 14.5766 14.8261 14.5766Z"
        fill="#7800A1"
      />
    </svg>

  )
}

export default IcWalletColored