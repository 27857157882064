import React, { Component } from "react";
import MobileSidebar from "./MobileSidebar";
import MobileHeader from "./MobileHeader";

class MobileSidebarWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileSidebarIsOpen: false,
    };
  }

  openCallback = () => {
    this.setState({ mobileSidebarIsOpen: true });
  };

  closeCallback = () => {
    this.setState({ mobileSidebarIsOpen: false });
  };

  render() {
    const { mobileSidebarIsOpen } = this.state;
    const { showBalance, balance, availableBalance, refreshBalance } =
      this.props;

    return (
      <React.Fragment>
        <div className="mobile">
          <MobileHeader
            openSidebar={this.openCallback}
            showBalance={showBalance}
            balance={balance}
            availableBalance={availableBalance}
            refreshBalance={refreshBalance}
          />
        </div>

        {mobileSidebarIsOpen && (
          <div className="mobile">
            <MobileSidebar closeSidebar={this.closeCallback} />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default MobileSidebarWrapper;
