import React from 'react';
import { I18n } from 'react-redux-i18n';
import { ToastContainer } from 'react-toastify';
import CashInInformation from './CashInInformation';

const CashInBoletoStepTwo = ({ amount, name, document, onClickBack, onClickContinue }) => {
  return (
    <React.Fragment>
      <div className="deposit-ticket">
        <div className="deposit-ticket__data__row">
          <h4>{I18n.t('cashIn.billet.stepTwo.addingText')}</h4>
          <h4 className="deposit-ticket__value">
            {amount.toLocaleString('pt-br', { style: "currency", currency: "BRL" })}
          </h4>
        </div>

        <hr />

        <div className="deposit-ticket__data">
          <CashInInformation
            title={I18n.t('cashIn.billet.stepTwo.formOfPayment')}
            description={I18n.t('cashIn.billet.stepTwo.invoice')}
          />
          <div className="deposit-ticket__data__row">
            <CashInInformation
              title={I18n.t('cashIn.billet.stepTwo.fullName')}
              description={name}
            />
            <CashInInformation
              title={I18n.t('cashIn.billet.stepTwo.document')}
              description={document}
            />
          </div>
        </div>

        <hr />

        <div className="deposit-ticket__information">
          <h6 className="deposit-ticket__information__title">
            {I18n.t('cashIn.billet.stepTwo.observation')}

          </h6>
          <p className="deposit-ticket__information__list__item">
            {I18n.t('cashIn.billet.stepTwo.obeservationText')}
          </p>
        </div>

        <hr />

        <div className="deposit-ticket__footer">
          <button
            type="button"
            onClick={onClickBack}
            className="deposit-ticket__change-value"
          >
            {I18n.t('cashIn.billet.stepTwo.changeValue')}
          </button>

          <button
            type="button"
            className="deposit-ticket__continue-button"
            onClick={onClickContinue}
          >
            {I18n.t('cashIn.billet.stepTwo.confirm')}
          </button>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default CashInBoletoStepTwo;

