/* eslint-disable max-len */
import React from "react";

const IcBalance = ({ size = 24, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 14.395H6V15.395H9V14.395Z"
        fill="#2A5082"
      />
      <path
        d="M7 16.395H0V17.395H7V16.395Z"
        fill="#2A5082"
      />
      <path
        d="M5 14.395H4V15.395H5V14.395Z"
        fill="#2A5082"
      />
      <path
        d="M37 11.395H10V27.395H37V11.395Z"
        fill="white"
      />
      <path
        d="M37.15 27.476H9.49301V23.357H10.67V26.3H35.97V12.177H10.67V21.592H9.49301V11H37.15V27.476Z"
        fill="#2A5082"
      />
      <path
        d="M31.853 23.945H14.788C14.788 23.4769 14.6021 23.028 14.2711 22.697C13.9401 22.366 13.4911 22.18 13.023 22.18V22.18V16.296C13.2549 16.296 13.4845 16.2503 13.6987 16.1615C13.9129 16.0728 14.1075 15.9427 14.2714 15.7787C14.4353 15.6147 14.5653 15.42 14.6539 15.2057C14.7426 14.9915 14.7881 14.7619 14.788 14.53H31.853C31.853 14.9981 32.039 15.447 32.37 15.778C32.701 16.109 33.1499 16.295 33.618 16.295V22.179C33.1499 22.179 32.701 22.365 32.37 22.696C32.039 23.027 31.853 23.4759 31.853 23.944V23.945Z"
        fill="#9FCBF1"
      />
      <path
        d="M32.442 24.534H14.2V23.942C14.1997 23.6299 14.0756 23.3307 13.855 23.11C13.6343 22.8894 13.3351 22.7653 13.023 22.765H12.435V15.704H13.023C13.3351 15.7037 13.6343 15.5797 13.855 15.359C14.0756 15.1383 14.1997 14.8391 14.2 14.527V13.942H32.442V14.53C32.4423 14.8421 32.5664 15.1413 32.787 15.362C33.0077 15.5826 33.3069 15.7067 33.619 15.707H34.207V22.768H33.619C33.3074 22.7683 33.0087 22.8919 32.7881 23.112C32.5675 23.332 32.4431 23.6304 32.442 23.942V24.534ZM15.303 23.357H31.335C31.441 22.9502 31.6535 22.579 31.9508 22.2818C32.248 21.9846 32.6192 21.772 33.026 21.666V16.81C32.6192 16.704 32.248 16.4915 31.9508 16.1942C31.6535 15.897 31.441 15.5258 31.335 15.119H15.303C15.197 15.5258 14.9845 15.897 14.6872 16.1942C14.39 16.4915 14.0188 16.704 13.612 16.81V21.666C14.0188 21.772 14.39 21.9846 14.6872 22.2818C14.9845 22.579 15.197 22.9502 15.303 23.357V23.357Z"
        fill="#2A5082"
      />
      <path
        d="M18 18.395H16V19.395H18V18.395Z"
        fill="#2A5082"
      />
      <path
        d="M30 18.395H29V19.395H30V18.395Z"
        fill="#2A5082"
      />
      <path
        d="M20.673 19.235C20.7271 18.723 20.9285 18.2378 21.2527 17.8379C21.5769 17.438 22.0101 17.1406 22.4998 16.9818C22.9895 16.823 23.5147 16.8096 24.0119 16.9431C24.5091 17.0766 24.9569 17.3514 25.3012 17.7342C25.6454 18.1169 25.8714 18.5913 25.9517 19.0998C26.0319 19.6083 25.9631 20.1292 25.7534 20.5994C25.5438 21.0695 25.2024 21.4689 24.7705 21.7491C24.3386 22.0292 23.8348 22.1782 23.32 22.178C22.5795 22.1367 21.8854 21.8039 21.3894 21.2525C20.8935 20.701 20.6359 19.9757 20.673 19.235V19.235Z"
        fill="white"
      />
      <path
        d="M23.322 22.767C22.884 22.7648 22.4515 22.6695 22.0532 22.4872C21.6549 22.305 21.3 22.04 21.012 21.71C20.4079 21.0282 20.078 20.1468 20.086 19.236C20.0512 18.3399 20.3719 17.4664 20.9782 16.8056C21.5844 16.1448 22.4272 15.7503 23.323 15.708V15.708C24.2191 15.7508 25.062 16.1461 25.668 16.8077C26.2739 17.4693 26.5938 18.3436 26.558 19.24C26.5652 20.151 26.2346 21.0324 25.63 21.714C25.3418 22.0429 24.987 22.3069 24.5891 22.4884C24.1912 22.67 23.7593 22.7649 23.322 22.767V22.767ZM23.322 16.883C22.7379 16.9248 22.1938 17.1954 21.8079 17.6359C21.4221 18.0765 21.2255 18.6514 21.261 19.236C21.2534 19.8561 21.4757 20.4571 21.885 20.923C22.0632 21.1296 22.2833 21.2959 22.5307 21.4109C22.7782 21.5259 23.0472 21.5869 23.32 21.59V21.59C23.5927 21.587 23.8617 21.5261 24.1091 21.4113C24.3565 21.2965 24.5767 21.1304 24.755 20.924C25.1656 20.4591 25.3887 19.8582 25.381 19.238C25.417 18.6534 25.221 18.0782 24.8355 17.6373C24.45 17.1964 23.9061 16.9253 23.322 16.883V16.883Z"
        fill="#2A5082"
      />
      <path
        d="M11 20.395H4V21.395H11V20.395Z"
        fill="#2A5082"
      />
    </svg>
  )
}

export default IcBalance;