import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import ROUTES from '../../data/routes';

const LoginActions = () => {
  return (
    <Fragment>
      <div className="login-actions">
        <a
          className="login-actions__link"
          href={ROUTES.CREATE_ACCOUNT}
        >
          {I18n.t('institucional.header.signUp')}
        </a>
        <div className="login-actions__divider" />
        <Link
          className="login-actions__link"
          to={ROUTES.LOGIN}
        >
          {I18n.t('institucional.header.login')}
        </Link>
      </div>
    </Fragment>
  )
}

export default LoginActions;
