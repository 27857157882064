import React from 'react';
import { Link } from 'react-router-dom';
import SwapHorizontal from '../../svg/ic_swap_horizontal.svg';
import ShoppingBasket from '../../svg/ic_shopping_basket.svg';
import { dateFromNow } from '../../app/pipe/dateFormatter';
import statusColor from '../../app/pipe/statusColor';
import MoneyValue from '../moneyValue/MoneyValue';
import { OperationType } from '../../data/enumerators/operationType';
import getPaymentOrderStatus from '../../app/pipe/paymentOrderStatus';

const getIcon = (type) => (type === OperationType.PAYMENT ? <ShoppingBasket /> : <SwapHorizontal />);

const OperationItem = ({ id, date, description, status, value, type }) => {
  return (
    <React.Fragment>
      <Link
        className="link-no-style"
        to={`/painel/historico/${id}`}
      >
        <div className="row item__wrapper">
          <div className="col-lg-1 col-2 pl-0 mobile--pr">
            <div className="icon__wrapper">
              {getIcon(type)}
            </div>
          </div>
          <div className="col-lg-2 d-flex align-items-center mobile-hidden">
            <div className="text">
              {dateFromNow(date)}
            </div>
          </div>
          <div className="col-5 d-flex align-items-center mobile mobile--pl mobile--pr">
            <div className="text text__operation">
              <p>
                {description}<br />
                <span className={"text text-" + (statusColor(status))}>
                  {getPaymentOrderStatus(status)} 
                </span>
              </p>
            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-center mobile-hidden">
            <div className="text text__operation">
              {description}
            </div>
          </div>
          <div className="col-lg-3 d-flex align-items-center justify-content-center mobile-hidden">
            <div className={"text text-" + (statusColor(status))}>
              {getPaymentOrderStatus(status)}
            </div>
          </div>
          <div className="col-lg-2 col-5 d-flex align-items-center justify-content-end pr-0 mobile--pl">
            <div className="text">
              <MoneyValue value={value} />
            </div>
          </div>
        </div>
      </Link>
    </React.Fragment>
  )
}

export default OperationItem;
