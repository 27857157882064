/* eslint-disable max-len */
import React from "react"

const IcDownloadColored = ({ size = 24, ...props }) => {
  return (
    <svg
      id="Grupo_816"
      data-name="Grupo 816"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 23.73 28.475"
      {...props}
    >
      <path
        id="Caminho_197"
        data-name="Caminho 197"
        d="M72,113.933h5.933V108"
        transform="translate(-71.341 -107.341)"
        fill="currentColor"
      />
      <path
        id="Caminho_198"
        data-name="Caminho 198"
        d="M92.753,132.708H88.734V131.39h4.019a.66.66,0,0,0,.659-.659V108.978a.66.66,0,0,0-.659-.659H77.206l-4.887,4.887v17.787H71V112.66L76.66,107H92.753a1.98,1.98,0,0,1,1.978,1.978V130.73A1.98,1.98,0,0,1,92.753,132.708Z"
        transform="translate(-71 -107)"
        fill="currentColor"
      />
      <path
        id="Caminho_231"
        data-name="Caminho 231"
        d="M72.978,136.4B8r3B4p7yhRXuBWLqsQ546WR43cqQwrbXMDFnBi6vSJBeif8tPW85a7r7DM961Jvk4hdryZoByEp8GC8HzsqJpRN4FxGM9,136.441Z"
        transform="translate(-71 -110.733)"
        fill="currentColor"
      />
      <path
        id="Caminho_199"
        data-name="Caminho 199"
        d="M79.614,115.614H75V114.3h3.3V111h1.318Z"
        transform="translate(-72.363 -108.363)"
        fill="currentColor"
      />
      <g
        id="Grupo_612"
        data-name="Grupo 612"
        transform="translate(9.902 9)"
      >
        <g
          id="Grupo_614"
          data-name="Grupo 614"
          transform="translate(0 0)"
        >
          <rect
            id="Retângulo_639"
            data-name="Retângulo 639"
            width="4"
            height="2"
            transform="translate(-0.028 0.257)"
            fill="currentColor"
          />
        </g>
      </g>
      <g
        id="Grupo_613"
        data-name="Grupo 613"
        transform="translate(9.902 5.544)"
      >
        <g
          id="Grupo_616"
          data-name="Grupo 616"
          transform="translate(0 0)"
        >
          <rect
            id="Retângulo_640"
            data-name="Retângulo 640"
            width="4"
            height="2"
            transform="translate(-0.028 -0.287)"
            fill="currentColor"
          />
        </g>
      </g>
      <g
        id="Grupo_614-2"
        data-name="Grupo 614"
        transform="translate(5.547 12.984)"
      >
        <path
          id="Caminho_229"
          data-name="Caminho 229"
          d="M209.955,129.278V122H205.1v7.278h-2.924l5.457,6.651,5.457-6.651Z"
          transform="translate(-200.897 -121.394)"
          fill="currentColor"
        />
        <path
          id="Caminho_230"
          data-name="Caminho 230"
          d="M206.805,136.491l-6.74-8.213h3.6V121h6.065v7.278h3.815Zm-4.176-7,4.176,5.088,4.175-5.088h-2.464v-7.278h-3.639v7.278Z"
          transform="translate(-200.065 -121)"
          fill="currentColor"
        />
      </g>
      <path
        id="Caminho_236"
        data-name="Caminho 236"
        d="M92.753,132.708H88.734V131.39h4.019a.66.66,0,0,0,.659-.659V108.978a.66.66,0,0,0-.659-.659H77.206l-4.887,4.887v18.843H71V112.66L76.66,107H92.753a1.98,1.98,0,0,1,1.978,1.978V130.73A1.98,1.98,0,0,1,92.753,132.708Z"
        transform="translate(-71 -107)"
        fill="currentColor"
      />
    </svg>

  )
}

export default IcDownloadColored