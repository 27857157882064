import React, { Component, Fragment } from 'react';
import ArrowBackIcon from '../../svg/institutional/ic_arrow_back.svg';
import DoubtAnswer from '../../data/enumerators/doubtAnswer'
import FaqList from '../../components/institutional/faq/FaqList';
import FaqFooter from '../../components/institutional/faq/FaqFooter';
import FaqTopics from '../../components/institutional/faq/FaqTopics';
import FaqQuestion from '../../components/institutional/faq/FaqQuestion';
import FaqDoubt from '../../components/institutional/faq/FaqDoubt';
import { FaqData } from '../../data/enumerators/faqTopic';
import Footer from '../../components/footer/Footer';
import FaqTableFee from "../../components/institutional/faq/FaqFee";

class PanelFaq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      questionNumber: null,
      mosaicTopics: [],
      topicQuestions: null,
      selectedQuestion: null,
      selectedTopic: false,
      isComponent: false,
      answerDoubt: null,
    }
  }

  componentDidMount = () => {
    const topicsMosaic = FaqData.map((e) => ({ title: e.title, topic: e.topic }));
    this.setState({ mosaicTopics: topicsMosaic });
  }

  selectTopic = (number) => {
    const cleanedFaq = this.filterQuestions(number);
    this.resetQuestion();
    this.setState({ current: number, topicQuestions: cleanedFaq, selectedTopic: true });
  }

  selectDoubtAnswer = (answerDoubt) => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
    this.setState({
      answerDoubt,
    });
  }

  selectQuestion = (question) => {
    question.isComponent = question.isComponent ? true : false;
    this.setState({ selectedQuestion: question, isComponent: question.isComponent, questionNumber: question.id, 
      answerDoubt: null, });
  }

  resetQuestion = () => {
    this.setState({ selectedQuestion: null, isComponent:false, questionNumber: null, answerDoubt: null, })
  }

  backToTopics = () => {
    this.setState({ selectedTopic: false });
  }

  filterQuestions = (topic) => (FaqData.find(e => (e.topic === topic)).questions);

  render() {
    const { current, mosaicTopics, topicQuestions, selectedQuestion,
      questionNumber, selectedTopic, answerDoubt, isComponent } = this.state;
    return (
      <Fragment>
        <div className="faq__desktop mobile-hidden">
          <div className="panel__faq">
            <div className="row m-0">
              <div className="col-12">
                <div className="institutional__faq">
                  <div className="institutional__faq__content">
                    {!selectedQuestion && !isComponent && (
                      <div className="institutional__faq__content__single">
                        <FaqTopics
                          mosaic={mosaicTopics}
                          current={current}
                          selectTopic={this.selectTopic}
                        />
                      </div>
                    )}
                    {selectedTopic && !isComponent && (
                      <div className="institutional__faq__content__single">
                        <FaqList
                          // eslint-disable-next-line max-len
                          topicTitle={(mosaicTopics.length > 0 && current && mosaicTopics[current - 1]).title || "Perguntas frequentes"}
                          topicQuestions={topicQuestions}
                          current={questionNumber}
                          resetQuestion={this.resetQuestion}
                          selectQuestion={this.selectQuestion}
                        />
                      </div>
                    )}
                    {selectedQuestion && !isComponent && (
                      <div className="institutional__faq__content__single">
                        <FaqQuestion selectedQuestion={selectedQuestion} />
                        <FaqDoubt
                          answer={this.selectDoubtAnswer}
                        />
                        <div className="panel__faq__footer">
                          <div className="row m-0">
                            <div className="col-12">
                              {answerDoubt === DoubtAnswer.NO && (
                                <FaqFooter doubt={selectedQuestion.title} />
                              )}
                              {answerDoubt === DoubtAnswer.YES && (
                                <div className="faq__footer__doubt">
                                  <span className="faq__footer__doubt__description">
                                    Ficamos felizes em responder a sua dúvida.
                                  </span>
                                  <button
                                    type="button"
                                    className="faq__footer__doubt__back faq__back"
                                    onClick={this.resetQuestion}
                                  >
                                    <ArrowBackIcon /> Voltar para início
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {selectedQuestion && isComponent && (                    
                      <FaqTableFee
                        resetQuestion={this.resetQuestion}
                      />                    
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq__mobile mobile">
          <div className="panel__faq">
            <div className="row m-0">
              <div className="col-12">
                {!isComponent && (
                <div className="institutional__faq">
                  <div className="institutional__faq__content">
                    {!selectedQuestion && !selectedTopic && (
                      <div className="institutional__faq__content__single">
                        <FaqTopics
                          mosaic={mosaicTopics}
                          selectTopic={this.selectTopic}
                        />
                      </div>
                    )}
                    {selectedTopic && !selectedQuestion && (
                      <Fragment>
                        <button
                          type="button"
                          className="faq__back"
                          onClick={this.backToTopics}
                        >
                          <ArrowBackIcon /> Voltar para &quot;Ajuda&quot;
                        </button>
                        <div className="institutional__faq__content__single">
                          <FaqList
                            // eslint-disable-next-line max-len
                            topicTitle={(mosaicTopics.length > 0 && current && mosaicTopics[current - 1]).title || "Perguntas frequentes"}
                            topicQuestions={topicQuestions}
                            resetQuestion={this.resetQuestion}
                            selectQuestion={this.selectQuestion}
                          />
                        </div>
                      </Fragment>
                    )}
                    {selectedQuestion && (
                      <Fragment>
                        <button
                          type="button"
                          className="faq__back"
                          onClick={this.resetQuestion}
                        >
                          <ArrowBackIcon /> Voltar para &quot;{(mosaicTopics[current - 1]).title}&quot;
                        </button>
                        <div className="institutional__faq__content__single">
                          <FaqQuestion selectedQuestion={selectedQuestion} />
                          <FaqDoubt answer={this.selectDoubtAnswer} />
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
                )}
              </div>
            </div>

            {selectedQuestion && isComponent && (
              <FaqTableFee
                resetQuestion={this.resetQuestion}
              />
            )}

            <div className="panel__faq__footer">
              <div className="row m-0">
                <div className="col-12">
                  {answerDoubt === DoubtAnswer.NO && !isComponent &&(
                    <FaqFooter doubt={selectedQuestion.title} />
                  )}
                  {answerDoubt === DoubtAnswer.YES && (
                    <div className="faq__footer__doubt">
                      <span className="faq__footer__doubt__description">
                        Ficamos felizes em responder a sua dúvida.
                      </span>
                      <button
                        type="button"
                        className="faq__footer__doubt__back faq__back"
                        onClick={this.resetQuestion}
                      >
                        <ArrowBackIcon /> Voltar para início
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex fixed-bottom'>
          <Footer />
        </div>
      </Fragment>
    )
  }
}

export default PanelFaq;
