import React from 'react';
import Carteira from '../../../svg/faq/ic_carteira.svg';
import MyAccount from '../../../svg/faq/ic_my_account.svg';
import Deposito from '../../../svg/faq/ic_deposito.svg';
import Pagamentos from '../../../svg/faq/ic_pagamentos.svg';
import Recebimento from '../../../svg/faq/ic_recebimento.svg';
import Retirada from '../../../svg/faq/ic_retirada.svg';
import Saldo from '../../../svg/faq/ic_saldo.svg';
import ContaDigital from '../../../svg/faq/ic_conta_digital.svg';
import Tarifa from '../../../svg/faq/ic_tarifa.svg';
import HistoricoExtrato from '../../../svg/faq/ic_historico_extrato.svg';
import { FaqTopicEnumerator } from '../../../data/enumerators/faqTopic';

const FaqIcon = ({ icon }) => {
  switch (icon) {
    case FaqTopicEnumerator.CREDIT_CARD:
      return <Carteira />;
    case FaqTopicEnumerator.CONTACT:
      return <MyAccount />;
    case FaqTopicEnumerator.DEPOSIT:
      return <Deposito />;
    case FaqTopicEnumerator.PAYMENT:
      return <Pagamentos />;
    case FaqTopicEnumerator.RECEIPT:
      return <Recebimento />;
    case FaqTopicEnumerator.WITHDRAWAL:
      return <Retirada />;
    case FaqTopicEnumerator.BALANCE:
      return <Saldo />;
    case FaqTopicEnumerator.DIGITAL_ACCOUNT:
      return <ContaDigital />;
    case FaqTopicEnumerator.HISTORY_AND_EXTRACT:
      return <HistoricoExtrato />;
    case FaqTopicEnumerator.FEE:
      return <Tarifa />;
    default:
      return <Carteira />;
  }
}

export default FaqIcon;
