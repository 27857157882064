import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';

import Loading from '../components/elements/Loading';

import Content from './Content';

export const history = createBrowserHistory();

class AppRouter extends React.PureComponent {
  render() {
    return (
      <Router history={history}>
        <div className="page-wrapper">
          <Loading />
          <Content />
        </div>
      </Router>
    );
  }
}

export default connect(
  null,
)(AppRouter);
