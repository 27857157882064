import ComponentType from "./componentType";

/* eslint-disable max-len */
export const FaqTopicEnumerator = {
  PAYMENT: 1,
  RECEIPT: 2,
  DIGITAL_ACCOUNT: 3,
  BALANCE: 4,
  HISTORY_AND_EXTRACT: 5,
  CREDIT_CARD: 6,
  DEPOSIT: 7,
  WITHDRAWAL: 8,
  FEE: 9,
  CONTACT: 10,
};


export const FaqData = [
  {
    id: '1',
    topic: FaqTopicEnumerator.PAYMENT,
    title: 'Pagamentos',
    questions: [
      {
        id: '1',
        title: 'Como efetuar o pagamento do bem adquirido?',
        description: `Para efetuar o pagamento do bem adquirido, você precisa aportar recursos na sua Conta Digital Superbid Pay e efetuar o pagamento no Superbid Exchange quando a compra for confirmada.\n
        Você receberá um e-mail com a confirmação da compra e liberação do pagamento.
        `,
        image: true,
        link: null
      },
      {
        id: '7',
        title: 'Como faço para pagar as compras realizadas no SuperBid Exchange?',
        description: `Após a confirmação da compra, efetue login no Superbid Exchange e acesse a página <a href="https://myaccount.superbid.com/"><u>Minha Conta</u></a>. Selecione a seção <a href="https://myaccount.superbid.com/minhascompras"><u>Minhas Compras</u></a>, escolha o bem que deseja pagar e prossiga conforme indicado,selecionando a forma de pagamento de sua preferência.`,
        image: true,
        link: null
      },
      {
        id: '2',
        title: 'Como faço para aportar recursos na Conta Digital Superbid Pay?',
        description: `Você pode aportar recursos na sua Conta Digital Superbid Pay de forma gratuita através de Transferência Bancária (TED ou DOC). Você também pode aportar recursos através de Boleto Bancário e é cobrada tarifa de R$ 4,90. Veja o passo a passo em cada uma das opções:\n
        Transferência Bancária através do Portal Superbid Pay:\n
          1.  Acesse a sua Conta Digital Superbid Pay através do link: https://superbidpay.com.br/login/
          2.  Seu login e senha são os mesmos utilizados no Superbid Exchange.
          3.  Selecione “Adicionar” para escolher o método de depósito do recurso.
          4.  Utilize os dados bancários para realizar a transferência (TED ou DOC), lembrando que para confirmação da operação é necessário que a conta bancária seja de mesma titularidade da Conta Digital.
          5.  Dentro de 1 dia útil o saldo estará disponível na sua Conta Digital Superbid Pay.\n
        Caso deseje realizar o aporte de recursos via Transferência Bancária durante o checkout, você precisa acessar sua conta no Superbid Exchange e seguir os passos:\n
          1.  Acesse “Minha Conta” no menu superior.
          2.  Selecione “Minhas Compras” e escolha qual bem deseja realizar o pagamento e selecione “Pagar este”.
          3.  Selecione “Adicionar” para escolher o método de depósito do recurso.
          4.  Utilize os dados bancários para realizar a transferência (TED ou DOC), lembrando que para confirmação da operação é necessário que a conta bancária seja de mesma titularidade da Conta Digital.
          5.  Dentro de 1 dia útil o saldo estará disponível na sua Conta Digital Superbid Pay.\n
        Boleto Bancário através do Portal Superbid Pay:\n
          1.  Acesse a sua Conta Digital Superbid Pay através do link: https://superbidpay.com.br/login/
          2.  Seu login e senha são os mesmos utilizados no Superbid Exchange.
          3.  Selecione “Adicionar” para escolher o método de depósito.
          4.  Em “Boleto Bancário” selecione o valor desejado e confirme.
          5.  Em seguida, imprima o Boleto Bancário ou copie o código de barras para pagamento no seu Internet Banking e efetue o pagamento.
          6.  Dentro de 1 dia útil o saldo estará disponível na sua Conta Digital Superbid Pay.\n
        Caso deseje realizar o aporte de recursos com Boleto Bancário durante o checkout, você precisa acessar sua conta no Superbid Exchange e seguir os passos:\n
          1.  Acesse “Minha Conta” no menu superior.
          2.  Selecione “Minhas Compras” e escolha qual bem deseja realizar o pagamento e selecione “Pagar este”.
          3.  Realize o aporte de recursos na sua Conta Digital Superbid Pay. 
          4.  Imprima o Boleto Bancário ou copie o código de barras para pagamento no seu Internet Banking e efetue o pagamento.
          5.  O valor do aporte estará disponível instantaneamente na sua Conta Digital Superbid Pay e será utilizado para o pagamento da sua compra de forma automática.\n
          `
      },
      {
        id: '3',
        title: 'Quais são as formas de pagamento disponíveis no SuperBid Exchange?',
        description: `O Superbid Exchange permite o pagamento da compra mediante aporte de recursos na Conta Digital Superbid Pay que pode ser realizado através de:\n
                      ● Transferência Bancária (TED ou DOC)*\n
                      ● Boleto Bancário**\n
                      ● Cartão de Crédito**\n
                      *Em caso de aporte de recursos na Conta Digital Superbid Pay através de Transferência Bancária (TED ou DOC) para pagamento de compras durante o checkout é necessário realizar a transferência pelo menos 1 dia útil antes do vencimento do pagamento da compra, para atender o prazo de compensação bancária e efetivação da transferência. Após este prazo, retorne ao checkout e efetue o pagamento com o saldo da sua Conta Digital Superbid Pay.\n
                      **Em caso de aporte de recursos na Conta Digital Superbid Pay utilizando Boleto Bancário ou Cartão de Crédito para pagamento de compra durante o checkout, o valor do aporte estará disponível instantaneamente na sua Conta Digital Superbid Pay e será utilizado para o pagamento da sua compra de forma automática.\n
                      Eventualmente, pode ser solicitado que o pagamento do valor do bem adquirido seja efetuado diretamente ao vendedor.`,
        image: true,
        link: null
      },
      {
        id: '4',
        title: 'Como pagar com saldo da Conta Digital Superbid Pay?',
        description: `Se a sua Conta Digital Superbid Pay possuir saldo suficiente, você pode pagar sua compra integralmente com esse saldo.\n
        Você realiza o pagamento através da seção “Minhas Compras”, selecionando o bem que deseja pagar e utilizando a opção “Saldo da Conta Digital”.\n
        Lembrando que você deve utilizar o saldo disponível na sua Conta Digital Superbid Pay para pagar o valor integral de sua compra, não sendo possível escolher um valor específico para realizar o pagamento.\n
        A confirmação do pagamento da sua compra com saldo da Conta Digital Superbid Pay é instantânea.
        `,
        image: true,
        link: null
      },
      {
        id: '5',
        title: ' Como aportar recursos com Cartão de Crédito e realizar o pagamento de suas compras?',
        description: `O Superbid Exchange permite o pagamento da compra mediante aporte de recursos na Conta Digital Superbid Pay através de Cartão de Crédito.\n
        Para aportar recursos usando cartão de crédito na Conta Digital Superbid Pay para pagamento de compras, selecione a opção Cartão de Crédito no checkout de pagamento do Superbid Exchange.\n
        Se ainda não realizou nenhum pagamento com Cartão de Crédito, serão solicitados os dados do seu Cartão de Crédito para aprovar a transação. Você também pode cadastrar os dados do seu Cartão de Crédito na sua Carteira e assim você não precisa digitá-los toda vez que for realizar um novo pagamento.\n
        A aprovação do pagamento leva poucos segundos.
        `,
        image: true,
        link: null
      },
      {
        id: '6',
        title: 'Posso utilizar o Cartão de Crédito de outra pessoa?',
        description: `Não. Por questões de segurança, o Cartão de Crédito deve ser da mesma titularidade do usuário da Conta Digital Superbid Pay.`,
        image: true,
        link: null
      },
      {
        id: '9',
        title: 'E se seu saldo da Conta Digital Superbid Pay não for suficiente?',
        description: `Caso o saldo não seja suficiente para pagar a compra realizada, você pode adicionar saldo em sua Conta Digital Superbid Pay.\n
        Para aportar recursos, realize uma transferência de sua conta bancária para sua Conta Digital, utilizando os dados bancários da “Superbid Pay Instituição de Pagamento Ltda.”, fornecidos no Portal Superbid Pay. Você também pode utilizar Boleto Bancário e Cartão de Crédito para aportar recursos na sua Conta Digital Superbid Pay.\n
        Lembrando que a Conta Digital Superbid Pay aceita somente transferências via TED ou DOC entre contas de mesma titularidade, ou seja, contas bancárias com o mesmo CPF ou CNPJ do titular da Conta Digital Superbid Pay.\n
        `,
        image: true,
        link: null
      },
      {
        id: '10',
        title: 'É possível selecionar mais de uma forma de pagamento?',
        description: `Sim, o checkout de pagamentos permite combinar a utilização do Saldo da Conta Digital Superbid Pay com outras formas de pagamento, caso o saldo de sua Conta Digital Superbid Pay não seja suficiente para o pagamento integral de sua compra.\n
        Se você deseja utilizar o saldo disponível em sua Conta Digital Superbid Pay e complementá-lo para pagar uma compra, você pode realizar um novo aporte para completar o valor através de Boleto Bancário ou Cartão de Crédito.\n
            1.  Primeiro selecione a opção “Saldo da Conta Digital” e será deduzido o saldo total disponível na sua Conta Digital Superbid Pay do valor necessário para pagar a compra.
            2.  Em seguida, será exibido o valor do complemento necessário para concluir o pagamento da compra. 
            3.  Você pode fazer o aporte do valor remanescente através de Boleto Bancário ou utilizar a opção Cartão de Crédito. 
        `,
        image: true,
        link: null
      },
      {
        id: '11',
        title: 'Como funciona o pagamento direto para o vendedor?',
        description: `Em alguns casos, o pagamento dos bens deve ser efetuado diretamente ao vendedor. Tal condição é informada no momento do pagamento do bem adquirido.\n
                      Para tanto, selecione uma das formas disponíveis para pagamento do valor do bem adquirido (em geral, depósito em conta ou boleto bancário) clicando no botão “Continuar para pagamento” na seção <a href="https://myaccount.superbid.com/minhascompras"><u>Minhas Compras</u></a>.\n
                      Além disso, você deve selecionar uma forma para pagamento das comissões e encargos do Superbid Exchange, utilizando as opções disponíveis na Conta Digital Superbid Pay.\n
                      A confirmação do pagamento do valor do bem depende dos prazos de compensação bancária.`,
        image: true,
        link: null
      },
      {
        id: '12',
        title: 'Existe alguma tarifa para realizar pagamentos?',
        description: `O pagamento das compras realizadas no Superbid Exchange está sujeito à cobrança de tarifas de acordo com a forma de pagamento escolhida:\n
        ● Boleto Bancário: é cobrada tarifa para emissão do boleto bancário. Consulte a Tabela de Tarifas abaixo.\n
        ● Utilização de Saldo da Conta Digital Superbid Pay: Isento de tarifas de pagamento.\n
        ● Cartão de crédito: é cobrada tarifa para realizar pagamento com cartão de crédito.\n
        As tarifas são adicionadas ao valor aportado para garantir a disponibilidade integral do recurso aportado, sem qualquer desconto de custos de processamento e liquidação financeira do sistema bancário. Esta cobrança diferenciada de tarifas neste contexto é autorizada pela legislação.\n
        Ressaltamos que entre as alternativas de aporte é oferecida a opção de aporte via transferência bancária, que é isenta de tarifas.\n
        Em caso de aporte de recursos na Conta Digital Superbid Pay através de Transferência Bancária (TED ou DOC) para pagamento de compras durante o checkout é necessário realizar a transferência pelo menos 1 dia útil antes do vencimento do pagamento da compra, para atender o prazo de compensação bancária e efetivação da transferência. Após este prazo, retorne ao checkout e efetue o pagamento com o saldo da sua Conta Digital Superbid Pay\n
        Consulte a Tabela de Tarifas abaixo.\n
              <div style="display:flex;flex-direction:column;padding:24px;border-radius:8px;box-shadow:2px 2px 6px 0px rgba(11, 47, 99, 0.08);">
          <div style="width:100%;display:flex;justify-content:space-between;border-bottom:1px dashed #EBECED;padding-bottom:8px">
            <span style="color:#313234;font-size:20px;font-weight:600;font-family:Inter">
              Operação
            </span>
            <span style="color:#313234;font-size:20px;font-weight:600;font-family:Inter">
              Tarifa
            </span>
          </div>
            <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Emisão de boleto para aporte de recurso na              conta digital Superbid Pay
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              R$ 4,90
            </span>
          </div>
              <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Transferência bancária
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              GRÁTIS
            </span>
          </div>
              <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Pagamento com cartão de crédito à vista*
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              5,00%
            </span>
          </div>
                <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Pagamento com cartão de crédito parcelado**
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              Até 27%
            </span>
          </div>
                <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Transferência bancária para adicionar dinheiro***
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              GRÁTIS
            </span>
          </div>
                <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Transferência bancária para retirar dinheiro
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              GRÁTIS
            </span>
          </div>
        <div style="display:flex;flex-direction:column;padding-top:8px">
          <span style="color:#73757D;font-size:12px;font-weight:400;font-family:Inter">* A tarifa será adicionada durante o pagamento após escolher o meio de pagamento “Cartão de Crédito”.</span>
              <span style="color:#73757D;font-size:12px;font-weight:400;font-family:Inter">**Tarifa varia de acordo com a quantidade de parcelas, durante o pagamento do bem serão exibidas as possibilidades de parcelamento, junto com os valores de parcela.</span>
                  <span style="color:#73757D;font-size:12px;font-weight:400;font-family:Inter">*** Verifique a Existência de tarifas no seu banco para realizar esta operação.</span>
        </div>
      </div>
        `,
        image: true,
        link: null
      },
      {
        id: '13',
        title: 'Existem valores mínimos ou máximos para pagamento?',
        description: `Não existem limites de valores estabelecidos para pagamento de bens adquiridos através do Superbid Exchange.`,
        image: true,
        link: null
      },
      {
        id: '14',
        title: 'O pagamento é seguro?',
        description: `As plataformas do Superbid Exchange são providas de soluções que previnem fraudes, melhoram a experiência do usuário e cumprem com todas as exigências regulatórias.\n
                      Durante o processo de cadastro (Onboarding), são utilizadas ferramentas de background check para consulta automática de informações de cadastro em bases externas.\n
                      Como consequência, o sistema de contas de pagamento da Superbid Pay dispõe das melhores soluções e ferramentas disponíveis no mercado para maximizar a segurança de dados e das transações financeiras.\n
                      Além disso, essas ferramentas protegem informações cadastrais e o fluxo de dinheiro dos usuários do Superbid Exchange, evitando riscos de fraudes.`,
        image: true,
        link: null
      },
      {
        id: '15',
        title: 'Como funciona a devolução de valores para o comprador, caso a venda seja desfeita?',
        description: `Se, por algum motivo, for necessário devolver o valor de uma compra realizada, o Superbid Exchange fará o possível para tornar este processo o mais simples e rápido.\n
                      Para solicitar a devolução de valores, entre em contato com a equipe de atendimento do Superbid Exchange através da <a href="https://www.superbid.net/canais-de-atendimento"><u>Central de Atendimento</u></a>, informando o motivo da solicitação.\n
                      Sua solicitação será analisada pelo vendedor. Se for aprovada, a compra/venda é desfeita e o valor devolvido, preferencialmente através de crédito em sua Conta Digital Superbid Pay, respeitando as seguintes condições e prazos:\n
                      ● Se o valor ainda não foi transferido para a conta do vendedor e permanece na sua Conta Digital Superbid Pay,
                      será realizado o desbloqueio com a liberação instantânea do valor na sua Conta Digital Superbid Pay;\n
                      ● Se o valor já foi transferido para a conta do vendedor, será feito um crédito em sua Conta Digital Superbid Pay.`,
        image: true,
        link: null
      },
      {
        id: '16',
        title: 'Posso realizar o pagamento de uma compra no Portal Superbid Pay',
        description: `Não, no Portal Superbid Pay você pode aportar recursos na sua Conta Digital Superbid Pay ou retirar recursos para sua conta bancária, sem vínculo direto com o pagamento de qualquer compra.\n
        O pagamento de suas compras deve ser realizado através do checkout no Superbid Exchange .
        `,
        image: true,
        link: null
      },
      {
        id: '17',
        title: 'Por que pediram um código enviado pelo meu banco via SMS/ Aplicativo no final da compra ?',
        description: `A dupla autenticação garante maior segurança durante a sua compra, assim o banco garante que apenas você realize transações em seu cartão de crédito.\n
        O código é enviado pelo seu banco, em aparelhos autorizados por você e deve ser utilizado apenas no momento da confirmação da compra.\n
        Não solicitamos este código fora do site (via mensagem, ligação ou e-mail), caso receba mensagens suspeitas entre em contato com o seu banco.`
      }
    ]
  },
  {
    id: '2',
    topic: FaqTopicEnumerator.RECEIPT,
    title: 'Recebimento',
    questions: [
      {
        id: '1',
        title: 'Como é realizado o recebimento de uma venda no Superbid Exchange?',
        description: `A Superbid Pay é responsável pelo processamento, movimentação de recursos financeiros e confirmação do pagamento das vendas realizadas através do Superbid Exchange.\n
                    Assim que o pagamento é confirmado, o valor é creditado em sua Conta Digital Superbid Pay ou em sua conta bancária, já descontadas eventuais comissões e encargos acordados comercialmente e tarifas praticadas pela Superbid Pay.\n
                    Para saber se o valor foi creditado na sua Conta Digital Superbid Pay ou transferido para sua conta bancária, acesse o seu “Extrato” disponível no portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a>.`,
        image: true,
        link: null
      },
      {
        id: '2',
        title: 'Posso transferir o saldo disponível na minha Conta Digital Superbid Pay para a minha conta bancária?',
        description: `Sim. O saldo disponível em sua Conta Digital Superbid Pay pode ser transferido para contas bancárias de qualquer instituição financeira. Lembrando que essa transferência é permitida somente para contas bancárias de mesma titularidade (CPF ou CNPJ) do titular da Conta Digital Superbid Pay.\n
                      Essa transferência pode ser solicitada através da opção <a href="https://superbidpay.com.br/painel/retirar-dinheiro"><u>Retirar Dinheiro</u></a> disponível no portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a>.\n
                      Para saber se a transferência foi confirmada, acesse o seu “Extrato” disponível no portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a>.`,
        image: true,
        link: null
      },
      {
        id: '3',
        title: 'Como faço para transferir o saldo disponível na minha Conta Digital Superbid Pay para a minha conta bancária?',
        description: `Basta acessar a sua Conta Digital através do portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a> ou através da página <a href="https://myaccount.superbid.com/"><u>Minha Conta</u></a> do Superbid Exchange, selecionando a opção <a href="https://superbidpay.com.br/painel"><u>Ver Detalhes</u></a> na seção Conta Digital Superbid Pay.\n
                      Antes de iniciar esta operação, verifique se o saldo está disponível para retirada.\n
                      1. Selecione a opção <a href="https://superbidpay.com.br/painel/retirar-dinheiro"><u>Retirar Dinheiro</u></a>;
                      2. Escolha o valor que deseja retirar da sua Conta Digital Superbid Pay;
                      3. Confirme os dados da sua conta bancária, para onde o dinheiro será transferido. Os dados da sua conta bancária ficarão salvos para você não ter que digitá-los novamente toda vez que for retirar dinheiro. Caso queira alterar a conta, basta acessar a opção “Alterar Conta”. Lembrando que você pode cadastrar somente contas bancárias de mesma titularidade (CPF ou CNPJ) do usuário da Conta Digital Superbid Pay;
                      4. Clique no botão “Confirmar Retirada” e pronto! Após a confirmação, o valor será creditado na sua conta bancária, conforme o prazo de compensação bancária.\n 
                      Para saber se a transferência foi confirmada, acesse o seu “Extrato” disponível no portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a>.`,
        image: true,
        link: null
      },
      {
        id: '4',
        title: 'Como funciona a transferência do pagamento para a minha conta bancária?',
        description: `Se você vende no Superbid Exchange, a Superbid Pay é responsável por processar e receber os pagamentos dos bens vendidos. Você pode escolher no cadastro do seu evento a opção de transferir automaticamente o dinheiro da Conta Digital Superbid Pay para a sua conta bancária ou fazer esta gestão manualmente através do portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a>, da forma que achar mais conveniente.\n
                      Na definição da condição comercial de cada evento ou loja, você escolhe a opção de transferência automática ou manual do dinheiro, além de poder definir a data que deseja recebê-lo diretamente em sua conta bancária.`,
        image: true,
        link: null
      },
      {
        id: '5',
        title: 'Posso utilizar o dinheiro que recebi de uma venda para realizar compras no Superbid Exchange?',
        description: `Sim. Você pode comprar no Superbid Exchange utilizando o saldo disponível em sua Conta Digital Superbid Pay.\n
                      Se você possui saldo suficiente na sua conta e deseja pagar alguma compra com este valor, basta escolher a opção “Utilizar Saldo Superbid Pay.`,
        image: true,
        link: null
      },
    ]
  },
  {
    id: '3',
    topic: FaqTopicEnumerator.DIGITAL_ACCOUNT,
    title: 'Conta Digital',
    questions: [
      {
        id: '1',
        title: 'O que é a Superbid Pay?',
        description: `A Superbid Pay é a empresa de soluções de pagamentos do Grupo Superbid. A Superbid Pay oferece, de forma exclusiva aos usuários do Superbid Exchange, uma plataforma completa, com Conta Digital Superbid Pay para pagamento de compras, recebimento de vendas e controle do dinheiro.`,
        image: true,
        link: null
      },
      {
        id: '2',
        title: 'O que é a Conta Digital Superbid Pay?',
        description: `A Conta Digital Superbid Pay é uma conta de pagamento utilizada para realizar pagamentos e recebimentos de compras e vendas realizadas no Superbid Exchange.\n
                      A Conta Digital Superbid Pay não faz operação de crédito e exige sempre a disponibilidade de saldo para realizar uma transação.\n
                      A Conta Digital Superbid Pay é muito semelhante a uma conta bancária, mas tem utilização restrita às transações realizadas no Superbid Exchange.`,
        image: true,
        link: null
      },
      {
        id: '3',
        title: 'Quem pode ter uma Conta Digital Superbid Pay?',
        description: `A Conta Digital Superbid Pay é disponibilizada em caráter exclusivo para os usuários do Superbid Exchange.`,
        image: true,
        link: null
      },
      {
        id: '4',
        title: 'Como faço para criar a minha Conta Digital Superbid Pay?',
        description: `Você precisa realizar o seu <a href="https://accounts.superbid.net/signup"><u>cadastro</u></a> no Superbid Exchange. Após a confirmação do seu cadastro, sua Conta Digital Superbid Pay é criada automaticamente. Você pode acessá-la através da página <a href="https://myaccount.superbid.com/"><u>Minha Conta</u></a> do Superbid Exchange ou pelo portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a>.`,
        image: true,
        link: null
      },
      {
        id: '5',
        title: 'Já tenho uma conta no Superbid Exchange, preciso cadastrar outra conta na Superbid Pay?',
        description: `Não. Ao concluir ou atualizar o seu cadastro no Superbid Exchange, você recebe uma Conta Digital Superbid Pay gratuitamente.`,
        image: true,
        link: null
      },
      {
        id: '6',
        title: 'Existe algum custo de abertura ou manutenção da Conta Digital Superbid Pay?',
        description: `Não. A abertura e a manutenção da Conta Digital Superbid Pay são totalmente gratuitas.\n
                      Algumas tarifas podem ser cobradas para realizar operações na sua Conta Digital Superbid Pay. Consulte a <a href="https://superbidpay.com.br/painel/ajuda"><u>Tabela de Tarifas.</u></a>`,
        image: true,
        link: null
      },
      {
        id: '7',
        title: 'Quais são os benefícios de ter uma Conta Digital Superbid Pay?',
        description: `Veja alguns dos benefícios exclusivos de ter uma Conta Digital Superbid Pay:\n
                      Para o Vendedor:\n
                      ● Transferência automática do dinheiro da sua Conta Digital Superbid Pay para a sua conta bancária;
                      ● Extrato e agenda de recebimentos para o controle financeiro das suas vendas;
                      ● Segurança no recebimento do valor de suas vendas através de instituição de pagamento regulada pelo Banco Central do Brasil.\n\n
                      Para o Comprador:\n
                      ● Múltiplas formas de pagamento de suas compras;
                      ● Extrato e agenda de pagamentos para o controle financeiro das suas compras;
                      ● Opções para adicionar, transferir dinheiro e realizar pagamentos com saldo disponível em sua Conta Digital Superbid Pay. Além disso, você pode armazenar os dados do seu cartão de crédito e realizar pagamentos sem ter o cartão físico em mãos;
                      ● Segurança no pagamento de suas compras através de instituição de pagamento regulada pelo Banco Central do Brasil.`,
        image: true,
        link: null
      },
      {
        id: '8',
        title: 'Como faço para encerrar a minha conta da Superbid Pay?',
        description: `Para encerrar sua conta da Superbid Pay acesse o menu "Configurações" no canto superior direito, na janela de configurações clique em "Encerrar conta".\n\n 
        Lembre-se que antes de prosseguir com sua solicitação de encerramento de conta o saldo deve estar zerado.`,
        image: true,
        link: null
      },
    ]
  },
  {
    id: '4',
    topic: FaqTopicEnumerator.BALANCE,
    title: 'Saldo',
    questions: [
      {
        id: '1',
        title: 'Posso transferir o saldo disponível na Conta Digital Superbid Pay para a minha conta bancária?',
        description: `Sim. O saldo disponível pode ser transferido para contas bancárias de qualquer instituição financeira. Lembrando que essa transferência é permitida somente para contas bancárias de mesma titularidade (CPF ou CNPJ) do titular da Conta Digital Superbid Pay.`,
        image: true,
        link: null
      },
      {
        id: '2',
        title: 'Como faço para transferir o saldo disponível na minha Conta Digital Superbid Pay para a minha conta bancária?',
        description: `Basta acessar a sua Conta Digital através do portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a> ou através da página <a href="https://myaccount.superbid.com/"><u>Minha Conta</u></a> do Superbid Exchange, selecionando a opção <a href="https://superbidpay.com.br/painel"><u>Ver Detalhes</u></a> na seção Conta Digital Superbid Pay.\n
                      Antes de iniciar esta operação, verifique se o saldo está disponível para retirada.\n
                      1. Selecione a opção <a href="https://superbidpay.com.br/painel/retirar-dinheiro"><u>Retirar Dinheiro</u></a>;
                      2. Escolha o valor que deseja retirar da sua Conta Digital Superbid Pay;
                      3. Confirme os dados da sua conta bancária, para onde o dinheiro será transferido. Os dados da sua conta bancária ficarão salvos para você não ter que digitá-los novamente toda vez que for retirar dinheiro. Caso queira alterar a conta, basta acessar a opção “Alterar Conta”. Lembrando que você pode cadastrar somente contas bancárias de mesma titularidade (CPF ou CNPJ) do usuário da Conta Digital Superbid Pay;
                      4. Clique no botão “Confirmar Retirada” e pronto! Após a confirmação, o valor será creditado na sua conta bancária, conforme o prazo de compensação bancária.\n
                      Para saber se a transferência foi confirmada, acesse o seu <a href="https://superbidpay.com.br/painel/extrato"><u>Extrato</u></a> disponível no portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a>.`,
        image: true,
        link: null
      },
      {
        id: '3',
        title: 'Existe um valor bloqueado na minha Conta Digital Superbid Pay, o que isso significa?',
        description: `Ao efetuar o pagamento de uma compra realizada no Superbid Exchange, o valor do pagamento é creditado na sua Conta Digital Superbid Pay e bloqueado durante o prazo de processamento da compra do bem. Após o prazo de processamento, este valor é transferido automaticamente pela Superbid Pay para a conta do vendedor para o pagamento da compra realizada.\n
                      Também pode haver bloqueio de valor em função da prestação de garantia, conforme Condições de Venda e Pagamento do Evento ou penalidade aplicada pelo Superbid Exchange. Esse valor será definitivamente desbloqueado ou debitado, conforme o caso, após a conclusão da transação.\n
                      Para mais informações, entre em contato com a equipe de atendimento do Superbid Exchange através da <a href="https://www.superbid.net/canais-de-atendimento"><u>Central de Atendimento</u></a>.`,
        image: true,
        link: null
      },
    ]
  },
  {
    id: '5',
    topic: FaqTopicEnumerator.HISTORY_AND_EXTRACT,
    title: 'Histórico & Extrato',
    questions: [
      {
        id: '1',
        title: 'Verifiquei uma operação com o status Aguardando Pagamento no meu histórico, o que isso significa?',
        description: `O status “Aguardando Pagamento” significa que você realizou alguma compra no Superbid Exchange ou emitiu boleto para adicionar saldo em sua Conta Digital Superbid Pay e ainda não realizou o pagamento.\n
                      Para prosseguir com o pagamento da compra realizada no Superbid Exchange você deve acessar a página <a href="https://myaccount.superbid.com/"><u>Minha Conta</u></a> do Superbid Exchange e escolher o bem que deseja pagar na seção <a href="https://myaccount.superbid.com/minhascompras"><u>Minhas Compras</u></a>.`,
        image: true,
        link: null
      },
      {
        id: '2',
        title: 'Verifiquei uma operação com o status Em Andamento no meu histórico, o que isso significa?',
        description: `O status “Em Andamento” significa que um pagamento foi iniciado, porém ainda não foi concluído. Neste caso, o sistema está aguardando a compensação bancária do pagamento realizado ou existem parcelas a vencer de uma compra com pagamento parcelado.`,
        image: true,
        link: null
      },
      {
        id: '3',
        title: 'Verifiquei uma operação com o status Autorizado no meu histórico, o que isso significa?',
        description: `O status “Autorizado” significa que a operação foi autorizada pela Superbid Pay, responsável pela conferência e transferência dos valores e foi encaminhada para liquidação financeira.`,
        image: true,
        link: null
      },
      {
        id: '4',
        title: 'Verifiquei uma operação com o status Cancelado no meu histórico, o que isso significa?',
        description: `O status “Cancelado” significa que o título emitido para pagamento foi cancelado.\n
                      Para mais informações, entre em contato com a equipe de atendimento do Superbid Exchange através da <a href="https://www.superbid.net/canais-de-atendimento"><u>Central de Atendimento</u></a>.`,
        image: true,
        link: null
      },
    ]
  },
  {
    id: '6',
    topic: FaqTopicEnumerator.CREDIT_CARD,
    title: 'Carteira',
    questions: [
      {
        id: '1',
        title: 'O que é carteira Superbid Pay?',
        description: `É a seção da Conta Digital Superbid Pay onde ficam armazenados e salvos os dados das contas bancárias e dos cartões de crédito do usuário.`,
        image: true,
        link: null
      },
      {
        id: '2',
        title: 'Quantas contas bancárias posso adicionar à minha carteira Superbid Pay?',
        description: `Você pode adicionar quantas contas bancárias quiser e pode alterar ou remover quando considerar necessário. Basta acessar a sua <a href="https://superbidpay.com.br/painel/carteira"><u>Carteira</u></a> e selecionar a opção “Adicionar Conta” ou “Remover”.\n
                      Você deve cadastrar ao menos uma conta bancária e toda vez que for retirar dinheiro da sua Conta Digital Superbid Pay será realizada a transferência para a conta bancária primária.\n
                      Lembrando que você pode alterar ou remover a conta bancária a qualquer momento.`,
        image: true,
        link: null
      },
      {
        id: '3',
        title: 'Posso adicionar contas bancárias de outras pessoas?',
        description: `Não. A conta bancária cadastrada deve ser de mesma titularidade (CPF ou CNPJ) do titular da Conta Digital Superbid Pay.`,
        image: true,
        link: null
      },
      {
        id: '4',
        title: 'Quantos Cartões de Crédito posso adicionar a minha carteira Superbid Pay?',
        description: `Você pode adicionar ou remover quantos cartões de crédito quiser. Basta acessar a sua <a href="https://superbidpay.com.br/painel/carteira"><u>Carteira</u></a> e selecionar a opção Adicionar cartão.`,
        image: true,
        link: null
      },
      {
        id: '5',
        title: 'Posso adicionar cartão de outras pessoas?',
        description: `Por questões de segurança, recomendamos que o cartão seja de mesma titularidade do cliente cadastrado na Conta Digital Superbid Pay.`,
        image: true,
        link: null
      },
    ]
  },
  {
    id: '7',
    topic: FaqTopicEnumerator.DEPOSIT,
    title: 'Adicione Dinheiro',
    questions: [
      {
        id: '1',
        title: 'Como faço para adicionar dinheiro à minha Conta Digital Superbid Pay?',
        description: `Você pode adicionar dinheiro à sua Conta Digital Superbid Pay de duas maneiras: emitindo e pagando um boleto bancário ou realizando uma transferência diretamente de sua conta bancária em instituição financeira.\n
                      Veja como é fácil:\n
                      Acesse a sua Conta Digital através do portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a> ou através da página <a href="https://myaccount.superbid.com/"><u>Minha Conta</u></a> do Superbid Exchange e selecione a opção <a href="https://superbidpay.com.br/painel/adicionar-dinheiro"><u>Adicionar Dinheiro</u></a>. Em seguida, escolha a forma como deseja adicionar o valor:\n
                      ● Boleto Bancário: defina um valor para adicionar à sua Conta Digital Superbid Pay. Em seguida, será gerado
                      o boleto com o valor definido para pagamento.\n
                      ● Transferência Bancária: ao selecionar esta opção, serão fornecidos os dados bancários para
                      transferência do valor para a sua Conta Digital Superbid Pay. Você define o valor no momento de fazer a
                      transferência da sua conta bancária para a sua Conta Digital Superbid Pay.\n\n
                      ATENÇÃO:\n
                      - Por se tratar de uma operação realizada através de uma Instituição de Pagamento, o beneficiário do boleto bancário ou o favorecido da transferência bancária é a “Superbid Pay Instituição de Pagamento Ltda.”. O valor pago ou transferido é creditado automaticamente na sua Conta Digital Superbid Pay.\n
                      - O crédito em sua Conta Digital Superbid Pay é reconhecido e conciliado utilizando o CPF ou CNPJ do remetente. Portanto, transfira dinheiro para a sua Conta Digital Superbid Pay utilizando somente contas bancárias de sua titularidade.`,
        image: true,
        link: null
      },
      {
        id: '2',
        title: 'Posso realizar transferência de uma conta bancária de terceiro?',
        description: `Não. O crédito em sua Conta Digital Superbid Pay é reconhecido e conciliado utilizando o CPF ou CNPJ do remetente. Portanto, o dinheiro deve ser transferido somente de contas bancárias de sua titularidade.`,
        image: true,
        link: null
      },
      {
        id: '3',
        title: 'Existe alguma tarifa para adicionar dinheiro na minha Conta Digital Superbid Pay?',
        description: `Não, a Superbid Pay não cobra tarifas para adicionar dinheiro na Conta Digital Superbid Pay.`,
        image: true,
        link: null
      },
      {
        id: '4',
        title: 'Quanto tempo leva para o dinheiro entrar na minha Conta Digital Superbid Pay?',
        description: `O dinheiro é creditado na sua Conta Digital Superbid Pay em até 1 dia útil após o pagamento do boleto ou confirmação da transferência bancária.`,
        image: true,
        link: null
      },
      {
        id: '5',
        title: 'Adicionei o dinheiro, mas o dinheiro ainda não entrou na minha Conta Digital Superbid Pay, e agora?',
        description: `O prazo para o dinheiro ser creditado na sua Conta Digital Superbid Pay depende do tempo necessário para a compensação bancária do valor e da forma escolhida para adicionar o dinheiro (Boleto ou Transferência Bancária).\n
                      Acompanhe o seu <a href="https://superbidpay.com.br/painel/extrato"><u>Extrato</u></a> para verificar se o dinheiro entrou na sua Conta Digital Superbid Pay.\n
                      Para mais informações, entre em contato com a equipe de atendimento do Superbid Exchange através da <a href="https://www.superbid.net/canais-de-atendimento"><u>Central de Atendimento</u></a>.`,
        image: true,
        link: null
      },
    ]
  },
  {
    id: '8',
    topic: FaqTopicEnumerator.WITHDRAWAL,
    title: 'Retire dinheiro',
    questions: [
      {
        id: '1',
        title: 'Como faço para retirar dinheiro da minha Conta Digital Superbid Pay?',
        description: `Basta acessar a sua Conta Digital através do portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a> ou através da página <a href="https://myaccount.superbid.com/"><u>Minha Conta</u></a> do Superbid Exchange, selecionando a opção <a href="https://superbidpay.com.br/painel"><u>Ver Detalhes</u></a> na seção Conta Digital Superbid Pay.\n
                      Antes de iniciar esta operação, verifique se o saldo está disponível para retirada.\n
                      1. Selecione a opção <a href="https://superbidpay.com.br/painel/retirar-dinheiro"><u>Retirar Dinheiro</u></a>;
                      2. Escolha o valor que deseja retirar da sua Conta Digital Superbid Pay;
                      3. Confirme os dados da sua conta bancária, para onde o dinheiro será transferido. Os dados da sua conta bancária ficarão salvos para você não ter que digitá-los novamente toda vez que for retirar dinheiro. Caso queira alterar a conta, basta acessar a opção “Alterar Conta”. Lembrando que você pode cadastrar somente contas bancárias de mesma titularidade (CPF ou CNPJ) do usuário da Conta Digital Superbid Pay;
                      4. Clique no botão “Confirmar Retirada” e pronto! Após a confirmação, o valor será creditado na sua conta bancária, conforme o prazo de compensação bancária.\n
                      Para saber se a transferência foi confirmada, acesse o seu <a href="https://superbidpay.com.br/painel/extrato"><u>Extrato</u></a> disponível no portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a>.`,
        image: true,
        link: null
      },
      {
        id: '2',
        title: 'Solicitei a retirada do dinheiro, quanto tempo leva para o dinheiro ser creditado na minha conta bancária?',
        description: `Caso a retirada do dinheiro seja solicitada até 12h00, o valor é creditado na conta bancária no mesmo dia da solicitação. Após este horário, o dinheiro é creditado no próximo dia útil.`,
        image: true,
        link: null
      },
      {
        id: '3',
        title: 'O dinheiro que retirei ainda não foi creditado na minha conta bancária, e agora?',
        description: `O prazo para o dinheiro ser creditado na sua conta bancária depende do tempo necessário para a compensação bancária do valor.\n
                      Acompanhe o seu <a href="https://superbidpay.com.br/painel/extrato"><u>Extrato</u></a> para verificar se a operação foi concluída.\n
                      Para mais informações, entre em contato com a equipe de atendimento do Superbid Exchange através da <a href="https://www.superbid.net/canais-de-atendimento"><u>Central de Atendimento</u></a>.`,
        image: true,
        link: null
      },
      {
        id: '4',
        title: 'O dinheiro pode ser transferido para a conta bancária de outra pessoa?',
        description: `Não. O dinheiro pode ser transferido somente para conta bancária de mesma titularidade (CPF ou CNPJ) do titular da Conta Digital Superbid Pay. Caso a conta bancária de destino seja de outra pessoa, a operação é cancelada e o valor estornado para a sua Conta Digital Superbid Pay.`,
        image: true,
        link: null
      },
      {
        id: '5',
        title: 'Existe alguma tarifa para retirar dinheiro da minha Conta Digital Superbid Pay?',
        description: `Alguma tarifa pode ser cobrada. Consulte a <a href="https://superbidpay.com.br/painel/ajuda"><u>Tabela de Tarifas</u></a> da Conta Digital Superbid Pay.`,
        image: true,
        link: null
      },
    ]
  },
  {
    id: '9',
    topic: FaqTopicEnumerator.FEE,
    title: 'Tarifa',
    questions: [
      {
        id: '1',
        title: 'Tarifas na Superbid Pay',
        description: `O pagamento das compras realizadas no Superbid Exchange está sujeito à cobrança de tarifas de acordo com a forma de pagamento escolhida:\n
        ● Boleto Bancário: é cobrada tarifa para emissão do boleto bancário. Consulte a Tabela de Tarifas abaixo.\n
        ● Utilização de Saldo da Conta Digital Superbid Pay: Isento de tarifas de pagamento.\n
        ● Cartão de crédito: é cobrada tarifa para realizar pagamento com cartão de crédito.\n
        As tarifas são adicionadas ao valor aportado para garantir a disponibilidade integral do recurso aportado, sem qualquer desconto de custos de processamento e liquidação financeira do sistema bancário. Esta cobrança diferenciada de tarifas neste contexto é autorizada pela legislação.\n
        Ressaltamos que entre as alternativas de aporte é oferecida a opção de aporte via transferência bancária, que é isenta de tarifas.\n
        Em caso de aporte de recursos na Conta Digital Superbid Pay através de Transferência Bancária (TED ou DOC) para pagamento de compras durante o checkout é necessário realizar a transferência pelo menos 1 dia útil antes do vencimento do pagamento da compra, para atender o prazo de compensação bancária e efetivação da transferência. Após este prazo, retorne ao checkout e efetue o pagamento com o saldo da sua Conta Digital Superbid Pay\n
        Consulte a Tabela de Tarifas abaixo.\n
              <div style="display:flex;flex-direction:column;padding:24px;border-radius:8px;box-shadow:2px 2px 6px 0px rgba(11, 47, 99, 0.08);">
          <div style="width:100%;display:flex;justify-content:space-between;border-bottom:1px dashed #EBECED;padding-bottom:8px">
            <span style="color:#313234;font-size:20px;font-weight:600;font-family:Inter">
              Operação
            </span>
            <span style="color:#313234;font-size:20px;font-weight:600;font-family:Inter">
              Tarifa
            </span>
          </div>
            <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Emisão de boleto para aporte de recurso na              conta digital Superbid Pay
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              R$ 4,90
            </span>
          </div>
              <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Transferência bancária
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              GRÁTIS
            </span>
          </div>
              <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Pagamento com cartão de crédito à vista*
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              5,00%
            </span>
          </div>
                <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Pagamento com cartão de crédito parcelado**
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              Até 27%
            </span>
          </div>
                <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Transferência bancária para adicionar dinheiro***
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              GRÁTIS
            </span>
          </div>
                <div style="display:flex;justify-content:space-between;padding:8px 0px;border-bottom: 1px solid #EBECED">
            <span style="width:200px;color:#313234;font-size:14px;font-weight:400;font-family:Inter">
              Transferência bancária para retirar dinheiro
            </span>
            <span style="color:#313234;font-size:14px;font-weight:400;font-family:Nunito">
              GRÁTIS
            </span>
          </div>
        <div style="display:flex;flex-direction:column;padding-top:8px">
          <span style="color:#73757D;font-size:12px;font-weight:400;font-family:Inter">* A tarifa será adicionada durante o pagamento após escolher o meio de pagamento “Cartão de Crédito”.</span>
              <span style="color:#73757D;font-size:12px;font-weight:400;font-family:Inter">**Tarifa varia de acordo com a quantidade de parcelas, durante o pagamento do bem serão exibidas as possibilidades de parcelamento, junto com os valores de parcela.</span>
                  <span style="color:#73757D;font-size:12px;font-weight:400;font-family:Inter">*** Verifique a Existência de tarifas no seu banco para realizar esta operação.</span>
        </div>
      </div>
        `,
        image: true,
        link: null
      },
    ]
  },
  {
    id: '10',
    topic: FaqTopicEnumerator.CONTACT,
    title: 'Minha Conta',
    questions: [
      {
        id: '1',
        title: 'Como faço para acessar minha Conta Digital Superbid Pay?',
        description: `O seu login e senha de acesso à Conta Digital Superbid Pay são os mesmos de acesso ao Superbid Exchange.\n
                      Você pode acessar a sua Conta Digital Superbid Pay a partir da página <a href="https://myaccount.superbid.com/"><u>Minha Conta</u></a> do Superbid Exchange, selecionando a opção <a href="https://superbidpay.com.br/painel"><u>Ver Detalhes</u></a> na seção Conta Digital Superbid Pay.`,
        image: true,
        link: null
      },
      {
        id: '2',
        title: 'Como faço para alterar os dados da minha Conta Digital Superbid Pay?',
        description: `A alteração ou atualização de dados como endereço, telefone e e-mail pode ser realizada através da seção <a href="https://superbidpay.com.br/painel/configuracoes"><u>Configurações</u></a> do portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a> ou através da página <a href="https://myaccount.superbid.com/"><u>Minha Conta</u></a> do Superbid Exchange, selecionando a opção “Alterar Cadastro” na seção “Meu Cadastro”.\n
                      Ao atualizar ou editar os dados cadastrais de sua Conta Digital Superbid Pay, você altera também os dados do seu cadastro no Superbid Exchange.`,
        image: true,
        link: null
      },
      {
        id: '3',
        title: 'Como faço para alterar a minha senha de acesso à Conta Digital Superbid Pay?',
        description: `Para alterar a senha de acesso à Conta Digital Superbid Pay, você deve acessar a página <a href="https://myaccount.superbid.com/"><u>Minha Conta</u></a> do Superbid Exchange, selecionando a opção “Gerenciar Senha” na seção “Meu Cadastro”.\n
                      Ao alterar a senha de acesso da sua Conta Digital Superbid Pay, você altera também a senha de acesso no Superbid Exchange.`,
        image: true,
        link: null
      },
      {
        id: '4',
        title: 'Como faço para recuperar a minha senha de acesso à Conta Digital Superbid Pay?',
        description: `Para recuperar a senha de acesso à Conta Digital Superbid Pay, você deve acessar a página de login do portal <a href="https://superbidpay.com.br/"><u>Superbid Pay</u></a> e selecionar a opção <a href="https://accounts.superbid.net/forgot-password"><u>Esqueci a Senha</u></a>.\n
                        Você também pode realizar através da página de login do Superbid Exchange, selecionando a opção <a href="https://accounts.superbid.net/forgot-password"><u>Esqueci minha Senha</u></a>.`,
        image: true,
        link: null
      },
      {
        id: '5',
        title: 'Como faço para encerrar minha conta da Superbid Pay?',
        description: `Para encerrar sua conta da Superbid Pay acesse o menu "Configurações" no canto superior direito, na janela de configurações clique em "Encerrar conta".</u></a>\n
                      Lembre-se que antes de prosseguir com sua solicitação de encerramento de conta o saldo deve estar zerado.</u></a>`,
        image: true,
        link: null
      }
    ]
  },
]
