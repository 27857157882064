import React from "react";

import styled from "styled-components";
import { space } from "styled-system";

import { Icon as Iconfy } from '@iconify/react';

const Component = styled(Iconfy)`
  ${space};
`;

const Icon = ({ icon, provider = "eva", size = 16, color, ...props }) => {
  const providerName = {
    eva: "eva",
    google: "ic",
  }

  return (
    <Component
      icon={`${providerName[provider]}:${icon}`}
      color={color}
      fontSize={size}
      {...props}
    />
  )
}

export default Icon;