/* eslint-disable max-len */
import React from "react";

const IcWithdrawColored = ({ size = 24, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.31882 11.4258H2.64661V12.1917H5.31882V11.4258Z"
        fill="#CD72FF"
      />
      <path
        d="M10.6633 21.0002C10.5864 20.9995 10.5124 20.9701 10.456 20.9178L4.10938 15.1732C4.06913 15.1342 4.03715 15.0874 4.0153 15.0358C3.99345 14.9841 3.98218 14.9286 3.98218 14.8725C3.98218 14.8164 3.99345 14.7608 4.0153 14.7092C4.03715 14.6575 4.06913 14.6108 4.10938 14.5717L10.456 8.82718C10.5024 8.7832 10.5616 8.75518 10.625 8.74724C10.6884 8.7393 10.7527 8.75185 10.8085 8.78304C10.8674 8.81889 10.9159 8.86966 10.9489 8.93023C10.9819 8.99079 10.9984 9.05901 10.9966 9.12797V11.4251H13.335C13.38 11.4257 13.4244 11.436 13.4651 11.4554C13.5058 11.4747 13.5418 11.5026 13.5707 11.5371C13.6349 11.6126 13.6698 11.7087 13.6689 11.8077V14.1054L19.4587 9.1268L13.6689 4.14818V6.44587C13.6751 6.54089 13.6435 6.63453 13.5809 6.70633C13.5184 6.77813 13.4299 6.82226 13.335 6.82911H4.98445V6.06322H13.0017V3.38229C12.9999 3.31405 13.0161 3.24655 13.0485 3.18646C13.0808 3.12637 13.1284 3.07578 13.1863 3.0397C13.2409 3.00839 13.3039 2.99507 13.3664 3.00164C13.429 3.0082 13.4879 3.03431 13.5347 3.07627L20.2152 8.82078C20.2574 8.85991 20.291 8.90731 20.314 8.96001C20.3369 9.01272 20.3488 9.06959 20.3488 9.12708C20.3488 9.18457 20.3369 9.24147 20.314 9.29417C20.291 9.34687 20.2574 9.39427 20.2152 9.4334L13.5347 15.1779C13.4878 15.2198 13.4289 15.2458 13.3663 15.2522C13.3038 15.2587 13.2408 15.2453 13.1863 15.2139C13.1283 15.1779 13.0808 15.1273 13.0484 15.0672C13.016 15.0071 12.9999 14.9396 13.0017 14.8713V12.1916H10.6633C10.6182 12.1909 10.5738 12.1805 10.533 12.1612C10.4922 12.1418 10.4561 12.114 10.427 12.0795C10.3632 12.0038 10.3286 11.9078 10.3294 11.8089V9.91534L4.85553 14.8696L10.3294 19.8238V17.5505C10.3232 17.4555 10.3548 17.3618 10.4174 17.29C10.4799 17.2182 10.5684 17.1741 10.6633 17.1673H18.3467V17.9332H10.9978V20.6135C10.9995 20.6824 10.983 20.7505 10.95 20.8109C10.917 20.8714 10.8685 20.9221 10.8097 20.9578C10.7654 20.9844 10.715 20.999 10.6633 21.0002Z"
        fill="#7800A1"
      />
      <path
        d="M21.0195 11.8088H18.6812V12.5747H21.0195V11.8088Z"
        fill="#CD72FF"
      />
      <path
        d="M8.32555 8.36206H1.31055V9.12796H8.32555V8.36206Z"
        fill="#7800A1"
      />
      <path
        d="M22.6894 15.2556H16.0083V16.0215H22.6894V15.2556Z"
        fill="#7800A1"
      />
    </svg>

  )
}

export default IcWithdrawColored;