
import RestService from '../services/rest';

async function listCreditOffers(id, filter) {
    return RestService.getRest(`/credit-offer?externalId=${id}${filter ? `&status=${filter}` : ''}`, {}, true);
}

async function getCreditOfferProposalByLoanId(id) {
    return RestService.getRest(`/credit-offer?loanId=${id}`, {}, true)
}

async function uploadCreditOfferDocument(data) {
    return RestService.postRest("/credit-offer/upload-documents", data, {}, true)
}

async function cancelCreditOffer(id,text) {
    return RestService.deleteRest(`/credit-offer/${id}`, text,{}, true)
}

export default {
    listCreditOffers,
    getCreditOfferProposalByLoanId,
    uploadCreditOfferDocument,
    cancelCreditOffer
}