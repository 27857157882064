/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { TermsAndPoliticsEnum } from '../../../data/enumerators/termsAndPolitics';

const PrivacyPolicy = ({ current }) => {
  return (
    <Fragment>
      {current === TermsAndPoliticsEnum.PRIVACY_POLICY && (
        <div>

          <p>A presente política de privacidade (<b>&quot;Política de Privacidade&quot;</b>) descreve como as empresas do Grupo Superbid realizam a coleta,
            armazenamento, uso, processamento, associação, proteção, compartilhamento e demais formas de tratamento das informações dos usuários,
            incluindo os seus dados pessoais, em conexão com os serviços das empresas do Grupo Superbid (www.superbid.net).
          </p>

          <p>Esta Política de Privacidade está dividida em 5 seções:</p>
          <p>(i)	Dados Coletados;</p>
          <p>(ii)	Segurança e Armazenamento;</p>
          <p>(iii)	Uso e Compartilhamento;</p>
          <p>(iv)	Consentimento, e</p>
          <p>(v)	Correção e Exclusão de Dados Pessoais.</p>

          <p>Ao utilizar os serviços das empresas do Grupo Superbid, o usuário está anuindo integralmente com as regras desta Política de Privacidade.
            Qualquer dúvida poderá ser esclarecida por meio do telefone [11-4950-9400] (todas as localidades), e do e-mail [<b>cac@superbid.net</b>]
            (<b>&quot;Canais de Atendimento&quot;</b>).
          </p>

          <p><b>Dados Coletados</b></p>

          <p>As empresas do Grupo Superbid coletam informações/dados pessoais capazes de identificar o usuário quando do cadastro junto
            às empresas do Grupo Superbid, visita nas páginas das empresas do Grupo Superbid e quando o usuário participa dos eventos
            divulgados pelas empresas do Grupo Superbid.
          </p>

          <p>As empresas do Grupo Superbid também podem receber informações que identifiquem o usuário advindas de parceiros de negócios
            ou de lojas do Superbid Exchange.
          </p>

          <p>As empresas do Grupo Superbid recebem e armazenam automaticamente em seus servidores informações das atividades do usuário
            advindas de seu navegador, incluindo seu endereço IP e informações de cookies dos sites das empresas do Grupo Superbid que
            o usuário visitou. Os referidos dados podem ser coletados e armazenados para o cumprimendo de deveres legais, para aperfeiçoar
            os serviços prestados ou para direcionar as melhores ofertas para o usuário.
          </p>

          <p><b>Segurança e Armazenamento</b></p>

          <p>As empresas do Grupo Superbid coletam informações/dados pessoais dos usuários e, por essa razão,
            a segurança de seus dados é prioridade.
          </p>

          <p>As empresas do Grupo Superbid empregam práticas de segurança da informação adequadas em todos
            os seus sistemas e infraestrutura, incluindo medidas físicas e digitais de segurança, visando
            garantir a proteção das informações/dados pessoais dos usuários. Essas medidas incluem
            controles de acessos, criptografia, implementação SSL, firewalls, entre outros mecanismos
            e protocolos. Com relação a dados de natureza financeira, são aplicadas medidas adicionais de
            segurança conforme a legislação e regulamentação aplicável.
          </p>

          <p>As empresas do Grupo Superbid possuem políticas, procedimentos e controles internos que seguem as
            regras de seguranca cibernética estabelecidas pelo Banco Central em relação a quaisquer informações
            financeiras que eventualmente venham a possuir de seus usuários.
          </p>

          <p><b>Tratamento de Dados Pessoais (Uso e Compartilhamento)</b></p>

          <p>Para os propósitos deste documento, &quot;Dados Pessoais&quot; são as informações sobre uma pessoa física que:
            (a) possam ser utilizadas para identificar, contatar ou localizar uma pessoa física específica, tais como,
            mas não se limitando a: nome, CPF; RG, e-mail, endereço e número de telefone celular; (b) possam ser combinadas
            com outras informações que podem ser usadas para identificar, contatar ou localizar uma pessoa física específica,
            tais como, mas não se limitando a: geolocalização, endereço IP e Cookies, ou (c) sejam definidas como &quot;Dados Pessoais&quot;
            na Lei nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais), doravante designada como “LGPD” e/ou pela Autoridade Nacional
            de Proteção de Dados Pessoais (“ANPD”).
          </p>

          <p>Para os propósitos deste documento “Tratamento de Dados Pessoais” significa qualquer operação ou conjunto de operações
            realizadas em relação a Dados Pessoais ou a conjuntos de Dados Pessoais, seja ou não por meios automatizados, como coleta,
            produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento,
            armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração ou,
            ainda, conforme definição dada pela ANPD.
          </p>

          <p>As Partes declaram que cumprem com a LGPD e que, portanto, adotam medidas técnicas e organizacionais (segurança da informação)
            aptas à proteção dos Dados Pessoais que porventura sejam tratados devido ao objeto deste instrumento. Quando a contratação
            implicar no tratamento de Dados Pessoais, inclusive quando existir transferência internacional de Dados Pessoais,
            as Partes deverão:
          </p>

          <p> a) cumprir a LGPD em relação ao tratamento de Dados Pessoais objeto deste Contrato, naquilo que for aplicável;</p>
          <p>b) assegurar que os direitos dos titulares dos Dados Pessoais sejam cumpridos, conforme disposto na LGPD;</p>
          <p>c) tratar os Dados Pessoais a que tenham acesso em razão do presente Contrato com a exclusiva finalidade de permitir a consecução de
            seu objeto, sem a possibilidade de utilizar esses Dados Pessoais para qualquer finalidade distinta;
          </p>
          <p>d) não divulgar a terceiros os Dados Pessoais a que tenham tido acesso, salvo mediante obrigação legal e/ou por decisão judicial;</p>
          <p>e) manter em absoluto sigilo todos os Dados Pessoais e informações que lhe tenham sido confiados, obrigação esta que subsistirá
            ao término do presente Contrato;
          </p>
          <p>f) não tratar Dados Pessoais obtidos em razão do presente Contrato fora do Brasil, exceto se o tratamento ocorrer em
            conformidade com os artigos 33 a 36 da LGPD;
          </p>
          <p>g) não reter quaisquer Dados Pessoais decorrentes da presente relação contratual por um período superior ao necessário para a
            consecução do objeto do presente Contrato, ou conforme necessário ou permitido pela lei aplicável.
            Finalizado o Contrato por qualquer causa, as Partes deverão apagar/destruir com segurança (mediante confirmação por escrito),
            ou devolver à outra Parte (quando solicitado) todos os documentos que contenham Dados Pessoais, a que tenham tido acesso em decorrência
            do presente Contrato, bem como qualquer cópia destes, seja de forma documental ou magnética, a menos que a sua manutenção seja exigida ou
            assegurada pela legislação vigente, e
          </p>
          <p>
            h) colaborar mutuamente para o cumprimento integral das disposições previstas na LGPD.
          </p>

          <p>As informações dos usuários, incluindo Dados Pessoais, serão utilizadas e compartilhadas com o objetivo de prestar e aprimorar os
            serviços das empresas do Grupo Superbid e cumprir obrigações legais e regulatórias. Com exceção das hipóteses previstas abaixo,
            as empresas do Grupo Superbid não compartilharão informações dos usuários com terceiros.
          </p>

          <p>As empresas do Grupo Superbid utilizarão e compartilharão as informações dos usuários para as seguintes finalidades:</p>

          <p>(i)	prestar, fornecer, manter, desenvolver, aprimorar, divulgar e ampliar a utilização dos serviços das empresas do Grupo Superbid;</p>

          <p>(ii)	identificar o usuário e personalizar a sua experiência e utilização dos serviços das empresas do Grupo Superbid;</p>

          <p>(iii)	possibilitar que as empresas do Grupo Superbid entrem em contato com o usuário a respeito dos serviços que disponibiliza,
            bem como para prestar suporte ao usuário;
          </p>

          <p>(iv)	possibilitar que as empresas do Grupo Superbid encaminhem ofertas ao usuário;</p>

          <p>(v)	permitir registros de crédito do usuário no Sistema de Informações de Crédito do Banco Central – (SCR);</p>

          <p>(vi)	permitir a consulta do nome do usuário em órgãos reguladores, birôs de crédito, serviços de compensação e Cadastro Positivo;</p>

          <p>(vii)	permitir a consulta de informações do usuário registradas na base de dados do
            Sistema de Informações de Crédito do Banco Central – (SCR);
          </p>

          <p>(viii)	disponibilizar e melhorar mecanismos de segurança dos serviços das empresas do Grupo Superbid;</p>

          <p>(ix)	detectar e proteger as empresas do Grupo Superbid e os usuários contra fraudes, abusos ou atos ilegais;</p>

          <p>(x)	detectar e impedir o uso de aplicativos e programas para propósitos fradulentos ou que visem alterar os serviços das
            empresas do Grupo Superbid, e
          </p>

          <p>(xi)	cumprir com obrigações legais de coleta e compartilhamento de dados com o poder público, na forma da legislação e
            regulamentação aplicáveis.
          </p>

          <p>Os dados de contato do usuário serão utilizados para as empresas do Grupo Superbid se comunicarem
            diretamente com o usuário e para o envio de informações relevantes, incluindo informações de transações,
            avisos sobre os serviços das empresas do Grupo Superbid, ofertas especiais, divulgação de eventos, notícias,
            práticas recomendadas, entre outras.
          </p>

          <p>Caso o usuário não queira receber tais informações deverá fazer a solicitação clicando no link disponível em
            todas as comunicações enviadas pelas empresas do Grupo Superbid.
          </p>

          <p><b>Consentimento</b></p>

          <p>Quando o usuário utiliza os serviços das empresas do Grupo Superbid concorda com os Termos e Condições de Uso do Superbid Exchange
            e da Conta Digital Superbid Pay e com a presente Política de Privacidade. O USUÁRIO CONSENTE EXPRESSAMENTE COM A COLETA, ARMAZENAMENTO, USO,
            PROCESSAMENTO, ASSOCIAÇÃO, COMPARTILHAMENTO, DIVULGAÇÃO E OUTROS MODOS DE TRATAMENTO DAS SUAS INFORMAÇÕES, INCLUINDO DADOS PESSOAIS,
            SOB QUALQUER DAS FORMAS E PARA TODAS AS FINALIDADES PREVISTAS NESTA POLÍTICA DE PRIVACIDADE.
          </p>

          <p>O USUÁRIO AUTORIZA O SUPERBID EXCHANGE E A SUPERBID PAY, PARA FINS DA LEI COMPLEMENTAR 105, DE 10 DE JANEIRO DE 2001,
            A COLETAR E COMPARTILHAR SUAS INFORMAÇÕES CADASTRAIS E DADOS PESSOAIS, BEM COMO INFORMAÇÕES RELATIVAS ÀS SUAS
            TRANSAÇÕES DE PAGAMENTO E SERVIÇOS CONTRATADOS COM EMPRESAS DO GRUPO SUPERBID, SEUS PRESTADORES DE SERVIÇOS,
            ALÉM DE OUTRAS INSTITUIÇÕES QUE CONTRIBUAM PARA O PROCESSAMENTO DAS TRANSAÇÕES DE PAGAMENTO E SERVIÇOS REALIZADOS A
            PARTIR DO SUPERBID EXCHANGE E DA CONTA DIGITAL SUPERBID PAY, DESDE QUE OBSERVADAS AS NORMAS RELATIVAS À PROTEÇÃO DE DADOS PESSOAIS,
            AO SIGILO BANCÁRIO E À PRESENTE POLÍTICA DE PRIVACIDADE.
          </p>

          <p>Alterações futuras desta Política de Privacidade que sejam consideradas relevantes, ou seja,
            que reduzam ou alterem direitos atribuídos ao usuário, serão comunicadas pelas empresas do Grupo Superbid com
            antecedência prévia adequada. Nesse caso, se o usuário não concordar com o teor da nova Política de Privacidade,
            as suas informações continuarão sendo tratadas em conformidade com a versão anterior pelo prazo de aviso prévio concedido,
            durante o qual o usuário poderá cessar o uso dos serviços das empresas do Grupo Superbid. Se o usuário continuar
            utilizando os serviços das empresas do Grupo Superbid após o término do prazo de aviso prévio, concordará integralmente
            com a nova Política de Privacidade, que passará a reger o tratamento das suas informações.
          </p>

          <p><b>Correção e Exclusão de Dados Pessoais</b></p>

          <p>O usuário poderá solicitar a revisão e correção de seus dados sem qualquer ônus e a qualquer tempo. Para isso,
            basta entrar em contato com as empresas do Grupo Superbid por meio do telefone [11-4950-9400] (todas as localidades)
            ou do e-mail [cac@superbid.net].(<b>&quot;Canais de Atendimento&quot;</b>).
          </p>

          <p>Se o usuário desejar excluir seus dados pessoais da base de cadastros das empresas do Grupo Superbid deverá entrar em
            contato através do e-mail [cac@superbid.net] para solicitar que suas informações sejam definitivamente eliminadas.
          </p>

          <p>Em observância à legislação aplicável ou cumprimento de ordem judicial, as empresas do Grupo Superbid poderão manter
            determinados dados armazenados por um período não inferior a 6 meses, após o seu pedido de exclusão. Referidos dados
            não serão anonimizados ou destruídos pelas empresas do Grupo Superbid antes da conclusão desse prazo.
          </p>

          <p>As empresas do Grupo Superbid armazenarão a solicitação do usuário de exclusão dos seus dados pessoais e, observado o prazo
            legal de guarda obrigatória de determinados dados, providenciarão a destruição ou anonimização, a critério exclusivo das empresas
            do Grupo Superbid, das informações capazes de identificar o usuário.
          </p>

          <p>Por outro lado, caso o usuário solicite a exclusão das suas informações mas ainda tenha alguma obrigação pendente de cumprimento
            com as empresas do Grupo Superbid, incluindo valores não pagos e saldo negativo, as informações dos usuários não serão excluídas
            e permanecerão armazenadas para o fim de viabilizar a solução da pendência e a adoção de medidas visando a cobrança do usuário
            pelas empresas do Grupo Superbid.
          </p>

          <p>O USUÁRIO CONCORDA COM ESTA POLÍTICA DE PRIVACIDADE E RECONHECE QUE ELA É COMPLEMENTAR AOS TERMOS E CONDIÇÕES DE USO DO
            SUPERBID EXCHANGE E DA CONTA DIGITAL SUPERBID PAY. O USUÁRIO RECONHECE, AINDA, QUE SE APLICAM AO TRATAMENTO DOS DADOS PESSOAIS
            DOS USUÁRIOS E DEMAIS INFORMAÇÕES OBJETO DESTA POLÍTICA DE PRIVACIDADE AS LEIS DO BRASIL.
          </p>

          <center>** ** ** **</center>

        </div>
      )}
    </Fragment>
  )
}

export default PrivacyPolicy;
