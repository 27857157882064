import { SettingsActions } from "../actions";

const defaultState = []
export default (state = defaultState, action) => {
    try {
        switch (action.type) {
            case SettingsActions.ACTION_GET_SETTINGS:
                return {
                    ...state,
                    settings: action.settings
                };

            default:
                return state;

        }
    }
    catch (err) {
        throw new Error(err.message);
    }
}
export function getSettings(state) {
    return state.settings;
}