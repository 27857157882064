import React from "react";

import Flex from "../elements/Flex";
import Typography from "../elements/Typography";

const Tabs = ({ tabs = [], selectedTab, onSelectTab, ...props }) => {
  return (
    <Flex {...props}>
      {tabs.map(tab => {
        const isSelected = selectedTab === tab.value;

        return (
          <Flex
            key={tab.value}
            width="120px"
            borderBottom="2px solid"
            borderColor={isSelected ? "brand.primary" : "text.lightest"}
            justifyContent="center"
            pb={0}
            onClick={() => onSelectTab(tab.value)}
          >
            <Typography
              fontSize="button.default"
              lineHeight="button.default"
              fontWeight="semibold"
              color={isSelected ? "brand.primary" : "text.dark"}
              textAlign="center"
              cursor="pointer"
            >
              {tab.title}
            </Typography>
          </Flex>
        )
      })}
    </Flex>
  )
}

export default Tabs;