import React, { Component, Fragment } from 'react';
import SupportHeader from '../../components/support/SupportHeader';
import OuvidoriaStepOne from './ouvidoria/OuvidoriaStepOne';
import OuvidoriaStepTwo from './ouvidoria/OuvidoriaStepTwo';
import OuvidoriaStepThree from './ouvidoria/OuvidoriaStepThree';

class SupportOuvidoria extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      protocol: null,
    }
  }

  nextStep = () => {
    this.setState(prevState => ({
      currentStep: prevState.currentStep < 3 ? prevState.currentStep += 1 : 3
    }));
  }

  getProtocol = (protocol) => {
    this.setState({ protocol: protocol });
  }

  render() {
    const { currentStep, protocol } = this.state;
    return (
      <Fragment>
        <div className="panel">
          <div className="panel__support">
            <SupportHeader title="Ouvidoria" />
            <OuvidoriaStepOne
              currentStep={currentStep}
              nextStep={this.nextStep}
            />
            <OuvidoriaStepTwo
              currentStep={currentStep}
              nextStep={this.nextStep}
              getProtocol={this.getProtocol}
            />
            <OuvidoriaStepThree
              currentStep={currentStep}
              nextStep={this.nextStep}
              protocol={protocol}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default SupportOuvidoria;
