import React from 'react';
import { Translate } from 'react-redux-i18n';

export const OperationType = {
  ALL: 0,
  RECEIPTS: 1,
  DEPOSIT: 2,
  WITHDRAWAL: 3,
};

export const OperationTypeMap = new Map([
  [OperationType.ALL, { description: <Translate value="operationTypeEnum.all" /> }],
  [OperationType.RECEIPTS, { description: <Translate value="operationTypeEnum.receipts" /> }],
  [OperationType.DEPOSIT, { description: <Translate value="operationTypeEnum.deposit" /> }],
  [OperationType.WITHDRAWAL, { description: <Translate value="operationTypeEnum.withdrawal" /> }],
]);

export const OperationTypeDropdownArray = [
  { id: OperationType.ALL, description: OperationTypeMap.get(OperationType.ALL).description },
  { id: OperationType.RECEIPTS, description: OperationTypeMap.get(OperationType.RECEIPTS).description },
  { id: OperationType.DEPOSIT, description: OperationTypeMap.get(OperationType.DEPOSIT).description },
  { id: OperationType.WITHDRAWAL, description: OperationTypeMap.get(OperationType.WITHDRAWAL).description },
];

export default OperationTypeMap;
