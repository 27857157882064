import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import SubmitButton from './SubmitButton';
import LoginCard from './LoginCard';
import ROUTES from '../../data/routes';
import 'react-toastify/dist/ReactToastify.css';
import { UserSelectors } from '../../app/store/reducers';
import TwoFactorAuthenticate from '../../data/enumerators/TwoFactorAuthenticate';
import { AuthActions } from '../../app/store/actions';
import LogoSbPay from '../../svg/logo/LogoSbPay';
// import Logo from '../../svg/login/ic_blue_logo.svg';

export class ConfirmIdentityContent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      secondFactorType: props.user && props.user.secondFactorType,
      email: props.user && props.user.email,
      cellphone: props.user && props.user.cellphone,
      code: ''
    };
  }

  componentDidMount() {
    // if (this.props.user == null) {
    //   this.props.history.push(ROUTES.LOGIN);
    // }
  }

  // hook renomeado, mover o fetch para componentDidUpdate(nextProps)

  // componentDidUpdate(){
  //    ...
  // }
  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({
        secondFactorType: nextProps.user.secondFactorType,
        email: nextProps.user.email,
        cellphone: nextProps.user.cellphone,
        code: ''
      });
    }
  }

  onCodeChange = (e) => {
    const code = e.target.value;
    this.setState(() => ({ code }));

    if (code.length === 6) {
      const element = document.getElementsByClassName('login__container__form__button')[0];
      element.focus();
    }
  };

  formIsValid = () => {
    const { code } = this.state;
    if (!code || code.length == 0) { throw new Error(I18n.t('error.codeEmpty')); }
  }

  confirmCode = async (e) => {
    e.preventDefault();
    try {

      this.formIsValid();
      await this.props.twoFactorConfirmCode(this.state.code);
      this.props.history.push(ROUTES.PANEL_START);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500
      });
    }
  }

  resendCode = async (secondFactorType) => {
    try {
      await this.props.resendCode(secondFactorType);
      this.setState(() => ({ code: '' }));
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500
      });
    }
  }

  changeSendType() {
    let { secondFactorType } = this.state;
    secondFactorType = secondFactorType == TwoFactorAuthenticate.SMS ?
      TwoFactorAuthenticate.EMAIL : TwoFactorAuthenticate.SMS;
    this.setState({ secondFactorType });
    this.resendCode(secondFactorType);
  }

  render() {
    const { secondFactorType, code, email, cellphone } = this.state;

    return (
      <LoginCard>
        <ToastContainer />
        <div className="row">
          <div className="col-12">
            <div className="login__logo ">
              {/* <Logo className="icon__login" /> */}
              <LogoSbPay
                width={270}
                height={84}
              />
            </div>
          </div>
        </div>

        <h3 className="login__container__text--card">{I18n.t('validation.cardTitle')}</h3>

        <div className="login__container__div">
          <span className="login__container__div__text--code">{I18n.t('validation.cardDescription')}
            {' '}
            {secondFactorType == TwoFactorAuthenticate.SMS && (
              I18n.t('validation.number') &&
              <mark className='login__container__highlight--code'>{cellphone}</mark>
            )}
            {secondFactorType == TwoFactorAuthenticate.EMAIL &&
              (
                I18n.t('validation.email') &&
                <mark className='login__container__highlight--code'>{email}</mark>
              )}
          </span>
        </div>

        <span className="login__container__title--code">{I18n.t('validation.inputTitle')}</span>
        <div className='row'>
          <div className='col-12'>
            <form
              onSubmit={this.confirmCode}
              className="login__container__form"
            >
              <input
                className="login__container__form__code--input"
                maxLength="6"
                type="tel"
                value={code}
                onChange={this.onCodeChange}
                placeholder="______"
              />

              <small className="login__container__form__small">{I18n.t('validation.smallText')}</small>

              <div className="row">
                <SubmitButton
                  name={I18n.t('validation.buttonText')}
                  hasShadow
                />
              </div>

              <div className="row login__container__form__links login__container__form__links--second-factor">
                <div className="col-6 d-flex justify-content-start align-items-center pl-0">
                  <button
                    type="button"
                    className="login__container__form__links__code"
                    onClick={() => this.resendCode(secondFactorType)}
                  >{I18n.t('validation.resubmit')}
                  </button>
                </div>

                <div className="col-6 d-flex justify-content-end align-items-center pr-0">
                  <button
                    type="button"
                    className="login__container__form__links__code"
                    onClick={() => this.changeSendType()}
                  >{I18n.t('validation.changeSendType')}
                    {' '}
                    {secondFactorType != TwoFactorAuthenticate.EMAIL && I18n.t('validation.email')}
                    {secondFactorType != TwoFactorAuthenticate.SMS && I18n.t('validation.cell')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </LoginCard>
    );
  }
}

const mapStateToProps = state => ({
  user: UserSelectors.getUser(state),
});

const mapDispatchToProps = dispatch => ({
  twoFactorConfirmCode: (code) => dispatch(AuthActions.twoFactorConfirmCode(code)),
  resendCode: (secondFactorType) => dispatch(AuthActions.resendTwoFactor(secondFactorType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ConfirmIdentityContent));
