import React, { Component } from 'react';
import InstitutionalMapList from '../../components/institutional/map/InstitutionalMapList';

class InstitutionalMap extends Component {
  componentDidMount = () => {
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <main className="main">
        <div className="institutional__section__header">
          Mapa do site
        </div>
        <div className="institutional__map">
          <InstitutionalMapList
            title="Lorem Ipsum"
            links={[
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' },
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' },
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' },
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' }]}
          />
          <InstitutionalMapList
            title="Lorem Ipsum"
            links={[
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' },
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' },
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' },
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' }]}
          />
          <InstitutionalMapList
            title="Lorem Ipsum"
            links={[
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' },
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' },
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' },
              { name: 'Lorem ipsum dolor sit amet consectetur', url: 'www.google.com' }]}
          />
        </div>
      </main>
    )
  }
}

export default InstitutionalMap;
