import React, { useEffect, useMemo, useState } from "react";
import { I18n } from "react-redux-i18n";

import IcMenu from "../icons/IcMenu";
import IcRefresh from "../icons/IcRefresh";
import IcEyeOpen from "../icons/IcEyeOpen";
import IcEyeClose from "../icons/IcEyeClose";
import LogoSbPay from "../../svg/logo/LogoSbPay";
import Alert from '../../svg/alert_orange.svg';
import { SettingsSelector } from "../../app/store/reducers";
import { SettingsActions } from "../../app/store/actions";
import { connect } from "react-redux";

const MobileHeader = ({
  openSidebar,
  showBalance,
  balance,
  availableBalance,
  refreshBalance,
  settingsList,
  getAllSettings
}) => {
  const [visibleBalance, setVisibleBalance] = useState(false);

    const disclaimerMessage = useMemo(() => {
      if(settingsList.settings){
          const disclaimer = settingsList.settings.find(setting => setting.key === "internetbankNotificationMessage");
          if(disclaimer.value !== '-'){
              return disclaimer.value;
          }
      }
        return null;

  },[settingsList])

  useEffect(() => {
    getAllSettings();
  },[])

  return (
  <div>
    {disclaimerMessage &&   
        <div className="disclaimer">
          <div>
            <Alert  />
          </div>
          <span>{disclaimerMessage}</span>
        </div>
    }
    <div className="mobile__header_container">
      <div className={`mobile__header ${!showBalance && 'mobile__header__shadow'}`}>
        <LogoSbPay
          style={{width: '115px', height: '35px'}}
          width={115}
          height={35}
        />
        <IcMenu onClick={openSidebar} />
      </div>

      {showBalance && (
        <React.Fragment>
          {/* <hr className="mobile__header__divider" /> */}

          <div className="mobile__header__balance">
            <div>
              <h6 className="mobile__header__balance__title">
                {I18n.t("header.balance")}
              </h6>
              <span className="mobile__header__balance__value">
                R${" "}
                {visibleBalance ? (
                  balance
                ) : (
                  <hr className="mobile__header__balance__value__hidden" />
                )}
              </span>
              <span className="mobile__header__balance__description">
                R${" "}
                {visibleBalance ? (
                  `${availableBalance} ${I18n.t("header.available")}`
                ) : (
                  <hr className="mobile__header__balance__description__hidden" />
                )}
              </span>
            </div>

            <div className="mobile__header__balance__menu">
              <IcRefresh
                className="mobile__header__balance__icon"
                onClick={refreshBalance}
              />
              {visibleBalance ? (
                <IcEyeOpen
                  className="mobile__header__balance__icon"
                  onClick={() => setVisibleBalance(false)}
                />
              ) : (
                <IcEyeClose
                  className="mobile__header__balance__icon"
                  onClick={() => setVisibleBalance(true)}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  settingsList: SettingsSelector.getSettings(state)
});

const mapDispatchToProps = (dispatch) => ({
  getAllSettings: () => dispatch(SettingsActions.getAllSettings())
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
