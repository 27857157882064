import React, { useCallback, useState } from "react";
import { useFormik } from 'formik';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { I18n } from "react-redux-i18n";

import * as Yup from "yup";

import Input from "../elements/Input";
import InputMask from "../elements/InputMask";
import { getCardType } from '../../helpers/card';
import { CreditCardActions } from "../../app/store/actions";
import FlipedCard from "./FlipedCard";
import IcClose from "../icons/IcClose";

const AddCreditCardModal = ({ taxIdentifier, onSaveCreditCard, onClose, saveCreditCard }) => {
  const [cardIsFlipped, setCardIsFlipped] = useState(false);

  const handleFlipCard = useCallback(() => {
    setCardIsFlipped(!cardIsFlipped)
  }, [cardIsFlipped]);

  const translate = useCallback((path) => {
    return I18n.t(`panel.walletPage.addCreditCardModal.${path}`)
  }, [I18n]);

  const addCreditCardValidationSchema = Yup.object().shape({
    number: Yup.string().isValidCardNumber(),
    holder: Yup.string().required(),
    taxIdentifier: Yup.string().required(),
    expiration: Yup.string().isValidCardExpiration(),
    brand: Yup.string().required(),
    cvv: Yup.string().isValidCardCVV('number'),
  });

  const handleSubmit = useCallback(async ({ number, holder, taxIdentifier, expiration, brand, cvv }) => {
    try {
      await saveCreditCard({ number, holder, taxIdentifier, expiration, brand, cvv });

      formik.resetForm();

      onSaveCreditCard();

      onClose();
    } catch (error) {
      toast.error(I18n.t('error.creditCard'), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      number: '',
      holder: '',
      taxIdentifier: taxIdentifier,
      expiration: '',
      brand: '',
      cvv: '',
    },
    validationSchema: addCreditCardValidationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <div className="add-credit-card-modal">
      <div className="add-credit-card-modal__header">
        <div>
          <h4>{translate("title")}</h4>
          <p>{translate("description")}</p>
          <hr />
        </div>
        <IcClose
          onClick={onClose}
        />
      </div>

      <div className="add-credit-card-modal__flags">
        <img
          src="/assets/images/cards/ic_master.svg"
          alt="mastercard-icon"
        />
        <img
          src="/assets/images/cards/ic_visa_blue.svg"
          alt="visa-icon"
        />
      </div>

      <div className="add-credit-card-modal__form-wrapper">
        <form
          className="add-credit-card-modal__form"
          onSubmit={formik.handleSubmit}
          style={{ gap: 16, marginTop: 16 }}
        >
          <InputMask
            name="number"
            placeholder={translate("form.number")}
            onChange={(e) => {
              formik.setFieldValue('brand', getCardType(e.target.value));
              formik.handleChange(e)
            }}
            mask="9999 9999 9999 9999"
            value={formik.values.number}
            error={formik.errors.number}
          />
          <Input
            name="holder"
            placeholder={translate("form.holder")}
            onChange={formik.handleChange}
            value={formik.values.holder}
            error={formik.errors.holder}
          />
          <div className="add-credit-card-modal__row">
            <InputMask
              name="expiration"
              placeholder={translate("form.expiration")}
              mask="99/99"
              onChange={formik.handleChange}
              value={formik.values.expiration}
              error={formik.errors.expiration}
            />
            <InputMask
              mt={{
                default: 1,
                sm: "0px"
              }}
              name="cvv"
              placeholder={translate("form.cvv")}
              mask="999"
              onChange={formik.handleChange}
              value={formik.values.cvv}
              error={formik.errors.cvv}
              onFocus={handleFlipCard}
              onBlur={handleFlipCard}
            />
          </div>
          <InputMask
            name="taxIdentifier"
            placeholder={translate(`form.${formik.values.taxIdentifier.length <= 11 ? "cpf" : "cnpj"}`)}
            mask={formik.values.taxIdentifier.length <= 11 ? "999.999.999-99" : "99.999.999/9999-99"}
            onChange={formik.handleChange}
            value={formik.values.taxIdentifier}
            readOnly
          />
        </form>


        <div className="mobile-hidden">
          <FlipedCard
            number={formik.values.number}
            holder={formik.values.holder}
            expiration={formik.values.expiration}
            cvv={formik.values.cvv}
            brand={formik.values.brand}
            cardIsFlipped={cardIsFlipped}
          />
        </div>
      </div>

      <button
        type="button"
        className="add-credit-card-modal__submit-button"
        onClick={() => formik.submitForm()}
      >
        {translate("form.submitButton")}
      </button>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  saveCreditCard: (creditCard) => dispatch(CreditCardActions.saveCreditCard(creditCard)),
});

export default connect(null, mapDispatchToProps)(AddCreditCardModal);