import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../data/routes';
import LoginActions from './LoginActions';
// import Logo from '../../svg/ic_white-logo.svg';
import LogoSbPay from '../../svg/logo/LogoSbPay';

const InstitutionalHeader = () => {
  return (
    <React.Fragment>
      <div className="institutional-header">
        <Link to={ROUTES.HOME}>
          {/* <Logo className="mobile-hidden icon__logo" /> */}
          <LogoSbPay />
        </Link>
        <LoginActions />
      </div>
    </React.Fragment>
  )
}

export default InstitutionalHeader;
