import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { Component, Error, Wrapper } from "./styles";

const InputMask = (
  {
    placeholder,
    mask,
    readOnly,
    error,
    className,
    style,
    onChange,
    name,
    type,
    value,
    mt,
    mb,
    ml,
    mr,
    m,
    ...props
  }, ref) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [hasError, setHasError] = useState(!!error);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current.value);
  }, []);

  const handleClearError = useCallback(() => {
    setHasError(false)
  }, []);

  const handleChange = useCallback((e) => {
    onChange(e);
  }, []);

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current.focus()
  }));

  useEffect(() => {
    if (error && !isFocused && !isFilled) {
      setHasError(true)
    }

    if (isFocused) {
      handleClearError();
    }
  }, [isFocused, error]);

  useEffect(() => {
    setIsFilled(!!value)
  }, [value]);

  return (
    <Wrapper
      mt={mt}
      mb={mb}
      mr={mr}
      ml={ml}
      m={m}
      className={className}
      style={style}
    >
      <Component
        isFocused={isFocused || isFilled}
        hasError={hasError}
      >
        <ReactInputMask
          inputRef={element => inputRef.current = element}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          type={type}
          name={name}
          mask={mask}
          value={value}
          maskChar=""
          readOnly={readOnly}
          required
          {...props}
        />

        <span>
          {placeholder}
        </span>
      </Component>
      {hasError && <Error>{error}</Error>}
    </Wrapper>
  )
}

export default forwardRef(InputMask);