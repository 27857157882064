import { addLoading, removeLoading } from './loading';
import CashOutRequests from '../../server/cash-out';

export const ACTION_WITHDRAW_MONEY = 'WITHDRAW_MONEY';

export const setCashOutData = CashOutData => ({
  type: ACTION_WITHDRAW_MONEY,
  cashout: CashOutData,
});

export const cashOutWithdraw = (cashout) => async (dispatch) => {
  let response;
  dispatch(addLoading());
  try {
    const cashOutResult = await CashOutRequests.withdrawMoney(cashout);
    response = await dispatch(setCashOutData(cashOutResult));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
  return response;
};
