import React, { useState } from "react";
import { connect } from 'react-redux'

import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import IcEyeOpen from '../icons/IcEyeOpen';
import IcEyeClose from '../icons/IcEyeClose';
import IcRefresh from '../icons/IcRefresh';
import TransactionCard from "../elements/TransactionCard";
import IcChevronFoward from '../icons/IcChevronFoward';
import IcExtract from "../icons/IcExtract";
import IcTransfer from '../icons/IcTransfer';
import IcCoin from "../icons/IcCoin";
import IcWallet from "../icons/IcWallet";
import { AccountActions } from "../../app/store/actions";

const TransactionsBalance = ({ balance = 0, getBalanceFinance, transactions }) => {
  const [visibleBalance, setVisibleBalance] = useState(true)

  return (
    <div className="balance_card">
      <div className="balance_card__header">
        <div className="balance_card__header__container">
          <h6 className="balance_card__header__container__title">{I18n.t('panel.startPage.balance')}</h6>
          {visibleBalance ? (
            <p className="balance_card__header__container__balance">
              {balance === 0 && 'R$ 0,00'}
              {balance !== null && balance !== 0 &&
                balance.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </p>
          ) : (
            <hr />
          )}
        </div>

        <div className="balance_card__header__icon">
          {visibleBalance ? (
            <IcEyeOpen
              width={24}
              height={24}
              onClick={() => setVisibleBalance(false)}
            />
          ) : (
            <IcEyeClose
              width={24}
              height={24}
              onClick={() => setVisibleBalance(true)}
            />
          )}
          <IcRefresh
            width={24}
            height={24}
            onClick={getBalanceFinance}
          />
        </div>
      </div>

      <div className="balance_card__mobile__header">
        <Link to="/painel/extrato">
          <div className="balance_card__mobile__header__button">
            <div className="balance_card__mobile__header__icon">
              <IcExtract />
            </div>
            <p className="balance_card__mobile__header__title">
              {I18n.t('panel.startPage.mobileMenu.extract')}
            </p>
          </div>
        </Link>
        <Link to="/painel/retirar-dinheiro">
          <div className="balance_card__mobile__header__button">
            <div className="balance_card__mobile__header__icon">
              <IcTransfer />
            </div>
            <p className="balance_card__mobile__header__title">
              {I18n.t('panel.startPage.mobileMenu.transfer')}
            </p>
          </div>
        </Link>
        <Link to="/painel/adicionar-dinheiro">
          <div className="balance_card__mobile__header__button">
            <div className="balance_card__mobile__header__icon">
              <IcCoin />
            </div>
            <p className="balance_card__mobile__header__title">
              {I18n.t('panel.startPage.mobileMenu.addMoney')}
            </p>
          </div>
        </Link>
        <Link to="/painel/carteira">
          <div className="balance_card__mobile__header__button">
            <div className="balance_card__mobile__header__icon">
              <IcWallet />
            </div>
            <p className="balance_card__mobile__header__title">
              {I18n.t('panel.startPage.mobileMenu.wallet')}
            </p>
          </div>
        </Link>
      </div>

      <h6
        className="balance_card__mobile__title"
      >
        {I18n.t('panel.startPage.history')}
      </h6>

      <hr className="balance_card__divider" />

      {transactions && transactions.length > 0 && transactions.map(transaction => (
        <TransactionCard
          key={transaction.id}
          title={transaction.message}
          date={transaction.createdAt}
          value={transaction.totalAmount}
        />
      ))}

      {transactions && transactions.length === 0 && (
        <div className="balance_card__empty">
          <IcExtract
            width={64}
            height={64}
          />

          <p className="balance_card__empty__text">{I18n.t('panel.startPage.emptyTransactions')}</p>
        </div>
      )}

      <hr className="balance_card__divider" />

      <Link
        to="/painel/extrato"
      >
        <button
          type="button"
          className="balance_card__button"
        >
          {I18n.t('panel.startPage.extract')}
          <IcChevronFoward />
        </button>
      </Link>

    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  getBalanceFinance: () => dispatch(AccountActions.balanceFinance()),
});

export default connect(null, mapDispatchToProps)(TransactionsBalance)