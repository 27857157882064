import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { SupportActions } from '../../../app/store/actions';

class OuvidoriaStepTwo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      protocol: '',
      message: '',
      contentLength: 0,
    }
  }

  handleChanges = (e) => {
    let changes = {};
    changes[e.target.name] = e.target.value;
    this.setState(changes);
  }

  handleMessage = (e) => {
    this.setState({ message: e.target.value, contentLength: (e.target.value).length });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { protocol, message } = this.state;
    if (protocol && message) {
      try {
        const res = await this.props.createSupportOuvidoria(message, protocol);
        this.props.getProtocol(res.protocolNumber);
        this.props.nextStep();
      } catch (err) {
        if (err && err.response && err.response.data) {
          if (err.response.data.error === 'protocol_number_not_found') {
            toast.error('Número de protocol não encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500
            });
          } else if (err.response.data.error === 'invalid_protocol_number') {
            toast.error('Não é possível criar uma ouvidoria a partir de uma ouvidoria.',
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1500
              });
          }
        } else {
          toast.error('Um erro aconteceu, por favor tente novamente.', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500
          });
        }
      }
    } else {
      toast.error('Por favor preencha todos os campos.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      });
    }
  }

  render() {
    const { currentStep } = this.props;
    const { contentLength } = this.state;
    return (
      <main>
        {currentStep === 2 && (
          <Fragment>
            <ToastContainer />
            <div className="ouvidoria__form">
              <form onSubmit={this.handleSubmit}>
                {/* eslint-disable-next-line max-len */}
                <div className="textarea__label">Número do protocolo</div>
                <input
                  className="support-email__text"
                  type="text"
                  name="protocol"
                  pattern="\d*"
                  onChange={this.handleChanges}
                />
                <div className="textarea__label">Mensagem</div>
                <textarea
                  className="support-email__text"
                  name="message"
                  cols="40"
                  rows="10"
                  maxLength="1024"
                  onChange={this.handleMessage}
                  placeholder="Escreva o que aconteceu e como podemos ajudar você"
                />
                <div className="support-email__character-counter">
                  Restam {1024 - contentLength} caracteres.
                </div>
                <div className="support-email__button__wrapper">
                  <button
                    type="submit"
                    className="support-email__button"
                  >Enviar mensagem
                  </button>
                </div>
              </form>
            </div>
          </Fragment>
        )}
      </main>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  createSupportOuvidoria: (message, protocol) => dispatch(SupportActions.createOuvidoria(message, protocol)),
});

export default connect(null, mapDispatchToProps)(OuvidoriaStepTwo);
