const formatCurrency = (value = '0') => {
    value = value.toString();
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    return value;
}

const parseCurrency = (value = '0') => {
    value = value.replaceAll('.', '');
    value = value.replace(',', '.');

    return Number(value);
}

export default { formatCurrency, parseCurrency }