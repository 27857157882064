import { addLoading, removeLoading } from './loading';
import PaymentRequests from '../../server/paymentOrder';

export const ACTION_GET_PAYMENT_FINANCE = 'GET_PAYMENT';
export const ACTION_GET_PAYMENT_ORDER = 'GET_ORDER';

export const setPaymentFinance = paymentData => ({
	type: ACTION_GET_PAYMENT_FINANCE,
	payment: paymentData,
});

export const setPaymentOrder = paymentOrder => ({
	type: ACTION_GET_PAYMENT_ORDER,
	paymentOrder: paymentOrder,
})

export const paymentsList = (startDate, endDate, status, orderHistory, limit) => async (dispatch) => {
	dispatch(addLoading());
	try {
		const { rows } = await PaymentRequests.getPaymentOrders(startDate, endDate, status, orderHistory, limit);
		await dispatch(setPaymentFinance(rows));
	} finally {
		dispatch(removeLoading());
	}
};

export const getPaymentOrder = (id) => async (dispatch) => {
	dispatch(addLoading());
	try {
		const paymentOrder = await PaymentRequests.getPaymentOrderById(id);
		await dispatch(setPaymentOrder(paymentOrder));
	} finally {
		dispatch(removeLoading());
	}
}
