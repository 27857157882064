import RestService from '../services/rest';

async function getPaymentOrders(startDate, endDate, status, orderHistory, limit) {
	let url = 'payment-order?';

	if (startDate) {
		url += `&startAt=${startDate}`;
	}

	if (endDate) {
		url += `&endAt=${endDate}`;
	}

	if (status) {
		url += `&status=${status}`;
	}

	if (orderHistory) {
		url += `&order=${orderHistory}`;
	}

	if (limit) {
		url += `&limit=${limit}`;
	}

	return RestService.getAuthenticated(url);
}

const getPaymentOrderById = async (id) => {
	return RestService.getAuthenticated(`payment-order/${id}`);
}

export default {
	getPaymentOrders,
	getPaymentOrderById
};
