import React from 'react';
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

const Modal = ({ children, isOpen, onClose }) => {
  return (
    <Popup
      open={isOpen}
      onClose={onClose}
      closeOnDocumentClick
      closeOnEscape
      position="center center"
    >
      <div className="w3-container w3-center w3-animate-bottom">
        {children}
      </div>
    </Popup>
  )
}

export default Modal;