import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import styled, { useTheme } from 'styled-components';
import { PaymentActions } from '../../app/store/actions';
import { AccountSelectors, PaymentSelectors, UserSelectors } from '../../app/store/reducers';

import IcChevronFoward from '../../components/icons/IcChevronFoward';
import TransactionsBalance from '../../components/modules/TransactionsBalance';

import Flex from '../../components/elements/Flex';
import Typography from '../../components/elements/Typography';
import Icon from '../../components/elements/Icon';
import routes from '../../data/routes';


const IncomeContainer = styled(Flex)`
  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.brand.primary};
  }
`;

const PanelStartPage = ({ balance, getPayments, payments, history }) => {
  const { colors } = useTheme()

  useEffect(() => {
    getPayments()
  }, []);

  return (
    <div
      className="panel_start"
    >
      <TransactionsBalance
        balance={balance && balance.real}
        transactions={payments && payments}
      />

      <div
        className="panel_start__links"
      >
        {/* <div
          className="panel_start__loan"
        >
          <section className="panel_start__loan__header">
            <h6>{I18n.t('panel.startPage.loan')}</h6>
            <p>{I18n.t('panel.startPage.loanDescription')}</p>
          </section>

          <Link
            to={{ pathname: preferences.simulateCredit }}
            target="_blank"
          >
            <button
              type="button"
              className="panel_start__loan__button"
            >
              {I18n.t('panel.startPage.loanSimulator')}
              <IcChevronFoward />
            </button>
          </Link>
        </div> */}

        <IncomeContainer
          flexDirection="column"
          border="1px solid"
          borderColor="text.lightest"
          borderRadius="xs"
          p={3}
          onClick={() => history.push(routes.PANEL_INFORM_INCOME)}
        >
          <Typography
            fontSize="title.sm"
            lineHeight="title.sm"
            fontWeight="semibold"
            color="text.dark"
          >
            Informe de rendimentos {new Date().getFullYear() - 1}
          </Typography>

          <Flex
            justifyContent="space-between"
            mt={0}
          >
            <Typography
              fontSize="body.sm"
              lineHeight="body.sm"
              color="text.medium"
            >
              Faça download do seu relatório anual
            </Typography>
            {/* <Typography
              fontSize="body.sm"
              lineHeight="body.sm"
              color="text.medium"
            >
              2021/2020
            </Typography> */}
          </Flex>

          <Flex
            justifyContent="space-between"
            mt={2}
            width="100%"
          >
            <Typography
              fontSize="button.md"
              lineHeight="button.md"
              fontWeight="bold"
              color="brand.primary"
              flex={1}
            >
              Relatórios anuais
            </Typography>

            <Icon
              icon="chevron-right-outline"
              size={20}
              color={colors.brand.primary}
            />
          </Flex>
        </IncomeContainer>

        <div>
          <Link to="/painel/adicionar-dinheiro">
            <div className="panel_start__section_button" title='Avançar para Adicionar saldo'>
              <button
                type="button"
                className="panel_start__loan__button"
              >
                {I18n.t('panel.startPage.addMoney')}
                <IcChevronFoward />
              </button>
            </div>
          </Link>

          <Link to="/painel/retirar-dinheiro">
            <div className="panel_start__section_button" title='Avançar para Transferir'>
              <button
                type="button"
                className="panel_start__loan__button"
              >
                {I18n.t('panel.startPage.transfer')}
                <IcChevronFoward />
              </button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: UserSelectors.getUser(state),
  payments: PaymentSelectors.getPayment(state),
  balance: AccountSelectors.getBalance(state),
});

const mapDispatchToProps = dispatch => ({
  getPayments: () => dispatch(PaymentActions.paymentsList(null, null, null, true, 6)),
});



export default connect(mapStateToProps, mapDispatchToProps)(PanelStartPage);
