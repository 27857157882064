import React from 'react';

const LoginCard = ({ children, hasMarginLogo }) => (
  <div className={`container ${hasMarginLogo && 'container-code'}`}>
    <div className="row justify-content-center">
      <div className={`col-lg-6 col-sm-12 ${hasMarginLogo && 'media-no-padding'}`}>
        <div className={`login__container white-card  ${hasMarginLogo && 'media-no-radius'}`}>
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default LoginCard;
