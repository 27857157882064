/* eslint-disable max-len */
import * as React from "react"

const IcEyeClose = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.81 13.89A8.93 8.93 0 0 0 21 8.12a1.006 1.006 0 1 0-2-.24 7.07 7.07 0 0 1-14 0 1.007 1.007 0 1 0-2 .24 8.93 8.93 0 0 0 3.18 5.77l-2.3 2.32a1 1 0 0 0 1.41 1.41l2.61-2.6c.97.49 2.02.802 3.1.92v3.56a1 1 0 0 0 2 0v-3.56a9.06 9.06 0 0 0 3.1-.92l2.61 2.6a1 1 0 0 0 1.41-1.41l-2.31-2.32Z"
      fill="currentColor"
    />
    <title>Exibir saldo</title>
  </svg>
)

export default IcEyeClose