export default {
  localhost: 'http://localhost:3025/',
  's4pay.mblabs.com.br': 'https://s4pay.mblabs.com.br/',
  'pre.s4pay.com.br': 'https://preapi.s4pay.com.br/',
  'stg.s4pay.com.br': 'https://stgapi.s4pay.com.br/',
  'stage.s4pay.com.br': 'https://api.stage.s4pay.com.br/',
  's4pay.com.br': 'https://api.s4pay.com.br/',
  'stage.superbidpay.com.br': 'https://api.stage.s4pay.com.br/',
  'superbidpay.com.br': 'https://api.s4pay.com.br/',
  'www2.s4pay.com.br': 'https://api-ecs.s4pay.com.br/',
};
