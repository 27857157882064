import { CashOutActions } from "../actions";

const defaultState = {
  cashout: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CashOutActions.ACTION_WITHDRAW_MONEY:
      return {
        ...state,
        withdrawMoney: action.withdrawMoney
      };
    default:
      return state;
  }
};

export function getCashOutData(state) {
  return state.cashOut.withdrawMoney;
}
