import { AccountActions } from "../actions";

const defaultState = {
	balance: null,
	extractList: null,
	transaction: null,
	schedules: null,
	details: null,
	isValidSchedule: null,
	deleteTicket: null
}

export default (state = defaultState, action) => {
	switch (action.type) {
		case AccountActions.ACTION_BALANCE_FINANCE:
			return {
				...state,
				balance: action.balance,
			};
		case AccountActions.ACTION_EXTRACT_FINANCE:
			return {
				...state,
				extractList: action.extractList
			};
		case AccountActions.ACTION_GET_TRANSACTION:
			return {
				...state,
				transaction: action.transaction
			};
		case AccountActions.ACTION_GET_ACCOUNT_DETAILS:
			return {
				...state,
				details: action.payload.transactionDetail
			}

		case AccountActions.ACTION_GET_SCHEDULE_CASHOUT:
			return {
				...state,
				schedules: action.payload.data
			};
		case AccountActions.ACTION_CHECK_SCHEDULE_CASHOUT:
			return {
				...state,
				isValidSchedule: action.payload.isValid
			}
		case AccountActions.ACTION_GET_DELETE_TICKET:
			return {
				...state,
				deleteTicket: action.payload.data
			}
		default:
			return state;
	}
};

export function getBalance(state) {
	return state.account.balance;
}

export function getTransaction(state) {
	return state.account.transaction;
}

export function getExtract(state) {
	return state.account.extractList;
}

export function getDetails(state) {
	return state.account.details;
}

export function getScheduleCashout(state){
	return state.account.schedules
}

export function getScheduleCashoutValidation(state){
	return state.account.isValidSchedule
}

export function getDeleteAccountTicket (state) {
	return state.account.deleteTicket
}
