import React from 'react';


import IcWithdrawColored from '../components/icons/IcWithdrawColored';
import IcSettings from '../components/icons/IcSettings';
import IcHelp from '../components/icons/IcHelpCircle';
import IcMoneyAddColored from '../components/icons/IcMoneyAddColored';
import IcWallet from '../components/icons/IcWallet';
import IcExtract from '../components/icons/IcExtract';
import IcHistory from '../components/icons/IcHistoryColored';
import IcHome from '../components/icons/IcHome';

import ROUTES from './routes';

export default {
  links: [
    {
      url: ROUTES.PANEL_START,
      name: 'Início',
      icon: <IcHome />,
    },
    {
      url: ROUTES.PANEL_HISTORY,
      name: 'Histórico',
      icon: <IcHistory />,
    },
    {
      url: ROUTES.PANEL_EXTRACT,
      name: 'Extrato',
      icon: <IcExtract size={24} />,
    },
    {
      url: ROUTES.PANEL_WALLET,
      name: 'Carteira',
      icon: <IcWallet size={24} />,
    },
    {
      url: ROUTES.PANEL_ADD_MONEY,
      name: 'Adicionar',
      icon: <IcMoneyAddColored />,
    },
    {
      url: ROUTES.PANEL_WITHDRAW_MONEY,
      name: 'Transferir',
      icon: <IcWithdrawColored />,
    },
    {
      url: ROUTES.PANEL_SETTINGS,
      name: 'Configurações',
      icon: <IcSettings />,
    },
    {
      url: ROUTES.PANEL_FAQ,
      name: 'Ajuda',
      icon: <IcHelp />,
    },
  ]
};
