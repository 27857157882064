import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ROUTES from '../../data/routes';
import { UserSelectors } from '../../app/store/reducers';
import ProfilePhoto from '../elements/ProfilePhoto';

const HeaderLeft = ({ user, closeSidebar }) => (
  <div className="col-md-4">
    <div className="header__left">
      <Link
        className="d-flex justify-content-start"
        to={ROUTES.PANEL_SETTINGS}
        onClick={closeSidebar} 
      >
        <ProfilePhoto />
        <span className="header__left__profile-name">
          {user && user.name}
        </span>
      </Link>
    </div>
  </div>
);

const mapStateToProps = state => ({
  user: UserSelectors.getUser(state),
});

export default connect(
  mapStateToProps,
  null,
)(HeaderLeft);
