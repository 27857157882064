import React, { useCallback, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';

import 'react-toastify/dist/ReactToastify.css';

import helpers from '../../helpers/currency';

const { formatCurrency, parseCurrency } = helpers

const CashInValue = ({ amount, setAmount, onContinue, balance }) => {
  const [isDisabled, setIsDisable] = useState(true);
  const [error, setError] = useState('')

  const handleAddAmount = useCallback(value => {
    const newValue = (parseCurrency(amount || '0') + parseCurrency(value))
      .toLocaleString('pt-br', { minimumFractionDigits: 2 })

    setAmount(newValue)
  }, [amount]);

  useEffect(() => {
    if (amount) {
      setIsDisable(parseCurrency(amount) > 100000 || parseCurrency(amount) < 30)
    } else {
      setIsDisable(true)
    }
  }, [amount])

  useEffect(() => {
    if (parseCurrency(amount) > 100000) {
      setError('O valor máximo do boleto é de R$ 100.000,00')
    } 
    else if(parseCurrency(amount) < 30 && parseCurrency(amount) !== 0){
      setError('O valor mínimo do boleto é de R$ 30,00')
    }
    else {
      setError('')
    }
  }, [amount])

  return (
    <div className="cash-in-value__content">
      <div className="cash-in-value__content__header">
        <h3 className="cash-in-value__content__title">
          {I18n.t("cashIn.value.title")}
        </h3>
        <p className="cash-in-value__content__description">
          {I18n.t("cashIn.value.description")} {' '}
          <b>{balance && balance.toLocaleString('pt-br', { style: 'currency', currency: "BRL" })}</b>
        </p>
      </div>

      <div className="cash-in-value__content__cashout_form">
        <input
          className="cash-in-value__content__cashout_form__input"
          value={`R$ ${amount}`}
          onChange={(e) => {
            setAmount(formatCurrency(e.target.value))
          }}
        />

        <p className="cash-in-value__content__cashout_form__error">
          {error}
        </p>

        <div className="cash-in-value__content__cashout_form__shortcuts">
          <button
            type='button'
            className="cash-in-value__content__cashout_form__shortcuts__button"
            onClick={() => handleAddAmount('50,00')}
          >+ {Number(50).toLocaleString('pt-br', { style: 'currency', currency: "BRL" })}
          </button>
          <button
            type='button'
            className="cash-in-value__content__cashout_form__shortcuts__button"
            onClick={() => handleAddAmount('200,00')}
          >+ {Number(200).toLocaleString('pt-br', { style: 'currency', currency: "BRL" })}
          </button>
          <button
            type='button'
            className="cash-in-value__content__cashout_form__shortcuts__button"
            onClick={() => handleAddAmount('500,00')}
          >+ {Number(500).toLocaleString('pt-br', { style: 'currency', currency: "BRL" })}
          </button>
        </div>
      </div>

      <button
        type="button"
        className="cash-in-value__content__button"
        onClick={() => onContinue(amount)}
        disabled={isDisabled}
      >{I18n.t("cashoutPage.continue")}
      </button>
    </div>
  )
}

export default CashInValue;
