import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import { useTheme } from 'styled-components';
import { Component, Error, Wrapper } from './styles';

const Select = (
  {
    placeholder,
    error,
    style,
    mt,
    mb,
    ml,
    mr,
    m,
    ...props
  }, ref) => {
  const selectRef = useRef(null);

  const { colors } = useTheme();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [hasError, setHasError] = useState(!!error);

  const color = useMemo(() => {
    if (isFocused || isFilled) {
      return colors.text.regular
    }

    if (error) {
      return colors.feedback.error
    }

    return colors.text.light
  }, [isFocused, isFilled, error]);

  const styles = useMemo(() => ({
    control: base => ({
      ...base,
      border: `1px solid ${color} !important`,
      borderRadius: "20px",
      height: "42px",
      padding: "0 16px",
      boxShadow: "none",
      color,
      fontFamily: "Manrope",
    }),
    valueContainer: base => ({
      ...base,
      padding: 0,
      height: "100%"
    }),
    input: base => ({
      ...base,
      fontWeight: 400,
      fontSize: "14px",
      color,
      fontFamily: "Manrope",
      '& input': {
        font: 'inherit',
      },
    }),
    placeholder: base => ({
      ...base,
      fontSize: "14px",
      color,
      display: isFocused || isFilled ? "flex" : "none",
    }),
    dropdownIndicator: base => ({
      ...base,
      color,
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      background: isFocused || isSelected ? "#a804df" : null,
      color: isFocused || isSelected ? "#FAFAFA" : null,
    })
  }), [isFocused, isFilled, error]);

  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!selectRef.current.state.value);
  }, []);

  const handleClearError = useCallback(() => {
    setHasError(false);
  }, []);

  useImperativeHandle(ref, () => ({
    focus: () => selectRef.current.select.focus(),
  }), []);

  useEffect(() => {
    if (error && !isFocused && !isFilled) {
      setHasError(true)
    }

    if (isFocused) {
      handleClearError();
    }
  }, [isFocused, error]);

  return (
    <Wrapper
      mt={mt}
      mb={mb}
      mr={mr}
      ml={ml}
      m={m}
      style={style}
    >
      <Component
        isFocused={isFocused || isFilled}
        hasError={hasError}
      >
        <ReactSelect
          ref={selectRef}
          styles={styles}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          placeholder={placeholder}
          openMenuOnFocus
          {...props}
        />

        <span>{placeholder}</span>
      </Component>
      {hasError && <Error>{error}</Error>}
    </Wrapper>
  )
}

export default forwardRef(Select);