import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import ROUTES from '../../../data/routes';

const CompanySection = () => {
  return (
    <Fragment>
      <div className="institutional__section__header">
        {I18n.t('institucional.aboutUs')}
      </div>
      <div
        className="create-banner"
      >
        <img
          src='/assets/images/institutional_background.png'
          alt="background"
        />
        <span className='create-banner-title'>A maneira mais segura de pagar na Superbid Exchange</span>
        <span className='create-banner-text'>
          Administre suas transações financeiras dentro do ecossistema Superbid
          Exchange com a conta digital Superbid Pay
        </span>
        <a
          href={ROUTES.CREATE_ACCOUNT}
          className='create-banner-register'
        >
          Quero me cadastrar
        </a>
        <a
          href={ROUTES.LOGIN}
          className='create-banner-register-login'
        >
          Login
        </a>
      </div>
    </Fragment>
  )
}

export default CompanySection;
