import { osName, isMobile, mobileVendor } from 'react-device-detect';
import { addLoading, removeLoading } from './loading';
import ConnectedDevicesRequests from '../../server/connected-devices';


export const ACTION_LIST_CONNECTED_DEVICES = 'LIST_CONNECTED_DEVICES';

export const saveList = data => ({
  type: ACTION_LIST_CONNECTED_DEVICES,
  connectedDeviceList: data,
});

export const getAllConnectedDevices = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await ConnectedDevicesRequests.getAll();
    dispatch(saveList(response.connectedDevices));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteConnectedDevices = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {

    let computerName = null;

    if (isMobile) {
      computerName = mobileVendor;
    } else {
      computerName = osName;
    }

    await ConnectedDevicesRequests.deleteById(id, computerName);
    dispatch(getAllConnectedDevices());
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
}

export const deleteAllConnectedDevices = () => async (dispatch) => {
  dispatch(addLoading());
  try {

    let computerName = null;

    if (isMobile) {
      computerName = mobileVendor;
    } else {
      computerName = osName;
    }

    await ConnectedDevicesRequests.deleteAll(computerName);
    dispatch(getAllConnectedDevices());
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
}
