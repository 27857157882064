import RestService from '../services/rest';

async function getAll() {
  return RestService.getAuthenticated('devices');
}

async function deleteById(id, computerName) {
  return RestService.deleteAuthenticated(`devices/${id}`, {
    computerName,
  });
}

async function saveDevice(token, dateDevice) {
  return RestService.postAuthenticated('devices/updateInfo', {
    token,
    dateDevice
  });
}

async function deleteAll(computerName) {
  return RestService.deleteAuthenticated('devices', {
    computerName,
  });
}

export default {
  getAll,
  deleteById,
  deleteAll,
  saveDevice,
};
