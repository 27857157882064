import React from 'react';
import FooterLinks from './FooterLinks';

const Footer = () => (
  <footer className="footer fixed-bottom">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="footer__navigation text-center">
            <FooterLinks />
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
