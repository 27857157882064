import { UtilsActions } from "../actions";

const defaultState = {
  bankList: null,
  transferOutFee: null,
  titleDoubtText: null,
  systemDataMatera: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case UtilsActions.ACTION_LIST_BANK:
      return {
        ...state,
        bankList: action.bankList
      };
    case UtilsActions.ACTION_TRANSFER_OUT_FEE:
      return {
        ...state,
        transferOutFee: action.transferOutFee
      };
    case UtilsActions.ACTION_TITLE_DOUBT_TEXT:
      return {
        ...state,
        titleDoubtText: action.titleDoubtText
      };
    case UtilsActions.ACTION_SYSTEM_DATE_MATERA:
      return {
        ...state,
        systemDataMatera: action.systemDataMatera
      };
    default:
      return state;
  }
};

export function getSystemDateMatera(state) {
  return state.utils.systemDataMatera;
}

export function getBanks(state) {
  return state.utils.bankList;
}

export function getTranferOutFee(state) {
  return state.utils.transferOutFee;
}

export function getTitleDoubtText(state) {
  return state.utils.titleDoubtText;
}
