/* eslint-disable max-len */
import { addLoading, removeLoading } from './loading';
import AccountRequests from '../../server/account';

export const ACTION_BALANCE_FINANCE = 'BALANCE_FINANCE';
export const ACTION_EXTRACT_FINANCE = 'EXTRACT_FINANCE';
export const ACTION_GET_TRANSACTION = 'GET_TRANSACTION';
export const ACTION_GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS';
export const ACTION_GET_SCHEDULE_CASHOUT = 'ACTION_GET_SCHEDULE_CASHOUT';
export const ACTION_CHECK_SCHEDULE_CASHOUT = 'ACTION_CHECK_SCHEDULE_CASHOUT';
export const ACTION_GET_DELETE_TICKET = 'ACTION_GET_DELETE_TICKET';
export const ACTION_POST_DELETE_TICKET = 'ACTION_POST_DELETE_TICKET';

export const setBalanceFinance = balanceData => ({
	type: ACTION_BALANCE_FINANCE,
	balance: {
		real: balanceData.find(o => o.balanceType === 'REAL').amount ? balanceData.find(o => o.balanceType === 'REAL').amount : 0,
		available: balanceData.find(o => o.balanceType === 'AVAILABLE').amount ? balanceData.find(o => o.balanceType === 'AVAILABLE').amount : 0,
		blocked: balanceData.find(o => o.balanceType === 'BLOCKED').amount ? balanceData.find(o => o.balanceType === 'BLOCKED').amount : 0,
	},
});

export const setExtractFinance = extractData => ({
	type: ACTION_EXTRACT_FINANCE,
	extractList: extractData
});

export const setTransaction = transactionData => ({
	type: ACTION_GET_TRANSACTION,
	transaction: transactionData,
});

export const setAccountDetail = transactionDetail => ({
	type: ACTION_GET_ACCOUNT_DETAILS,
	payload: {
		transactionDetail
	}
});


export const setScheduleCashout = data => ({
	type: ACTION_GET_SCHEDULE_CASHOUT,
	payload: {
		data
	}
})


export const setScheduleCashoutValidation = isValid => ({
	type: ACTION_CHECK_SCHEDULE_CASHOUT,
	payload: {
		isValid
	}
})

export const setDeleteAccountTicket = data => ({
	type: ACTION_GET_DELETE_TICKET,
	payload: {
		data
	}
})

export const balanceFinance = () => async (dispatch) => {
	dispatch(addLoading());
	try {
		const balance = await AccountRequests.getBalanceFinance();

		await dispatch(setBalanceFinance(balance));
	} finally {
		dispatch(removeLoading());
	}
};

export const getTransaction = (id) => async (dispatch) => {
	dispatch(addLoading());
	try {
		let transaction = null;

		transaction = await AccountRequests.getTransactionById(id);

		await dispatch(setTransaction(transaction));
	} finally {
		dispatch(removeLoading());
	}
};

export const getDeleteAccountTicket = () => async (dispatch) => {
	dispatch(addLoading());
	try {
		const data = await AccountRequests.getDeleteAccountTicket();

		await dispatch(setDeleteAccountTicket(data));
	} catch (error) {
		throw new Error(error)
	}
	finally {
		dispatch(removeLoading());
	}
}

export const getPublicTransaction = (id, userId) => async (dispatch) => {
	dispatch(addLoading());
	try {
		let transaction = null;
		transaction = await AccountRequests.getPublicTransaction(id, userId);
		await dispatch(setTransaction(transaction));
	} finally {
		dispatch(removeLoading());
	}
};

export const getExtractDetails = (extract) => async (dispatch) => {
	dispatch(addLoading());
	try {
		const transaction = extract;
		await dispatch(setTransaction(transaction));
	} finally {
		dispatch(removeLoading());
	}
};

export const postDeleteAccountTicket = (body) => async(dispatch) => {
	dispatch(addLoading());
	try {
		let ticket =  await AccountRequests.postDeleteAccountTicket(body)
		await dispatch(setDeleteAccountTicket(ticket));
	} catch (error) {
		throw new Error(error);
	}
	finally {
		dispatch(removeLoading())
	}
}

export const extractFinance = (startDate, endDate, typeFilter, orderExtract) => async (dispatch) => {
	dispatch(addLoading());
	try {
		const extract = await AccountRequests.getExtractFinance(startDate, endDate, typeFilter, orderExtract) || [];

		// const dates = [...new Set(extract.filter(e => e.type !== 'S').map(o => o.creditDate ? o.creditDate : null))];
		// const extractPerDay = dates.map((date, index) => {
		// 	let extractAmount = extract.find((extr) => extr.creditDate === date && extr.type === 'S');
		// 	return {
		// 		id: index,
		// 		date,
		// 		balance: extractAmount && extractAmount.amount,
		// 		list: extract.filter(extr => extr.creditDate === date && extr.type !== 'S')
		// 	}
		// });

		// await dispatch(setExtractFinance(extractPerDay));
		await dispatch(setExtractFinance(extract));
		return extract;
	} catch (err) {
		throw new Error(err);
	} finally {
		dispatch(removeLoading());
	}
}

export const extractFinanceCSV = (startDate, endDate) => async (dispatch) => {
	dispatch(addLoading());
	try {
		await AccountRequests.getExtractFinanceInCSV(startDate, endDate);
	} catch (err) {
		throw new Error(err);
	} finally {
		dispatch(removeLoading());
	}
};

export const getAccountDetails = () => async (dispatch) => {
	dispatch(addLoading());

	try {
		const transactionData = await AccountRequests.getAccountDetails();

		dispatch(setAccountDetail(transactionData.account))
	} catch (err) {
		throw new Error(err);
	} finally {
		dispatch(removeLoading());
	}
}



export const getScheduleCashout = () => async (dispatch) => {
	dispatch(addLoading());
	try {
		
		const schedules = await AccountRequests.getScheduleCashout();

		dispatch(setScheduleCashout(schedules));

	} catch (err) {
		throw new Error(err);
	} finally {
		dispatch(removeLoading());
	}
}

export const getScheduleCashoutValidation = (amount) => async (dispatch) => {
	dispatch(addLoading());
	try {
		const data = await AccountRequests.getScheduleCashoutValidation(amount);
		dispatch(setScheduleCashoutValidation(data));
		if(data.data === false){
			return data.data;
		}
		else {
			return data;
		}
	} catch (err) {
		throw new Error(err);
	} finally {
		dispatch(removeLoading());
	}
}
