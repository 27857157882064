import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { I18n } from 'react-redux-i18n';
import { SettingsSelector, UserSelectors } from "../../app/store/reducers";
import { AuthActions, SettingsActions } from "../../app/store/actions";

import HeaderMenuButton from "./HeaderMenuButton";

import IcEyeOpen from "../icons/IcEyeOpen";
// import IcNotification from "../icons/IcNotification";
import IcEyeClose from "../icons/IcEyeClose";
import IcRefresh from "../icons/IcRefresh";
import Routes from "../../data/routes";
import { Menu } from "./styles";
import LogoSbPay from "../../svg/logo/LogoSbPay";
import Alert from '../../svg/alert_orange.svg';

const Header = ({balance, user, logout, showHeaderBalanceWeb, refreshBalance, getAllSettings, settingsList }) => {
  const [showBalance, setShowBalance] = useState(false);
  const [showMenu, setShowMenu] = useState(false);


  useEffect(() => {
    getAllSettings();
  },[])

  const disclaimerMessage = useMemo(() => {
      if(settingsList.settings){
          const disclaimer = settingsList.settings.find(setting => setting.key === "internetbankNotificationMessage");
          if(disclaimer.value !== '-'){
              return disclaimer.value;
          }
      }
        return null;

  },[settingsList])


  return (
    	<div>
        {disclaimerMessage && 
            <div className="disclaimer">
              <Alert style={{marginRight: '10px', marginLeft: '2px'}} />
              <span>{disclaimerMessage}</span>
          </div>
        }
      <header className={`header ${disclaimerMessage ? 'header__margin' : ''}`}>
        <a href={Routes.HOME}>
          <LogoSbPay />
        </a>

        <section className="actions">
          {showHeaderBalanceWeb && (
          <div className="balance_container">
            <div className="balance_box">
              <span className="balance_title">{I18n.t("header.balance")}</span>

              <span className="balance_value">
                R$ {showBalance ? <hr className="balance_hidden" /> : balance}
              </span>
            </div>

            <IcRefresh
              className="balance_icon"
              onClick={refreshBalance}
            />

            {showBalance ? (
              <IcEyeClose
                className="balance_icon"
                onClick={() => setShowBalance(false)}
              />
            ) : (
              <IcEyeOpen
                className="balance_icon"
                onClick={() => setShowBalance(true)}
              />
            )}
          </div>
        )}

          {/* <div className="icon">
          <IcNotification />
        </div> */}

          <div
            className="icon profile"
            onClick={() => setShowMenu(!showMenu)}
            aria-hidden="true"
          >
            {user && user.photoUrl && (
            <img
              src={user.photoUrl}
              alt="profile-avatar"
              className="profile_avatar"
              style={{
                width: 32,
                height: 32,
                borderRadius: "50%"
              }}
            />
          )}

            {user && !user.photoUrl && (
            <span title="Menu perfil">
              {user.name.split(" ")[0].slice(0, 1).toUpperCase()}
              {user.name.split(" ").length > 1 && user.name.split(" ")[1].slice(0, 1).toUpperCase()}
            </span>
          )}


            {showMenu && (
            <Menu
              onMouseLeave={() => setShowMenu(false)}
            >
              <HeaderMenuButton
                title={I18n.t("header.menu.settings")}
                icon="settings-2-outline"
                link={Routes.PANEL_SETTINGS}
              />
              <HeaderMenuButton
                title={I18n.t("header.menu.ombudsman")}
                icon="headphones-outline"
                link={Routes.OMBUDSMAN}
                target="_blank"
                rel="noreferrer"
              />
              <HeaderMenuButton
                title={I18n.t("header.menu.help")}
                icon="question-mark-circle-outline"
                link={Routes.HELP}
              />
              <HeaderMenuButton
                title={I18n.t("header.menu.informIncome")}
                customIconProvider="google"
                icon="outline-summarize"
                link={Routes.PANEL_INFORM_INCOME}
              />
              <HeaderMenuButton
                title={I18n.t("header.menu.logout")}
                icon="log-out-outline"
                onClick={logout}
              />
            </Menu>
          )}
          </div>
        </section>
      </header>
    </div>
  )
}



const mapStateToProps = (state) => ({
  user: UserSelectors.getUser(state),
  settingsList: SettingsSelector.getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.cleanAuth()),
  getAllSettings: () => dispatch(SettingsActions.getAllSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
