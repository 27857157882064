import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SupportEmailContent from '../../components/support/SupportEmailContent';
import SupportHeader from '../../components/support/SupportHeader';
import { UtilsSelectors } from '../../app/store/reducers';

const SupportEmail = ({ getDoubt}) => {
  return (
    <Fragment>
      <div className="panel">
        <div className="panel__support">
          <SupportHeader title={getDoubt} /><br />
          <span className="panel__support__text mobile-hidden">Mensagem</span>
          <SupportEmailContent />
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  getDoubt: UtilsSelectors.getTitleDoubtText(state),
});

export default connect(mapStateToProps, null)(SupportEmail);
