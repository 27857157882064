import moment from 'moment-timezone';

const formatExtractDate =  async (stringDate) => {


    switch (stringDate) {
        case "7 dias":
        return moment().subtract(1, 'w').format('YYYY-MM-DD');

        case "15 dias":
        return moment().subtract(15, 'd').format('YYYY-MM-DD');

        case "30 dias":
        return moment().subtract(1, 'month').format('YYYY-MM-DD');

        case "60 dias": 
        return moment().subtract(2, 'month').format('YYYY-MM-DD');

        case "90 dias":
        return moment().subtract(3, 'month').format('YYYY-MM-DD');
    }

}


export default formatExtractDate;