import React from 'react';
import styled from 'styled-components';
import { border, color, flexbox, layout, position, shadow, space } from 'styled-system';

const Component = styled.div`
  display: flex;
  gap: ${({ gap }) => gap};

  ${layout};
  ${flexbox};
  ${shadow};
  ${border};
  ${color};
  ${position};
  ${space};
`;

const Flex = ({ children, ...props }) => {
  return (
    <Component
      {...props}
    >
      {children}
    </Component>
  )
}

export default Flex;