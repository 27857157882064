import RestService from '../services/rest';

async function getUser() {
  return RestService.getAuthenticated('user/me');
}

async function updateAddressUser(address) {
  return RestService.putAuthenticated('user/address', address);
}

async function updateCellphoneUser(phone) {
  return RestService.putAuthenticated('user/phone', phone);
}

async function updateEmailUser(email) {
  return RestService.putAuthenticated('user/email', email);
}

async function updateFileUser(file) {
  return RestService.postFileAuthenticated('user/validation-file', file);
}

// async function updateUser(id,data){
//   return RestService.postAuthenticated()
// }

export default {
  getUser,
  updateAddressUser,
  updateCellphoneUser,
  updateEmailUser,
  updateFileUser
};
