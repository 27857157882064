
import SettingRequests from '../../server/settings';

export const ACTION_GET_SETTINGS = 'GET_SETTINGS';
export const saveList = list => ({
    type: ACTION_GET_SETTINGS,
    settings: list,
});

export const getAllSettings = () => async (dispatch) => {
    try {
        const list = await SettingRequests.getAllSettings();
        await dispatch(saveList(list))
        return list
    } catch (err) {
        throw err;
    }
};