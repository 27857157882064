/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from "react";

const SelectAccountCard = ({ bankName, name, agency, account, accountType, checked = false, onSelect }) => {
  const checkedClassName = useMemo(() => checked ? "checked" : "", [checked]);

  return (
    <div
      className={`select-account-card ${checkedClassName}`}
      onClick={onSelect}
      aria-hidden
    >
      <div
        className="select-account-card__check"
      >
        <div className={`select-account-card__check__circle ${checkedClassName}`}>
          <div className="select-account-card__check__circle__dot" />
        </div>
        <p className={`select-account-card__check__label ${checkedClassName}`}>{bankName}</p>
      </div>

      <hr className="select-account-card__divider" />

      <div className="select-account-card__content">
        <span>{name}</span>
        <p>Agência: {agency}</p>
        <p>{accountType === 1 ? "Conta corrente" : "Conta poupança"}: {account}</p>
      </div>
    </div>
  )
}

export default SelectAccountCard;