import React, { Component } from 'react';
import AboutSection from '../../components/institutional/about/AboutSection';
import CompanySection from '../../components/institutional/about/CompanySection';
import PillarSection from '../../components/institutional/about/PillarSection';

class InstitutionalAbout extends Component {
  componentDidMount = () => {
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <main className="main">
        <CompanySection />
        <AboutSection />
        <PillarSection />
      </main>
    )
  }
}

export default InstitutionalAbout;
