import React, { useCallback } from "react";
import { Translate } from "react-redux-i18n";
import { toast } from "react-toastify";

const CashInInformation = ({ title, description }) => {
  const copyToClipboard = useCallback((text, id) => {
    const browser = navigator.userAgent;
    if (browser.includes('Safari') && !browser.includes('Chrome')) {
      const el = window.document.getElementById(id);
      el.select();
    } else {
      navigator.clipboard.writeText(text);
      toast.success(<Translate value="toastMessage.copy" />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      });
    }
  }, []);

  const idItem = `${title - description}`;

  return (
    <div className="ted-information">
      <h6 className="ted-information__title">
        {title}
      </h6>
      <p
        className="ted-information__description"
        onClick={() => copyToClipboard(description, idItem)}
        id={idItem}
        aria-hidden
      >
        {description}
      </p>
    </div>
  )
}

export default CashInInformation