import React, { useCallback, useMemo } from "react";
import { I18n } from "react-redux-i18n";
import helpers from '../../helpers/number'
import Button from "../elements/Button";
import IcPencil from "../icons/IcPencil";

const CashOutDetails = ({ details, onClickBack, onClickChangeAccount, onConfirm }) => {
  const cashout = useMemo(() => {
    const { comment, amount, limitTime } = details;
    const { fee, bank, agency, account, accountTypeDestination, customer, taxIdentifier } = comment

    const currency = (value) => {
      return value.toLocaleString('pt-br', { style: "currency", currency: "BRL" })
    }

    const format = (value) => {
      return value || "********";
    }

    const parseTaxIdentifier = (value) => {
      if (!value) {
        return null
      }

      if (value.length <= 11) return helpers.cpfMask(taxIdentifier);

      return helpers.cnpjMask(taxIdentifier)
    }

    return {
      total: format(currency(amount + fee)),
      fee: currency(fee),
      amount: format(currency(amount)),
      bank: format(bank),
      agency: format(agency),
      account: format(account),
      accountTypeDestination: format(accountTypeDestination),
      customer: format(customer),
      taxIdentifier: format(parseTaxIdentifier(taxIdentifier)),
      limitTime
    }
  }, [details]);

  const translate = useCallback((path) => {
    return I18n.t(`cashoutPage.${path}`)
  }, [I18n]);

  return (
    <div className="cashout_details">
      <div className="cashout_details__header">
        <p className="cashout_details__header__title">
          {translate("totalValue")}
        </p>
        <h3 className="cashout_details__header__value">
          {cashout.total}
        </h3>
      </div>

      <hr className="cashout_details__divider" />

      <section className="cashout_details__fee">
        {cashout.fee > 0 && (
          <div className="cashout_details__fee__information">
            <p className="cashout_details__fee__information__text">
              {translate("fee")}
            </p>
            <span className="cashout_details__fee__information__value">
              {cashout.fee}
            </span>
          </div>
        )}

        <div className="cashout_details__fee__information">
          <p className="cashout_details__fee__information__text">
            {translate("amount")}
          </p>
          <span className="cashout_details__fee__information__value">
            {cashout.amount}
          </span>
        </div>
      </section>

      <hr className="cashout_details__divider" />

      <div className="cashout_details__flex">
        <div className="cashout_details__account">
          <p className="cashout_details__account__title">
            {translate("bank")}
          </p>
          <span className="cashout_details__account__value">
            {cashout.bank}
          </span>
        </div>

        <div className="cashout_details__update">
          <Button
            variant="secondary"
            color="secondary"
            onClick={onClickChangeAccount}
            style={{ textAlign: "right" }}
          >
            {translate("changeAccount")}
          </Button>
          <IcPencil
            onClick={onClickChangeAccount}
          />
        </div>
      </div>

      <div className="cashout_details__flex">
        <div className="cashout_details__account">
          <p className="cashout_details__account__title">
            {translate("agency")}
          </p>
          <span className="cashout_details__account__value">
            {cashout.agency}
          </span>
        </div>
        <div className="cashout_details__account">
          <p className="cashout_details__account__title">
            {translate("account")}
          </p>
          <span className="cashout_details__account__value">
            {cashout.account}
          </span>
        </div>
        <div className="cashout_details__account">
          <p className="cashout_details__account__title">
            {translate("type")}
          </p>
          <span className="cashout_details__account__value">
            {cashout.accountTypeDestination}
          </span>
        </div>
      </div>

      <div className="cashout_details__flex">
        <div className="cashout_details__account">
          <p className="cashout_details__account__title">
            {translate("name")}
          </p>
          <span className="cashout_details__account__value">
            {cashout.customer}
          </span>
        </div>
        <div className="cashout_details__account">
          <p className="cashout_details__account__title">
            {translate("cpf")}
          </p>
          <span className="cashout_details__account__value">
            {cashout.taxIdentifier}
          </span>
        </div>
      </div>

      <hr className="cashout_details__divider" />

      <div className="cashout_details__account">
        <p className="cashout_details__account__title">
          {translate("note.title")}
        </p>
        <span className="cashout_details__account__value">
          {translate("note.description1")}
          {' '}{cashout.limitTime}h{' '}
          {translate("note.description2")}
        </span>
      </div>

      <hr className="cashout_details__divider" />

      <div className="cashout_details__footer">
        <Button
          variant="secondary"
          onClick={onClickBack}
        >
          {translate("changeValue")}
        </Button>
        <Button
          onClick={onConfirm}
        >
          {translate("confirm")}
        </Button>
      </div>
    </div>
  )
}

export default CashOutDetails;