import React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import ROUTES from '../../data/routes';

const FooterLinks = () => (
  <div className="footer__navigation-links">
    <Link
      className="footer__navigation-links__single"
      to={ROUTES.PANEL_ABOUT}
    >
      {I18n.t('institucional.footer.about')}
    </Link>
    <Link
      className="footer__navigation-links__single"
      to={ROUTES.PANEL_FAQ}
    >
      {I18n.t('institucional.footer.help')}
    </Link>
    <Link
      className="footer__navigation-links__single"
      to={ROUTES.PANEL_TERMS}
    >
      {I18n.t('institucional.footer.terms')}
    </Link>
    <a
      className="footer__navigation-links__single"
      href={ROUTES.OMBUDSMAN}
      target="_blank"
      rel="noreferrer"
    >
      {I18n.t('institucional.footer.ombudsman')}
    </a>
  </div>
);

export default FooterLinks;
