import { UserInformIncomeActions } from "../actions";

const defaultState = {
    incomes: [],
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case UserInformIncomeActions.ACTION_SAVE_INCOMES:
            return {
                ...state,
                incomes: action.payload.incomes,
            };
        default:
            return state;
    }
};

export function getUserInformIncomes(state) {
    return state.informIncome.incomes;
}
