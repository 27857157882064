/* eslint-disable max-len */
import * as React from "react"

const IcHelpCircle = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1.667a8.333 8.333 0 1 0 0 16.666 8.333 8.333 0 0 0 0-16.666Zm0 15a6.667 6.667 0 1 1 0-13.334 6.667 6.667 0 0 1 0 13.334Z"
      fill="currentColor"
    />
    <path
      d="M10 5a2.917 2.917 0 0 0-2.917 2.917.833.833 0 1 0 1.667 0A1.25 1.25 0 1 1 10 9.167a.833.833 0 0 0-.833.833v1.667a.833.833 0 0 0 1.666 0V10.7A2.908 2.908 0 0 0 10 5ZM10 15a.833.833 0 1 0 0-1.667A.833.833 0 0 0 10 15Z"
      fill="currentColor"
    />
  </svg>
)

export default IcHelpCircle