import styled, { css } from "styled-components";
import { space } from "styled-system";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  ${space};
`;

export const filled = css`
    input {
        border-color: ${({ theme }) => theme.colors.text.regular};
        color: ${({ theme }) => theme.colors.text.regular};
    }

    span {
        top: -8px;
        left: 12px;
        font-size: 10px;
        padding: 0 8px;
        color: ${({ theme }) => theme.colors.text.regular};
        background: ${({ theme }) => theme.colors.background.default};
    }
`;

export const Component = styled.div`
  position: relative;

  input {
    width: 100%;
    height: 42px;

    padding: 0 16px;

    background: transparent;

    border: 1px solid ${({ theme }) => theme.colors.text.light};
    border-radius: 20px;

    font-weight: 400;
    font-size: 14px;
  }

  span {
    color: ${({ theme }) => theme.colors.text.light};
    position: absolute;
    top: 10px;
    left: 16px;
    font-size: 14px;
    letter-spacing: 0.05em;
    pointer-events: none;
    transition: .2s;
  }

  ${({ isFocused }) => isFocused && filled};
  ${({ isFilled }) => isFilled && filled};
  ${({ hasError }) => hasError && css`
      input {
        border-color: ${({ theme }) => theme.colors.feedback.error};
      }

      span {
        color: ${({ theme }) => theme.colors.feedback.error};
      }
  `};
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.feedback.error};
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  margin-top: 6px;
  margin-bottom: 0;
`;