import RestService from '../services/rest';

async function getRequestBankList() {
  return RestService.getAuthenticated('utils/banks');
}

async function getCep(cep) {
  return RestService.getAuthenticated(`utils/address?cep=${cep}`);
}

async function getTransferOutFee() {
  return RestService.getAuthenticated(`utils/transfer-out-fee`);
}

async function getSystemDateMatera() {
  return RestService.getAuthenticated(`utils/matera/system-date`);
}

export default {
  getCep,
  getRequestBankList,
  getTransferOutFee,
  getSystemDateMatera
};
