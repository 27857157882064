import { PaymentActions } from "../actions";

const defaultState = {
	payment: null,
	paymentOrder: null,
}

export default (state = defaultState, action) => {
	switch (action.type) {
		case PaymentActions.ACTION_GET_PAYMENT_FINANCE:
			return {
				...state,
				paymentsData: action.payment,
			};
		case PaymentActions.ACTION_GET_PAYMENT_ORDER:
			return {
				...state,
				paymentOrder: action.paymentOrder,
			};
		default:
			return state;
	}
};

export function getPayment(state) {
	return state.payment.paymentsData;
}

export function getPaymentOrder(state) {
	return state.payment.paymentOrder;
}

