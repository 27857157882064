import React from 'react';
import NumberFormat from 'react-number-format';

const MoneyValue = ({ value, renderText }) => {
  return (
    <NumberFormat
      value={(typeof value) === 'string' ? (+value) : value}
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      decimalScale="2"
      fixedDecimalScale
      prefix="R$ "
      renderText={renderText}
    />
  )
}

export default MoneyValue;
