import React from 'react';
import {
  I18n,
  Translate,
} from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import ROUTES from '../data/routes';

const PageNotFound = () => (
  <main className="main page-not-found">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <div className="page-not-found__icon">
            <img
              src="/assets/images/ic_page_not_found.jpg"
              alt="page not found"
            />
          </div>
          <h1 className="page-not-found__title">
            <Translate value="pageNotFound.title" />
          </h1>
          <p className="page-not-found__description">
            <Translate value="pageNotFound.description" />
            <br />
            <Translate value="pageNotFound.descriptionTwo" />
          </p>
          <Link
            to={ROUTES.PANEL_START}
            className="link-no-style"
          >
            <span className="panel__content__back p-0">{I18n.t('cashIn.transfer.goBack')}</span>
          </Link>
        </div>
      </div>
    </div>
  </main>
);

export default PageNotFound;
