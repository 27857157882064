import { addLoading, removeLoading } from './loading';
import UtilsRequests from '../../server/utils';

export const ACTION_LIST_BANK = 'LIST_BANK';
export const ACTION_TRANSFER_OUT_FEE = 'GET_TRANSFER_OUT_FEE';
export const ACTION_TITLE_DOUBT_TEXT = 'SET_TITLE_DOUBT_TEXT';
export const ACTION_SYSTEM_DATE_MATERA = "ACTION_SYSTEM_DATE_MATERA";

export const setSystemDateMatera = data => ({
  type: ACTION_SYSTEM_DATE_MATERA,
  systemDataMatera: data
});

export const setBankListFinance = bankListData => ({
  type: ACTION_LIST_BANK,
  bankList: bankListData
});

export const setTransferOutFee = transferOutData => ({
  type: ACTION_TRANSFER_OUT_FEE,
  transferOutFee: transferOutData
});

export const setTitleDoubt = titleDoubtText => ({
  type: ACTION_TITLE_DOUBT_TEXT,
  titleDoubtText: titleDoubtText
});

export const getSystemDateMatera = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await UtilsRequests.getSystemDateMatera();
    await dispatch(setSystemDateMatera(data));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

export const getBankList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const bankList = await UtilsRequests.getRequestBankList();
    await dispatch(setBankListFinance(bankList));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

export const getAddressViaCep = (zipcode) => async (dispatch) => {
  dispatch(addLoading());
  let address = null;
  try {
    address = await UtilsRequests.getCep(zipcode);
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
  return address;
};

export const getTransferOutfee = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    let fee = await UtilsRequests.getTransferOutFee();
    fee = parseFloat(fee);
    await dispatch(setTransferOutFee(fee));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

export const titleDoubtText = (doubt) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await dispatch(setTitleDoubt(doubt));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};
