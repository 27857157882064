import React from 'react';
import { NavLink } from 'react-router-dom';
import ROUTES from '../../data/routes';
import SidebarRoutes from '../../data/sidebarMobile';
import ArrowRight from '../../svg/Arrow-right.svg';
import IcLogout from '../icons/IcLogout';

const MobileNavigation = ({ closeSidebar }) => {

  const logout = () => {
    localStorage.removeItem('auth');
    location.reload();
  }

  return (
    <aside className="sidebar mobile__sidebar__section col-lg-12 p-0">

      <div className="widget widget-dashboard">
        <ul className="sidebar__menu">
          <li
            className="sidebar__menu__item"
          >
            {
              SidebarRoutes.links.map((link, linkIndex) => (
                <NavLink
                  // eslint-disable-next-line react/no-array-index-key
                  key={(linkIndex + 1)}
                  exact={(link.url === ROUTES.PANEL_START)}
                  className="sidebar__menu__item__link"
                  activeClassName="sidebar__menu__item__link--active"
                  to={link.url}
                  onClick={closeSidebar}
                >
                  <span className="sidebar__menu__item__link__icon">
                    {link.icon}
                  </span>
                  <span className="sidebar__menu__item__link__name">
                    {link.name}
                  </span>
                </NavLink>
              ))
            }
            <div
              className="sidebar__menu__item__link"
              onClick={logout}
              aria-hidden="true"
            >
              <span className='sidebar__menu_item__link__icon'>
                <IcLogout />
              </span>
              <span
                className='sidebar__menu__item__link__name'
                style={{ marginLeft: '8px' }}
              >
                Logout
              </span>
            </div>
          </li>
        </ul>
      </div>


      <a href={ROUTES.SUPERBID_MARKETPLACE}>
        <div className='mobile__sidebar__menu__last'>
          <span className='sidebar__menu__final'>
            Superbid Exchange
          </span>
          <ArrowRight />
        </div>
      </a>

    </aside>
  );
}


export default MobileNavigation;
