import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
// import One from '../../../svg/institutional/ic_number_one.svg';
// import Two from '../../../svg/institutional/ic_number_two.svg';
// import Three from '../../../svg/institutional/ic_number_three.svg';

const UtilizationSection = () => {
  return (
    <Fragment>
      <div className="institutional__section institutional__section--background">
        <div className="institutional__section__title">
          {I18n.t('institucional.utilizationSection.title')}
        </div>
        <div className="utilization__steps">
          <div className="utilization__steps__single">
            <div className="utilization__steps__single__description">
              {I18n.t('institucional.utilizationSection.stepOne')}
            </div>
          </div>
          <div className="utilization__steps__single">
            <div className="utilization__steps__single__description">
              {I18n.t('institucional.utilizationSection.stepTwo')}
            </div>
          </div>
          <div className="utilization__steps__single">
            <div className="utilization__steps__single__description">
              {I18n.t('institucional.utilizationSection.stepThree')}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default UtilizationSection;
