import React, { Component, Fragment } from 'react';
import { TermsAndPoliticsMap, TermsAndPoliticsEnum } from '../../data/enumerators/termsAndPolitics';
import TermsOfUse from '../../components/institutional/terms/TermsOfUse';
import SecurityPolicy from '../../components/institutional/terms/SecurityPolicy';
import TermsAndPolitics from '../../components/institutional/terms/TermsAndPolitics';
import PrivacyPolicy from '../../components/institutional/terms/PrivacyPolicy';
import Footer from '../../components/footer/Footer';

class InstitutionalTerms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 3,
    }
  }

  componentDidMount = () => {
    window.scrollTo({ top: 0 });
  }

  toggleTerm = (current) => {
    this.setState({ current: current });
    window.scrollTo(0, 0)
  }

  render() {
    const { current } = this.state;
    return (
      <Fragment>
        <main className="main">
          <div className="institutional__section__header">
            {current === TermsAndPoliticsEnum.TERMS_OF_USE && (
              <span>TERMOS E CONDIÇÕES DE USO DO SUPERBID EXCHANGE E DA CONTA DIGITAL SUPERBID PAY</span>
            )}
            {current === TermsAndPoliticsEnum.PRIVACY_POLICY && (
              <span>POLÍTICA DE PRIVACIDADE DO GRUPO SUPERBID</span>
            )}
            {current !== TermsAndPoliticsEnum.PRIVACY_POLICY && current !== TermsAndPoliticsEnum.TERMS_OF_USE && (
              TermsAndPoliticsMap.get(current)
            )}
          </div>
          <div className="institutional__terms">
            <div className="institutional__terms__description">
              <TermsOfUse current={current} />
              <SecurityPolicy current={current} />
              <TermsAndPolitics current={current} />
              <PrivacyPolicy current={current} />
            </div>
          </div>
          <div className="institutional__terms">
            <div className="institutional__terms__toggler">
              <div className="institutional__terms__toggler__title">
                Veja também:
              </div>
              <div className="institutional__terms__toggler__action">
                {Array.from(TermsAndPoliticsMap) && Array.from(TermsAndPoliticsMap).length > 0 && (
                  Array.from(TermsAndPoliticsMap)
                    .filter(e => e[0] !== current)
                    .map(e => (
                      <button
                        key={e}
                        type="button"
                        onClick={() => { this.toggleTerm(e[0]) }}
                        className="institutional__terms__toggler__action__single"
                      >
                        {e[1]}
                      </button>
                    ))
                )}
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </Fragment>
    )
  }
}

export default InstitutionalTerms;
