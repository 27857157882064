import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import SecurityIcon from '../../../svg/institutional/ic_security_institucional.svg';
import StarIcon from '../../../svg/institutional/ic_stars.svg';
import ThumbUpIcon from '../../../svg/institutional/ic_thumb up.svg';

const PillarSection = () => {
  return (
    <Fragment>
      <div className="institutional__section institutional__section">
        <div className="institutional__section__title">
          {I18n.t('institucional.pillarSection.title')}
        </div>
        <div className="mobile mb-2" />
        <div className="pillar__steps">
          <div className="pillar__steps__single">
            <div className="d-flex align-center justify-content-center">
              <SecurityIcon />
            </div>
            <div className="pillar__steps__single__title">
              {I18n.t('institucional.pillarSection.titleSecurity')}
            </div>
            <div className="pillar__steps__single__description">
              {I18n.t('institucional.pillarSection.descriptionSecurity')}
            </div>
          </div>
          <div className="pillar__steps__single">
            <div className="d-flex align-center justify-content-center">
              <ThumbUpIcon />
            </div>
            <div className="pillar__steps__single__title">
              {I18n.t('institucional.pillarSection.practicality')}
            </div>
            <div className="pillar__steps__single__description">
              {I18n.t('institucional.pillarSection.descriptionPracticality')}
            </div>
          </div>
          <div className="pillar__steps__single">
            <div className="d-flex align-center justify-content-center">
              <StarIcon />
            </div>
            <div className="pillar__steps__single__title">
              {I18n.t('institucional.pillarSection.transparency')}
            </div>
            <div className="pillar__steps__single__description">
              {I18n.t('institucional.pillarSection.descriptionTransparency')}
            </div>
          </div>
          <div className="mobile mb-5" />
        </div>
      </div>
    </Fragment>
  )
}

export default PillarSection;
