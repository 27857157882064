/* eslint-disable max-len */
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import * as eva from 'eva-icons';
import * as Yup from 'yup';
import { Translate } from 'react-redux-i18n';
import { UserActions, AuthActions } from '../../../app/store/actions';
import TwoFactorAuthenticate from '../../../data/enumerators/TwoFactorAuthenticate';
import Button from '../../elements/Button';

import numberHelpers from '../../../helpers/number';
import Input from '../../elements/Input';

const EmailModal = ({ cellphone, onCancel, onClose, sendCode, changeEmail }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const formatedCellphone = useMemo(() => {
    const lastFourDigits = cellphone.slice(-4);

    return lastFourDigits.padStart(cellphone.length, "*")
  }, [cellphone]);

  const nextStep = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const handleSendCode = useCallback(async () => {
    await sendCode(TwoFactorAuthenticate.SMS);
    nextStep();
  }, [nextStep]);

  const handleSubmitCode = useCallback((data) => {
    emailForm.setFieldValue("twoFactorCode", data.code);
    nextStep();
  }, [nextStep]);

  const handleResendCode = useCallback(async () => {
    await sendCode(TwoFactorAuthenticate.EMAIL);
    toast.success("Código enviado novamente!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 1500
    })
  }, [sendCode]);

  const handleChangeEmail = useCallback(async (data) => {
    delete data.email_confirmation;

    const { email, twoFactorCode } = data;

    try {
      await changeEmail({
        email,
        twoFactorCode
      });

      toast.success(<Translate value="toastMessage.changeEmail" />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      });

      onClose();
    } catch (error) {
      toast.error(<Translate value='error.codeInvalid' />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      });
    }
  }, []);

  const validationSchema = Yup.object().shape({
    code: Yup.string().min(6, "Digite todo o código").required("Este campo é necessário")
  });

  const changeEmailSchema = Yup.object().shape({
    ddd: Yup.string().required(),
    email: Yup.string().required(),
    email_confirmation: Yup.string()
      .oneOf([Yup.ref('email'), null], "Telefones devem corresponder"),
    twoFactorCode: Yup.string().required()
  })

  const codeForm = useFormik({
    initialValues: {
      code: ""
    },
    onSubmit: handleSubmitCode,
    validationSchema
  });

  const emailForm = useFormik({
    initialValues: {
      email: "",
      email_confirmation: "",
      twoFactorCode: codeForm.values.code
    },
    onSubmit: handleChangeEmail,
    validationSchema: changeEmailSchema,
  });

  useEffect(() => {
    eva.replace();
  }, []);

  return (
    <div className="update-modal">
      <header>
        <h5>Alterar email</h5>
        <button
          type="button"
          className="update-modal__close-button"
          onClick={onCancel}
        >
          <i
            data-eva="close-outline"
          />
        </button>
      </header>

      {currentStep === 0 && (
        <Fragment>
          <p className="update-modal__title">
            Precisamos confirmar sua identidade através do telefone antes de alterar seu e-mail
          </p>

          <div className="update-modal__content">
            <img
              src="/assets/images/phone_android.svg"
              alt="mail-icon"
            />
            <div className="update-modal__content__flex">
              <span className="update-modal__content__flex__text">
                Telefone cadastrado
              </span>
              <span className="update-modal__content__flex__text">
                {numberHelpers.cellphoneMask(cellphone)}
              </span>
            </div>
          </div>


          <footer className="update-modal__footer">
            <Button
              onClick={handleSendCode}
            >
              Enviar código
            </Button>
            <Button
              variant="secondary"
              onClick={onCancel}
            >
              Cancelar
            </Button>
          </footer>
        </Fragment>
      )}

      {currentStep === 1 && (
        <div className="two-factor-code">
          <p className="update-modal__title">
            Digite o código que você recebeu no {formatedCellphone}
          </p>

          <span className="update-modal__code">Código</span>

          <input
            name="code"
            maxLength="6"
            type="tel"
            value={codeForm.values.code}
            onChange={codeForm.handleChange}
            placeholder="______"
          />

          {codeForm.errors.code && (
            <p className="two-factor-code__error">{codeForm.errors.code}</p>
          )}

          <Button
            type="button"
            onClick={handleResendCode}
            variant="secondary"
            className="two-factor-code__resend-code"
          >
            Reenviar código
          </Button>

          <footer className="two-factor-code__footer">
            <Button
              variant="secondary"
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              onClick={codeForm.handleSubmit}
            >
              Confirmar
            </Button>
          </footer>
        </div>
      )}

      {currentStep === 2 && (
        <div className="update-form">
          <p className="update-modal__title">
            Digite seu novo email
          </p>

          <form onSubmit={emailForm.handleSubmit}>
            <Input
              mt={1}
              name="email"
              placeholder="Email"
              onChange={emailForm.handleChange}
              value={emailForm.values.email}
              error={emailForm.errors.email}
            />
            <Input
              mt={1}
              name="email_confirmation"
              placeholder="Confirmação de email"
              onChange={emailForm.handleChange}
              value={emailForm.values.email_confirmation}
              error={emailForm.errors.email_confirmation}
            />

            <footer className="two-factor-code__footer">
              <Button
                variant="secondary"
                onClick={onCancel}
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                onClick={() => {
                  handleChangeEmail(emailForm.values)
                }}
              >
                Confirmar
              </Button>
            </footer>
          </form>
        </div>
      )
      }
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  changeEmail: (email) => dispatch(UserActions.changeEmail(email)),
  sendCode: (twoFactorAuthenticate) => dispatch(AuthActions.sendTwoFactor(twoFactorAuthenticate))
});

export default connect(
  null,
  mapDispatchToProps,
)(EmailModal);
