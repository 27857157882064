/* eslint-disable react/button-has-type */
import React from "react";

const Button = (
  {
    children,
    type = "button",
    variant = "primary",
    color = "primary",
    className,
    onClick,
    ...props
  }) => {
  const variantClassName = variant === "secondary" ? 
  "button__secondary" : variant === "outline" ? 
  "button__outline" : "button__primary";
  const colorClassName = color === "secondary" ?
   "button__color__secondary" : variant === "outline" ? 
   "button__color__outline" : "button__color__primary";

  return (
    <button
      type={type}
      className={`button ${variantClassName} ${colorClassName} ${className}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button