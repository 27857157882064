/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import CashInBoletoStepOne from '../../components/cashIn/CashInBoletoStepOne';
import CashInBoletoStepTwo from '../../components/cashIn/CashInBoletoStepTwo';
import CashInBoletoStepThree from '../../components/cashIn/CashInBoletoStepThree';
import { UserSelectors, CashInSelectors, AccountSelectors } from '../../app/store/reducers';
import { CashInActions } from '../../app/store/actions';
import 'react-toastify/dist/ReactToastify.css';

import currency from '../../helpers/currency';
import number from '../../helpers/number';

const PanelCashInBoleto = ({ balance, user, apiTicket, generateTicket }) => {
  const [currentStep, setCurrentStep] = useState(1);

  const [ticket, setTicket] = useState({
    amount: '',
    document: null,
    name: ''
  });

  const parsedCurrency = useMemo(() => currency.parseCurrency(ticket.amount), [ticket])

  const handleNextStep = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const handlePreviousStep = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const handleGenerateTicket = useCallback(async () => {
    try {
      await generateTicket({ amount: parsedCurrency });

      handleNextStep();
    } catch (error) {
      toast.error(<Translate value="error.generateBoleto" />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      });
    }
  }, [parsedCurrency, handleNextStep]);

  useEffect(() => {
    if (user) {
      setTicket({
        ...ticket,
        document: number.cpfMask(user.taxIdentifier),
        name: user.name
      })
    }
  }, [user]);

  return (
    <main className="content-container">
      <div className="content-container__view">
        {currentStep !== 3 && (
          <div className="content-container__head mobile-hidden">
            <h3 className="content-container__head__title">
              <b>
                {I18n.t('cashIn.transfer.titleThree')}
              </b>
            </h3>
            <h6 className="content-container__head__description">
              {currentStep === 1 ? I18n.t('cashIn.transfer.description') : I18n.t('cashIn.transfer.descriptionTwo')}
            </h6>
          </div>
        )}

        {currentStep === 1 && (
          <CashInBoletoStepOne
            initialAmount={ticket.amount}
            balance={balance && balance.real}
            onContinue={(amount) => {
              setTicket({
                ...ticket,
                amount,
              })
              handleNextStep()
            }}

          />
        )}

        {currentStep === 2 && (
          <CashInBoletoStepTwo
            currentStep={currentStep}
            previousStep={currentStep - 1}
            document={ticket.document}
            name={ticket.name}
            amount={parsedCurrency}
            onClickBack={handlePreviousStep}
            onClickContinue={handleGenerateTicket}
          />
        )}

        {currentStep === 3 && apiTicket && (
          <CashInBoletoStepThree
            amount={parsedCurrency}
            ticket={apiTicket}
          />
        )}

      </div>
    </main>
  )
}

const mapStateToProps = state => ({
  user: UserSelectors.getUser(state),
  apiTicket: CashInSelectors.getBoletoData(state),
  balance: AccountSelectors.getBalance(state)
});

const mapDispatchToProps = dispatch => ({
  generateTicket: (amount) => dispatch(CashInActions.generateBoleto(amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelCashInBoleto);
