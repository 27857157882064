import { useFormik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import * as Yup from 'yup'
import { I18n } from 'react-redux-i18n';
import { AccountTypeArray } from '../../data/enumerators/accountType';
import { UtilsActions, BankTransferActions } from '../../app/store/actions';
import { UtilsSelectors, UserSelectors } from '../../app/store/reducers';
import Input from '../elements/Input';
import InputMask from '../elements/InputMask';
import Select from '../elements/Select';
import TwoLabelsInput from '../elements/InputTwoLabels';

import IcClose from '../icons/IcClose';

const AddAccountModal = ({ bankList, taxIdentifier = '', createBankTransfer, onClose, onCancel }) => {

  const bankOptions = useMemo(() => {
    if (bankList) {
      const importantCodes = ["184", "479", "341", "260", "237","652", "001", "033", "077", "104"];
      
      const initalBanks = bankList
        .filter(bks => bks.code === importantCodes.find(impt => bks.code === impt));

      const banks = bankList.filter(bks => bks.code !== "n/a")
        .filter(bks => bks.code !== importantCodes.find(impt => bks.code === impt));

      banks.unshift(...initalBanks);

      return banks.map(bank => ({
        label: `${bank.code} - ${bank.fullName}`,
        value: bank.id
      }))
    }

    return []
  }, [bankList]);

  const accountTypeOptions = useMemo(() => ([
    { value: 1, label: I18n.t('accountTypeEnum.current') },
    { value: 2, label: I18n.t('accountTypeEnum.savings') }
  ]), [AccountTypeArray]);

  const translate = useCallback((path) => {
    return I18n.t(`panel.walletPage.addAccountModal.${path}`)
  }, [I18n]);

  const handleSubmit = useCallback(async (data) => {
    try {
      data.branchDestination = data.branchDestination.toString();

      let result =  await createBankTransfer(data);

      if (!Object.keys(result).length){
        toast.error(translate("toast.alreadyCreated"), {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500
        })
      }
      else {
        toast.success(translate("toast.sucess"), {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500
        })
      }
      onClose();
    } catch (error) {
      // Todo - Tratar erro
      toast.error(translate("toast.error"), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      })
    }
  }, []);

  const addAccountSchema = Yup.object().shape({
    name: Yup.string().required(),
    taxIdentifier: Yup.string().required(),
    bankDestination: Yup.string().required(),
    branchDestination: Yup.string().required(),
    accountDestination: Yup.string().required(),
    accountDigitDestination: Yup.string().required(),
    accountTypeDestination: Yup.string().required(),
    bankName: Yup.string().required()
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      taxIdentifier: taxIdentifier || "",
      bankDestination: "",
      branchDestination: "",
      accountDestination: "",
      accountDigitDestination: "",
      accountTypeDestination: "",
      bankName: "",
    },
    onSubmit: handleSubmit,
    validationSchema: addAccountSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  const handleSelectInstitution = useCallback(({ value }) => {
    const bank = bankList.find(account => account.id === value);

    formik.setFieldValue("bankDestination", bank.code);
    formik.setFieldValue("bankName", bank.name);
  }, [formik]);

  const handleSelectAccountType = useCallback(({ value }) => {
    formik.setFieldValue("accountTypeDestination", value.toString());
  }, [formik]);

  return (
    <div className="addAccount-modal">
      <div className="addAccount-modal__header">
        <div>
          <h4>{translate("title")}</h4>
          <p>{translate("description")}</p>
        </div>
        <IcClose
          className="addAccount-modal__header__close"
          size={16}
          onClick={onClose}
        />
      </div>

      <form
        className="addAccount-modal__form"
        onSubmit={formik.handleSubmit}
        style={{ gap: 16, marginTop: 16 }}
      >
        <Input
          name="name"
          placeholder={translate("form.name")}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.errors.name}
        />

        <InputMask
          name="taxIdentifier"
          placeholder={translate(`form.taxIdentifier.${formik.values.taxIdentifier.length <= 11 ? "cpf" : "cnpj"}`)}
          mask={formik.values.taxIdentifier.length <= 11 ? "999.999.999-99" : "99.999.999/9999-99"}
          value={formik.values.taxIdentifier}
          error={formik.errors.taxIdentifier}
          onChange={formik.handleChange}
          readOnly
          mt={1}
        />

        <Select
          name="bankDestination"
          placeholder={translate("form.bankDestination")}
          options={bankOptions}
          onChange={handleSelectInstitution}
          error={formik.errors.bankDestination}
          mt={1}
        />



        {/* <Flex
          gap="16px"
          mt={1}
        > */}

        <Input
          mt={1}
          name="branchDestination"
          placeholder={translate("form.branchDestination")}
          value={formik.values.branchDestination}
          onChange={formik.handleChange}
          error={formik.errors.branchDestination}
          type="text"
        // width="50%"
        />

        <TwoLabelsInput
          mt={1}
          namePrimary="accountDestination"
          nameSecondary="accountDigitDestination"
          placeholderPrimary={translate("form.accountDestination")}
          placeholderSecondary={translate("form.accountDigitDestination")}
          onChangePrimary={formik.handleChange}
          onChangeSecondary={formik.handleChange}
          error={formik.errors.accountDestination || formik.errors.accountDigitDestination}
        // width="50%"
        />
        {/* </Flex> */}

        <Select
          placeholder={translate("form.accountType")}
          options={accountTypeOptions}
          onChange={handleSelectAccountType}
          error={formik.errors.accountTypeDestination}
          mt={1}
        />
      </form>

      <div className="addAccount-modal__submit">
        <button
          type="button"
          onClick={onCancel || onClose}
        >
          {translate("button.cancel")}
        </button>
        <button
          type="button"
          onClick={formik.handleSubmit}
        >
          {translate("button.submit")}
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  bankList: UtilsSelectors.getBanks(state),
  user: UserSelectors.getUser(state),
});

const mapDispatchToProps = dispatch => ({
  getBankList: () => dispatch(UtilsActions.getBankList()),
  createBankTransfer: (bankTransfer) => dispatch(BankTransferActions.createBankTransfer(bankTransfer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAccountModal);