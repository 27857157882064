/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n'
import dayjs from 'dayjs';

import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import DatePickerModal from '../../components/elements/DatePickerModal';
import { AccountActions } from '../../app/store/actions';
import { AccountSelectors, UserSelectors } from '../../app/store/reducers';
import ExtractCard from '../../components/extract/ExtractCard';

import Tabs from "../../components/modules/Tabs";

import Flex from "../../components/elements/Flex";
import Typography from "../../components/elements/Typography";
import Icon from "../../components/elements/Icon";
import Button from "../../components/elements/Button"
import Divider from '../../components/elements/Divider';

import EmptyExtract from "../../components/extract/EmptyExtract";
import formatExtractDate from '../../helpers/downloadExtract';
import ExtractPDF from '../../components/documents/extractPDF';

const IconButton = styled(Icon)`
  &:hover{
    cursor: pointer;
    color:${({ theme }) => theme.colors.functional.primary[800]};
  }
`;

const PanelExtractPage = ({extractList,schedules, user,getExtractFinance,getScheduleCashout, account, getAccountDetails }) => {

  const theme = useTheme();
  const [selectedTab, setTab] = useState("all");
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [downloadMenu, setShowDownloadMenu] = useState(false);
  const [isDownloadClicked, setIsDownloadClicked] = useState(false);
  const [dateSelected, setIsDateSelected] = useState(false);


  const [params, setParams] = useState({
    dateStart: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
    dateEnd: null,
    typeFilter: 1,
    orderExtract: true
  });

  const translate = useCallback((path) => {
    return I18n.t(`panel.informIncome.${path}`);
  }, [I18n]);

  const optionsDowload = ["7 dias", "15 dias", "30 dias", "60 dias", "90 dias", "Período personalizado"];

  const tabs = useMemo(() => [
    { value: "all", title: "Todos", typeFilter: 1 },
    { value: "incoming", title: "Entrada", typeFilter: 2 },
    { value: "outcoming", title: "Saída", typeFilter: 3 },
    { value: "schudule", title: "Futuros", typeFilter: 4 },
  ], []);

  useEffect(() => {
    if (user && user.createdAt) {
      setParams({
        ...params,
        dateStart: dayjs().subtract(1, 'days').format('YYYY-MM-DD')
      })
    }
  }, [user]);

  useEffect(() => {
    const { dateStart, dateEnd, typeFilter, orderExtract } = params;
    
    getAccountDetails();

    getExtractFinance(dateStart, dateEnd, typeFilter, orderExtract);
  }, [params]);

  const handleToggleFilter = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  const handleToggleDownloadMenu = useCallback(() => {
    setShowDownloadMenu(!downloadMenu);
  }, [downloadMenu]);

  const formatAccount = () => {
    let acc = account.number.toString();

    acc = `${acc.slice(0, acc.length - 1)}-${acc.slice(acc.length - 1)}`

    return acc
  }

  useEffect(() => {

    const downloadPdf = async () => {

      if(dateSelected){
        const { dateStart, dateEnd, typeFilter, orderExtract } = params;

        
        const extract = await getExtractFinance(dateStart, dateEnd, typeFilter, orderExtract);
  
        const pdfData = {
          name: user.name,
          document: user.taxIdentifier,
          agency: account.branch,
          account: formatAccount(),
          startDate: dateStart,
          endDate: dateEnd,
          extractList: extract
        }
  
        const blob = await pdf((
          <ExtractPDF
            pdfData={pdfData}
            translate={translate}
            title="Extrato_SuperbidPay"
          />
        )).toBlob();
        saveAs(blob, 'Extrato_SuperbidPay')

        setIsDateSelected(false);
      }
    }

    downloadPdf()

  },[dateSelected])


  const handleDownloadExtract = async (date) => {

      if(date === "Período personalizado"){
          setIsDownloadClicked(true);
          setDatePickerIsOpen(true);
          handleToggleDownloadMenu();
          return;
      }
      else{
        const startDate = await formatExtractDate(date);

        const extract = await getExtractFinance(startDate, null, 1, true);

        const pdfData = {
          name: user.name,
          document: user.taxIdentifier,
          agency: account.branch,
          account: formatAccount(),
          startDate,
          endDate: moment(),
          extractList: extract
        }

        const blob = await pdf((
          <ExtractPDF
            pdfData={pdfData}
            translate={translate}
            title="Extrato_SuperbidPay"
          />
        )).toBlob();
        saveAs(blob, 'Extrato_SuperbidPay')
        return;
      }


  }


  const handleSelectTab = useCallback((value) => {
    setTab(value);

    if(tabs.find(tab => tab.value === value).typeFilter === 4){
      return getScheduleCashout();
    }

    setParams({
      ...params,  
      typeFilter: tabs.find(tab => tab.value === value).typeFilter
    });
  }, [tabs,getScheduleCashout]);

  return (
    <Flex
      flexDirection="column"
      width="100%"
      p={{
        default: 2,
        sm: 3
      }}
    >
      {/* Header */}

      <Flex
        width="100%"
        justifyContent="space-between"
      >
        <Typography
          fontSize="title.sm"
          lineHeight="title.sm"
          fontWeight="semibold"
          color="text.dark"
          display={{
            default: "flex",
            sm: "none"
          }}
        >
          Extrato
        </Typography>

        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onSelectTab={handleSelectTab}
          display={{
            default: "none",
            sm: "flex"
          }}
        />

        <Flex
          position="relative"
        >
          {/* Filter */}

          {showMenu && (
            <Flex
              flexDirection="column"
              position="absolute"
              bg="background.default"
              top="calc(100% + 16px)"
              right="0px"
              borderRadius="xs"
              boxShadow="popup.normal"
              width="180px"
              zIndex={999}
              p={1}
              onMouseLeave={handleToggleFilter}
            >
              <Flex
                flexDirection="column"
                display={{
                  default: "flex",
                  sm: "none"
                }}
              >
                <Button
                  variant="secondary"
                  type="button"
                  textAlign="left"
                  display={{
                    default: "flex",
                    sm: "none"
                  }}
                  onClick={() => {
                    setShowMenu(false);
                    handleSelectTab("all");
                  }}
                  style={{ textAlign: "left" }}
                >
                  {I18n.t('extractPage.nav.all')}
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  textAlign="left"
                  display={{
                    default: "flex",
                    sm: "none"
                  }}
                  onClick={() => {
                    setShowMenu(false);
                    handleSelectTab("incoming");
                  }}
                  style={{ textAlign: "left" }}
                >
                  {I18n.t('extractPage.nav.incoming')}
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  textAlign="left"
                  onClick={() => {
                    setShowMenu(false);
                    handleSelectTab("outcoming");
                  }}
                  display={{
                    default: "flex",
                    sm: "none"
                  }}
                  style={{ textAlign: "left" }}
                >
                  {I18n.t('extractPage.nav.outcoming')}
                </Button>
              </Flex>
              <Button
                variant="secondary"
                type="button"
                textAlign="left"
                onClick={() => {
                  setShowMenu(false);
                  setParams({
                    ...params,
                    orderExtract: true
                  })
                }}
                style={{ textAlign: "left" }}
              >
                {I18n.t('extractPage.nav.new')}
              </Button>
              <Button
                variant="secondary"
                type="button"
                textAlign="left"
                onClick={() => {
                  setShowMenu(false);
                  setParams({
                    ...params,
                    orderExtract: false
                  })
                }}
                style={{ textAlign: "left" }}
              >
                {I18n.t('extractPage.nav.old')}
              </Button>
              <Button
                variant="secondary"
                type="button"
                textAlign="left"
                onClick={() => {
                  setShowMenu(false);
                  setDatePickerIsOpen(true);
                }}
                style={{ textAlign: "left" }}
              >
                {I18n.t('extractPage.nav.selectDate')}
              </Button>
            </Flex>
          )}

          <Button
            variant="outline"
            type="button"
            style={{marginRight: '24px'}}
            onClick={handleToggleDownloadMenu}
          >
            Download do extrato
            <Icon
              icon="download-outline"
              size={20}
              style={{marginLeft: '8px'}}
              color={theme.colors.brand.primary}
            />
          </Button>

          {downloadMenu && (
            <Flex
              flexDirection="column"
              position="absolute"
              gap="16px"
              bg="background.default"
              top="calc(100% + 4px)"
              right="0px"
              borderRadius="8px"
              width="240px"
              zIndex={999}
              p={1}
              boxShadow="4px 4px 80px 0px rgba(11, 47, 99, 0.24)"
              onMouseLeave={handleToggleDownloadMenu}
            >
              {optionsDowload.map((option,index) => {
              return (
                <div
                  key={index}
                  role='button'
                  onClick={() => handleDownloadExtract(option)}
                  className='extract-download__option'
                >{option}
                </div>
              )
            })}
            </Flex>
          )}

          <IconButton
            icon="filter-list"
            provider="google"
            color="text.dark"
            size={24}
            onClick={handleToggleFilter}
          />
        </Flex>
      </Flex>

      <Divider
        mt={2}
        ml="-24px"
        width="calc(100% + 48px)"
        border="1px dashed"
        borderColor="text.lightest"
        display={{
          default: "flex",
          sm: "none"
        }}
      />

      {/* Content */}

      <Flex
        flex={1}
        flexDirection="column"
        width="100%"
        border="1px dashed"
        borderRadius="xs"
        borderColor={{
          default: "transparent",
          sm: "text.lightest"
        }}
        gap="24px"
        mt={2}
        p={{
          default: "none",
          sm: 3
        }}
      >
        {selectedTab !== "schudule"&& extractList && extractList.map((extract, index) => (
          <ExtractCard
            key={index}
            type={extract.type}
            balance={extract.amount}
            date={extract.creditDate}
            extract={extract}
          />        
        ))}

        {selectedTab !== "schudule"&& extractList && !extractList.length && (
          <EmptyExtract />
        )}

        {selectedTab === "schudule" && schedules && schedules.rows.map(transaction => (
          !transaction.processAt && (
            <ExtractCard
              key={transaction.id}
              type="F"
              balance={Number(transaction.amount)}
              date={transaction.scheduleDate}
            />
          )
        ))}

        {schedules && !schedules.rows.length && (
          <EmptyExtract />
        )}
      </Flex>

      {/* DatePicker */}

      <DatePickerModal
        isOpen={datePickerIsOpen}
        onClose={() => setDatePickerIsOpen(false)}
        onSelectDate={(value) => {
          if (value[0]) {
            setParams({
              ...params,
              dateStart: dayjs(value[1].from).format('YYYY-MM-DD'),
              dateEnd: dayjs(value[1].to).format('YYYY-MM-DD')
            })
            if(isDownloadClicked){
              setIsDateSelected(true);
            }

          } else {
            setParams({
              ...params,
              dateStart: dayjs().subtract(1, 'days').format('YYYY-MM-DD')
            })
          }
          setIsDownloadClicked(false);
          setDatePickerIsOpen(false)
        }}
      />
    </Flex>
  )
}

const mapStateToProps = state => ({
  balance: AccountSelectors.getBalance(state),
  extractList: AccountSelectors.getExtract(state),
  user: UserSelectors.getUser(state),
  schedules:AccountSelectors.getScheduleCashout(state),
  account: AccountSelectors.getDetails(state)
});

const mapDispatchToProps = dispatch => ({
  getBalanceFinance: () => dispatch(AccountActions.balanceFinance()),
  getExtractFinance: (dateStart, dateEnd, typeFilter, orderExtract) =>
    dispatch(AccountActions.extractFinance(dateStart, dateEnd, typeFilter, orderExtract)),
  getScheduleCashout:() => dispatch(AccountActions.getScheduleCashout()),
  getAccountDetails: () => dispatch(AccountActions.getAccountDetails())
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelExtractPage);
