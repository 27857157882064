import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from "react-toastify";
import SupportMessageSent from './SupportMessageSent';
import { SupportActions } from '../../app/store/actions';
import 'react-toastify/dist/ReactToastify.css';

class SupportEmailContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailContent: '',
      contentLength: 0,
      protocol: null,
    }
  }

  handleChange = (e) => {
    this.setState({ emailContent: e.target.value, contentLength: (e.target.value).length });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { emailContent } = this.state;
    if (emailContent) {
      const res = await this.props.createSupportEmail(emailContent);
      this.setState({ protocol: res.protocolNumber });
    } else {
      toast.error('Por favor preencha todos os campos.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      });
    }
  }

  render() {
    const { contentLength, protocol } = this.state;
    return (
      <Fragment>
        <ToastContainer />
        {!protocol ? (
          <form
            className="panel__support__textarea"
            onSubmit={this.handleSubmit}
          >
            <textarea
              className="support-email__text"
              cols="40"
              rows="10"
              maxLength="1024"
              onChange={this.handleChange}
              placeholder="Escreva o que aconteceu e como podemos ajudar você"
            />
            <div className="support-email__character-counter">
              Restam {1024 - contentLength} caracteres.
            </div>
            <div className="support-email__button__wrapper">
              <div className="support-email__button__wrapper">
                <button
                  className="support-email__button"
                  type="submit"
                >Enviar mensagem
                </button>
              </div>
            </div>
          </form>
        ) :
          (
            <Fragment>
              <SupportMessageSent protocolNumber={protocol} />
            </Fragment>
          )}
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  createSupportEmail: (message) => dispatch(SupportActions.createEmail(message)),
});

export default connect(null, mapDispatchToProps)(SupportEmailContent);
