/* eslint-disable max-len */
import React from "react";

const IcBarCodeColored = ({ size = 24, ...props }) => {
  return (
    <svg
      id="Grupo_815"
      data-name="Grupo 815"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 23.73 25.708"
      {...props}
    >
      <g
        id="Grupo_611"
        data-name="Grupo 611"
        transform="translate(0)"
      >
        <path
          id="Caminho_197"
          data-name="Caminho 197"
          d="M72,113.933h5.933V108"
          transform="translate(-71.341 -107.341)"
          fill="currentColor"
        />
        <path
          id="Caminho_198"
          data-name="Caminho 198"
          d="M92.753,132.708H74.588V131.39H92.753a.66.66,0,0,0,.659-.659V108.978a.66.66,0,0,0-.659-.659H77.206l-4.887,4.887v18.843H71V112.66L76.66,107H92.753a1.98,1.98,0,0,1,1.978,1.978V130.73A1.98,1.98,0,0,1,92.753,132.708Z"
          transform="translate(-71 -107)"
          fill="currentColor"
        />
        <path
          id="Caminho_199"
          data-name="Caminho 199"
          d="M79.614,115.614H75V114.3h3.3V111h1.318Z"
          transform="translate(-72.363 -108.363)"
          fill="currentColor"
        />
        <rect
          id="Retângulo_629"
          data-name="Retângulo 629"
          width="8.466"
          height="0.989"
          transform="translate(4.448 10.118) rotate(90)"
          fill="currentColor"
        />
        <rect
          id="Retângulo_631"
          data-name="Retângulo 631"
          width="8.466"
          height="1.977"
          transform="translate(8.402 10.118) rotate(90)"
          fill="currentColor"
        />
        <rect
          id="Retângulo_633"
          data-name="Retângulo 633"
          width="8.466"
          height="0.989"
          transform="translate(13.345 10.118) rotate(90)"
          fill="currentColor"
        />
        <rect
          id="Retângulo_635"
          data-name="Retângulo 635"
          width="8.466"
          height="1.977"
          transform="translate(17.299 10.118) rotate(90)"
          fill="currentColor"
        />
        <rect
          id="Retângulo_632"
          data-name="Retângulo 632"
          width="8.466"
          height="1.977"
          transform="translate(11.368 10.118) rotate(90)"
          fill="currentColor"
        />
        <rect
          id="Retângulo_636"
          data-name="Retângulo 636"
          width="8.466"
          height="1.977"
          transform="translate(20.265 10.118) rotate(90)"
          fill="currentColor"
        />
        <rect
          id="Retângulo_637"
          data-name="Retângulo 637"
          width="8.466"
          height="0.989"
          transform="translate(20.05 5.949) rotate(180)"
          fill="currentColor"
        />
        <rect
          id="Retângulo_638"
          data-name="Retângulo 638"
          width="8.466"
          height="0.989"
          transform="translate(20.05 3.972) rotate(180)"
          fill="currentColor"
        />
      </g>
    </svg>

  )
}

export default IcBarCodeColored;