import RestService from '../services/rest';

async function createEmail(message) {
  return RestService.postAuthenticated('support', { message });
}

async function createOuvidoria(message, protocol) {
  return RestService.postAuthenticated('support/ouvidoria', { message, protocol });
}

export default {
  createEmail,
  createOuvidoria
};
