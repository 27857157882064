import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { I18n } from 'react-redux-i18n';
import LoginFooter from '../components/login/LoginFooter';
import ConfirmIdentityContent from '../components/login/ConfirmIdentityContent';

class ConfirmIdentity extends Component {
  componentDidMount = () => {
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <main className="login">
        <Helmet>
          <title>{I18n.t('validation.pageTitle')} - Superbid Pay</title>
        </Helmet>
        <ConfirmIdentityContent />
        <LoginFooter />
      </main>
    )
  }
}

export default ConfirmIdentity;
