/* eslint-disable max-len */
import React from 'react';

const CardWhite = ({ children, noPadding, noShadow, dBlock, hasMaxWidth, noRadius, balanceHome, walletCard, cashIn, outReceipt, noBorder, ...props }) => (
  <React.Fragment>
    <div
      className={`card card--white 
    ${noShadow ? 'no-shadow' : ''} ${noPadding ? 'no-padding' : ''} ${dBlock ? 'd-block' : ''} ${hasMaxWidth ? 'small-balance' : ''} ${outReceipt ? 'out-receipt' : ''}
    ${noRadius ? 'no-border-radius' : ''} ${balanceHome ? 'balance-home' : ''} ${walletCard ? 'wallet-card' : ''} ${cashIn ? 'cash-in' : ''} ${noBorder ? 'no-border' : ''}`}
      {...props}
    >
      {children}
    </div>
  </React.Fragment>
);

export default CardWhite;
