import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ToastContainer } from 'react-toastify/';
import { BankTransferActions, UtilsActions, CreditCardActions, AccountActions } from '../../app/store/actions';
import {
  BankTransferSelectors,
  UtilsSelectors,
  CreditCardSelectors,
  AccountSelectors,
  UserSelectors
} from '../../app/store/reducers';
import WalletCard from '../../components/wallet/CreditCard';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import AccountCard from '../../components/wallet/AccountCard';
import 'react-toastify/dist/ReactToastify.css';
import IcAdd from '../../svg/ic_add_button.svg';

import IcChevronFoward from '../../components/icons/IcChevronFoward';
import AddCreditCardModal from '../../components/wallet/AddCreditCardModal';
import AddAccountModal from '../../components/wallet/AddAccountModal';
import RemoveModal from '../../components/wallet/RemoveModal';
import Modal from '../../components/elements/Modal';

import Flex from "../../components/elements/Flex";

const PanelWallet = (
  { bankTransferList,
    creditCardsList,
    user,
    bankList,
    getAllBankTransfer,
    getBankList,
    listAllCreditCard,
    removeCreditCard,
    removeBankTransfer
  }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const [creditCardId, setCreditCardId] = useState('');
  const [accountId, setAccountId] = useState('');

  const [swiperWidth, setSwiperWidth] = useState(600)

  const openModal = useCallback((type = 'addCard') => {
    setVisibleModal(true);
    setModalType(type);
  }, []);

  const closeModal = useCallback(() => {
    setVisibleModal(false);
  }, []);

  const handleRemoveCreditCard = useCallback(async () => {
    await removeCreditCard(creditCardId);
    closeModal()
  }, [creditCardId]);

  const handleRemoveAccount = useCallback(async () => {
    await removeBankTransfer(accountId);
    closeModal();
  }, [accountId]);

  const translate = useCallback((path) => {
    return I18n.t(`panel.walletPage.${path}`)
  }, [I18n]);

  useEffect(() => {
    getAllBankTransfer();
    listAllCreditCard();
    getBankList();
  }, []);

  const handleSetSwiperSize = useCallback(() => {
    const section = document.getElementById("wallet-section");

    setSwiperWidth(section.clientWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleSetSwiperSize);
  });

  useEffect(() => {
    handleSetSwiperSize();
  }, [handleSetSwiperSize]);

  return (
    <Fragment>
      <main className="wallet">
        <section
          className="wallet__section"
          id="wallet-section"
        >
          <div className="wallet__section__header">
            <h3>{translate("outsideCardList")}</h3>
          </div>

          {creditCardsList && creditCardsList.length > 0 && (
            <Fragment>
              {/* Web */}

              <Flex
                width="100%"
              >
                <Swiper
                  slidesPerView="auto"
                  spaceBetween={24}
                  preventClicks
                  className="wallet__section__carousel"
                  style={{
                    width: `${swiperWidth}px`
                  }}
                >
                  {creditCardsList.map(card => (
                    <SwiperSlide
                      key={card.id}
                      className="wallet__section__carousel__card"
                    >
                      <WalletCard
                        company={card.brand}
                        lastFourDigits={card.lastFourDigits}
                        onClickRemove={() => {
                          setCreditCardId(card.id);
                          openModal("removeCreditCard");
                        }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Flex>

              {/* Mobile */}

              <div className="wallet__section__content">
                {creditCardsList.map(card => (
                  <WalletCard
                    key={card.id}
                    company={card.brand}
                    lastFourDigits={card.lastFourDigits}
                    onClickRemove={() => {
                      setCreditCardId(card.id);
                      openModal("removeCreditCard");
                    }}
                  />
                ))}
              </div>
            </Fragment>
          )}

          {creditCardsList && !creditCardsList.length && (
            <Fragment>
              <div className="wallet__empty-credit-card web">
                <div
                  className="wallet__empty-credit-card__circle"
                  aria-hidden="true"
                >
                <img
                  src="/assets/images/card.svg"
                  alt="pig-coin"
                  style={{width: '50px'}}
                />
                </div>
                <p className="wallet__empty-credit-card__text">
                  {translate("emptyCreditCard.webDescription")}
                </p>
              </div>

              <div className="wallet__empty-state mobile">
                <img
                  src="/assets/images/card.svg"
                  alt="pig-coin"
                />
                <div className="wallet__empty-state__content">
                  <h3>{translate("emptyCreditCard.title")}</h3>
                  <p>{translate("emptyCreditCard.mobileDescription")}</p>
                </div>
              </div>
            </Fragment>
          )}

        </section>

        <section className="wallet__section">
          <div className="wallet__section__header">
            <h3>{translate("outsideAccountList")}</h3>
            <button
              type="button"
              onClick={() => openModal("addAccount")}
            >
              {translate("accountListButton")}
            </button>
          </div>

          {bankTransferList && bankTransferList.length > 0 && (
            <div className="wallet__section__accounts">
              {bankTransferList.map((account, index) => (
                <AccountCard
                  key={account.id}
                  accountNumber={index + 1}
                  name={account.name}
                  bankCode={account.bankDestination}
                  bankName={account.bankName}
                  agency={account.branchDestination}
                  account={account.accountDestination}
                  type={account.validationStatus}
                  accountType={Number(account.accountTypeDestination)}
                  onClickRemove={() => {
                    setAccountId(account.id);
                    openModal("removeAccount");
                  }}
                />
              ))}
            </div>
          )}

          {bankTransferList && !bankTransferList.length && (
            <div className="wallet__empty-state">
              <img
                src="/assets/images/pig.svg"
                alt="pig-coin"
              />
              <div className="wallet__empty-state__content">
                <h3>{translate("emptyAccount.title")}</h3>
                <p>{translate("emptyAccount.description")}</p>
                <button
                  type="button"
                  onClick={() => openModal("addAccount")}
                >
                  {translate("emptyAccount.addButton")}
                  <IcChevronFoward />
                </button>
              </div>
            </div>
          )}
        </section>

        <Modal
          isOpen={visibleModal}
          onClose={closeModal}
        // style={customStyles}
        >
          {modalType === "addCard" && (
            <AddCreditCardModal
              taxIdentifier={user && user.taxIdentifier}
              onSaveCreditCard={listAllCreditCard}
              onClose={closeModal}
            />
          )}

          {modalType === "addAccount" && (
            <AddAccountModal
              bankList={bankList && bankList.length > 0 ? bankList : []}
              onClose={closeModal}
              taxIdentifier={user && user.taxIdentifier}
              userId={user && user.id}
            />
          )}
          {modalType === "removeCreditCard" && (
            <RemoveModal
              type="card"
              onClickCancel={closeModal}
              onClickRemove={handleRemoveCreditCard}
            />
          )}
          {modalType === "removeAccount" && (
            <RemoveModal
              type="account"
              onClickCancel={closeModal}
              onClickRemove={handleRemoveAccount}
            />
          )}
        </Modal>
      </main>
      <ToastContainer />
    </Fragment>
  )
}

const mapStateToProps = state => ({
  user: UserSelectors.getUser(state),
  balance: AccountSelectors.getBalance(state),
  bankList: UtilsSelectors.getBanks(state),
  bankTransferList: BankTransferSelectors.getBankTransferList(state),
  creditCardsList: CreditCardSelectors.getCreditCards(state),
});

const mapDispatchToProps = dispatch => ({
  getBalanceFinance: () => dispatch(AccountActions.balanceFinance()),
  getAllBankTransfer: () => dispatch(BankTransferActions.getAllBankTransfer()),
  removeBankTransfer: (id) => dispatch(BankTransferActions.deleteBankTransfer(id)),
  getBankList: () => dispatch(UtilsActions.getBankList()),
  listAllCreditCard: () => dispatch(CreditCardActions.listAllCreditCard()),
  removeCreditCard: (id) => dispatch(CreditCardActions.removeCreditCard(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelWallet);