import React from "react"
import IcBalance from "../icons/IcBalance";

const EmptyExtract = () => {
  return (
    <div className="empty-extract">
      <div className="empty-extract__icon">
        <IcBalance size={120} />
      </div>
      <p className="empty-extract__text">Nenhuma entrada encontrada</p>
    </div>
  )
}

export default EmptyExtract;