/* eslint-disable max-len */
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import * as eva from 'eva-icons';
import * as Yup from 'yup';
import InputMask from '../../elements/InputMask';
import { UserActions, AuthActions } from '../../../app/store/actions';
import TwoFactorAuthenticate from '../../../data/enumerators/TwoFactorAuthenticate';
import Button from '../../elements/Button';

import numberHelper from '../../../helpers/number'

const PhoneModal = ({ email, onCancel, onClose, sendCode, changePhoneNumber }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const formatedEmail = useMemo(() => {
    const domain = `@${email.split("@")[1]}`;

    return domain.padStart(email.length, "*");
  }, [email]);


  const nextStep = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const handleSendCode = useCallback(async () => {
    await sendCode(TwoFactorAuthenticate.EMAIL);
    nextStep();
  }, [nextStep]);

  const handleSubmitCode = useCallback((data) => {
    cellphoneForm.setFieldValue("twoFactorCode", data.code);
    nextStep();
  }, [nextStep]);

  const handleResendCode = useCallback(async () => {
    await sendCode(TwoFactorAuthenticate.EMAIL);
  }, [sendCode]);

  const handleChangePhone = useCallback(async (data) => {
    data.phone = numberHelper.keepOnlyNumber(data.phone).slice(2);

    delete data.phone_confirmation;

    const { ddd, phone, twoFactorCode } = data;

    try {
      await changePhoneNumber({
        ddd,
        phone,
        twoFactorCode
      });

      toast.success(<Translate value="toastMessage.changeTelefone" />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      });

      onClose();
    } catch (error) {
      toast.error(<Translate value='error.codeInvalid' />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      });
    }
  }, [onClose]);

  const validationSchema = Yup.object().shape({
    code: Yup.string().min(6, "Digite todo o código").required("Este campo é necessário")
  });

  const changePhoneSchema = Yup.object().shape({
    ddd: Yup.string().required(),
    phone: Yup.string().required(),
    phone_confirmation: Yup.string()
      .oneOf([Yup.ref('phone'), null], "Telefones devem corresponder"),
    twoFactorCode: Yup.string().required()
  })

  const codeForm = useFormik({
    initialValues: {
      code: ""
    },
    onSubmit: handleSubmitCode,
    validationSchema
  });

  const cellphoneForm = useFormik({
    initialValues: {
      ddd: "",
      phone: "",
      phone_confirmation: "",
      twoFactorCode: codeForm.values.code
    },
    onSubmit: handleChangePhone,
    validationSchema: changePhoneSchema
  });

  useEffect(() => {
    eva.replace();
  }, []);

  return (
    <div className="update-modal">
      <header>
        <h5>Alterar telefone</h5>
        <button
          type="button"
          className="update-modal__close-button"
          onClick={onCancel}
        >
          <i
            data-eva="close-outline"
          />
        </button>
      </header>

      {currentStep === 0 && (
        <Fragment>
          <p className="update-modal__title">
            Precisamos confirmar sua identidade através do e-mail antes de alterar seu telefone
          </p>

          <div className="update-modal__content">
            <img
              src="/assets/images/mark_email_read.svg"
              alt="mail-icon"
            />
            <div className="update-modal__content__flex">
              <span className="update-modal__content__flex__text">E-mail</span>
              <span className="update-modal__content__flex__text">{email}</span>
            </div>
          </div>


          <footer className="update-modal__footer">
            <Button
              onClick={handleSendCode}
            >
              Enviar código
            </Button>
            <Button
              variant="secondary"
              onClick={onCancel}
            >
              Cancelar
            </Button>
          </footer>
        </Fragment>
      )}

      {currentStep === 1 && (
        <div className="two-factor-code">
          <p className="update-modal__title">
            Digite o código que você recebeu no {formatedEmail}
          </p>

          <span className="update-modal__code">Código</span>

          <input
            name="code"
            maxLength="6"
            type="tel"
            value={codeForm.values.code}
            onChange={codeForm.handleChange}
            placeholder="______"
          />

          {codeForm.errors.code && (
            <p className="two-factor-code__error">{codeForm.errors.code}</p>
          )}

          <Button
            type="button"
            onClick={handleResendCode}
            variant="secondary"
            className="two-factor-code__resend-code"
          >
            Reenviar código
          </Button>

          <footer className="two-factor-code__footer">
            <Button
              variant="secondary"
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              onClick={codeForm.handleSubmit}
            >
              Confirmar
            </Button>
          </footer>
        </div>
      )}

      {currentStep === 2 && (
        <div className="update-form">
          <p className="update-modal__title">
            Digite seu novo endereço telefone
          </p>

          <form onSubmit={cellphoneForm.handleSubmit}>
            <InputMask
              mt={1}
              name="phone"
              mask="(99) 99999-9999"
              placeholder="Telefone"
              onChange={(e) => {
                if (e.target.value.length === 5) {
                  cellphoneForm.setFieldValue('ddd', `${e.target.value.slice(1, 2)}${e.target.value.slice(2, 3)}`);
                }

                cellphoneForm.handleChange(e);
              }}
              value={cellphoneForm.values.phone}
              error={cellphoneForm.errors.phone}
            />
            <InputMask
              mt={1}
              name="phone_confirmation"
              mask="(99) 99999-9999"
              placeholder="Confirmação de telefone"
              onChange={cellphoneForm.handleChange}
              value={cellphoneForm.values.phone_confirmation}
              error={cellphoneForm.errors.phone_confirmation}
            />
          </form>

          <footer className="two-factor-code__footer">
            <Button
              variant="secondary"
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleChangePhone(cellphoneForm.values)
              }}
            >
              Confirmar
            </Button>
          </footer>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  changePhoneNumber: (phone) => dispatch(UserActions.changePhoneNumber(phone)),
  sendCode: (TwoFactorAuthenticate) => dispatch(AuthActions.sendTwoFactor(TwoFactorAuthenticate))
});

export default connect(
  null,
  mapDispatchToProps,
)(PhoneModal);

