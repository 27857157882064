import React, { Fragment } from 'react';
import ChevronLeftIcon from '../../../svg/institutional/ic_chevron_left.svg';

const FaqTableFee = ({ resetQuestion }) => (
  <Fragment>
    <div className="faq__fee">
      <div className="faq__fee__title">
        <ChevronLeftIcon
          className="faq__fee__title__icon"
          onClick={resetQuestion}
        />
        Tarifas
      </div>
      <div className="faq__fee__subtitle">
        <span>
          Tarifas cobradas para você realizar operações através da sua Conta Digital Superbid Pay:
        </span>
      </div>
      <div className="faq__fee__container">
        <ul>
          <li>Depósito de recursos: </li>
          <ul>
            <li>Boleto Bancário: <b>R$ 4,90</b></li>
            <li>Transferência Bancária: <b>GRÁTIS*</b></li>
            <li>Cartão de Crédito:</li>
            <div style={{marginLeft: '20px'}}>
              <li>à vista (1 parcela): <b>5%</b></li>
              <li>2 a 6 parcelas: <b>14%</b></li>
              <li>7 a 12 parcelas: <b>27%</b></li>
            </div>
          </ul>
          <li>Transferência Bancária: <b>GRÁTIS</b></li>
        </ul>

        <small>
          *Verifique a existência de tarifas no seu banco para
          realizar transferências (TED ou DOC) para sua Conta Digital.
        </small>
      </div>
    </div>
  </Fragment>
)

export default FaqTableFee;