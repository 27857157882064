/* eslint-disable react/no-danger */
import React, { useCallback } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import IcDownloadColored from '../icons/IcDownloadColored';
import IcBarCodeColored from '../icons/IcBarCodeColored';
import IcCheck from '../icons/IcCheck';
import number from '../../helpers/number';

const CashInBoletoStepThree = ({ amount, ticket }) => {
  const handleCopyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text);
    toast.success(<Translate value="toastMessage.copy" />,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      });
  }, [])

  const handleOpenDocument = useCallback(() => {
    window.open(ticket.boletoUrl, '_blank')
  }, [])

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="cashin-ticket">
        <div className="cashin-ticket__header">
          <div className="cashin-ticket__header__icon">
            <IcCheck size={22} />
          </div>
          <p className="cashin-ticket__header__title">
            {I18n.t('cashIn.billet.stepThree.barCodeTitle')}
          </p>
          <span className="cashin-ticket__header__value">
            {Number(amount).toLocaleString('pt-br', { style: "currency", currency: "BRL" })}
          </span>
        </div>

        <hr className="cashin-ticket__divider" />

        <div className="cashin-ticket__code">
          <p className="cashin-ticket__code__title">
            {I18n.t('cashIn.billet.stepThree.barCode')}
          </p>
          <span className="cashin-ticket__code__value">
            {number.boletoMask(ticket.typeableLine)}
          </span>
        </div>

        <hr className="cashin-ticket__divider" />

        <p className="cashin-ticket__payment-option">
          {I18n.t('cashIn.billet.stepThree.paymentOptions')}
        </p>

        <div className="cashin-ticket__action">
          <button
            type="button"
            className="cashin-ticket__action__button"
            onClick={handleOpenDocument}
          >
            <IcDownloadColored size={32} />
            {I18n.t('cashIn.billet.stepThree.downloadCode')}
          </button>
          <button
            type="button"
            className="cashin-ticket__action__button"
            onClick={() => handleCopyToClipboard(ticket.typeableLine)}
          >
            <IcBarCodeColored size={32} />
            {I18n.t('cashIn.billet.stepThree.copyCode')}
          </button>
        </div>

        <hr className="cashin-ticket__divider" />

        <div className="cashin-ticket__important">
          <p className="cashin-ticket__important__title">
            {I18n.t('cashIn.billet.stepThree.tip')}
          </p>
          <p className="cashin-ticket__important__item">
            {I18n.t('cashIn.billet.stepThree.tipText')}
          </p>
          <p className="cashin-ticket__important__item">
            {I18n.t('cashIn.billet.stepThree.tipTextTwo')}
          </p>
        </div>

        <hr className="cashin-ticket__divider" />

        <div className="cashin-ticket__check">
          <p className="cashin-ticket__check__title">
            {I18n.t('cashIn.billet.stepThree.tipChecklistTitle')}
          </p>

          <CheckList>
            <p>
              {I18n.t('cashIn.billet.stepThree.tipChecklistOne.text1')}{' '}
              <b>
                {I18n.t('cashIn.billet.stepThree.tipChecklistOne.text2')}{' '}
              </b>
              {I18n.t('cashIn.billet.stepThree.tipChecklistOne.text3')}{' '}
              <b>
                {I18n.t('cashIn.billet.stepThree.tipChecklistOne.text4')}
              </b>
            </p>
          </CheckList>
          <CheckList>
            <p>
              {I18n.t('cashIn.billet.stepThree.tipChecklistTwo.text1')}{' '}
              <b>
                {I18n.t('cashIn.billet.stepThree.tipChecklistTwo.text2')}{' '}
              </b>
            </p>
          </CheckList>
          <CheckList>
            <p>
              {I18n.t('cashIn.billet.stepThree.tipChecklistThree')}
            </p>
          </CheckList>
        </div>

        <hr className="cashin-ticket__divider" />

        <p className="cashin-ticket__important__item">
          {I18n.t('cashIn.billet.stepThree.footer')}
        </p>
      </div>
    </React.Fragment>
  )
}

const CheckList = ({ children }) => {
  return (
    <div className="cashin-ticket__checklist">
      <IcCheck size={16} />
      {children}
    </div>
  )
}

export default CashInBoletoStepThree;
