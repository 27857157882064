import React, { useCallback } from 'react'
import { I18n } from 'react-redux-i18n';
import Button from '../elements/Button';

const CashoutTimeModal = ({ onCancel, onContinue, cashoutLimitTime }) => {
  const translate = useCallback((path) => {
    return I18n.t(`cashoutPage.cashoutLimitTimeModal.${path}`)
  }, [I18n]);

  return (
    <div className="cashout-modal-time">
      <h4>{translate("title")}</h4>
      <p>
        {translate("text1")}
        {' '}{cashoutLimitTime}h{' '}
        {translate("text2")}
      </p>
      <div>
        <Button
          variant="secondary"
          onClick={onCancel}
        >
          {translate("cancel")}
        </Button>
        <Button onClick={onContinue}>
          {translate("confirm")}
        </Button>
      </div>
    </div>
  )
}

export default CashoutTimeModal;