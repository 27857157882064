/* eslint-disable max-len */
import React from "react";

const IcHome = ({ size = 24, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.21171 9.47586H6.23792V4.27173H9.21171V9.47586ZM6.98137 8.73242H8.46826V5.01518H6.98137V8.73242Z"
        fill="#7800A1"
      />
      <path
        d="M19.9924 21.0001H4.3793V12.4614L4.50896 12.3497C6.41534 10.7002 8.21851 8.93502 9.90827 7.0642C10.5765 6.34765 11.2676 5.60696 12.0917 4.75593L12.2007 4.64282H12.5821L12.9959 5.07317C15.7303 7.89662 17.3807 9.60144 19.8924 12.3835L19.9883 12.4897L19.9924 21.0001ZM5.12275 20.2566H19.249V12.7752C16.7986 10.0663 15.1586 8.37248 12.4648 5.5911L12.3917 5.51592C11.6738 6.26213 11.0538 6.92696 10.4517 7.57179C8.78152 9.42017 7.00201 11.1668 5.12275 12.8021V20.2566Z"
        fill="#7800A1"
      />
      <path
        d="M12.1855 6.50211L19.62 13.9366L21.1069 12.4497L12.1855 3.52832L3.26413 12.4497L4.75103 13.9366L12.1855 6.50211Z"
        fill="#CD72FF"
      />
      <path
        d="M19.6207 14.4607L12.1862 7.02621L4.74825 14.4607L2.73584 12.4483L12.1841 3L21.6324 12.4483L19.6207 14.4607ZM12.1862 5.97793L19.6207 13.4124L20.582 12.451L12.1862 4.05379L3.78963 12.4503L4.75101 13.4117L12.1862 5.97793Z"
        fill="#7800A1"
      />
      <path
        d="M9.95515 15.0525H6.98135V20.6284H9.95515V15.0525Z"
        fill="#CD72FF"
      />
      <path
        d="M10.3269 21H6.60965V14.6807H10.3269V21ZM7.3531 20.2565H9.58344V15.4241H7.3531V20.2565Z"
        fill="#7800A1"
      />
      <path
        d="M15.1593 18.3979H11.0703V14.6807H15.1593V18.3979ZM11.8138 17.6545H14.4159V15.4241H11.8138V17.6545Z"
        fill="#7800A1"
      />
      <path
        d="M4.37931 20.2566H3.63586V21H4.37931V20.2566Z"
        fill="#7800A1"
      />
      <path
        d="M2.8924 20.2566H2.14896V21H2.8924V20.2566Z"
        fill="#7800A1"
      />
      <path
        d="M21.851 20.2566H20.7358V21H21.851V20.2566Z"
        fill="#7800A1"
      />
    </svg>

  )
}

export default IcHome;