import React from 'react';
import { I18n } from 'react-redux-i18n';
import SearchIcon from '../../svg/ic_search.svg';

const EmptyState = () => (
  <main className="main empty-state">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <div className="empty-state__icon">
            <SearchIcon />
          </div>
          <h1 className="empty-state__title">
            {I18n.t('emptyState.title')}
          </h1>
          <p className="empty-state__description">
            {I18n.t('emptyState.description')}
          </p>
        </div>
      </div>
    </div>
  </main>
);

export default EmptyState;
