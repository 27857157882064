import React, { useCallback } from "react"
import dayjs from 'dayjs';

import IcArrowFoward from '../icons/IcArrowFoward'

dayjs.locale('pt-br')

const TransactionCard = ({ date, title, value }) => {
  const handleDate = useCallback(() => {
    const isToday = dayjs(date).isSame(dayjs(), 'day');

    return isToday ? 'Hoje' : dayjs(date).locale('pt-br').format("DD MMM").toLocaleUpperCase()
  }, [])

  return (
    <div className="transaction_card">
      <div className="transaction_card__icon">
        <IcArrowFoward />
      </div>


      <div className="transaction_card__content">
        <h6>{title}</h6>
        <span>{value && Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
      </div>

      <span className="transaction_card__date">{date && handleDate()}</span>
    </div>
  )
}

export default TransactionCard