import RestService from '../services/rest';

async function generateBoleto(amount) {
  return RestService.postAuthenticated('account/deposit/boleto', amount);
}

async function getDepositTed(tedId) {
  return RestService.getAuthenticated(`account/deposit/ted/${tedId}`);
}

export default {
  generateBoleto,
  getDepositTed
};
