import React, { useState } from 'react';
import CashInValue from './CashInValue';


const CashInBoletoStepOne = ({ balance, initialAmount, onContinue }) => {
  const [amount, setAmount] = useState(initialAmount)

  return (
    <CashInValue
      balance={balance}
      amount={amount}
      setAmount={setAmount}
      onContinue={onContinue}
    />
  )
}

export default CashInBoletoStepOne;
