/* eslint-disable max-len */
import React from "react";

const IcHistoryColored = ({ size = 24, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.0753 18.503L12.3583 13.173L12.2488 5.75846C13.3531 5.68544 14.4588 5.87887 15.4728 6.32245C16.4868 6.76603 17.3793 7.44677 18.0753 8.30736C20.2598 11.2206 20.2598 13.0408 19.8962 14.1338C19.5326 15.2268 19.1675 17.0471 18.0753 18.503Z"
        fill="#CD72FF"
      />
      <path
        d="M16.3952 16.6229C16.3003 16.6229 16.2075 16.5947 16.1286 16.542C16.0497 16.4892 15.9883 16.4142 15.952 16.3265C15.9157 16.2388 15.9063 16.1424 15.9249 16.0493C15.9435 15.9562 15.9892 15.8708 16.0564 15.8037L19.6545 12.2056C19.6988 12.1598 19.7517 12.1233 19.8102 12.0981C19.8687 12.073 19.9317 12.0598 19.9954 12.0592C20.0591 12.0587 20.1222 12.0708 20.1812 12.0949C20.2401 12.1191 20.2937 12.1547 20.3387 12.1997C20.3837 12.2447 20.4193 12.2983 20.4435 12.3572C20.4676 12.4162 20.4797 12.4794 20.4792 12.543C20.4786 12.6067 20.4654 12.6697 20.4403 12.7282C20.4151 12.7867 20.3786 12.8397 20.3328 12.8839L16.7347 16.482C16.6902 16.5267 16.6373 16.5621 16.579 16.5863C16.5207 16.6105 16.4583 16.623 16.3952 16.6229V16.6229Z"
        fill="#7800A1"
      />
      <path
        d="M14.4757 15.4232C14.3807 15.4234 14.2879 15.3953 14.2089 15.3426C14.1298 15.2899 14.0683 15.2149 14.0319 15.1272C13.9956 15.0395 13.9861 14.9429 14.0046 14.8498C14.0232 14.7566 14.069 14.6711 14.1362 14.604L18.9339 9.80631C18.9781 9.7605 19.0311 9.72397 19.0896 9.69883C19.1481 9.6737 19.211 9.66048 19.2747 9.65993C19.3384 9.65938 19.4016 9.67152 19.4605 9.69564C19.5195 9.71976 19.573 9.75537 19.6181 9.80041C19.6631 9.84544 19.6987 9.899 19.7228 9.95795C19.747 10.0169 19.7591 10.0801 19.7586 10.1437C19.758 10.2074 19.7448 10.2704 19.7197 10.3289C19.6945 10.3874 19.658 10.4404 19.6122 10.4846L14.8145 15.2823C14.77 15.3269 14.7172 15.3623 14.6591 15.3865C14.601 15.4107 14.5386 15.4232 14.4757 15.4232V15.4232Z"
        fill="#7800A1"
      />
      <path
        d="M12.8577 13.8055C12.7623 13.8055 12.6692 13.777 12.59 13.7238C12.5109 13.6706 12.4494 13.595 12.4135 13.5067C12.3775 13.4184 12.3687 13.3214 12.3881 13.228C12.4075 13.1347 12.4544 13.0492 12.5226 12.9826L17.4991 8.12357C17.5902 8.03459 17.7128 7.98541 17.8401 7.98685C17.9674 7.98828 18.0888 8.04022 18.1778 8.13123C18.2668 8.22225 18.316 8.34489 18.3145 8.47216C18.3131 8.59943 18.2612 8.72092 18.1701 8.8099L13.1935 13.6689C13.1038 13.7568 12.9832 13.8058 12.8577 13.8055V13.8055Z"
        fill="#7800A1"
      />
      <path
        d="M12.6182 10.9265C12.5227 10.9265 12.4293 10.8979 12.3501 10.8445C12.2709 10.7911 12.2094 10.7153 12.1736 10.6267C12.1378 10.5382 12.1292 10.4409 12.1489 10.3475C12.1687 10.254 12.2159 10.1686 12.2846 10.1021L16.0616 6.44269C16.1068 6.39883 16.1603 6.36431 16.2189 6.3411C16.2775 6.31789 16.34 6.30645 16.4031 6.30743C16.4661 6.30841 16.5283 6.3218 16.5861 6.34683C16.644 6.37185 16.6963 6.40803 16.7402 6.45329C16.7841 6.49855 16.8186 6.55199 16.8418 6.61059C16.865 6.66919 16.8765 6.73178 16.8755 6.7948C16.8745 6.85782 16.8611 6.92004 16.8361 6.97788C16.811 7.03573 16.7749 7.08807 16.7296 7.13194L12.9526 10.7914C12.8631 10.8784 12.7431 10.9269 12.6182 10.9265V10.9265Z"
        fill="#7800A1"
      />
      <path
        d="M14.7779 3.95939H10.4475C10.3203 3.95939 10.1983 3.90886 10.1083 3.81889C10.0184 3.72893 9.96783 3.60692 9.96783 3.4797C9.96783 3.35247 10.0184 3.23046 10.1083 3.1405C10.1983 3.05054 10.3203 3 10.4475 3H14.7779C14.9052 3 15.0272 3.05054 15.1171 3.1405C15.2071 3.23046 15.2577 3.35247 15.2577 3.4797C15.2577 3.60692 15.2071 3.72893 15.1171 3.81889C15.0272 3.90886 14.9052 3.95939 14.7779 3.95939V3.95939Z"
        fill="#7800A1"
      />
      <path
        d="M3.94203 12.2911C3.84071 12.291 3.74201 12.2588 3.66009 12.1992C3.57818 12.1396 3.51724 12.0555 3.48603 11.9591C3.45481 11.8627 3.45494 11.7589 3.48636 11.6626C3.51778 11.5663 3.57887 11.4824 3.66092 11.4229L5.21537 10.2978C5.26643 10.2608 5.32427 10.2343 5.38558 10.2197C5.4469 10.2051 5.51049 10.2027 5.57273 10.2126C5.63497 10.2226 5.69465 10.2447 5.74833 10.2778C5.80202 10.3108 5.84867 10.3541 5.88564 10.4051C5.9226 10.4562 5.94915 10.514 5.96376 10.5753C5.97837 10.6366 5.98076 10.7002 5.9708 10.7625C5.96083 10.8247 5.93869 10.8844 5.90567 10.9381C5.87264 10.9918 5.82936 11.0384 5.7783 11.0754L4.22385 12.2005C4.14184 12.2597 4.04318 12.2914 3.94203 12.2911V12.2911Z"
        fill="#7800A1"
      />
      <path
        d="M6.62092 12.7203C6.54495 12.7204 6.47002 12.7025 6.40234 12.668C6.33466 12.6335 6.2762 12.5833 6.23177 12.5217L5.10662 10.9672C5.03197 10.8641 5.00135 10.7355 5.02148 10.6098C5.04161 10.4841 5.11084 10.3716 5.21395 10.2969C5.31707 10.2223 5.44561 10.1917 5.57131 10.2118C5.69701 10.2319 5.80957 10.3011 5.88422 10.4043L7.00937 11.9587C7.06114 12.0303 7.09212 12.1148 7.09892 12.2029C7.10572 12.291 7.08807 12.3793 7.04789 12.458C7.00772 12.5366 6.94658 12.6027 6.87124 12.6489C6.7959 12.695 6.70928 12.7195 6.62092 12.7195V12.7203Z"
        fill="#7800A1"
      />
      <path
        d="M20.0547 13.5651C19.9917 13.5651 19.9293 13.5527 19.8711 13.5285C19.8129 13.5044 19.76 13.4691 19.7155 13.4246C19.6709 13.38 19.6356 13.3271 19.6115 13.2689C19.5874 13.2107 19.575 13.1484 19.575 13.0854C19.5728 11.241 18.8392 9.47275 17.5351 8.16858C16.2309 6.8644 14.4626 6.13078 12.6183 6.12865C12.491 6.12865 12.369 6.07811 12.2791 5.98814C12.1891 5.89818 12.1385 5.77617 12.1385 5.64895C12.1385 5.52172 12.1891 5.39971 12.2791 5.30975C12.369 5.21979 12.491 5.16925 12.6183 5.16925C14.7175 5.1708 16.7304 6.00522 18.2151 7.48936C19.6998 8.9735 20.535 10.9861 20.5373 13.0854C20.5373 13.1486 20.5248 13.2112 20.5005 13.2696C20.4762 13.328 20.4406 13.381 20.3958 13.4256C20.3509 13.4702 20.2977 13.5055 20.2391 13.5294C20.1806 13.5533 20.1179 13.5655 20.0547 13.5651V13.5651Z"
        fill="#7800A1"
      />
      <path
        d="M7.58032 8.09491C7.48382 8.09485 7.38956 8.06569 7.30989 8.01123C7.23022 7.95677 7.16882 7.87955 7.13373 7.78964C7.09864 7.69974 7.09148 7.60134 7.11318 7.50731C7.13489 7.41328 7.18446 7.32798 7.25541 7.26255C8.71576 5.91455 10.6309 5.16702 12.6182 5.16926C12.7455 5.16926 12.8675 5.21979 12.9574 5.30975C13.0474 5.39972 13.0979 5.52173 13.0979 5.64895C13.0979 5.77618 13.0474 5.89819 12.9574 5.98815C12.8675 6.07811 12.7455 6.12866 12.6182 6.12866C10.8717 6.1265 9.18864 6.7833 7.90522 7.96787C7.81685 8.04974 7.70079 8.09513 7.58032 8.09491V8.09491Z"
        fill="#7800A1"
      />
      <path
        d="M5.1818 13.565C5.05457 13.565 4.93256 13.5145 4.8426 13.4245C4.75264 13.3346 4.70209 13.2126 4.70209 13.0853C4.70164 12.4101 4.78727 11.7376 4.95691 11.084C4.98895 10.9609 5.06862 10.8555 5.17837 10.7911C5.28811 10.7266 5.41896 10.7085 5.54212 10.7405C5.66528 10.7725 5.77066 10.8522 5.83508 10.962C5.8995 11.0717 5.91769 11.2025 5.88564 11.3257C5.73635 11.9003 5.66102 12.4916 5.66148 13.0853C5.66148 13.2126 5.61096 13.3346 5.52099 13.4245C5.43103 13.5145 5.30902 13.565 5.1818 13.565V13.565Z"
        fill="#7800A1"
      />
      <path
        d="M12.6182 21C10.5194 20.9977 8.5073 20.1629 7.02324 18.6789C5.53918 17.1948 4.70441 15.1827 4.70209 13.0839C4.70209 12.9567 4.75264 12.8346 4.8426 12.7447C4.93256 12.6547 5.05457 12.6042 5.1818 12.6042C5.30902 12.6042 5.43103 12.6547 5.52099 12.7447C5.61095 12.8346 5.66148 12.9567 5.66148 13.0839C5.66361 14.9283 6.39724 16.6965 7.70141 18.0007C9.00559 19.3049 10.7738 20.0385 12.6182 20.0406C12.7454 20.0406 12.8674 20.0912 12.9574 20.1811C13.0474 20.2711 13.0979 20.3931 13.0979 20.5203C13.0979 20.6475 13.0474 20.7695 12.9574 20.8595C12.8674 20.9495 12.7454 21 12.6182 21V21Z"
        fill="#7800A1"
      />
      <path
        d="M12.6182 21C12.491 21 12.369 20.9495 12.279 20.8595C12.189 20.7695 12.1385 20.6475 12.1385 20.5203C12.1385 20.3931 12.189 20.2711 12.279 20.1811C12.369 20.0912 12.491 20.0406 12.6182 20.0406C14.4626 20.0385 16.2308 19.3049 17.535 18.0007C18.8392 16.6965 19.5728 14.9283 19.5749 13.0839C19.5749 12.9567 19.6255 12.8346 19.7154 12.7447C19.8054 12.6547 19.9274 12.6042 20.0546 12.6042C20.1818 12.6042 20.3038 12.6547 20.3938 12.7447C20.4838 12.8346 20.5343 12.9567 20.5343 13.0839C20.532 15.1827 19.6972 17.1948 18.2132 18.6789C16.7291 20.1629 14.717 20.9977 12.6182 21V21Z"
        fill="#7800A1"
      />
      <path
        d="M17.5941 18.7811C17.5311 18.7811 17.4688 18.7687 17.4107 18.7445C17.3525 18.7203 17.2997 18.6849 17.2553 18.6402L12.0392 13.4241C11.9945 13.3796 11.959 13.3267 11.9349 13.2685C11.9107 13.2102 11.8982 13.1477 11.8983 13.0846V5.64895C11.8983 5.52172 11.9489 5.39971 12.0388 5.30975C12.1288 5.21979 12.2508 5.16925 12.378 5.16925C12.5052 5.16925 12.6273 5.21979 12.7172 5.30975C12.8072 5.39971 12.8577 5.52172 12.8577 5.64895V12.8868L17.9307 17.9605C17.9979 18.0276 18.0437 18.1131 18.0623 18.2062C18.0808 18.2994 18.0713 18.3959 18.035 18.4837C17.9986 18.5714 17.937 18.6463 17.858 18.699C17.779 18.7517 17.6861 18.7798 17.5912 18.7797L17.5941 18.7811Z"
        fill="#7800A1"
      />
    </svg>

  )
}

export default IcHistoryColored;