/* eslint-disable max-len */
import * as React from "react"

const IcWallet = ({ size = 32, ...props }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      d="M23 2h-1v3h1V2ZM25.831 3.12l-1.419 2.458.945.545 1.42-2.458-.946-.546ZM28.672 5.561l-2.459 1.42.546.945 2.458-1.42-.545-.945ZM30 9h-2v1h2V9ZM24.457 12.605h-1.091V9.877H3.18a1.066 1.066 0 0 0-1.089 1.091H1a2.158 2.158 0 0 1 2.18-2.182h21.277v3.819Z"
      fill="currentColor"
    />
    <path
      d="M27.185 29.515h-24A2.16 2.16 0 0 1 1 27.333V10.968h1.091a1.067 1.067 0 0 0 1.091 1.091h24l.003 17.456ZM2.091 12.868v14.466a1.067 1.067 0 0 0 1.091 1.091h22.911V13.15H3.182a2.207 2.207 0 0 1-1.091-.282Z"
      fill="currentColor"
    />
    <path
      d="M27.185 24.061h-7.91a3.546 3.546 0 1 1 0-7.092h7.91a1.639 1.639 0 0 1 1.637 1.637v3.819a1.639 1.639 0 0 1-1.637 1.636Zm-7.91-6a2.455 2.455 0 0 0 0 4.91h7.91a.545.545 0 0 0 .546-.546v-3.819a.545.545 0 0 0-.546-.546l-7.91.001Z"
      fill="currentColor"
    />
  </svg>
)

export default IcWallet
