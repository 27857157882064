import React from 'react';
import ExtractPublicDetails from '../components/extract/ExtractPublicDetails';

const PublicExtractDetails = () => (
  <div className="panel">
    <div className="panel__head mobile-hidden" />
    <div className="panel__container">
      <div className="row m-0">
        <div className="col-12 p-0">
          <ExtractPublicDetails />
        </div>
      </div>
    </div>
  </div>
);

export default PublicExtractDetails;
