import React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import ROUTES from '../../data/routes';

const LoginFooter = () => (
  <footer className="login__bottom__links justify-content-center row">
    <div className="col-lg-2 col-3">
      <Link to={ROUTES.FAQ}>{I18n.t('login.help')}</Link>
    </div>
    <div className="col-lg-2 col-6">
      <Link to={ROUTES.TERMS}>{I18n.t('login.terms')}</Link>
    </div>
    <div className="col-lg-2 col-3">
      <Link to={ROUTES.ABOUT_US}>{I18n.t('login.about')}</Link>
    </div>
    <div className="col-lg-2 col-3">
      <a
        href={ROUTES.OMBUDSMAN}
        target="_blank"
        rel="noreferrer"
      > {I18n.t('login.ombudsman')}
      </a>
    </div>
    <div className="col-lg-2 col-3">
      <Link to={ROUTES.REPORT_OMBUDSMAN}>{I18n.t('login.reportOmbudsman')}</Link>
    </div>
  </footer>
);


export default LoginFooter;
