import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { setLocale } from "yup";
import PanelSidebar from "../components/panel/PanelSidebar";
import Header from "../components/header/Header";
import { AccountSelectors, AuthSelectors } from "../app/store/reducers";
import MobileSidebarWrapper from "../components/mobile/MobileSidebarWrapper";
import { AccountActions } from "../app/store/actions";

import schemaLocale from "../helpers/schemaValidation";

export const PanelRoute = ({
  showHeaderBalanceWeb = true,
  showHeaderBalanceMobile,
  isAuthenticated,
  component: Component,
  noHeader,
  title,
  balance,
  getBalanceFinance,
  ...rest
}) => {
  const formatedBalance =
    balance &&
    balance.real.toLocaleString("pt-br", { minimumFractionDigits: 2 });

  const formatedAvailableBalance =
    balance &&
    balance.available.toLocaleString("pt-br", { minimumFractionDigits: 2 });

  useEffect(() => {
    getBalanceFinance();
  }, [showHeaderBalanceWeb]);

  setLocale(schemaLocale);

  return (
    <main className="main-panel">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="mobile-hidden">
        {!noHeader && (
          <Header
            showHeaderBalanceWeb={showHeaderBalanceWeb}
            balance={formatedBalance}
            refreshBalance={() => getBalanceFinance()}
          />
        )}
      </div>

      <MobileSidebarWrapper
        showBalance={showHeaderBalanceMobile}
        balance={formatedBalance}
        availableBalance={formatedAvailableBalance}
        refreshBalance={() => getBalanceFinance()}
      />

      <div className="main-panel__container">
        <PanelSidebar />

        <div className="main-panel__content">
          <Route
            {...rest}
            component={(props) =>
              isAuthenticated ? (
                <div
                  className="main-panel__content__item"
                >
                  <Component {...props} />
                </div>
              ) : (
                <Redirect to="/login" />
              )
            }
          />
        </div>
      </div>
    </main>
  )
}

// export const PanelRoute = ({
//   showHeaderBalanceWeb = true,
//   showHeaderBalanceMobile,
//   isAuthenticated,
//   component: Component,
//   noHeader,
//   title,
//   balance,
//   getBalanceFinance,
//   ...rest
// }) => {
//   const formatedBalance =
//     balance &&
//     balance.real.toLocaleString("pt-br", { minimumFractionDigits: 2 });

//   const formatedAvailableBalance =
//     balance &&
//     balance.available.toLocaleString("pt-br", { minimumFractionDigits: 2 });

//   useEffect(() => {
//     getBalanceFinance();
//   }, [showHeaderBalanceWeb]);

//   setLocale(schemaLocale);

//   return (
//     <React.Fragment>
//       <div className="mobile-hidden">
//         {!noHeader && (
//           <Header
//             showHeaderBalanceWeb={showHeaderBalanceWeb}
//             balance={formatedBalance}
//             refreshBalance={() => getBalanceFinance()}
//           />
//         )}
//       </div>
//       <MobileSidebarWrapper
//         showBalance={showHeaderBalanceMobile}
//         balance={formatedBalance}
//         availableBalance={formatedAvailableBalance}
//         refreshBalance={() => getBalanceFinance()}
//       />
//       <main className="main">
//         <Helmet>
//           <title>{title}</title>
//         </Helmet>

//         <div
//           className="container-fluid"
//           style={{ flex: 1 }}
//         >
//           <div
//             className="row"
//             style={{ height: "100%" }}
//           >
//             <PanelSidebar />

//             <Route
//               {...rest}
//               component={(props) =>
//                 isAuthenticated ? (
//                   <div className="col-lg-10 p-0 panel__content--border">
//                     <Component {...props} />
//                   </div>
//                 ) : (
//                   <Redirect to="/login" />
//                 )
//               }
//             />
//           </div>
//         </div>
//       </main>
//     </React.Fragment>
//   );
// };

const mapStateToProps = (state) => ({
  isAuthenticated: AuthSelectors.getIsAuthenticated(state),
  balance: AccountSelectors.getBalance(state),
});

const mapDispatchToProps = (dispatch) => ({
  getBalanceFinance: () => dispatch(AccountActions.balanceFinance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelRoute);
