import React from 'react';
import { Translate } from 'react-redux-i18n';

const PaymentOrderStatus = {
  ALL: 0,
  WAITING_PAYMENT: 1,
  IN_PROGRESS: 2,
  AUTHORIZED: 3,
  PAID: 4,
  CANCELED: 5,
  BALANCE_LOCKED: 6,
  CANCEL_IN_PROGRESS: 7,
};

export const PaymentOrderStatusMap = new Map([
  [PaymentOrderStatus.ALL, { id: 0, description: <Translate value="paymentTypeEnum.all" /> }],
  [PaymentOrderStatus.WAITING_PAYMENT, { id: 1, description: <Translate value="paymentTypeEnum.waitingPayment" /> }],
  [PaymentOrderStatus.IN_PROGRESS, { id: 2, description: <Translate value="paymentTypeEnum.inProgress" /> }],
  [PaymentOrderStatus.AUTHORIZED, { id: 3, description: <Translate value="paymentTypeEnum.authorized" /> }],
  [PaymentOrderStatus.PAID, { id: 4, description: <Translate value="paymentTypeEnum.paid" /> }],
  [PaymentOrderStatus.CANCELED, { id: 5, description: <Translate value="paymentTypeEnum.canceled" /> }],
  [PaymentOrderStatus.BALANCE_LOCKED, { id: 6, description: <Translate value="paymentTypeEnum.release" /> }],
  [PaymentOrderStatus.CANCEL_IN_PROGRESS, {
    id: 7,
    description: <Translate value="paymentTypeEnum.cancelInProgress" />
  }],
]);

export const PaymentOrderStatusArray = [
  { id: 0, description: <Translate value="paymentTypeEnum.all" /> },
  { id: 1, description: <Translate value="paymentTypeEnum.waitingPayment" /> },
  { id: 2, description: <Translate value="paymentTypeEnum.inProgress" /> },
  { id: 3, description: <Translate value="paymentTypeEnum.authorized" /> },
  { id: 4, description: <Translate value="paymentTypeEnum.paid" /> },
  { id: 5, description: <Translate value="paymentTypeEnum.canceled" /> },
  { id: 6, description: <Translate value="paymentTypeEnum.release" /> },
  { id: 7, description: <Translate value="paymentTypeEnum.cancelInProgress" /> },
]

export default PaymentOrderStatus;
