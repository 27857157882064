import React from "react";

const IcCheck = ({ size = 24, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.25 6.43372L10.4297 0.254028L11.25 1.07434L4.25 8.07434L0.996094 4.82043L1.81641 4.00012L4.25 6.43372Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IcCheck;