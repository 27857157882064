import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const InstitutionalMapList = ({ title, links }) => {
  return (
    <Fragment>
      <div className="map__list">
        <div className="map__list__title">
          {title}
        </div>
        {links && links.length > 0 && (
          links.map((e, i) => (
            <Link
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className="map__list__single"
              to={e.url}
            >
              {e.name}
            </Link>
          ))
        )}
      </div>
    </Fragment>
  )
}

export default InstitutionalMapList;
