import preferences from '../../data/preferences';

function getBaseUrlAdiq() {
  const { hostname } = window.location;
  const url = preferences.urlAdiq[hostname];
  if (url) return url;
  return preferences.urlAdiq.localhost;
}

export default {
  getBaseUrlAdiq,
};
