const statusColor = (status) => {
  const colors = {
    '1': () => 'yellow',
    '2': () => 'blue',
    '3': () => 'light-blue',
    '4': () => 'green',
    '5': () => 'red',
    'default': () => '',
  };
  return (colors[`${status}`] || colors['default'])();
}

export default statusColor;
