import React, { Fragment } from 'react';
import DoubtAnswer from '../../../data/enumerators/doubtAnswer'

const FaqDoubt = ({ answer }) => {
  return (
    <Fragment>
      <div className="faq__doubt">
        <div className="row m-0">
          <div className="col-12">
            <p className="faq__doubt__title">A sua dúvida foi respondida?</p>

            <div className="row m-0 justify-content-between">
              <div className="col-4">
                <label
                  htmlFor="doubt__answer__yes"
                  className="faq__doubt__container"
                > 
                  Sim
                  <input
                    id="doubt__answer__yes"
                    type="radio"
                    className="faq__doubt__container__input"
                    name="radio"
                    value={DoubtAnswer.YES}
                    onClick={() => answer(DoubtAnswer.YES)}
                  />
                  <span className="faq__doubt__container__checkmark" />
                </label>
              </div>

              <div className="col-4">
                <label
                  className="faq__doubt__container"
                  htmlFor="doubt__answer__no"
                > 
                  Não
                  <input
                    id="doubt__answer__no"
                    type="radio"
                    name="radio"
                    className="faq__doubt__container__input"
                    value={DoubtAnswer.NO}
                    onClick={() => answer(DoubtAnswer.NO)}
                  />

                  <span className="faq__doubt__container__checkmark" />
                </label>
              </div>

            </div>
            
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FaqDoubt;
