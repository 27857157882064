import React from 'react';
import BalanceIcon from '../../svg/paymentMethods/ic_balance.svg';
import SingleBilletIcon from '../../svg/paymentMethods/ic_single_billet.svg';
import CreditCardIcon from '../../svg/paymentMethods/ic_credit_card.svg';

const PaymentMethodIcon = ({ method }) => {
  const methods = {
    'BOLETO': <SingleBilletIcon className="paymet-method__icon" />,
    'SALDO': <BalanceIcon className="paymet-method__icon" />,
    'CARTAO_CREDITO': <CreditCardIcon className="paymet-method__icon" />,
    'BOLETO_PARCELADO': <SingleBilletIcon className="paymet-method__icon" />,
    'default': <SingleBilletIcon className="paymet-method__icon-hidden" />
  };
  return (methods[method] || methods['default']);
}

export default PaymentMethodIcon;
