import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AuthSelectors } from '../app/store/reducers';

import InstitutionalHeader from '../components/institutional/InstitutionalHeader';
import InstitutionalFooter from '../components/institutional/InstitutionalFooter';

const InstitutionalRoute = ({
  component: Component,
  isAuthenticated,
  title,
  path,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div className="institutional-route__content">
        <InstitutionalHeader />

        <Helmet>
          <title>{title}</title>
        </Helmet>

        <Route
          {...rest}
          component={props => (
            !isAuthenticated ? (
              <Component {...props} />
            ) : (
              <Redirect to={`/painel${path}`} />
            )
          )}
        />
        <div className="institutional-route__footer">
          <InstitutionalFooter />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: AuthSelectors.getIsAuthenticated(state),
});

export default connect(mapStateToProps)(InstitutionalRoute);
