import React from 'react';

const SubmitButton = ({ name, hasShadow, onClick }) => (
  <button
    type="submit"
    onClick={onClick}
    className={`login__container__form__button ${hasShadow ? 'button-shadow' : ''}`}
  >
    {name}
  </button>
);

export default SubmitButton;
