import React, { useMemo } from "react";

import IcTrash from '../../svg/ic_trash-outline.svg';

const CreditCard = ({ company, lastFourDigits, onClickRemove }) => {
  const companyImage = useMemo(() => {
    switch (company) {
      case 'Visa':
        return '/assets/images/cards/ic_visa_blue.svg';
      case 'Mastercard':
        return "/assets/images/cards/ic_master.svg"
      default:
        return "/assets/images/cards/ic_master.svg";
    }
  }, [company])

  return (
    <article className="credit-card">
      <div className="credit-card__header">
        <div
          className="credit-card__header__flag"
        >
          <img
            src={companyImage}
            alt="card-flag"
          />
        </div>
        <div title="Excluir cartão">
          <IcTrash
            alt="ic-trash"
            className="credit-card__header__icon"
            onClick={onClickRemove}
          />
        </div>
      </div>

      <div className="credit-card__data">
        <h4>{company}</h4>
        <p>**** **** **** {lastFourDigits}</p>
      </div>

      <IcTrash
        alt="ic-trash"
        onClick={onClickRemove}
        className="credit-card__mobile-icon"
      />

      <hr className="credit-card__mobile-divider" />
    </article>
  )
}

export default CreditCard;