/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { TermsAndPoliticsEnum } from '../../../data/enumerators/termsAndPolitics';

const TermsOfUse = ({ current }) => {
  return (
    <Fragment>
      {current === TermsAndPoliticsEnum.TERMS_OF_USE && (
        <div>
          <p><b>TERMOS E CONDIÇÕES DE USO DO SUPERBID EXCHANGE E DA CONTA DIGITAL SUPERBID PAY</b></p>

          <p>Este documento (“Termos e Condições”) regula o uso da plataforma tecnológica de serviços interativos de leilões e vendas online denominada “Superbid Exchange” e da conta de pagamento denominada “Conta Digital Superbid Pay. O usuário deverá ler atentamente este documento antes de realizar seu cadastro. Os usuários do Superbid Exchange podem ser compradores, agentes de venda que atuam em nome próprio (vendedores) ou agentes de venda mandatados por vendedores para a venda de bens (Leiloeiros Oficiais, Leiloeiros Judiciais, Pregoeiros Administrativos, Leiloeiros Rurais, Corretores de Imóveis, Empresas Organizadoras de Leilão e Empresas Intermediadoras da Venda Direta) – “usuário”. Em caso de dúvidas, o usuário poderá entrar em contato por meio do telefone [11-4950-9400] (todas as localidades) e e-mail [cac@superbid.net] (“Canais de Atendimento”). As informações de contato dos Canais de Atendimento também estão disponíveis no Superbid Exchange. Ao aderir a estes Termos e Condições, o usuário aceita e concorda com todas as regras aqui estabelecidas, as quais constituem acordo integral com relação ao uso do Superbid Exchange, dos demais produtos, serviços e lojas a ele relacionados e da Conta Digital Superbid Pay, no que for aplicável. São realizados no Superbid Exchange os seguintes eventos: (i) leilões públicos, (ii) leilões privados e (iii) vendas diretas com confirmação de compra - “Compre Já”, recebimento de propostas, ou híbrida (confirmação de compra - “Compre Já” + proposta), sendo cada uma dessas modalidades sujeita a regras específicas.</p>

          <p><b>1. CADASTRO</b></p>

          <p><b>1.1.</b> Para participar dos eventos realizados no Superbid Exchange e utilizar todas as suas funcionalidades, o usuário deverá realizar o seu cadastro no Superbid Exchange, mediante preenchimento das informações e fornecimento dos documentos solicitados.</p>

          <p><b>1.2.</b> O Superbid Exchange poderá, a qualquer momento, entrar em contato com o usuário para solicitar o envio de dados e/ou documentos adicionais, com o intuito de esclarecer, revisar ou aprovar o cadastro do usuário e as informações por ele fornecidas.</p>

          <p><b>1.3.</b> As informações e os documentos exigidos para a realização do cadastro podem variar de acordo com o usuário em questão (pessoa jurídica ou pessoa física) e/ou com as funcionalidades que serão utilizadas, entre outros fatores.</p>

          <p><b>1.4.</b> Cada usuário está autorizado a ter apenas UM cadastro a ele associado no Superbid Exchange, seja de comprador ou de agente de venda. Caso seja identificado mais de um cadastro para o mesmo usuário, um ou todos esses cadastros poderão ser suspensos ou cancelados.</p>

          <p><b>1.5.</b> Além das informações cadastrais mencionadas acima, o usuário do Superbid Exchange deverá observar os seguintes requisitos para poder utilizar o Superbid Exchange:</p>

          (i) no caso de pessoas físicas, o usuário deverá ter mais de 18 (dezoito) anos;<br />
          (ii) no caso de pessoas jurídicas, deverá ser representada por pessoas autorizadas e com poderes para agir em seu nome, tais como sócios, diretores ou procuradores e, se tiver sede no Brasil, estar devidamente cadastrada no CNPJ, e<br />
          (iii) atender a critérios mínimos de prevenção à fraude determinados pelo Grupo Superbid com base na legislação e normativos vigentes, incluindo, se necessário, a prestação de esclarecimentos e o fornecimento de informações ou documentação adicional às solicitadas inicialmente na realização do cadastro.<br />

          <p><b>1.6.</b> Ao se cadastrar, o usuário deverá criar um “Nome de Usuário” para sua identificação e login no Superbid Exchange, bem como uma senha pessoal e intransferível.</p>

          <p><b>1.7.</b> O Superbid Exchange poderá impedir a utilização de Nome de Usuário que guarde semelhança com o nome Superbid, com o nome dos agentes de venda dos bens divulgados no Superbid Exchange, que contenha dados pessoais do usuário que permitam a sua identificação e/ou permitam que terceiros possam contatá-lo (URL, endereço eletrônico, etc.) ou que contenha termo considerado ofensivo.</p>

          <p><b>1.8.</b> O usuário é responsável por garantir a confidencialidade do seu Nome de Usuário e senha criados no ato do cadastro no Superbid Exchange, não devendo divulgá-los a terceiros. O usuário é o único e exclusivo responsável por todas e quaisquer atividades que ocorram em seu cadastro para o uso do Superbid Exchange, portanto, deve ter cuidado ao utilizar redes públicas desconhecidas e/ou de terceiros, e/ou gravar senhas de forma automática em computadores, celulares, tablets e/ou outros dispositivos.</p>

          <p><b>1.9.</b> O Superbid Exchange não será, em qualquer hipótese, responsável por quaisquer prejuízos causados ao usuário ou a terceiros pela divulgação e utilização indevida de seus dados de Nome de Usuário e senha.</p>

          <p><b>1.10.</b> O usuário declara que todas as informações e conteúdos enviados para fins de seu cadastro são verdadeiros, exatos, atuais e completos, responsabilizando-se civilmente pela veracidade e exatidão das informações fornecidas. O usuário também se compromete a manter seus dados cadastrais permanentemente atualizados e a enviar informações e esclarecimentos sempre que solicitado.</p>

          <p><b>1.11.</b> O cadastro do usuário no Superbid Exchange está condicionado à análise e aprovação dos dados cadastrais fornecidos pelo usuário. O Superbid Exchange se reserva o direito de recusar qualquer solicitação de cadastro e/ou de suspender um cadastro previamente aceito em qualquer das seguintes situações:</p>
          (i) a identidade do usuário não possa ser verificada;<br />
          (ii) se qualquer informação fornecida pelo usuário for falsa, incorreta, desatualizada ou incompleta;<br />
          (iii) caso seja verificada qualquer pendência financeira junto ao Superbid Exchange, e<br />
          (iv) fique demonstrado que há indícios de que o usuário cometeu fraude ou atos dolosos ou teve qualquer comportamento que possa, de qualquer modo, causar danos a terceiros ou ao Superbid Exchange.<br />

          <p><b>1.12.</b> O usuário tem ciência de que ao aceitar estes Termos e Condições de Uso e concluir seu cadastro no Superbid Exchange, o Superbid Exchange poderá, diretamente ou por meio de terceiros, realizar consultas a diversas fontes com a finalidade de validar a identidade do usuário, bem como para cumprir deveres regulatórios e legais.</p>

          <p><b>1.13.</b> Uma vez concluído e aprovado o cadastro, o usuário receberá em até 1 dia útil permissão de acesso ao Superbid Exchange, que deverá ser realizado por meio do Nome de Usuário e senha por ele criados para fazer uso das funcionalidades disponibilizadas pelo Superbid Exchange.</p>

          <p><b>1.14.</b> Quando houver alteração dos dados cadastrais, é obrigação do usuário atualizálos no Superbid Exchange.</p>

          <p><b>2. CONTA DIGITAL SUPERBID PAY</b></p>

          <p><b>2.1.</b> Ao se cadastrar no Superbid Exchange é criada uma conta de pagamento na modalidade pré-paga, de titularidade única e exclusiva do usuário (“Conta Digital Superbid Pay), a qual deverá ser utilizada para realizar transações através do Superbid Exchange, de forma a garantir uma maior transparência e segurança às partes envolvidas (compradores, agentes de venda, Superbid Exchange e Superbid Pay).</p>

          <p><b>2.2.</b> A Conta Digital Superbid Pay mencionada acima somente será criada para os usuários residentes e domiciliados no Brasil. Os usuários não residentes cadastrados no Superbid Exchange deverão utilizar outros meios de pagamento disponíveis para participar dos eventos.</p>

          <p><b>2.3.</b> A Conta Digital Superbid Pay é emitida e gerenciada pela Superbid Pay Instituição de Pagamento Ltda., inscrita no CNPJ/MF sob o nº 35.084.163/0001-84 (“Superbid Pay”). A contratação da Conta Digital Superbid Pay estabelece vínculo jurídico diretamente entre o usuário e a Superbid Pay, conforme estes Termos e Condições.</p>

          <p><b>2.4.</b> O usuário aceita e concorda com a criação da Conta Digital Superbid Pay junto à Superbid Pay, bem como concorda que a Superbid Pay compartilhe seus dados pessoais/cadastrais para atendimento aos requisitos legais e consulte seus dados pessoais/cadastrais para validação das informações e estabelecimento de perfil de crédito.</p>

          <p><b>2.5.</b> Não será cobrado qualquer valor do usuário a título de abertura e manutenção da Conta Digital Superbid Pay.</p>

          <p><b>2.6.</b> A Conta Digital Superbid Pay pode ser acessada e utilizada por meio do Superbid Exchange (www.superbid.net) e do Portal Superbid Pay (www.superbidpay.com.br). A Conta Digital Superbid Pay é exclusiva do usuário e somente este pode utilizá-la.</p>

          <p><b>2.7.</b> São direitos do titular da Conta Digital Superbid Pay:</p>

          <p>1. realizar a movimentação de recursos previamente aportados na sua Conta Digital Superbid Pay;</p>

          <p>2. ter acesso a todas as movimentações de entrada e saída de recursos ocorridas em sua Conta Digital Superbid Pay;</p>

          <p>3. realizar transferências e pagamentos, respeitando as condições estabelecidas nestes Termos e Condições;</p>

          <p>4. ser atendido e/ou encerrar a Conta Digital Superbid Pay nas condições estabelecidas nestes Termos e Condições a qualquer momento, de maneira fácil e rápida.</p>

          <p><b>2.8.</b> São obrigações do titular da Conta Digital Superbid Pay:</p>

          <p>1. utilizar a Conta Digital Superbid Pay respeitando as condições estabelecidas nestes Termos e Condições;</p>

          <p>2. pagar os encargos e tributos relacionados à utilização de sua Conta Digital Superbid Pay ou a outros produtos contratados do Superbid Exchange ou da Superbid Pay;</p>

          <p>3. manter seus dados cadastrais sempre atualizados junto ao Superbid Exchange e à Superbid Pay;</p>

          <p>4. informar à Superbid Pay antes de realizar transferências ou pagamentos fora do seu padrão de uso, tais como compras ou retiradas de alto valor, evitando eventuais bloqueios preventivos para a sua segurança;</p>

          <p>5. conferir atentamente seu saldo e as transações anteriormente a qualquer realização de movimentação em sua Conta Digital Superbid Pay;</p>

          <p>6. informar imediatamente à Superbid Pay caso verifique alguma movimentação atípica na sua Conta Digital Superbid Pay ou não reconhecida por ele;</p>

          <p>7. ler e aceitar o Aviso de Privacidade do Grupo Superbid.</p>

          <p><b>2.9.</b> A Conta Digital Superbid Pay permanecerá ativa pelo prazo de 6 meses a contar da última transação realizada (aporte e/ou retirada), a partir de quando será inativada mediante prévia notificação ao usuário. Eventual saldo remanescente na conta inativada será transferido para a conta bancária de titularidade do usuário em instituição financeira cadastrada no Superbid Exchange ou de outra forma informada por meio dos Canais de Atendimento.</p>

          <p><b>2.10.</b> A Tabela de Tarifas para a realização de transações utilizando a Conta Digital Superbid Pay está publicada no endereço https://superbidpay.com.br/ajuda. A alteração das tarifas vigentes será previamente comunicada aos usuários.</p>

          <p><b>2.11.</b> A Superbid Pay comunicará ao Banco Central do Brasil, ao Conselho de Controle de Atividades Financeiras ou a outros órgãos que a legislação previr, as operações que possam estar configuradas na Lei 9.613/98 (que dispõe sobre os crimes de lavagem ou ocultação de bens, direitos e valores) e demais disposições legais pertinentes à matéria.</p>

          <p><b>2.12.</b> A Conta Digital Superbid Pay poderá ser bloqueada, sem que seja permitida a realização de nenhuma movimentação, a qualquer momento, caso haja qualquer suspeita de inconsistências cadastrais, crimes financeiros, operações fora do seu padrão de uso e/ou utilização indevida que desrespeite qualquer condição presente nestes Termos e/ou na legislação vigente aplicável. Nesses casos, a Conta Digital Superbid Pay somente será liberada após o esclarecimento e regularização da situação que motivou o bloqueio.</p>

          <p><b>2.13.</b> Para comprar ou vender bens, o usuário precisa obrigatoriamente cadastrar no Superbid Exchange conta bancária de sua titularidade. Para tanto, deverá acessar a seção &quot;Minha Conta&quot; do Superbid Exchange, clicar no botão “Ver Detalhes” dentro da seção “Conta Digital”. Neste momento o usuário será direcionado para a página da Superbid Pay, onde deve clicar no botão “Carteira” e posteriormente acessar “Contas bancárias” e incluir os dados da conta bancária de sua titularidade. Nenhum valor poderá ser retirado ou recebido através de conta bancária de terceiros.</p>

          <p><b>2.14.</b> Quando houver alteração dos dados bancários cadastrados, é obrigação do usuário atualizá-los na página da Superbid Pay.</p>

          <p><b>2.15.</b> Se, por qualquer motivo e a qualquer tempo, a Conta Digital Superbid Pay não possuir saldo suficiente para pagamento dos valores devidos pelo usuário, o Superbid Exchange e a Superbid Pay poderão (i) não prestar o serviço solicitado ou, alternativamente, (ii) debitar da Conta Digital Superbid Pay os valores respectivos quando a mesma apresentar saldo positivo. Caso o usuário não venha a aportar recursos na Conta Digital Superbid Pay ou caso os recursos aportados não sejam suficientes para quitar os valores devidos, o usuário ficará sujeito às penalidades devidas em virtude da inadimplência. Aporte de Recursos</p>

          <p><b>2.16.</b> Por se tratar de uma conta de pagamento pré-paga, a realização de quaisquer pagamentos por meio da Conta Digital Superbid Pay sempre dependerá da existência de fundos em reais previamente aportados.</p>

          <p><b>2.17.</b> Para aportar recursos na sua Conta Digital Superbid Pay, o usuário poderá utilizar qualquer um dos métodos disponíveis, entre eles: (i) Boleto Bancário, (ii) Transferência Bancária, e (iii) Cartão de Crédito. Não será possível realizar aportes na Conta Digital Superbid Pay a partir de dinheiro em espécie ou cheques. A Superbid Pay poderá, a seu critério, estabelecer limites para aporte com certos instrumentos de pagamento.</p>

          <p><b>2.18.</b> Eventual saldo de recursos mantidos na Conta Digital Superbid Pay não será remunerado.</p>

          <p><b>2.19.</b> Os recursos destinados para aporte na Conta Digital Superbid Pay do usuário somente estarão disponíveis quando os mesmos forem compensados, conforme prazos estabelecidos pelas instituições financeiras. Retirada de Recursos</p>

          <p><b>2.20.</b> O usuário poderá, a qualquer momento, retirar todo ou parte do saldo disponível e livre para movimentação em sua Conta Digital Superbid Pay. Para tanto, deverá clicar no botão “Retirar dinheiro” e digitar o valor que pretende retirar. O valor retirado somente pode ser transferido para uma conta bancária em instituição financeira de titularidade do próprio usuário.</p>

          <p><b>2.21.</b> Os saldos mantidos na Conta Digital Superbid Pay poderão ser bloqueados, total ou parcialmente, a critério do Superbid Exchange e da Superbid Pay, nas seguintes situações: (i) por riscos de ações judiciais, reclamações pendentes de compradores ou agentes de venda ou por débitos em aberto de qualquer natureza contra o Superbid Exchange ou a Superbid Pay; (ii) em caso de suspeita de fraude ou qualquer ato contrário às disposições destes Termos e Condições; (iii) questões relacionadas à idoneidade do usuário ou suas informações cadastrais, ou (iv) por ordem judicial ou administrativa.</p>

          <p><b>2.22.</b> Pode ser cobrada tarifa pela retirada de recursos da Conta Digital Superbid Pay, conforme Tabela de Tarifas publicada no portal https://superbidpay.com.br/ajuda. Este valor é descontado do valor da transferência.</p>

          <p><b>2.23.</b> O Superbid Exchange poderá, em conjunto com a Superbid Pay, a seu exclusivo critério e a qualquer momento, acrescentar novos serviços e funcionalidades de utilização da Conta Digital Superbid Pay.</p>

          <p><b>3. MODALIDADES DE VENDA</b></p>

          <p><b>3.1.</b> Leilões Públicos</p>

          <p><b>3.1.1.</b>Evento público em que os bens são vendidos a quem ofertar o maior valor</p>
          (lance). Os leilões públicos são realizados por Leiloeiros Oficiais, Leiloeiros Judiciais, Leiloeiros Rurais e Pregoeiros Administrativos legalmente habilitados para o exercício das funções, em data, horário e local previamente determinados e divulgados em jornais de grande circulação e no Superbid Exchange. Todas as regras, prazos e condições de pagamento constarão das Condições de Venda e Pagamento do evento.

          <p><b>3.2.</b> Leilões Privados</p>

          <p><b>3.2.1.</b> O leilão privado é um evento restrito a convidados e é acessado através de URL própria. Todas as regras, prazos e condições de pagamento constarão das Condições de Venda e Pagamento do evento.</p>

          <p><b>3.3.</b> Venda Direta com Confirmação de Compra – Compre JÁ</p>

          <p><b>3.3.1.</b> Modalidade de venda em que o interessado em adquirir o bem ofertado pelo valor indicado no Superbid Exchange deve confirmar a compra clicando no botão “Compre Já” e realizar o pagamento do bem conforme Condições de Venda e Pagamento do evento.</p>

          <p><b>3.4.</b> Venda Direta com Recebimento de Propostas</p>

          <p><b>3.4.1.</b>Modalidade de venda em que o interessado em adquirir o bem deverá encaminhar proposta para aquisição do bem ofertado. A proposta encaminhada será analisada pelo vendedor. Todas as regras, prazos e condições de pagamento constarão das Condições de Venda e Pagamento do evento.</p>

          <p><b>3.5.</b> Venda Direta Híbrida</p>

          <p><b>3.5.1.</b>Modalidade de venda em que o interessado em adquirir o bem ofertado poderá confirmar a compra pelo valor indicado no Superbid Exchange, clicando no botão “Compre Já”, ou poderá encaminhar proposta para aquisição do bem, a qual será analisada pelo vendedor. Caso tenha clicado no botão “Compre Já” ou receba a confirmação de aceitação de sua proposta, o usuário comprador deverá realizar o pagamento do bem conforme Condições de Venda e Pagamento do evento.</p>

          <p><b>4. PROCEDIMENTO PARA VENDA DE BENS NO SUPERBID EXCHANGE</b></p>

          <p><b>4.1.</b> Para vender bens no Superbid Exchange, os agentes de venda devem preencher os seguintes requisitos:</p>
          ● ter CNPJ ou CPF ativo e sem restrições ou ser Leiloeiro Oficial matriculado em Junta Comercial;
          ● ter situação regular junto à Receita Federal, Junta Comercial, Órgãos de Proteção ao Crédito, Cartórios de Protesto, Banco Central, Poder Judiciário, dentre outros;
          ● não ter status no Cadastro Nacional de Empresas Inidôneas e Suspensas do Portal da Transparência;
          ● não ter capital social inferior a R$ 1.000,00 (mil reais), no caso de Pessoa Jurídica.

          <p><b>4.2.</b> Os agentes de venda devem assinar contrato para utilização dos serviços do Superbid Exchange e abertura da Conta Digital Superbid Pay para recebimento dos valores das vendas realizadas no Superbid Exchange ou das comissões, no caso de agentes de venda.</p>

          <p><b>4.3.</b> Os agentes de venda devem cadastrar no Superbid Exchange os eventos que pretendem realizar, conforme as modalidades disponíveis no Superbid Exchange e os bens que serão vendidos, os quais deverão ser vinculados aos respectivos proprietários.</p>

          <p><b>4.4.</b> Deve ser igualmente cadastrada no Superbid Exchange pelos agentes de venda a forma de pagamento dos bens, sempre através da Superbid Pay, e as Condições de Venda e Pagamento do evento.</p>

          <p><b>4.5.</b> Os agentes de venda serão responsáveis pelo atendimento de 2º nível aos usuários compradores, ou seja, esclarecimento de dúvidas sobre os eventos, bens, pré e pós-venda, conforme exemplificado abaixo:</p>

          <p>1. informações técnicas sobre os bens quanto ao funcionamento, dados adicionais, fotos, etc;</p>

          <p>2. orientações adicionais quanto às regras e procedimentos de visitação dos bens;</p>

          <p>3. gestão dos eventos restritos ou privados para oferta de lances/propostas;</p>

          <p>4. gestão e análise das solicitações de cancelamento de lance/proposta. O ofertante do lance/proposta deverá receber a formalização quanto ao cancelamento ou não do lance/proposta. Quando houver decisão de cancelamento do lance, o Superbid Exchange deverá receber do agente de venda a formalização em tempo hábil para execução do cancelamento no Superbid Exchange, no caso de evento em andamento. O agente de venda é responsável pela comunicação e formalização do cancelamento do lance/proposta aos demais participantes do evento, se houver. Quando constatados lances/propostas em duplicidade ou lance/proposta ofertado erroneamente por manuseio do incremento, a solicitação será aprovada imediatamente pelo Superbid Exchange;</p>

          <p>5. cobrança e prorrogação de prazos de pagamento;</p>

          <p>6. informações adicionais sobre as regras e procedimentos necessários para retiradas dos bens;</p>

          <p>7. atualizações, atrasos ou quaisquer outros assuntos referentes à entrega dos bens ou documentação;</p>

          <p>8. reclamações acerca de divergências e/ou irregularidades referentes aos bens.</p>

          <p><b>4.6.</b> Os bens vendidos deverão ser entregues ao usuário comprador nas condições ofertadas, no prazo previsto nas Condições de Venda e Pagamento do evento.</p>

          <p><b>4.7.</b> É de responsabilidade dos agentes de venda diligenciar junto aos vendedores proprietários dos bens a resolução de eventuais problemas apresentados pelos compradores, em especial com relação à entrega dos bens adquiridos e respectiva documentação, se o caso, dentro do prazo constante das Condições de Venda e Pagamento e nas mesmas condições em que foram ofertados.</p>

          <p><b>5. PROCEDIMENTO PARA COMPRA DE BENS NO SUPERBID EXCHANGE</b></p>

          <p><b>5.1.</b> O usuário cadastrado deverá escolher os eventos de seu interesse e solicitar autorização para participar. Para habilitação eletrônica é cobrada tarifa de habilitação, conforme previsto nas Condições de Venda e Pagamento do evento. Alternativamente, o usuário poderá enviar por correio cópia autenticada dos seguintes documentos:</p>
          ● Pessoa Física: documento de identidade com foto e CPF;
          ● Pessoa Jurídica: Contrato Social ou Estatuto Social com Ata de Eleição da Diretoria e cópia do documento de identidade e CPF dos representantes legais.

          <p><b>5.2.</b> Se houver evento presencial o usuário poderá se habilitar pessoalmente no ato do evento portando os documentos acima mencionados, conforme o caso.</p>

          <p><b>5.3.</b> O usuário deverá ler e aceitar as Condições de Venda e Pagamento do evento selecionado. Uma cópia das Condições de Venda e Pagamento aceitas será encaminhada ao e-mail cadastrado pelo usuário.</p>

          <p><b>5.4.</b> Os lances/confirmações de compra/propostas poderão ser realizados através do Superbid Exchange e/ou presencialmente (na data do encerramento do evento, se o caso) simultaneamente e em tempo real.</p>

          <p><b>5.5.</b> Os lances/confirmações de compra/propostas realizados são VINCULANTES, IRREVOGÁVEIS e IRRETRATÁVEIS. O usuário é responsável por todos os lances/confirmações de compra/propostas registrados em seu nome, pelo que não podem ser anulados e/ou cancelados em nenhuma hipótese.</p>

          <p><b>5.6.</b> O usuário poderá ofertar mais de um lance/proposta para um mesmo bem, prevalecendo sempre o maior lance/proposta ofertados.</p>

          <p><b>5.7.</b> Nos leilões o usuário poderá programar lances automáticos, de forma que, se outro usuário superar seu lance, o sistema automaticamente gerará um novo lance para aquele usuário, acrescido de um incremento fixo e pré-determinado, até um limite máximo definido pelo usuário, com o objetivo de que o mesmo tenha certeza de que até o valor estipulado o seu lance será o vencedor. Os lances automáticos ficarão registrados no Superbid Exchange com a data em que forem programados.</p>

          <p><b>5.8.</b> Os lotes terão horário previsto de fechamento (relógio disponível na seção &quot;tela de lance&quot; do Superbid Exchange), sendo certo que, caso algum lance seja recebido nos 3 últimos minutos do fechamento, o cronômetro retroagirá novamente a 3 minutos do encerramento do lote e assim sucessivamente a cada lance efetuado nos últimos 3 minutos, para que todos os usuários interessados tenham a oportunidade de efetuar novos lances.</p>

          <p><b>5.9.</b> Durante os leilões, profissionais do Superbid Exchange poderão auxiliar os usuários no que se fizer necessário, nos eventos físicos ou através dos Canais de Atendimento. Comissões e Encargos Relacionados aos Eventos</p>

          <p><b>5.10.</b> O usuário comprador aceita e se obriga a pagar a comissão devida ao Leiloeiro Oficial (5% do valor do bem) e os encargos de administração, bem como as comissões devidas aos agentes de venda e ao Superbid Exchange, se o caso, conforme previsto nas Condições de Venda e Pagamento de cada evento.</p>

          <p><b>5.11.</b> Todas as regras, prazos e condições de pagamento constarão das Condições de Venda e Pagamento do evento. Pagamentos e Inadimplência</p>

          <p><b>5.12.</b> Uma vez encerrado o evento, o usuário comprador deverá realizar o pagamento dos valores devidos em razão dos bens adquiridos, conforme previsto nas Condições de Venda e Pagamento de cada evento.</p>

          <p><b>5.13.</b> O usuário poderá utilizar os recursos mantidos na sua Conta Digital Superbid Pay para pagar, total ou parcialmente, os valores por ele devidos em decorrência dos bens adquiridos nos eventos realizados no Superbid Exchange ou aportar recursos na Conta Digital Superbid Pay por meio de Boleto Bancário e Cartão de Crédito. O aporte pode estar sujeito à cobrança de tarifas conforme Tabela de Tarifas publicada no portal https://superbidpay.com.br/ajuda. Este valor será adicionado ao valor do pagamento devido.</p>

          <p><b>5.14.</b> Em nenhuma hipótese as tarifas indicadas no item anterior serão estornadas ou reembolsadas pela Superbid Pay aos usuários, mesmo nos casos de não utilização do recurso aportado em razão de cancelamento de compras realizadas no Superbid Exchange.</p>

          <p><b>5.15.</b> Ao aceitar os presentes Termos e Condições, o usuário desde já outorga, de forma irrevogável e irretratável, os mais amplos poderes para a Superbid Pay proceder com o bloqueio e a transferência de recursos existentes na sua Conta Digital Superbid Pay para pagamento dos valores devidos aos agentes de venda e ao Superbid Exchange, de acordo com as regras previstas nas Condições de Venda e Pagamento de cada evento.</p>

          <p><b>5.16.</b> Caso o usuário comprador não efetue o pagamento dos valores devidos, a compra será cancelada, ficando o usuário sujeito às penalidades previstas nas Condições de Venda e Pagamento do evento. Nesta hipótese o usuário poderá ter seus dados cadastrais incluídos nos órgãos de proteção ao crédito.</p>

          <p><b>5.17.</b> O usuário inadimplente não será admitido a participar de qualquer outro evento divulgado no Superbid Exchange, pelo que seu cadastro ficará bloqueado e poderá ser suspenso. Caso sejam identificados outros cadastros vinculados a este cadastro bloqueado, os mesmos serão igualmente bloqueados.</p>

          <p><b>5.18.</b> O usuário desde já autoriza o Superbid Exchange e a Superbid Pay a reter e/ou debitar do saldo de sua Conta Digital Superbid Pay os valores relativos às penalidades devidas em razão da inadimplência do usuário, tudo conforme Condições de Venda e Pagamento do evento, bem como repassá-los aos efetivos beneficiários. A dedução e retenção dos valores devidos será feita quando da ocorrência de qualquer transação que exija o pagamento por parte do usuário.</p>

          <p><b>5.19.</b> Ainda que o usuário seja bloqueado para a participação em eventos divulgados no Superbid Exchange, o usuário poderá continuar a acessar livremente a sua Conta Digital Superbid Pay para a retirada de saldo residual eventualmente disponível. Retirada dos Bens</p>

          <p><b>5.20.</b> O usuário comprador deverá retirar os bens adquiridos no local e prazo constantes das Condições de Venda e Pagamento do evento, mediante a apresentação da Nota de Venda/Nota de Arrematação.</p>

          <p><b>5.21.</b> No ato da retirada, o usuário comprador ou seu procurador ou a transportadora responsável pela retirada deverá conferir o conteúdo e as condições dos bens adquiridos, sendo constatada qualquer divergência e/ou irregularidade, o fato deverá ser imediatamente informado, por escrito, ao agente de venda e ao SUPERBID EXCHANGE, devendo o comprador suspender a retirada até que estejam solucionadas as dúvidas existentes.</p>

          <p><b>6. SEGURANÇA DO SUPERBID EXCHANGE</b></p>

          <p><b>6.1.</b> O SUPERBID EXCHANGE NÃO SOLICITA OU EXIGE QUE SEUS USUÁRIOS DIVULGUEM SUAS SENHAS, DADOS DE CARTÃO DE CRÉDITO OU OUTROS DADOS BANCÁRIOS POR E-MAIL, TELEFONE OU QUALQUER OUTRO CANAL DE ATENDIMENTO PERSONALIZADO. Portanto, se o usuário receber qualquer comunicação com esse tipo de abordagem e conteúdo, não deve responder, mas sim desconsiderá-la e, se possível, encaminhar o seu relato para os Canais de Atendimento do Superbid Exchange.</p>

          <p><b>6.2.</b> O usuário é o único e exclusivo responsável por manter em segurança e sigilo seu Nome de Usuário e senha, evitando assim o uso não autorizado de seu cadastro. O usuário deve avisar imediatamente o Superbid Exchange, por meio dos Canais de Atendimento, em caso de suspeita de acesso indevido, ou atividade que possa dar ensejo a tal, para que o Superbid Exchange possa tomar as devidas providências.</p>

          <p><b>6.3.</b> É vedado ao usuário fazer dois logins simultâneos em computadores ou navegadores diferentes. A identificação de logins concorrentes de um mesmo usuário pela plataforma gera o bloqueio do referido login por questões de segurança, em razão de suspeita de fraude ou tentativa de invasão.</p>

          <p><b>6.4.</b> As senhas, Nomes de Usuário e todos os dados fornecidos pelos usuários ao Superbid Exchange são transmitidos criptografados (Certificado de segurança SSL – Secure Socket Layer).</p>

          <p><b>6.5.</b> O usuário declara estar ciente da Política de Segurança do Superbid Exchange disponível em: https://www.superbid.net/politica-de-seguranca/.</p>

          <p><b>7. PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS</b></p>

          <p><b>7.1.</b> O Superbid Exchange está comprometido em garantir a privacidade e a proteção dos dados pessoais fornecidos por seus usuários, nos termos do que estabelece a Lei Geral de Proteção de Dados Pessoais nº 13.709/2018 (“LGPD”).</p>

          <p><b>7.2.</b> Diante disso, o usuário deve estar ciente de que ao aceitar estes Termos e Condições seus dados pessoais serão tratados conforme previsto no Aviso de Privacidade disponível em: https://www.superbid.net/aviso-de-privacidade/.</p>

          <p><b>8. RESPONSABILIDADES DO USUÁRIO</b></p>

          <p><b>8.1.</b> Para o devido acesso e uso do Superbid Exchange e da Conta Digital Superbid Pay, o usuário reconhece e aceita que deverá:</p>

          <p>1. arcar com eventuais custos adicionais de conexão e acesso à internet para contratação de serviços disponibilizados no Superbid Exchange e realização de transações de pagamento;</p>

          <p>2. fornecer somente informações cadastrais e/ou declarações verdadeiras, exatas, atuais e completas;</p>

          <p>3. atualizar imediatamente tais informações cadastrais e/ou declarações sempre que houver quaisquer alterações nestas, e</p>

          <p>4. respeitar os direitos de propriedade intelectual do Superbid Exchange e da Superbid Pay.</p>

          <p><b>8.2.</b> Além disso, o usuário reconhece ser vedado:</p>

          <p>1. divulgar, ceder ou emprestar seu Nome de Usuário ou senha para quaisquer terceiros;</p>

          <p>2. violar qualquer lei e/ou regulamento municipal, estadual ou nacional em vigor;</p>

          <p>3. utilizar o Superbid Exchange em atividades não permitidas pela legislação ou que resultem em benefícios diretos ou indiretos para agentes criminosos e/ou quaisquer práticas ilícitas;</p>

          <p>4. realizar engenharia reversa do Superbid Exchange;</p>

          <p>5. assumir a personalidade ou identidade de outra pessoa, física ou jurídica;</p>

          <p>6. usar o Superbid Exchange para carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que viole quaisquer direitos de terceiro;</p>

          <p>7. usar o Superbid Exchange para carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer tipo de anúncio, propaganda ou material promocional não solicitado ou não autorizado pelo Superbid Exchange, tais como mensagens não solicitadas ou mensagens enviadas em massa;</p>

          <p>8. carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível qualquer conteúdo que contenha vírus ou qualquer outro código, arquivo ou programa de computador com o propósito de interromper, destruir ou limitar a funcionalidade de qualquer software, hardware ou equipamento;</p>

          <p>9. ameaçar, coagir ou de qualquer forma constranger os demais usuários ou vendedores;</p>

          <p>10. violar direitos de sigilo e privacidade de terceiros, e</p>

          <p>11. praticar quaisquer atos que direta ou indiretamente, no todo ou em parte, possam causar prejuízo ao Superbid Exchange, à Superbid Pay, aos compradores, aos agentes de venda, a outros usuários ou a qualquer terceiro.</p>

          <p><b>9. PROPRIEDADE INTELECTUAL</b></p>

          <p><b>9.1.</b> O Superbid Exchange, bem como seus textos, gráficos, imagens, fotos, ilustrações, marcas comerciais, nomes comerciais, marcas de serviço, logotipos, informações, código-fonte, layouts, nomes de domínio, software, know-how, e outros materiais são todos protegidos por direitos de propriedade intelectual</p>
          (“direitos de propriedade intelectual”).

          <p><b>9.2.</b> Todos os direitos de propriedade intelectual presentes no Superbid Exchange são de propriedade do Superbid Exchange. A exploração comercial, transmissão, divulgação, modificação, reprodução, cópia ou quaisquer outras formas de utilização comercial ou obtenção de vantagens econômicas de tais direitos pelo usuário são rigorosamente proibidas.</p>

          <p><b>9.3.</b> O uso não autorizado de qualquer marca, logotipo e/ou design do Superbid Exchange é vedado e sujeitará o usuário às penalidades previstas em lei, especialmente na Lei 9.279/1996.</p>

          <p><b>10. VALIDADE; ALTERAÇÃO DOS TERMOS E CONDIÇÕES; TÉRMINO</b></p>

          <p><b>10.1.</b> Estes Termos e Condições terão início na data de cadastro do usuário no Superbid Exchange e serão válidos por prazo indeterminado, obrigando as partes, seus herdeiros e sucessores.</p>

          <p><b>10.2.</b> O Superbid Exchange poderá, a qualquer momento, alterar o conteúdo destes Termos e Condições, mediante envio ao usuário de comunicação com, no mínimo, 10 dias de antecedência.</p>

          <p><b>10.3.</b> Caso o usuário não concorde com as alterações, poderá solicitar imediatamente o cancelamento do seu acesso ao Superbid Exchange e a rescisão destes Termos e Condições, sem a incidências de quaisquer penalidades.</p>

          <p><b>10.4.</b> O não cancelamento ou o uso do Superbid Exchange após comunicação da alteração será entendido como aceite do usuário ao novo conteúdo dos Termos e Condições.</p>

          <p><b>10.5.</b> O Superbid Exchange poderá rescindir estes Termos e Condições e interromper o fornecimento dos serviços e/ou funcionalidades do Superbid Exchange, bem como da Conta Digital Superbid Pay, sem aplicação de qualquer penalidade, nos seguintes casos:</p>

          <p>1. uso indevido de funcionalidades do Superbid Exchange ou da Conta Digital Superbid Pay por parte do usuário, em desconformidade com a regulamentação aplicável ou em qualquer tipo de operação ilegal;</p>

          <p>2. violação de quaisquer disposições destes Termos e Condições e demais termos/políticas do Superbid Exchange, em especial da cláusula 8.2 destes Termos e Condições;</p>

          <p>3. falecimento do usuário, quando pessoa física;</p>

          <p>4. decretação de falência, recuperação judicial, extrajudicial ou insolvência civil do usuário, quando pessoa jurídica;</p>

          <p>5. mediante aviso prévio ao usuário, com antecedência de pelo menos 10 (dez) dias, e</p>

          <p>6. nos casos previstos na Cláusula 1.11 destes Termos e Condições.</p>

          <p><b>10.6.</b> O usuário poderá solicitar o cancelamento do seu acesso ao Superbid Exchange a qualquer momento, sendo desnecessária a apresentação de justificativa. O cancelamento do acesso ao Superbid Exchange implicará também no cancelamento automático da Conta Digital Superbid Pay.</p>

          <p><b>10.7.</b> Na hipótese de cancelamento da Conta Digital Superbid Pay, por qualquer motivo, o usuário deverá promover a transferência de eventual saldo para a conta bancária de titularidade do usuário em instituição financeira cadastrada no Superbid Exchange ou de outra forma informada por meio dos Canais de Atendimento.</p>

          <p><b>11. LIMITE DE RESPONSABILIDADE</b></p>

          <p><b>11.1.</b> O Superbid Exchange e a Superbid Pay viabilizam a intermediação e a aproximação de agentes de venda e compradores e, portanto, não se responsabilizam por prejuízos ou danos advindos das transações efetuadas entre os agentes de venda e os compradores, limitando a sua atuação à prestação dos serviços pelos quais expressamente se obrigam. Considerando que o Superbid Exchange não figura como parte nas transações de venda e compra de bens realizadas entre os usuários do Superbid Exchange, a responsabilidade por todas as obrigações delas decorrentes, sejam fiscais, trabalhistas, consumeristas ou de qualquer outra natureza, será exclusivamente do respectivo usuário.</p>

          <p><b>11.2.</b> O Superbid Exchange e a Superbid Pay não fazem quaisquer declarações, nem asseguram, endossam, garantem ou assumem a responsabilidade pela qualidade satisfatória, comercialidade e adequação do Superbid Exchange, nem por quaisquer bens divulgados ou oferecidos por agentes de venda no, ou por meio dos serviços do Superbid Exchange.</p>

          <p><b>11.3.</b> O agente de venda é responsável pela origem, existência, qualidade, segurança, quantidade, legitimidade, autenticidade, por vícios ou defeitos dos bens ofertados, por todo conteúdo dos anúncios que veicular no Superbid Exchange, incluindo a descrição dos bens, débitos, Condições de Venda e Pagamento do Evento, bem como pela entrega dos bens nas condições ofertadas.</p>

          <p><b>11.4.</b> O usuário está ciente e de acordo que o Superbid Exchange não se responsabiliza pelos bens vendidos, pelo conteúdo dos anúncios veiculados no Superbid Exchange, pela transação de venda e compra, bem como pela entrega dos bens aos compradores.</p>

          <p><b>11.5.</b> O usuário reconhece que todo sistema que depende da rede mundial de computadores está sujeito a falhas e indisponibilidade. Assim, não haverá responsabilidade do Superbid Exchange ou da Superbid Pay em caso de indisponibilidade do Superbid Exchange ou da Conta Digital Superbid Pay, sendo que nessas hipóteses o usuário poderá entrar em contato com o Superbid Exchange através dos Canais de Atendimento.</p>

          <p><b>12. INDENIZAÇÃO</b></p>

          <p><b>12.1.</b> O usuário concorda em isentar, indenizar, defender e manter o Superbid Exchange e a Superbid Pay e seus respectivos diretores, agentes, sócios e funcionários indenes de qualquer prejuízo, responsabilidade, ação judicial ou demanda, incluindo honorários advocatícios, decorrentes do uso indevido do Superbid Exchange ou da Conta Digital Superbid Pay ou de violação destes Termos e Condições.</p>

          <p><b>12.2.</b> O usuário agente de venda concorda em isentar, indenizar, defender e manter o Superbid Exchange e a Superbid Pay e seus respectivos diretores, agentes, sócios e funcionários indenes de qualquer prejuízo, responsabilidade, ação judicial ou demanda, incluindo honorários advocatícios, decorrentes da venda dos bens no Superbid Exchange, em especial no tocante a reinvindicações com relação à origem, existência, qualidade, segurança, quantidade, legitimidade, autenticidade, vícios ou defeitos dos bens ofertados e conteúdo dos anúncios veiculados no Superbid Exchange.</p>

          <p><b>13. COMUNICAÇÕES</b></p>

          <p><b>13.1.</b> O usuário autoriza o Superbid Exchange a contatá-lo por qualquer meio</p>
          (telefone e e-mail) para enviar comunicações de seu interesse a respeito do Superbid Exchange e da Conta Digital Superbid Pay, especialmente em caso de prevenção a fraudes.

          <p><b>13.2.</b> O usuário reconhece, desde já, que todo acesso ao Superbid Exchange ocorrerá apenas mediante autenticação própria, nos termos da Cláusula 1 acima, de forma que ficam inteiramente validadas e com força probatória perante qualquer juízo ou tribunal todas as comunicações enviadas e/ou disponibilizadas pelo Superbid Exchange, as quais serão consideradas válidas e passarão a integrar este documento para todos os fins e efeitos de direito.</p>

          <p><b>14. MANUTENÇÃO E INTERRUPÇÃO DE FUNCIONALIDADES</b></p>

          <p><b>14.1.</b> O Superbid Exchange se reserva o direito de promover, a qualquer momento, manutenções, modificações ou atualizações em seu sistema, de modo que os serviços e/ou funcionalidades do Superbid Exchange, incluindo da Conta Digital Superbid Pay poderão ficar temporariamente indisponíveis.</p>

          <p><b>14.2.</b> Além disso, o Superbid Exchange poderá interromper ou alterar o fornecimento de determinadas funcionalidades e/ou serviços do Superbid Exchange e da Conta Digital Superbid Pay, mediante o envio de notificação ao usuário sobre tais alterações com antecedência mínima de 10 dias.</p>

          <p><b>15. DISPOSIÇÕES GERAIS</b></p>

          <p><b>15.1.</b> Estes Termos e Condições estão integralmente disponíveis para consulta no Superbid Exchange e poderão ser acessados pelo usuário a qualquer momento.</p>

          <p><b>15.2.</b> Fica eleito pelo Superbid Exchange, pela Superbid Pay e pelo usuário o Foro Central da Comarca da Capital do Estado de São Paulo para dirimir quaisquer questões relativas aos presentes Termos e Condições.</p>

          <center>** ** ** **</center>

        </div>
      )}
    </Fragment>
  )
}

export default TermsOfUse;
