import React, { useRef, useCallback, useState, useEffect, forwardRef, useImperativeHandle } from "react"
import { Component, Container, Error, Wrapper } from "./styles";

const TwoLabelsInput = (
  {
    namePrimary,
    nameSecondary,
    placeholderPrimary,
    placeholderSecondary,
    onChangePrimary,
    onChangeSecondary,
    valuePrimary,
    valueSecondary,
    error,
    mt,
    mb,
    ml,
    mr,
    m,
    width,
  }, ref) => {
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [hasError, setHasError] = useState(!!error);

  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!input1Ref.current.value || !!input2Ref.current.value)
  }, [input1Ref]);

  const handleClearError = useCallback(() => {
    setHasError(false)
  }, []);

  useImperativeHandle(ref, () => ({
    focusPrimary: input1Ref.current.focus(),
    focusSecondary: input2Ref.current.focus(),
  }), []);

  useEffect(() => {
    if (error && !isFocused && !isFilled) {
      setHasError(true);
    }

    if (isFocused) {
      handleClearError();
    }
  }, [isFocused, error]);

  useEffect(() => {
    setIsFilled(!!valuePrimary || !!valueSecondary)
  }, [valuePrimary, valueSecondary]);

  return (
    <Wrapper
      mt={mt}
      mb={mb}
      mr={mr}
      ml={ml}
      m={m}
      width={width}
    >
      <Container
        isFocused={isFocused || isFilled}
        hasError={hasError}
      >
        <Component
          isFocused={isFocused || isFilled}
          hasError={hasError}
        >
          <input
            ref={input1Ref}
            name={namePrimary}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onChange={onChangePrimary}
            value={valuePrimary}
            required
          />
          <span>{placeholderPrimary}</span>
        </Component>

        <Component
          isFocused={isFocused || isFilled}
          hasError={hasError}
        >
          <input
            ref={input2Ref}
            name={nameSecondary}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onChange={onChangeSecondary}
            value={valueSecondary}
            required
          />
          <span>{placeholderSecondary}</span>
        </Component>
      </Container>
      {hasError && <Error>{error}</Error>}
    </Wrapper>
  )
}

export default forwardRef(TwoLabelsInput)