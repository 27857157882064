import React from 'react';
import Flex from '../elements/Flex';
import Typography from '../elements/Typography';

const PageTitle = ({ title, description }) => {
  return (
    <Flex
      flexDirection="column"
      width="100%"
    >
      <Typography
        variant="title"
        fontSize="header.h2"
        lineHeight="header.h2"
        fontWeight="bold"
        color="text.medium"
        textAlign="left"
      >
        {title}
      </Typography>
      <Typography
        fontSize="regular.xs"
        lineHeight="regular.xs"
        color="text.medium"
        fontWeight="regular"
        textAlign="left"
        mt={0}
      >
        {description}
      </Typography>
    </Flex>
  )
}

export default PageTitle;