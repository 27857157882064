import React, { Fragment } from 'react';
import BlueCheckIcon from '../../../svg/ic_blue_check_circle.svg';

const OuvidoriaStepThree = ({ currentStep, protocol }) => {
  return (
    <main>
      {currentStep === 3 && (
        <Fragment>
          <div className="support-message__sent">
            <BlueCheckIcon />
            <div className="support-message__sent__text">
              Mensagem enviada com sucesso!
            </div>
            <div className="support-message__sent__description">
              A sua questão foi registrada com sucesso e a nossa equipe entrará em contato em
              até 5 dias úteis para ajudá-lo neste assunto.
            </div>
          </div>
          <div className="support-phone__card support-phone__card--with-shadow">
            <main>
              <div className="support-phone__description">
                Número de protocolo da ouvidoria:
              </div>
              <div className="support-phone__protocol-number">
                {protocol}
              </div>
            </main>
          </div>
        </Fragment>
      )}
    </main>
  )
}

export default OuvidoriaStepThree;
