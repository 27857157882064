import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment-timezone';
import Dropdown from '../../components/elements/Dropdown';
import DatePickerModal from '../../components/elements/DatePickerModal';
import OperationList from '../../components/elements/OperationList';
import { PaymentActions } from '../../app/store/actions';
import { PaymentSelectors } from '../../app/store/reducers';
import { PaymentOrderStatusArray } from '../../data/enumerators/paymentOrderStatus';
import FilterIcon from '../../svg/ic_filter.svg';
import MobileFiltersHistory from '../../components/mobile/MobileFiltersHistory';
import CashOperationButton from '../../components/elements/CashOperationButton';

class PanelHistoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      periodFilter: null,
      dateStart: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      dateEnd: moment().format('YYYY-MM-DD'),
      statusFilter: 0,
      datePickerIsOpen: false,
      periodOptions: [
        { id: 0, description: 'Definir data', order: 6, days: 0 },
        { id: 1, description: 'Últimos 7 dias', order: 1, days: 7 },
        { id: 2, description: 'Últimos 15 dias', order: 2, days: 15 },
        { id: 3, description: 'Últimos 30 dias', order: 3, days: 30 },
        { id: 4, description: 'Últimos 60 dias', order: 4, days: 60 },
        { id: 5, description: 'Últimos 90 dias', order: 5, days: 90 },
      ],
      orderSearch: [
        { id: 1, description: 'Mais recente', order: 1, testee: 5 },
        { id: 0, description: 'Mais antigo', order: 2, testee: 6 },
      ],
      orderHistory: true,
      start: '',
      end: '',
      mobileFiltersIsOpen: false
    };
  }

  async componentDidMount() {
    const { dateStart, statusFilter, orderHistory } = this.state;
    this.props.paymentsList(dateStart, null, statusFilter, orderHistory);
  }

  onChangeOrder = ({ value }) => {
    const { dateStart, dateEnd, statusFilter } = this.state;
    this.setState({ orderHistory: value });
    this.props.paymentsList(dateStart, dateEnd, statusFilter, value);
  }

  handleChangePeriod = ({ value }) => {
    const { statusFilter, orderHistory, periodOptions } = this.state;
    this.setState({ start: '', end: '' });

    if (value === 0) {
      this.openDatePicker();
    } else {
      const selected = periodOptions.find(o => o.id === value);
      this.setState({
        dateStart: moment().subtract(selected.days, 'd').format('YYYY-MM-DD'),
        dateEnd: null,
      });

      this.props.paymentsList(moment().subtract(selected.days, 'd').format('YYYY-MM-DD'),
        null,
        statusFilter, orderHistory);
    }

  }

  handleChangeStatus = ({ value }) => {
    const { dateStart, dateEnd, orderHistory } = this.state;
    this.setState({ statusFilter: value });
    this.props.paymentsList(dateStart, dateEnd, value, orderHistory);
  }

  openDatePicker = () => {
    this.setState({ datePickerIsOpen: true });
  }

  closeDatePicker = () => {
    this.setState({ datePickerIsOpen: false });
  }

  selectDate = (selectedDays) => {
    const { statusFilter, orderHistory } = this.state;

    selectedDays = selectedDays[1];

    const dateStart = moment(selectedDays.from).format('YYYY-MM-DD');
    const dateEnd = moment(selectedDays.to).format('YYYY-MM-DD');

    this.setState({
      start: moment(selectedDays.from).format('DD/MM/YY'),
      end: moment(selectedDays.to).format('DD/MM/YY')
    });

    this.setState({
      dateStart,
      dateEnd
    });

    this.closeDatePicker();
    this.props.paymentsList(dateStart, dateEnd, statusFilter, orderHistory);
  }

  formatDateString = (start, end) => {
    if (moment(start).isSame(moment(end), 'day')) {
      return `Filtrando dia ${start}`;
    }
    return `Filtrando de ${start} até ${end}`;
  }

  openMobileFilters = () => {
    window.scrollTo(0, 0);
    this.setState({ mobileFiltersIsOpen: true });
  }

  closeMobileFilters = () => {
    this.setState({ mobileFiltersIsOpen: false });
  }

  onAdvancedSearch = (searchParams) => {
    const { dateStart, dateEnd, orderHistory } = this.state;
    this.setState({ statusFilter: searchParams.statusFilter });
    this.props.paymentsList(dateStart, dateEnd, searchParams.statusFilter, orderHistory);

    this.closeMobileFilters();
  }

  render() {
    const { periodOptions, statusFilter, orderSearch, periodFilter,
      datePickerIsOpen, start, end, mobileFiltersIsOpen } = this.state;
    const { payment } = this.props;

    return (
      <React.Fragment>
        <CashOperationButton />
        <DatePickerModal
          isOpen={datePickerIsOpen}
          onClose={this.closeDatePicker}
          onSelectDate={this.selectDate}
        />
        <div className="panel__history">
          {mobileFiltersIsOpen && (
            <MobileFiltersHistory
              statusFilter={statusFilter}
              closeFilter={this.closeMobileFilters}
              onFilterCallback={this.onAdvancedSearch}
            />
          )}
          <div className="panel__history__container">
            <div className="row panel__history__title">
              <div className="col-12">
                <span className="panel__title">{I18n.t('panel.historyPage.pageTitle')}</span>
              </div>
            </div>
            <div className="row mobile">
              <div className="col-10 pl-0">
                <Dropdown
                  items={periodOptions.sort((a, b) => a.order - b.order)}
                  itemValue='id'
                  itemLabel='description'
                  isSearchable={false}
                  handleChange={this.handleChangePeriod}
                  value={periodFilter}
                  secondaryColor
                />
                <div className="col-14 pl-0">
                  <Dropdown
                    defaultValue
                    items={orderSearch.sort((a, b) => a.order - b.order)}
                    itemValue='id'
                    itemLabel='description'
                    isSearchable={false}
                    secondaryColor
                    handleChange={this.onChangeOrder}
                    value={statusFilter}
                  />
                </div>
                {start && end && (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-12 d-flex justify-content-end align-items-center">
                        <span className="selected__date">{this.formatDateString(start, end)}</span>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className="panel__history__filterHistory d-flex align-items-end justify-content-end">
                <FilterIcon
                  onClick={this.openMobileFilters}
                  className="filter-icon"
                />
              </div>
            </div>
            <div className="row mobile-hidden">
              <div className="col-lg-4">
                <Dropdown
                  items={periodOptions.sort((a, b) => a.order - b.order)}
                  itemValue='id'
                  itemLabel='description'
                  label={I18n.t('panel.historyPage.filter.periodLabel')}
                  isSearchable={false}
                  handleChange={this.handleChangePeriod}
                  value={periodFilter}
                  secondaryColor
                />
                {start && end && (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-12 d-flex justify-content-end align-items-center">
                        <span className="selected__date">{this.formatDateString(start, end)}</span>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className="col-lg-4">
                <Dropdown
                  defaultValue
                  items={PaymentOrderStatusArray}
                  itemValue='id'
                  itemLabel='description'
                  label={I18n.t('panel.historyPage.filter.statusLabel')}
                  isSearchable={false}
                  handleChange={this.handleChangeStatus}
                  value={statusFilter}
                  secondaryColor
                  noMaxHeight
                />
              </div>
              <div className="col-lg-4">
                <Dropdown
                  defaultValue
                  items={orderSearch.sort((a, b) => a.order - b.order)}
                  itemValue='id'
                  itemLabel='description'
                  label={I18n.t('panel.historyPage.filter.dropdownOrder')}
                  isSearchable={false}
                  mobileHidden
                  secondaryColor
                  handleChange={this.onChangeOrder}
                  value={statusFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="panel__history__list">
          <OperationList operations={payment} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  payment: PaymentSelectors.getPayment(state),
});

const mapDispatchToProps = dispatch => ({
  paymentsList: (dateStart, dateEnd, status, orderHistory) => dispatch(
    PaymentActions.paymentsList(dateStart, dateEnd, status, orderHistory, Number.MAX_SAFE_INTEGER)
  ),

});

export default connect(mapStateToProps, mapDispatchToProps)(PanelHistoryPage);
