import { addLoading, removeLoading } from './loading';
import CashInRequests from '../../server/cashin';

export const ACTION_GENERATE_BOLETO = 'GENERATE_BOLETO';
export const ACTION_GET_DATA_TED = 'CASHIN_TED_FINANCE';

export const setBoletoData = BoletoData => ({
  type: ACTION_GENERATE_BOLETO,
  boleto: BoletoData,
});

export const setCashInDataTed = cashInTedData => ({
  type: ACTION_GET_DATA_TED,
  cashInTed: cashInTedData,
});

export const generateBoleto = (amount) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const balance = await CashInRequests.generateBoleto(amount);
    await dispatch(setBoletoData(balance));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

export const getCashInTed = (tedId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const cashIn = await CashInRequests.getDepositTed(tedId);
    await dispatch(setCashInDataTed(cashIn));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(removeLoading());
  }
};
