import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import CloseIcon from '../../svg/ic_close.svg';
import MobileSingleFitler from './MobileSingleFilter';
import { PaymentOrderStatusArray } from '../../data/enumerators/paymentOrderStatus';

class MobileFiltersHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusFilter: this.props.statusFilter,
    };
  }

  selectStatus = (id) => {
    if (id === this.state.statusFilter) {
      this.setState({ statusFilter: 0 });
    } else {
      this.setState({ statusFilter: id });
    }
  }

  advancedSearch = () => {
    this.props.onFilterCallback(this.state);
  }

  render() {
    const { closeFilter } = this.props;
    const { statusFilter } = this.state;

    return (
      <React.Fragment>
        <div className="mobile-filters__wrapper mobile">
          <div className="row">
            <div className="col-9 d-flex align-items-center justify-content-start">
              <div className="mobile-filters__title">
                {I18n.t('mobile.filter')}
              </div>
            </div>
            <div className="col-3 d-flex align-items-center justify-content-end">
              <div className="mobile-filters__close">
                <CloseIcon onClick={closeFilter} />
              </div>
            </div>
          </div>
          <MobileSingleFitler
            title="status"
            options={PaymentOrderStatusArray}
            selected={statusFilter}
            onSelect={this.selectStatus}
          />
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-end mt-1">
              <button
                className="mobile-filters__action modal__button modal__button--submit modal__form--valid"
                type="button"
                onClick={this.advancedSearch}
              >
                {I18n.t('mobile.filterSearch')}
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default MobileFiltersHistory
