import moment from 'moment';
import { axiosInstance } from '../initialization';
import ServerUtil from '../utils/server';
import AuthService from './auth';

async function request(method, uri, data = {}, headers = {}, extras = {}) {
  if (!headers['Content-Type']) headers['Content-Type'] = 'application/json';
  try {
    const response = await axiosInstance({
      method,
      url: `${ServerUtil.getBaseUrl()}${uri}`,
      data,
      headers,
      ...extras,
    });
    if (response && response.data) {
      if (extras && extras.responseType === 'blob') {
        const startDate = uri.split('=')[1].slice(0, 10);
        const endDate = uri.split('=')[2];
        let nameFile = 'extrato';
        if (startDate) {
          nameFile += '_' + startDate;
        }

        if (endDate) {
          nameFile += '_' + endDate;
        } else {
          nameFile += '_' + moment().format('YYYY-MM-DD');
        }
        nameFile += '.csv';

        let blob = new Blob(["\ufeff", response.data], { type: 'text/csv;charset=utf-8;' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = nameFile;
        link.click();
      }
      if (response.data) return response.data;
      return response.data;
    }
    return response;
  } catch (err) {
    throw err;
  }
}


async function financialRequest(method, uri, data = {}, headers = {}, extras = {}) {
  if (!headers['Content-Type']) headers['Content-Type'] = 'application/json';

  headers['api-access-key'] = '789f0155-07f7-442e-baf5-acdf602b9890'

  try {
    const response = await axiosInstance({
      method,
      url: `https://fin-api.stage.s4pay.com.br${uri}`,
      data,
      headers,
      ...extras,
    });

    return response.data;

  } catch (err) {
    throw err;
  }
}




function publicHeader(header = {}) {
  header['content-type'] = 'application/json';
  return header;
}

async function getRest(uri, header, isFinancial) {
  if (isFinancial) {
    return financialRequest('get', uri, {}, header)
  }

  else {
    return request('get', uri, {}, header);
  }
}

async function postRest(uri, data, header, isFinancial) {
  if (isFinancial) {
    return financialRequest('post', uri, data, publicHeader(header));
  }
  else {
    return request('post', uri, data, publicHeader(header));
  }
}

async function putRest(uri, data, header) {
  return request('put', uri, data, publicHeader(header));
}

async function deleteRest(uri, data, header, isFinancial){
  if(isFinancial){
    return financialRequest('delete', uri,data, publicHeader(header))
  }
  else {
    return request('delete',uri,data,publicHeader(header))
  }
}

function authenticatedHeader(header = {}) {
  const auth = AuthService.get();
  if (auth) header.Authorization = `Bearer ${auth.token}`;
  return header;
}

async function getAuthenticated(uri, header, extras) {
  return request('get', uri, {}, authenticatedHeader(header), extras);
}

async function postAuthenticated(uri, data, header) {
  return request('post', uri, data, authenticatedHeader(header));
}

async function postFileAuthenticated(uri, data, header) {
  return request('post', uri, data, {
    ...authenticatedHeader(header),
    'content-type': 'multipart/form-data;'
  });
}

async function putAuthenticated(uri, data, header) {
  return request('put', uri, data, authenticatedHeader(header));
}

async function deleteAuthenticated(uri, data, header) {
  return request('delete', uri, data, authenticatedHeader(header));
}

export default {
  getRest,
  postRest,
  putRest,
  deleteRest,
  getAuthenticated,
  postAuthenticated,
  putAuthenticated,
  authenticatedHeader,
  deleteAuthenticated,
  postFileAuthenticated,
};
