import React, { Component, Fragment } from 'react';
import ArrowBackIcon from '../../svg/institutional/ic_arrow_back.svg';
import FaqList from '../../components/institutional/faq/FaqList';
import FaqTopics from '../../components/institutional/faq/FaqTopics';
import FaqQuestion from '../../components/institutional/faq/FaqQuestion';
import { FaqData } from '../../data/enumerators/faqTopic';
import FaqTableFee from "../../components/institutional/faq/FaqFee";

class InstitutionalFaq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      questionNumber: null,
      mosaicTopics: [],
      topicQuestions: null,
      selectedQuestion: null,
      isComponent: false,
      selectedTopic: false,
    }
  }

  componentDidMount = () => {
    window.scrollTo({ top: 0 });
    const topicsMosaic = FaqData.map((e) => ({ title: e.title, topic: e.topic }));
    this.setState({ mosaicTopics: topicsMosaic });
  }

  selectTopic = (number) => {
    const cleanedFaq = this.filterQuestions(number);
    this.resetQuestion();
    this.setState({ current: number, topicQuestions: cleanedFaq, selectedTopic: true });
  }

  selectQuestion = (question) => {
    question.isComponent = question.isComponent ? true : false;
    this.setState({ selectedQuestion: question, isComponent: question.isComponent, questionNumber: question.id });
  }

  resetQuestion = () => {
    this.setState({ selectedQuestion: null, isComponent: false, questionNumber: null })
  }

  backToTopics = () => {
    this.setState({ selectedTopic: false });
  }

  filterQuestions = (topic) => (FaqData.find(faq => (faq.topic === topic)).questions);

  render() {
      const { current, mosaicTopics, topicQuestions, selectedQuestion,
      questionNumber, selectedTopic, isComponent } = this.state;
    return (
      <Fragment>
        <div className="faq__desktop mobile-hidden">
          <div className="panel__faq">
            <div className="row m-0">
              <div className="col-12">
                <div className="institutional__faq">
                  <div className="institutional__faq__content">
                    {!selectedQuestion && !isComponent && (
                      <div className="institutional__faq__content__single">
                        <FaqTopics
                          mosaic={mosaicTopics}
                          current={current}
                          selectTopic={this.selectTopic}
                        />
                      </div>
                    )}
                    {selectedTopic && !isComponent && (
                      <div className="institutional__faq__content__single">
                        <FaqList
                          // eslint-disable-next-line max-len
                          topicTitle={(mosaicTopics.length > 0 && current && mosaicTopics[current - 1]).title || "Perguntas frequentes"}
                          topicQuestions={topicQuestions}
                          current={questionNumber}
                          resetQuestion={this.resetQuestion}
                          selectQuestion={this.selectQuestion}
                        />
                      </div>
                    )}
                    {selectedQuestion && !isComponent && (
                      <div className="institutional__faq__content__single">
                        <FaqQuestion selectedQuestion={selectedQuestion} />                       
                      </div>
                    )}
                    {selectedQuestion && isComponent && (
                      <FaqTableFee
                        resetQuestion={this.resetQuestion}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq__mobile mobile">
          <div className="panel__faq">
            <div className="row m-0">
              <div className="col-12">
                {!isComponent && (
                  <div className="institutional__faq">
                    <div className="institutional__faq__content">
                      {!selectedQuestion && !selectedTopic && (
                        <div className="institutional__faq__content__single">
                          <FaqTopics
                            mosaic={mosaicTopics}
                            selectTopic={this.selectTopic}
                          />
                        </div>
                      )}
                      {selectedTopic && !selectedQuestion && (
                        <Fragment>
                          <button
                            type="button"
                            className="faq__back"
                            onClick={this.backToTopics}
                          >
                            <ArrowBackIcon /> Voltar para &quot;Ajuda&quot;
                          </button>
                          <div className="institutional__faq__content__single">
                            <FaqList
                              // eslint-disable-next-line max-len
                              topicTitle={(mosaicTopics.length > 0 && current && mosaicTopics[current - 1]).title || "Perguntas frequentes"}
                              topicQuestions={topicQuestions}
                              resetQuestion={this.resetQuestion}
                              selectQuestion={this.selectQuestion}
                            />
                          </div>
                        </Fragment>
                      )}
                      {selectedQuestion && (
                        <Fragment>
                          <button
                            type="button"
                            className="faq__back"
                            onClick={this.resetQuestion}
                          >
                            <ArrowBackIcon /> Voltar para &quot;{(mosaicTopics[current - 1]).title}&quot;
                          </button>
                          <div className="institutional__faq__content__single">
                            <FaqQuestion selectedQuestion={selectedQuestion} />
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {selectedQuestion && isComponent && (
              <FaqTableFee
                resetQuestion={this.resetQuestion}
              />
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default InstitutionalFaq;
