/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Input from '../../components/elements/Input';
import Button from '../../components/elements/Button';
import routes from '../../data/routes';
import { AccountActions } from '../../app/store/actions';
import { AccountSelectors } from '../../app/store/reducers';
import { formatDate } from '../../app/pipe/dateFormatter';

const PanelDeleteAccounts = ({ deleteTicket, getDeleteAccountTicket, balance, postDeleteAccountTicket }) => {

    const [reason, setReason] = useState(null);
    const [writeReason, setWriteReason] = useState('');
    const [step, setStep] = useState(0);
    const[hasError, setHasError] = useState(false);
    const inputRef = useRef(null);

    async function submitTicket(){
        if(balance.available > 0){
            setStep(2);
        }
        else if(reason === "Outros" && !writeReason){
          setHasError(true);
        }
        else {
            let body = {
                reason
            }
            try {
                await postDeleteAccountTicket(body);
                toast.success("Solicitação recebida com sucesso", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 1500
                })
            } catch (error) {
              toast.error("Falha ao enviar solicitação", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1500
              })
            }
   
        }
    }

    useEffect(() => {
        if(balance && balance.available > 0){
            setStep(2);
        }
        else {
            getDeleteAccountTicket();
        }
    }, [])

    useEffect(() => {
      if(reason === "Outros"){
        inputRef.current.focus();
      }
      else {
        inputRef.current.clearError();
        setHasError(false);
      }
    }, [reason])

    useEffect(() => {
        if(deleteTicket !== null && deleteTicket.data !== null){
            setStep(1);
        }
    },[deleteTicket]);

  return (
    <div className="panel-configuration">
      <h1 className="panel-configuration__title">Encerrar conta</h1>
      {step === 0 && (

        <React.Fragment>
          <span className='delete-account__subtitle'>Quais motivos te levaram a encerrar sua 
            conta conosco? (opcional)
          </span>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
            <div className='delete-account__container'>
              <input 
                className='delete-account__checkbox' 
                checked={reason && reason.includes("Não utiliza a plataforma")}
                onChange={() => setReason("Não utiliza a plataforma")} 
                value="Não utiliza a plataforma" 
                type="checkbox" 
                id="noUse"
                name="noUse"
              />
              <label
                className='delete-account__label'
                htmlFor="noUse"
              >Não utilizo a plataforma
              </label>
            </div>
            <div className='delete-account__container'>
              <input 
                className='delete-account__checkbox' 
                checked={reason && reason.includes("Tive problemas com a plataforma")}
                type="checkbox" 
                onChange={() => setReason("Tive problemas com a plataforma")} 
                value="Tive problemas com a plataforma" 
                name="hadProblem"
                id="hadProblem"
              />
              <label
                className='delete-account__label'
                htmlFor="hadProblem"
              >Tive problemas com a plataforma
              </label>
            </div>
            <div className='delete-account__container'>
              <input 
                className='delete-account__checkbox' 
                type="checkbox" 
                checked={reason && reason.includes("Outros")}
                name="others" 
                onChange={() => setReason("Outros")} 
                value="Outros"
                id="others"
              />
              <label
                className='delete-account__label'
                htmlFor="others"
              >Outros
              </label>
            </div>
          </div>
          <Input 
            style={{ marginTop: '10px' }}
            placeholder="Outros motivos" 
            ref={inputRef}
            error={hasError && "Digite o outro motivo para continuar"}
            value={writeReason}
            disabled={reason !== "Outros"}
            onChange={(e) => setWriteReason(e.target.value)}
          />
          <div style={{ display: 'flex', marginTop: '24px', alignItems: 'center' }}>
            <Button
              onClick={() => submitTicket()}
              className='delete-account__button'
            >Continuar
            </Button>
            <Link to={routes.PANEL_SETTINGS}>
              <Button
                variant="secondary"
                style={{ marginLeft: '24px' }}
              >Voltar
              </Button>
            </Link>
          </div>
        </React.Fragment>
        )
        
        }
      {step === 1 && (
        <React.Fragment>
          <span
            style={{color: '#313234'}}
            className='delete-account__subtitle'
          >{`Solicitação recebida em ${formatDate(deleteTicket.createdAt)}` }
          </span>
          <div style={{display: 'flex', flexDirection: 'column', gap: '20px', margin: '24px 0px'}}>
            <span className="delete-account__text">Recebemos seu pedido de exclusão de conta e caso 
              não haja nenhuma pendência (pagamentos em aberto, 
              lances em eventos em andamento e ofertas condicionais) sua conta será encerrada em 30 dias corridos.
            </span>
            <span className="delete-account__text">
              Caso encontremos pendência entraremos em contato com você pelo e-mail cadastrado.
            </span>
          </div>
          <Link to={routes.PANEL_START}>
            <Button className='delete-account__button'>Voltar para Home</Button>
          </Link>
                 
        </React.Fragment>
        )}
      {step === 2 && (
        <React.Fragment>
          <span
            style={{color: '#313234'}}
            className='delete-account__subtitle'
          >Aguardando transferência
          </span>
          <div style={{display: 'flex', flexDirection: 'column', gap: '20px', margin: '24px 0px'}}>
            <span className="delete-account__text">
              Para seguir com o encerramento é necessário que você transfira todo o seu saldo na carteira Superbid Pay. 
            </span>
          </div>
          <Link to={routes.PANEL_START}>
            <Button className='delete-account__button'>Voltar para Home</Button>
          </Link>
                        
        </React.Fragment>
        )}



    </div>
  )
}

const mapStateToProps = state => ({
    deleteTicket: AccountSelectors.getDeleteAccountTicket(state),
    balance: AccountSelectors.getBalance(state),
  });

const mapDispatchToProps = dispatch => ({
    getDeleteAccountTicket: () => dispatch(AccountActions.getDeleteAccountTicket()),
    postDeleteAccountTicket: (body) => dispatch(AccountActions.postDeleteAccountTicket(body))
  });

  export default connect(mapStateToProps, mapDispatchToProps)(PanelDeleteAccounts);