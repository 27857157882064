import {
    getCreditCardNameByNumber,
    isSecurityCodeValid,
    isValid
} from 'creditcard.js'
import dayjs from 'dayjs';

const cardNumberRegExp = new RegExp('^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$');
const cardExpirationRegExp = new RegExp(['[0-9]{2}/[0-9]{2}']);

const visaCardRegExp = new RegExp('^4');
const masterCardRegExp = new RegExp('(^5[1-5]|^2[2-3])');

function isValidCardExpiration(message) {
    return this.test("isValidCardExpiration", message, function (value) {
        const { path, createError } = this;

        if (!value) {
            return createError({ path, message: message || "Campo necessario" });
        }

        if (value.length < 5) {
            return createError({ path, message: message || "Muito pequeno" });
        }

        if (!value.match(cardExpirationRegExp)) {
            return createError({
                path,
                message: message || "Expiração invalida"
            });
        }

        const expirationDate = dayjs()
            .set('month', Number(value.split('/')[0]) - 1)
            .set('year', Number(`20${value.split('/')[1]}`));

        const today = dayjs();

        if (expirationDate.isBefore(today)) {
            return createError({
                path,
                message: message || "Expiração invalida"
            });
        }

        return true;
    });
}

function isValidCardCVV(parentName, message) {
    return this.test("isValidCardCVV", message, function (value) {
        const { path, createError, parent } = this;

        if (!value) {
            return createError({ path, message: message || 'Campo necessario' });
        }

        if (value.length < 3) {
            return createError({ path, message: message || "Muito pequeno" });
        }

        if (!parent.number) {
            return createError({ path, message: message || 'CVV inválido' });
        }

        if (parent.hasOwnProperty(parentName)) {
            const isValidCVV = isSecurityCodeValid(parent[parentName], value);

            if (!isValidCVV) {
                return createError({
                    path,
                    message: message || 'CVV inválido'
                })
            }
        }

        return true
    })
}

function isValidCardNumber(message) {
    return this.test("isValidCardNumber", message, function (value) {
        const { path, createError } = this;

        if (!value) {
            return createError({ path, message: message || 'Campo necessario' });
        }

        value = value.replace(/\D/g, '')

        const cardIsValid = isValid(value);

        if (!cardIsValid) {
            return createError({
                path,
                message: message || "Cartão inválido"
            })
        }

        const cardType = getCreditCardNameByNumber(value);

        if (cardType === "Mastercard" || cardType === "Visa") {
            return true;
        }

        return createError({
            path,
            message: message || "Apenas cartões Mastercard e Visa são aceitos"
        })
    });
}

const getCardType = (cardNumber) => {
    cardNumber = cardNumber.replace(/\D/g, '');

    return getCreditCardNameByNumber(cardNumber);
}

const removeSpaces = stringValue => stringValue.replace(/\s/g, '');

const getBrandImageUrl = (cardNumber) => {
    const cardBrand = getCreditCardNameByNumber(cardNumber).toLowerCase();

    if (cardBrand === "mastercard") {
        return "/assets/images/cards/ic_master.svg";
    }

    if (cardBrand === "visa") {
        return "/assets/images/cards/ic_visa.svg";
    }

    return null
}

export {
    isValidCardExpiration,
    cardNumberRegExp,
    cardExpirationRegExp,
    visaCardRegExp,
    masterCardRegExp,
    getCardType,
    isValidCardCVV,
    isValidCardNumber,
    getBrandImageUrl,
    removeSpaces
}