import React, { useCallback, useEffect, useMemo } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { I18n } from "react-redux-i18n";
import styled, { useTheme } from "styled-components";
import { connect } from "react-redux";
import dayjs from "dayjs";
import InformIncomePDF from "../../components/documents/InformIncomePDF";
import Flex from "../../components/elements/Flex";
import Icon from "../../components/elements/Icon";
import Typography from "../../components/elements/Typography";
import PageTitle from "../../components/modules/PageTitle";
import { AccountSelectors, UserInformIncomeSelectors, UserSelectors } from "../../app/store/reducers";
import { AccountActions, UserInformIncomeActions } from "../../app/store/actions";

const UploadButton = styled(Flex)`
  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.brand.primary};
    
    svg, p {
      color: ${({ theme }) => theme.colors.brand.primary};
    }
  }
`;

const PanelInformIncome = ({ incomes = [], user, account, getIncomes, getAccountDetails }) => {
  const theme = useTheme();

  const translate = useCallback((path) => {
    return I18n.t(`panel.informIncome.${path}`);
  }, [I18n]);

  useEffect(() => {
    getIncomes();
    getAccountDetails();
  }, []);

  const income = useMemo(() => {
    if (user && account && incomes.length > 0) {
      let previousBalance = incomes[0].saldoDisponivel ? incomes[0].saldoDisponivel : null;
      let currentBalance = incomes[1].saldoDisponivel ? incomes[1].saldoDisponivel : null;

      let year = Number(dayjs(incomes[1].data).format("YYYY"));

      const formatAccount = () => {
        let acc = account.number.toString();

        acc = `${acc.slice(0, acc.length - 1)}-${acc.slice(acc.length - 1)}`

        return acc
      }

      return {
        year,
        name: user.name,
        document: user.taxIdentifier,
        agency: account.branch,
        account: formatAccount(),
        company: {
          name: "Superbid Pay Instituição de Pagamento LTDA",
          document: "35.084.163/0001-84"
        },
        balance: {
          initial: previousBalance,
          final: currentBalance
        }
      }
    }

    return null;
  }, [user, account, incomes]);

  return (
    <Flex
      flexDirection="column"
      width="100%"
      maxWidth="600px"
      alignSelf="center"
      py={3}
    >
      <PageTitle
        title={translate("pageTitle")}
        description={translate("description")}
      />

      <Flex
        flexDirection="column"
        width="100%"
        gap="16px"
        mt={1}
      >
        {income && (
          <PDFDownloadLink
            document={(
              <InformIncomePDF
                theme={theme}
                translate={translate}
                income={income}
              />
            )}
            fileName={`${translate("pageTitle")}_${income.year}`}
            style={{ textDecoration: "none" }}
          >
            <UploadButton
              alignItems="center"
              border="1px solid"
              borderColor="text.lightest"
              borderRadius="xs"
              px={2}
              py={0}
            >
              <Icon
                icon="download-outline"
                size={20}
                color={theme.colors.brand.primary}
              />

              <Typography
                color="text.dark"
                fontSize="title.sm"
                lineHeight="title.sm"
                fontWeight="semibold"
                ml={0}
              >
                {income.year}
              </Typography>
            </UploadButton>
          </PDFDownloadLink>
        )}
      </Flex>
    </Flex>
  )
}

const mapStateToProps = state => ({
  incomes: UserInformIncomeSelectors.getUserInformIncomes(state),
  user: UserSelectors.getUser(state),
  account: AccountSelectors.getDetails(state)
});

const mapDispatchToProps = dispatch => ({
  getIncomes: () => dispatch(UserInformIncomeActions.getIncomes()),
  getAccountDetails: () => dispatch(AccountActions.getAccountDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PanelInformIncome);