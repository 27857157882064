import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n'
import { ToastContainer } from "react-toastify";
import routes from '../../data/routes';
import CashInInformation from './CashInInformation'

import 'react-toastify/dist/ReactToastify.css';

Modal.setAppElement('#app');

const CashInTed = ({ bank, agency, account, type, name, document }) => {
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="deposit_ted">
        <div className="deposit_ted__data">
          <CashInInformation
            title={I18n.t("cashIn.transfer.bank")}
            description={bank}
          />
          <div className="deposit_ted__data__row">
            <CashInInformation
              title={I18n.t("cashIn.transfer.agency")}
              description={agency}
            />
            <CashInInformation
              title={I18n.t("cashIn.transfer.account")}
              description={account}
            />
            <CashInInformation
              title={I18n.t("cashIn.transfer.type")}
              description={type}
            />
          </div>
        </div>

        <hr />

        <div className="deposit_ted__data">
          <CashInInformation
            title={I18n.t("cashIn.transfer.name")}
            description={name}
          />
          <CashInInformation
            title={I18n.t("cashIn.transfer.document")}
            description={document}
          />
        </div>

        <hr />

        <div className="deposit_ted__information">
          <h6 className="deposit_ted__information__title">
            {I18n.t("cashIn.transfer.attention")}
          </h6>
          <ul className="deposit_ted__information__list">
            <li className="deposit_ted__information__list__item">
              {I18n.t("cashIn.transfer.attentionDescription")}
            </li>
            <li className="deposit_ted__information__list__item">
              {I18n.t("cashIn.transfer.attentionDescriptionTwo")}
            </li>
          </ul>
        </div>

        <hr />

        <Link
          to={routes.HOME}
          className="deposit_ted__back-button"
        >
          <button type="button">
            {I18n.t("cashIn.transfer.goBack")}
          </button>
        </Link>
      </div>
    </React.Fragment>
  )
}


export default CashInTed;
