/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { toast, ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import CashOutValue from '../../components/cashOut/CashOutValue';
import { BankTransferActions, AccountActions, UtilsActions, SettingsActions, CashOutActions } from '../../app/store/actions';
import { BankTransferSelectors, AccountSelectors, UtilsSelectors, SettingsSelector, UserSelectors, LoadingSelectors } from '../../app/store/reducers';
import CashOutDetails from '../../components/cashOut/CashoutDetails';
import ReceiptDetails from '../../components/cashOut/ReceiptDetails';
import getAccountType from '../../app/pipe/accountType';

import helpers from '../../helpers/currency';
import AccountListSelect from '../../components/cashOut/AccountListSelect';
// import ROUTES from '../../data/routes';
import AddAccountModal from '../../components/wallet/AddAccountModal';

import AccountValidationStatus from '../../data/enumerators/accountValidation';

import numberHelpers from '../../helpers/number'
import CashoutTimeModal from '../../components/cashOut/CashoutTimeModal';
import ScheduledValidationModal from '../../components/cashOut/CashOutDuplicateModal';

const { parseCurrency } = helpers;

Modal.setAppElement('#app');

const customStyles = {
  overlay: {
    background: 'rgb(40, 51, 74, 0.5)',
    zIndex: 99
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    overflox: "hidden"
  },
}

const PanelCashOut = (
  { user,
    loading,
    balanceFinance,
    bankTransferList,
    settingsList,
    transaction,
    fee,
    getBankList,
    getTransaction,
    getAllSettings,
    getAllBankTransfer,
    getTransferOutfee,
    withdrawMoney,
    getScheduleCashoutValidation,
    systemDataMatera,
    getSystemDateMatera
  }) => {
  const [amount, setAmount] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [account, setAccount] = useState({});
  const [visibleSelectAccount, setVisibleSelectAccount] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(new Date())
  const [scheduledValidationModal, setScheduleValidationModal] = useState(false);
  const [modalType, setModalType] = useState("addAccount");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const cashoutLimitTime = useMemo(() => {
    let time = dayjs().set('hour', 15);

    if (settingsList.settings) {
      const config = settingsList.settings.find(setting => setting.key === "cashoutLimitTime");

      if (config) {
        time = time.set('hour', config.value.slice(0, 2))
      }
    }

    return Number(time.format("HH"));
  }, [settingsList]);

  const cashoutDetails = useMemo(() => {
    const { accountTypeDestination, accountDestination, branchDestination, bankDestination, bankName, name, taxIdentifier } = account;

    let accountType = null;

    if (accountTypeDestination) {
      accountType = getAccountType(accountTypeDestination)
    }

    return {
      amount: amount ? parseCurrency(amount) : 0,
      comment: {
        account: accountDestination,
        agency: branchDestination,
        bank: bankDestination && bankName ? `${bankDestination} - ${bankName}` : null,
        customDescription: "Retirada agendada",
        customer: name,
        fee: fee || 0,
        taxIdentifier: taxIdentifier && numberHelpers.keepOnlyNumber(taxIdentifier),
        type: "withdrawal",
        accountTypeDestination: accountType
      },
      limitTime: cashoutLimitTime
    }
  }, [amount, account, cashoutLimitTime]);

  const receiptDetails = useMemo(() => {
    const cashoutType = transaction && transaction.transactionId ? "NOW" : "SCHEDULE";
    const createdAt = transaction && transaction.transactionId && transaction.entryDate || scheduleDate;
    const operationNumber = transaction && transaction.transactionId;

    return {
      type: cashoutType,
      id: operationNumber,
      amount: cashoutDetails.amount,
      name: cashoutDetails.comment.customer,
      bank: cashoutDetails.comment.bank,
      agency: cashoutDetails.comment.agency,
      account: cashoutDetails.comment.account,
      fee: cashoutDetails.comment.fee,
      createdAt,
      cashoutLimitTime,
      systemDataMatera,
      accountStatus: account.validationStatus
    }
  }, [transaction, cashoutDetails, account, cashoutLimitTime, scheduleDate, systemDataMatera]);

  const handleNextStep = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const handlePrevStep = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const handleOpenModal = useCallback((type = "addAccount") => {
    setModalType(type)
    setModalIsOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const handleChangeToSelectAccount = useCallback(() => setVisibleSelectAccount(true), []);
  const handleChangeToCashoutDetail = useCallback(() => setVisibleSelectAccount(false), []);

  const handleCashout = useCallback(async () => {
    if (balanceFinance.real < parseCurrency(amount) + fee) {
      toast.error(I18n.t('toastMessage.insuficientbalance'), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000
      });
    }

    const cashOut = {
      amount: parseCurrency(amount),
      bankName: account.bankName,
      bankDestination: account.bankDestination,
      branchDestination: account.branchDestination,
      accountDestination: account.accountDestination.split('-')[0],
      accountDigitDestination: account.accountDigitDestination,
      taxIdentifier: account.taxIdentifier,
      name: account.name,
      accountTypeDestination: account.accountTypeDestination
    }

    try {
      const response = await withdrawMoney(cashOut);

      if (response.cashout) {
        if (response.cashout.transactionId) {
          await getTransaction(response.cashout.transactionId);
        }
        if (response.cashout.scheduleDate) {
          setScheduleDate(response.cashout.scheduleDate)
        }
      }

      handleNextStep();
    } catch (error) {
      toast.error(I18n.t('toastMessage.insuficientbalance'), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000
      });
    }
  }, [account, handleNextStep]);

  const handleVerifyCashout = useCallback(async () => {
    let valid = await getScheduleCashoutValidation({ amount: cashoutDetails.amount });
    if (valid === false) {
      setScheduleValidationModal(true);
      return;
    }
    if (dayjs().isAfter(dayjs().set('hour', 10).set('minute', 0))) {
      handleOpenModal('cashoutLimitTime');
    } else {
      handleCashout();
    }
  }, [handleOpenModal, handleCashout, cashoutLimitTime]);

  const handleContinueCashout = useCallback(() => {
    handleCloseModal();
    handleCashout();
  }, [handleCloseModal, handleCashout]);

  const handleSelectAccount = useCallback(() => {
    if (bankTransferList.length > 0 && bankTransferList.some(acc => acc.validationStatus !== AccountValidationStatus.FAILED)) {
      const accountSelected = bankTransferList.find(acc => acc.favorite) || bankTransferList.find(acc => acc.validationStatus !== AccountValidationStatus.FAILED)

      if (!accountSelected) {
        setAccount({})
      } else {
        setAccount(accountSelected)
      }
    } else {
      setAccount({})
    }
  }, [bankTransferList])

  const handleGetData = useCallback(async () => {
    await getTransferOutfee();
    await getAllSettings();
    await getBankList();
    await getAllBankTransfer();
    await getSystemDateMatera();
  }, [getTransferOutfee, getAllSettings, getBankList, getAllBankTransfer, getSystemDateMatera]);

  useEffect(() => {
    handleGetData()
  }, []);

  useEffect(() => {
    handleSelectAccount()
  }, [handleSelectAccount]);

  useEffect(() => {
    if (currentStep === 1 && !loading && !Object.keys(account).length) {
      handleOpenModal("addAccount")
    }
  }, [account, currentStep, loading]);

  return (
    <React.Fragment>
      <ScheduledValidationModal
        isOpen={scheduledValidationModal}
        handleCashout={handleCashout}
        onClose={() => setScheduleValidationModal(false)}
      />

      <main className="cashout_page">
        <div className="cashout_page__box">

          {currentStep !== 2 && (
            <section className="cashout_page__header">
              <h2 className="cashout_page__header__title">{I18n.t('cashoutPage.title')}</h2>
              <p className="cashout_page__header__description">{I18n.t('cashoutPage.description')}</p>
            </section>
          )}

          {currentStep === 0 && (
            <CashOutValue
              balance={balanceFinance && balanceFinance.real}
              amount={amount}
              setAmount={setAmount}
              onContinue={handleNextStep}
            />
          )}

          {currentStep === 1 && !visibleSelectAccount && (
            <div className="cashout_page__details__box">
              <CashOutDetails
                details={cashoutDetails}
                onClickBack={handlePrevStep}
                onClickChangeAccount={handleChangeToSelectAccount}
                onConfirm={handleVerifyCashout}
              />
            </div>
          )}

          {currentStep === 1 && visibleSelectAccount && (
            <AccountListSelect
              accounts={bankTransferList || []}
              setAccount={setAccount}
              selectedAccount={account}
              onClickAddAccount={() => handleOpenModal("addAccount")}
              onClickBack={handleChangeToCashoutDetail}
              onSelectAccount={handleChangeToCashoutDetail}
            />
          )}

          {currentStep === 2 && (
            <ReceiptDetails
              details={receiptDetails}
              fee={fee}
            />
          )}
        </div>
      </main>
      {!scheduledValidationModal && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          style={customStyles}
        >
          {modalType === "addAccount" && user && (
            <AddAccountModal
              taxIdentifier={user.taxIdentifier}
              onClose={handleCloseModal}
              onCancel={() => {
                if (!Object.keys(account).length) {
                  handlePrevStep();
                }

                handleCloseModal();
              }}
            />
          )}
          {modalType === "cashoutLimitTime" && (
            <CashoutTimeModal
              onCancel={handleCloseModal}
              onContinue={handleContinueCashout}
              cashoutLimitTime={cashoutLimitTime}
            />
          )}
        </Modal>
      )}
      <ToastContainer />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  loading: LoadingSelectors.getLoading(state),
  user: UserSelectors.getUser(state),
  bankList: UtilsSelectors.getBanks(state),
  settingsList: SettingsSelector.getSettings(state),
  transaction: AccountSelectors.getTransaction(state),
  bankTransferList: BankTransferSelectors.getBankTransferList(state),
  fee: UtilsSelectors.getTranferOutFee(state),
  balanceFinance: AccountSelectors.getBalance(state),
  systemDataMatera: UtilsSelectors.getSystemDateMatera(state),
});

const mapDispatchToProps = dispatch => ({
  getAllBankTransfer: () => dispatch(BankTransferActions.getAllBankTransfer()),
  getAllSettings: () => dispatch(SettingsActions.getAllSettings()),
  getTransaction: (id) => dispatch(AccountActions.getTransaction(id)),
  getBalanceFinance: () => dispatch(AccountActions.balanceFinance()),
  getTransferOutfee: () => dispatch(UtilsActions.getTransferOutfee()),
  getSystemDateMatera: () => dispatch(UtilsActions.getSystemDateMatera()),
  removeBankTransfer: (id) => dispatch(BankTransferActions.deleteBankTransfer(id)),
  getBankList: () => dispatch(UtilsActions.getBankList()),
  withdrawMoney: (cashOut) => dispatch(CashOutActions.cashOutWithdraw(cashOut)),
  getScheduleCashoutValidation: (amount) => dispatch(AccountActions.getScheduleCashoutValidation(amount))
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelCashOut);


