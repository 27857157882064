import RestService from '../services/rest';
import RestAdiq from '../services/restAdiq';

async function authenticateCreditCard() {
  return RestService.postAuthenticated('credit-card/auth');
}

async function getNumberToken(creditCard, header) {
  return RestAdiq.postAuthenticated('v1/tokens/card', creditCard, header);
}

async function saveCreditCard(creditCard, header) {
  return RestAdiq.postAuthenticated('credit-card/save', creditCard, header);
}

async function listCreditCard() {
  return RestService.getAuthenticated('credit-card');
}

async function postCreditCard(creditCard) {
  return RestService.postAuthenticated('credit-card', creditCard);
}

async function removeCreditCard(id) {
  return RestService.deleteAuthenticated(`credit-card/${id}`);
}

export default {
  authenticateCreditCard,
  getNumberToken,
  saveCreditCard,
  postCreditCard,
  listCreditCard,
  removeCreditCard
};
