import React from 'react';
import styled from 'styled-components';
import { typography, color, space, layout, flexbox } from 'styled-system';

const Paragraph = styled.p`
  font-family: ${({ theme, variant }) => variant === "title" ? theme.fonts.title : theme.fonts.body};
  text-decoration: ${({ textDecoration }) => textDecoration};
  cursor: ${({ cursor }) => cursor};

  ${layout};
  ${color};
  ${typography};
  ${space};
  ${flexbox};
`;

const BoldComponent = styled.b`
  font-family: inherit;
  text-decoration: inherit;
  cursor: inherit;

  ${layout};
  ${color};
  ${typography};
  ${space};
  ${flexbox};
`;

const SpanComponent = styled.b`
  font-family: inherit;
  text-decoration: inherit;
  cursor: inherit;

  ${layout};
  ${color};
  ${typography};
  ${space};
  ${flexbox};
`;


export const Span = ({ ...props }) => {
  return (
    <SpanComponent
      fontWeight="semibold"
      {...props}
    />
  )
}

export const Bold = ({ ...props }) => {
  return (
    <BoldComponent
      fontWeight="bold"
      {...props}
    />
  )
}

const Typography = ({ children, ...props }) => {
  return (
    <Paragraph
      mb="0px"
      {...props}
    >
      {children}
    </Paragraph>
  )
}

export default Typography;