/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Component, Error, Wrapper } from "./styles";

const Input = (
  {
    type = "text",
    placeholder,
    error,
    className,
    value,
    style,
    mt,
    mb,
    ml,
    mr,
    m,
    width,
    ...props
  }, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [hasError, setHasError] = useState(!!error);

  const inputRef = useRef(null);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current.value);
  }, []);

  const handleClearError = useCallback(() => {
    setHasError(false)
  }, []);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus()
    },
    clearError: () =>
    handleClearError()
  }));

  useEffect(() => {
    if (error && !isFocused && !isFilled) {
      setHasError(true);
    }

    if (isFocused) {
      handleClearError();
    }
  }, [isFocused, error]);

  useEffect(() => {
    setIsFilled(!!value)
  }, [value]);

  return (
    <Wrapper
      className={className}
      style={style}
      mt={mt}
      mb={mb}
      mr={mr}
      ml={ml}
      m={m}
      width={width}
    >
      <Component
        isFocused={isFocused || isFilled}
        hasError={hasError}
      >
        <input
          ref={inputRef}
          type={type}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          required
          {...props}
        />

        <span>
          {placeholder}
        </span>
      </Component>
      {hasError && <Error>{error}</Error>}
    </Wrapper>
  )
}

export default forwardRef(Input);
