import React, { useCallback } from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

import theme from "../../styles/theme";
import numberHelpers from '../../helpers/number';
import currencyHelpers from '../../helpers/currency';

Font.register({
  family: "Manrope",
  fonts: [
    {
      src: "/assets/fonts/Manrope-Regular.ttf",
      fontStyle: "normal",
      fontWeight: 400,
      format: "truetype",
    },
    {
      src: "/assets/fonts/Manrope-SemiBold.ttf",
      fontStyle: "normal",
      fontWeight: 600,
      format: "truetype",
    },
  ]
});

const Logo = "/assets/images/logo.png";

const InformIncomePDF = ({ income, translate }) => {
  const currency = useCallback((value = 0) => {

    if (value === null) {
      return "R$ 0,00";
    }

    if (typeof value === "string") {
      value = currencyHelpers.parseCurrency(value);
    }

    return value.toLocaleString("pt-Br", {
      style: "currency",
      currency: "BRL",
    });
  }, []);

  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
      >
        <View style={styles.header}>
          <View>
            <Text style={styles.title}>
              {translate("pageTitle")}
            </Text>
            <View style={styles.subtitle}>
              <Text style={styles.subtitleDescription}>
                {translate("calendarYear")}
              </Text>
              <Text style={styles.subtitleYear}>
                {" "}{income.year}
              </Text>
            </View>
          </View>

          <Image
            src={`${Logo}`}
            style={styles.logo}
          />
        </View>

        <View style={styles.content}>
          <Text style={styles.contentTitle}>
            {translate("recipient.title")}
          </Text>
          <View style={styles.divider} />

          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle}>
              {translate("recipient.client")}:
            </Text>
            <Text style={styles.infoValue}>{income.name}</Text>
          </View>

          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle}>
              {translate("recipient.document")}:
            </Text>
            <Text style={styles.infoValue}>{numberHelpers.documentMask(income.document)}</Text>
          </View>

          {income.agency && income.account && (
            <View style={styles.infoRow}>
              <View style={styles.infoContainer}>
                <Text style={styles.infoTitle}>
                  {translate("recipient.agency")}:
                </Text>
                <Text style={styles.infoValue}>{income.agency}</Text>
              </View>

              <View style={styles.infoContainer}>
                <Text style={styles.infoTitle}>
                  {translate("recipient.account")}:
                </Text>
                <Text style={styles.infoValue}>{income.account}</Text>
              </View>
            </View>
          )}
        </View>

        <View style={styles.content}>
          <Text style={styles.contentTitle}>
            {translate("company.title")}
          </Text>
          <View style={styles.divider} />

          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle}>
              {translate("company.name")}:
            </Text>
            <Text style={styles.infoValue}>
              {income.company.name}
            </Text>
          </View>

          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle}>
              {translate("company.document")}:
            </Text>
            <Text style={styles.infoValue}>
              {income.company.document}
            </Text>
          </View>
        </View>

        <View style={styles.content}>
          <Text style={styles.contentTitle}>
            {translate("payment.title")}
          </Text>
          <View style={styles.divider} />

          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle}>
              {translate("payment.balance")}{" "}
              {`31/12/${income.year - 1}`}:
            </Text>
            <Text style={styles.infoValue}>
              {currency(income.balance.initial)}
            </Text>
          </View>

          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle}>
              {translate("payment.balance")}{" "}
              {`31/12/${income.year}`}:
            </Text>
            <Text style={styles.infoValue}>
              {currency(income.balance.final)}
            </Text>
          </View>

          <View style={{ ...styles.divider, marginTop: 16 }} />
          <Text style={{ ...styles.text, textAlign: "left", marginTop: 12 }}>
            {translate("obs")}
          </Text>
        </View>

        <View style={styles.footer}>
          <View style={styles.divider} />
          <Text style={styles.text}>
            {translate("copyright")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffff",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    borderTopWidth: 3,
    borderTopColor: theme.colors.brand.primary,
    paddingTop: 28,
    paddingHorizontal: 40
  },
  logo: {
    width: "118px",
    height: "36px",
  },
  title: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 20,
    color: theme.colors.text.medium,
  },
  subtitle: {
    fontFamily: "Manrope",
    fontWeight: 400,
    flexDirection: "row",
    alignItems: "center",
  },
  subtitleDescription: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 14,
    color: theme.colors.text.medium,
  },
  subtitleYear: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 14,
    color: theme.colors.functional.primary[700],
  },
  content: {
    paddingHorizontal: 40,
    paddingTop: 40
  },
  contentTitle: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 16,
    color: theme.colors.functional.primary[900]
  },
  divider: {
    width: "100%",
    backgroundColor: theme.colors.text.lightest,
    height: "1px",
    marginTop: 8,
  },
  infoContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginTop: 8
  },
  infoTitle: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 12,
    color: theme.colors.text.regular
  },
  infoValue: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: 12,
    color: theme.colors.text.medium,
    marginLeft: 4
  },
  infoRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  footer: {
    flex: 1,
    justifyContent: "flex-end",
    paddingHorizontal: 40,
    marginBottom: 16
  },
  text: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 12,
    color: theme.colors.text.medium,
    textAlign: "center",
    marginTop: 8
  }
});

export default InformIncomePDF;