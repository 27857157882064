import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../data/routes';

const InstitutionalFooterLinks = () => {
  return (
    <Fragment>
      <div className="institutional-footer-links">
        <Link
          className="institutional-footer-links__single"
          to={ROUTES.ABOUT_US}
        >
          Sobre
        </Link>
        <Link
          className="institutional-footer-links__single"
          to={ROUTES.FAQ}
        >
          Ajuda
        </Link>
        <Link
          className="institutional-footer-links__single"
          to={ROUTES.TERMS}
        >
          Termos & Políticas
        </Link>
        <a
          className="institutional-footer-links__single"
          href={ROUTES.OMBUDSMAN}
          target="_blank"
          rel="noreferrer"
        >
          Ouvidoria
        </a>
        <Link
          className="institutional-footer-links__single"
          to={ROUTES.REPORT_OMBUDSMAN}
        >
          Relatórios da ouvidoria
        </Link>
      </div>
    </Fragment>
  )
}

export default InstitutionalFooterLinks;
