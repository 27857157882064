import React from 'react';
import { Translate } from 'react-redux-i18n';

export const AccountType = {
  CONTA_CORRENTE: 1,
  POUPANCA: 2,
};

export const AccountTypeMap = new Map([
  [AccountType.CONTA_CORRENTE, { description: <Translate value="accountTypeEnum.current" /> }],
  [AccountType.POUPANCA, { description: <Translate value="accountTypeEnum.savings" /> }],
]);

export const AccountTypeArray = [
  { id: AccountType.CONTA_CORRENTE, description: <Translate value="accountTypeEnum.current" /> },
  { id: AccountType.POUPANCA, description: <Translate value="accountTypeEnum.savings" /> },
];

export default AccountTypeMap;
