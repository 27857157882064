import moment from 'moment';
import RestService from '../services/rest';

async function getBalanceFinance() {
	return RestService.getAuthenticated('account/balance');
}

async function getExtractFinance(startDate, endDate, typeFilter, orderExtract) {
	let url = `account/extract?startAt=${startDate}&type=${typeFilter}`;

	if (endDate) {
		url += `&endAt=${endDate}`;
	}

	if (orderExtract) {
		url += `&order=${orderExtract}`;
	}

	return RestService.getAuthenticated(url);
}

async function getScheduleCashout() {
	return RestService.getAuthenticated("scheduled-cashout");
}

async function getScheduleCashoutValidation(amount){
	return RestService.postAuthenticated("scheduled-cashout/verify-balance", amount)
}

async function getExtractFinanceInCSV(startDate = moment().format('YYYY-MM-DD'), endDate) {
	let url = `account/extract-csv?startAt=${startDate}`;

	if (endDate) {
		url += `&endAt=${endDate}`;
	}

	return RestService.getAuthenticated(url, {}, { responseType: 'blob' });
}

async function getTransactionById(id) {
	return RestService.getAuthenticated(`account/extract/${id}`);
}

async function getPublicTransaction(id, userId) {
	return RestService.getRest(`account/extract-public/${id}/user/${userId}`);
}

async function getAccountDetails() {
	return RestService.getAuthenticated("account/account-details");
}

async function getDeleteAccountTicket(){
	return RestService.getAuthenticated("account/delete-account-ticket");
}

async function postDeleteAccountTicket(body) {
	return RestService.postAuthenticated("account/delete-account-ticket", body);
}

export default {
	getBalanceFinance,
	getExtractFinance,
	getTransactionById,
	getPublicTransaction,
	getExtractFinanceInCSV,
	getAccountDetails,
	getScheduleCashout,
	getScheduleCashoutValidation,
	getDeleteAccountTicket,
	postDeleteAccountTicket
};
