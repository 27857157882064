import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import queryString from 'query-string';
import ROUTES from '../../data/routes';
import Check from '../../svg/panel/panelExtract/ic_check.svg';
import MoneyValue from "../moneyValue/MoneyValue";
import CardWhite from "../card/CardWhite";
import { AccountActions } from '../../app/store/actions';
import { AccountSelectors } from '../../app/store/reducers';
import BackPage from '../../svg/panel/panelExtract/ic_keyboard_backspace.svg';

class ExtractPublicDetails extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
    const { getPublicTransaction, transaction} = this.props;
    if(!transaction){
      let params = queryString.parse(location.search);
      await getPublicTransaction(params.transactionId, params.user);
    }
  }

  render() {
    const { transaction } = this.props;
    return (
      <CardWhite
        noShadow
      >
        {transaction && (
          <div className="history__card">
            <div className="row mobile mb-1">
              <div className="col-12">
                <Link
                  to={ROUTES.PANEL_EXTRACT}
                  className="panel__head__back link-no-style d-flex"
                >
                  <BackPage className="panel__head__ic_keyboard_backspace" />
                  <span className="panel__head__back__text">{I18n.t('panel.extractDetailsPage.backPageText')}</span>
                </Link>
              </div>
            </div>
            <div className="history__card__title">
              <span className="uppercase-text">
                {(transaction.comment && transaction.comment.customDescription) && 
                transaction.comment.customDescription}
                {(transaction.comment && !transaction.comment.customDescription) && transaction.comment}
                {(!transaction.comment) && transaction.description}
              </span>
            </div>
            <div className="history__card__header">
              <div className="history__card__header__number">
                {I18n.t('extractPage.operationNumber')}: {(!transaction.transactionId) && '-'}
                {transaction.transactionId}
              </div>
            </div>
            <div className="extract__card__content">
              <div className="extract__card__content__value">
                <div className="extract__card__content__value__title">
                  {I18n.t('extractPage.valueTransaction')}:
                </div>
                <div className="extract__card__content__value__description">
                  <MoneyValue value={transaction.amount} />
                </div>
              </div>
              {(transaction.counterpart && transaction.counterpart.name) && (
                <div className="extract__card__content__value">
                  <div className="extract__card__content__value__title">
                    {I18n.t('extractPage.receive')}:
                  </div>
                  <div className="extract__card__content__value__description">
                    {transaction.counterpart && transaction.counterpart.name}
                  </div>
                </div>
              )}
              {transaction.comment && transaction.comment.bank && (
                
                <div className="extract__card__content__account">
                  <div className="extract__card__content__value">
                    <div className="extract__card__content__value__title">
                      Tarifa:
                    </div>
                    <div className="extract__card__content__value__description">
                      <MoneyValue value={transaction.comment.fee} />
                    </div>
                  </div>
                  <div className="extract__card__content__account__info">
                    <div className="extract__card__content__account__info__title">
                      Documento:
                    </div>
                    <div className="extract__card__content__account__info__description">
                      {transaction.comment.taxIdentifier}
                    </div>
                  </div>
                  <div className="extract__card__content__account__info">
                    <div className="extract__card__content__account__info__title">
                      {I18n.t('extractPage.bank')}:
                    </div>
                    <div className="extract__card__content__account__info__description">
                      {transaction.comment.bank}
                    </div>
                  </div>
                  <div className="extract__card__content__account__info">
                    <div className="extract__card__content__account__info__title">
                      {I18n.t('extractPage.agency')}:
                    </div>
                    <div className="extract__card__content__account__info__description">
                      {transaction.comment.agency}
                    </div>
                  </div>
                  <div className="extract__card__content__account__info">
                    <div className="extract__card__content__account__info__title">
                      {I18n.t('extractPage.accountBank')}:
                    </div>
                    <div className="extract__card__content__account__info__description">
                      {transaction.comment.account}
                    </div>
                  </div>
                  <div className="extract__card__content__account__info">
                    <div className="extract__card__content__account__info__title">
                      {I18n.t('extractPage.descriptionBank')}:
                    </div>
                    <div className="extract__card__content__account__info__description">
                      {transaction.comment.customDescription}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <Check className="panel__container__icon--green" />
                <span className="panel__container__text--footer">
                  {I18n.t('extractPage.textOperation')}
                  {' '}
                  {transaction.creditDate ? moment(transaction.creditDate).format('DD/MM/YYYY') : ''}
                  {' '}
                  {I18n.t('extractPage.like')}
                  {' '}
                  {transaction.entryDate ? moment(transaction.entryDate).format('HH:mm') : ''}
                </span>
              </div>
            </div>
          </div>
        )}
      </CardWhite>
    )
  }
}

const mapStateToProps = state => ({
  transaction: AccountSelectors.getTransaction(state),
});

const mapDispatchToProps = dispatch => ({
  getPublicTransaction: (id, userId) => dispatch(AccountActions.getPublicTransaction(id, userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtractPublicDetails);

