export default {
    colors: {
        brand: {
            primary: "#BE00FF",
            secondary: "#FA6400",
            complementary: "#E6E6E6",
        },
        feedback: {
            error: "#BD3939",
            warning: "#FF7D25",
            sucess: "#59AC7C",
            report: "#0084DC",
            tooltip: "#545B67"
        },
        text: {
            lightest: "#EBECED",
            light: "#BEC5CB",
            regular: "#73757D",
            medium: "#66696F",
            dark: "#313234",
            darkest: "#131415"
        },
        background: {
            default: "#ffffff"
        },
        surface: {
            white: "#FFFFFF",
            light: "#F6F6F6",
            medium: "#4C5871",
            dark: "#3C3C3C"
        },
        functional: {
            primary: {
                100: "#F9F1FF",
                200: "#F3E4FF",
                300: "#E7C5FF",
                400: "#DAA1FF",
                500: "#CC72FF",
                600: "#BE00FF",
                700: "#A900E4",
                800: "#9300C5",
                900: "#7800A1",
                1000: "#540072"
            }
        }
    },
    fonts: {
        default: "Manrope",
        title: "Plus Jakarta Sans",
        body: "Manrope"
    },
    fontSizes: {
        header: {
            h1: "30px",
            h2: "26px",
            h3: "24px",
            h4: "20px",
            h5: "18px",
            h6: "16px"
        },
        title: {
            xxl: "30px",
            xl: "26px",
            lg: "24px",
            md: "20px",
            sm: "18px",
            xs: "16px"
        },
        body: {
            md: "16px",
            sm: "14px",
            xs: "12px",
            xxs: "10px"
        },
        button: {
            default: "16px"
        }
    },
    lineHeights: {
        header: {
            h1: "38px",
            h2: "33px",
            h3: "30px",
            h4: "25px",
            h5: "23px",
            h6: "20px"
        },
        title: {
            xxl: "41px",
            xl: "36px",
            lg: "33px",
            md: "27px",
            sm: "25px",
            xs: "22px"
        },
        body: {
            md: "22px",
            sm: "19px",
            xs: "16px",
            xxs: "14px"
        },
        button: {
            default: "22px"
        }
    },
    fontWeights: {
        default: 400,
        regular: 400,
        semibold: 600,
        bold: 800
    },
    shadows: {
        card: {
            normal: "2px 2px 6px rgba(11, 47, 99, 0.08)",
            hover: "2px 2px 12px rgba(11, 47, 99, 0.16)",
        }
    },
    radii: {
        s: "16px",
        xs: "8px",
        xxs: "4px",
        full: "50%"
    },
    breakpoints: {
        sm: "600px"
    },
    space: [8, 16, 24, 32, 40, 48]
}