import React from 'react';
import OperationItem from './OperationItem';
import EmptyState from './EmptyState';

const OperationList = ({ operations }) => (
  <div className="list">
    <div className="list__container">
      {(operations && operations.length > 0) ? (
        operations.map((operation) => {
          if(!operation.safeBalance && !operation.hasFee && operation.status === 1){
            return null;
          }
          else {
            return (
              <OperationItem
              key={operation.id}
              id={operation.id}
              type={operation.status}
              date={operation.createdAt}
              description={operation.message}
              status={operation.status}
              value={operation.totalAmount}
            />
            )
          }
        }
        )
      ) : <EmptyState />}
    </div>
  </div>
);

export default OperationList;
