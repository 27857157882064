import React, { Fragment } from 'react';
import FaqIcon from './FaqIcon';

const FaqTopics = ({ mosaic, current, selectTopic }) => (
  <Fragment>
    <div className="faq__topic">
      <div className="faq__topic__title">
        Qual é a sua dúvida?
      </div>
      <div className="faq__topic__mosaic">
        <div className="faq__topic__mosaic__wrapper">
          {mosaic && mosaic.length > 0 && (
            mosaic.map((item, i) => (
              <Fragment key={item.topic}>
                <div
                  className={`faq__topic__mosaic__action 
                    ${i >= (mosaic.length - 2) && 'faq__topic__mosaic__action--no-border'}
                    ${!(i % 2) && 'faq__topic__mosaic__action--right-border'}`}
                >
                  <button
                    className={`faq__topic__mosaic__action__button 
                        ${current === item.topic ? 'faq__topic__mosaic__action__button--selected' : ''}`}
                    type="button"
                    onClick={() => { selectTopic(item.topic) }}
                  >
                    <FaqIcon
                      icon={item.topic}
                      className={`faq__topic__mosaic__action__icon
                      ${current === item.topic} ? faq__topic__mosaic__action__icon--selected`}
                    />
                    {item.title}
                  </button>
                </div>
              </Fragment>
            ))
          )}
        </div>
      </div>
    </div>
  </Fragment>
)

export default FaqTopics;
