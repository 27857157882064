const AccountValidationStatus = {
    PENDING: 1,
    OK: 2,
    FAILED: 3,
    VALIDATION_IN_PROGRESS: 4,
    // TODO: mudar de 4 pra 2
  };
  
  export const AccountValidationStatusMap = new Map([
    [AccountValidationStatus.PENDING, 'PENDING'],
    [AccountValidationStatus.VALIDATION_IN_PROGRESS, 'VALIDATION_IN_PROGRESS'],
    [AccountValidationStatus.OK, 'OK'],
    [AccountValidationStatus.FAILED, 'FAILED'],
  ]);
  
  export default AccountValidationStatus;