const Bank = {
  ITAU: 0,
  SANTANDER:1,
  NUBANK: 2,
  BRADESCO: 3,
};

export const BankMap = new Map([
  [Bank.ITAU, { description: '341 - Banco Itaú S/A'  }],
  [Bank.SANTANDER, { description: '033 - Banco Santander S/A' }],
  [Bank.NUBANK, { description: '260 - Nu Pagamentos S/A' }],
  [Bank.BRADESCO, { description: '321 - Banco Bradesco S/A' }],
]);

export default Bank;
