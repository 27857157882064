/* eslint-disable max-len */
import * as React from "react"

const IcRefresh = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.3 13.93a1 1 0 0 0-1.25.65 7.14 7.14 0 0 1-6.87 4.92 7.1 7.1 0 0 1-7.18-7 7.1 7.1 0 0 1 7.18-7 7.26 7.26 0 0 1 4.65 1.67l-2.17-.36a1 1 0 0 0-1.137 1.221 1 1 0 0 0 .817.759l4.24.7h.17a1 1 0 0 0 .34-.06.33.33 0 0 0 .1-.06.78.78 0 0 0 .2-.11l.09-.11c0-.05.09-.09.13-.15.04-.06 0-.1.05-.14.028-.058.051-.118.07-.18l.75-4a1.017 1.017 0 1 0-2-.38l-.27 1.45a9.21 9.21 0 0 0-6.03-2.25 9.1 9.1 0 0 0-9.18 9 9.1 9.1 0 0 0 9.18 9A9.12 9.12 0 0 0 21 15.18a1.001 1.001 0 0 0-.7-1.25Z"
      fill="currentColor"
    />
    <title>Atualizar saldo</title>
  </svg>
)

export default IcRefresh
