import { CashInActions } from "../actions";

const defaultState = {
  boleto: null,
  cashInTed: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CashInActions.ACTION_GENERATE_BOLETO:
      return {
        ...state,
        boleto: action.boleto
      };
    case CashInActions.ACTION_GET_DATA_TED:
      return {
        ...state,
        cashInTed: action.cashInTed
      };
    default:
      return state;
  }
};

export function getBoletoData(state) {
  return state.cashIn.boleto;
}

export function getDataTed(state) {
  return state.cashIn.cashInTed;
}
