import React, { useCallback, useMemo } from 'react'
import { I18n } from 'react-redux-i18n';

const RemoveModal = ({ type = "account", onClickCancel, onClickRemove }) => {


  const translate = useCallback((path) => {
    return I18n.t(`panel.walletPage.removeModal.${path}`)
  }, [I18n]);

  const content = useMemo(() => ({
    title: translate(`${[type]}.title`),
    description: translate(`${[type]}.description`)
  }), [type, translate])

  return (
    <div className="remove-modal">
      <h3>{content.title}</h3>
      <p>{content.description}</p>

      <button
        type="button"
        onClick={onClickRemove}
      >
        {translate("button.remove")}
      </button>
      <button
        type="button"
        onClick={onClickCancel}
      >
        {translate("button.cancel")}
      </button>
    </div>
  )
}

export default RemoveModal;