import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as eva from 'eva-icons'
import { ToastContainer } from 'react-toastify';
import { UserSelectors, ConnectedDevicesSelectors, AccountSelectors } from '../../app/store/reducers';
import { UserActions, ConnectedDevicesAction } from '../../app/store/actions';
import DeviceItem from '../../components/configuration/DeviceItem'
import Modal from '../../components/elements/Modal'
import numberHelper from '../../helpers/number'
import EmailModal from '../../components/configuration/modals/EmailModal';
import PhoneModal from '../../components/configuration/modals/PhoneModal';
import AddressModal from '../../components/configuration/modals/AddressModal';
import DeleteAccountModal from '../../components/configuration/modals/DeleteAccountModal';
import routes from '../../data/routes';

const PanelConfiguration = ({ user, connectedDevices, getAllConnectedDevices, balance, history }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState("email");

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const validateDeleteAccountModal = useCallback(() => {
      if(balance && balance.available > 0){
          history.push(routes.PANEL_DELETE_ACCOUNT);
      }
      else {
        openModal("deleteAccount");
      }
  },[])

  const openModal = useCallback((type = "email") => {
    setModalIsOpen(true);
    setModalType(type);
  }, []);

  useEffect(() => {
    getAllConnectedDevices();
  }, [getAllConnectedDevices]);

  useEffect(() => {
    eva.replace();
  }, []);

  return (
    <div className="panel-configuration">
      <h1 className="panel-configuration__title">Configurações</h1>

      {user && (
        <Fragment>
          <section className="panel-configuration__section">
            <h3 className="panel-configuration__section__header__title">
              Meus dados
            </h3>

            <div className="panel-configuration__section__content">
              <div className="panel-configuration__section__content__item">
                <div className="panel-configuration__section__content__item__info">
                  <span>Nome completo</span>
                  <p>{user.name}</p>
                </div>
                <i
                  data-eva="chevron-right-outline"
                  className="hidden"
                />
              </div>

              <hr />

              <button
                type="button"
                className="panel-configuration__section__content__item"
                onClick={() => openModal("email")}
              >
                <div className="panel-configuration__section__content__item__info">
                  <span>E-mail</span>
                  <p>{user.email}</p>
                </div>
                <i data-eva="chevron-right-outline" />
              </button>

              <hr />

              <button
                type="button"
                className="panel-configuration__section__content__item"
                onClick={() => openModal("cellphone")}
              >
                <div className="panel-configuration__section__content__item__info">
                  <span>Celular</span>
                  <p>{numberHelper.cellphoneMask(user.cellphone)}</p>
                </div>
                <i data-eva="chevron-right-outline" />
              </button>
            </div>
          </section>

          <section className="panel-configuration__section">
            <h3 className="panel-configuration__section__header__title">
              Endereço
            </h3>

            <button
              type="button"
              className="panel-configuration__section__address"
              onClick={() => openModal("address")}
            >
              <div>
                <span>CEP {numberHelper.cepMask(user.address.zipCode)}</span>
                <p>
                  {user.address.street}, {user.address.number} - {user.address.district} -
                  {user.address.city}, {user.address.uf}
                </p>
              </div>
              <i data-eva="chevron-right-outline" />
            </button>
          </section>
          <section className="panel-configuration__section">
            <h3 className="panel-configuration__section__header__title">
              Geral
            </h3>

            <button
              type="button"
              className="panel-configuration__section__address"
              onClick={() => validateDeleteAccountModal()}
            >
              <div className='panel-configuration__section__content__item__info'>
                <span style={{color: '#313234'}}>Encerrar conta</span>
              </div>
              <i data-eva="chevron-right-outline" />
            </button>
          </section>  
        </Fragment>
      )}

      <section className="panel-configuration__section">
        <h3 className="panel-configuration__section__header__title">
          Dispositivos conectados
        </h3>

        <div className="panel-configuration__devices">
          {connectedDevices && connectedDevices.map((item) => (
            <DeviceItem
              key={item.id}
              id={item.id}
              computerName={item.computerName}
              browser={item.browser}
              operationSystem={item.operationSystem}
            />
          ))}
        </div>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onClose={closeModal}
      >
        {modalType === "email" && (
          <EmailModal
            cellphone={user && user.cellphone}
            onCancel={closeModal}
            onClose={closeModal}
          />
        )}

        {modalType === "cellphone" && (
          <PhoneModal
            email={user && user.email}
            onCancel={closeModal}
            onClose={closeModal}
          />
        )}

        {modalType === "address" && (
          <AddressModal
            accountId={user && user.accountId}
            onConfirm={closeModal}
            onCancel={closeModal}
            onClose={closeModal}
          />
        )}

        {modalType === "deleteAccount" && (
          <DeleteAccountModal
            onConfirm={closeModal}
            onCancel={closeModal}
            onClose={closeModal}
          />
        )}
      </Modal>

      <ToastContainer />
    </div>
  )
}

const mapStateToProps = state => ({
  user: UserSelectors.getUser(state),
  connectedDevices: ConnectedDevicesSelectors.getDevicesList(state),
  balance: AccountSelectors.getBalance(state),
});

const mapDispatchToProps = dispatch => ({
  
  updateAddress: (address) => dispatch(UserActions.changeAddressUser(address)),
  getAllConnectedDevices: () => dispatch(ConnectedDevicesAction.getAllConnectedDevices()),
  removeDevice: (id) => dispatch(ConnectedDevicesAction.deleteConnectedDevices(id)),
  removeAllDevices: () => dispatch(ConnectedDevicesAction.deleteAllConnectedDevices()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PanelConfiguration);