import React, { Component } from 'react';
import Modal from 'react-modal';
import { I18n } from 'react-redux-i18n';
import ROUTES from '../../data/routes';
import IconInstruction from '../../svg/elements/ic_instruction_ted.svg';
import DepositBanks from '../../components/elements/DepositBanks';
import Santander from '../../svg/banks/ic_santander.svg';
import Bank from '../../data/enumerators/bank';

Modal.setAppElement('#app');

const customStyles = {
  overlay: {
    background: 'rgb(40, 51, 74, 0.5)',
  },

  content: {
    overflow: 'hidden',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    padding: '0',
    margin: 'auto',
    height: '500px',
    width: '500px',
    background: '#fff',
    borderRadius: '5px',
    border: 'none',
    position: 'absolute',
  },
  months: {
    fontWeight: 'bold',
  }
}

const depositBanks = [
  {
    id: Bank.SANTANDER,
    icon: <Santander className="card__company" />,
    description: 'Santander 033',
    route: ROUTES.PANEL_ADD_MONEY_TRANSFER,
  },
]

class PanelCashinBanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoIsOpen: true,
    }
  }

  openInfoModal = () => {
    this.setState({ infoIsOpen: true });
  }
  closeInfoModal = () => {
    this.setState({ infoIsOpen: false });
  }

  render() {
    const { infoIsOpen } = this.state;

    return (
      <React.Fragment>
        <Modal
          isOpen={infoIsOpen}
          style={customStyles}
          onRequestClose={this.closeInfoModal}
          className="mobile-modal mobile-modal__deposit"
        >
          <div className="row">
            <div className="modal__deposit__close col-12 d-flex justify-content-end align-items-center">
              <button
                type="button"
                className="modal__deposit__close__button"
                onClick={this.closeInfoModal}
              >
                X
              </button>
            </div>
          </div>
          <div className="row">
            <div className="modal__deposit__image col-12 d-flex justify-content-center align-items-center">
              <IconInstruction />
            </div>
          </div>
          <div className="row">
            <span className="modal__deposit__title col-12 d-flex justify-content-center align-items-center">
              {I18n.t('cashIn.modalCashIn.modalTitle')}
            </span>
          </div>
          <div className="row">
            <span className="modal__deposit__textOne col-12 d-flex justify-content-center align-items-center">
              {I18n.t('cashIn.modalCashIn.modalDescriptionOne')}
            </span>
          </div>
          <div className="row">
            <span className="modal__deposit__textTwo col-12 d-flex justify-content-center align-items-center">
              {I18n.t('cashIn.modalCashIn.modalDescriptionTwo')}
            </span>
          </div>
          <div className="row">
            <div className="modal__deposit__continue col-12 d-flex justify-content-center align-items-center">
              <button
                type="button"
                className="modal__deposit__continue__button"
                onClick={this.closeInfoModal}
              >
                Continuar
              </button>
            </div>
          </div>
        </Modal>
        <div className="panel__deposit">
          <div className="panel__deposit__container">
            <div className="row">
              <div className="col-12 panel__deposit__title">
                {I18n.t('panel.depositPage.pageTitle')}
              </div>
            </div>
            <div className="row">
              <div className="col-12 panel__deposit__description">
                {I18n.t('panel.depositPage.descriptionBank')}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12 panel__deposit__methods">
                {depositBanks && depositBanks.length > 0 && (
                  depositBanks.map((bank) => (
                    <DepositBanks
                      key={bank.route}
                      icon={bank.icon}
                      description={bank.description}
                      route={bank.route}
                      code={bank.id}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PanelCashinBanks;
