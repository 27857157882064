import { I18n } from 'react-redux-i18n';
import { removeSpaces } from '../../../helpers/card';
import CreditCardRequests from '../../server/credit-card';

import { addLoading, removeLoading } from './loading';

export const ACTION_LIST_CREDIT_CARD = 'LIST_CREDIT_CARD';

export const ACTION_UPDATE_CREDIT_CARD = 'UPDATE_CREDIT_CARD';

export const setCreditList = data => ({
  type: ACTION_LIST_CREDIT_CARD,
  creditCardList: data
});

export const saveCreditCard = (creditCard) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const date = creditCard.expiration.split('/');
    const sixDigits = removeSpaces(creditCard.number.slice(0,7))
    const card = {
      brand: creditCard.brand.toLowerCase(),
      cardholderName: creditCard.holder,
      cardNumber: creditCard.number.replace(/\s/g, ''),
      expirationMonth: date[0],
      expirationYear: date[1],
      securityCode: creditCard.cvv,
      taxIdentifier: creditCard.taxIdentifier,
      sixDigits,
    }

    let response = await CreditCardRequests.saveCreditCard(card);
    const lastFourDigits = creditCard.number.substr(-4);

    creditCard = {
      brand: creditCard.brand,
      cardholderName: creditCard.holder,
      lastFourDigits,
      cardId: response.vaultId,
      token: response.token,
      taxIdentifier: creditCard.taxIdentifier,
      sixDigits,
      tokenFiserv: response.tokenFiserv ? response.tokenFiserv : null,
    }

    await CreditCardRequests.postCreditCard(creditCard);

    await dispatch(listAllCreditCard());
  } catch (err) {
    throw new Error(I18n.t('error.creditCard'));
  } finally {
    dispatch(removeLoading());
  }
};

export const listAllCreditCard = () => async (dispatch) => {
  try {
    dispatch(addLoading());
    const response = await CreditCardRequests.listCreditCard();
    await dispatch(setCreditList(response));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

export const removeCreditCard = (id) => async (dispatch) => {
  try {
    dispatch(addLoading());
    await CreditCardRequests.removeCreditCard(id);
    await dispatch(listAllCreditCard());
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};
