import React, { Fragment } from 'react';
import BlueCheckIcon from '../../svg/ic_blue_check_circle.svg';

const SupportMessageSent = ({ protocolNumber }) => {
  return (
    <Fragment>
      <div className="support-message__sent">
        <BlueCheckIcon />
        <div className="support-message__sent__text">
          Mensagem enviada com sucesso!
        </div>
        <div className="support-message__sent__description">
          Vamos analisar o seu problema e retornar assim que possível.
          O nosso prazo de resposta é de até 5 dias úteis.
        </div>
      </div>
      <div className="support-phone__card support-phone__card--with-shadow">
        <main>
          <div className="support-phone__description">
            Número de protocolo no atendimento:
          </div>
          <div className="support-phone__protocol-number">
            {protocolNumber}
          </div>
        </main>
      </div>
    </Fragment>
  )
}

export default SupportMessageSent;
