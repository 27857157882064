import { v4 as uuidv4 } from 'uuid';

function getDeviceId() {
  if (!localStorage.getItem('diviceId')) {
    localStorage.setItem('deviceId', uuidv4());
  }
  return localStorage.getItem('deviceId');
}

export default getDeviceId;

