import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import * as eva from 'eva-icons';
import ROUTES from '../../../data/routes';
import { UtilsActions } from '../../../app/store/actions';


const FaqFooter = () => {
  useEffect(() => {
    eva.replace({
      width: 22,
      height: 22,
      fill: "#A900E4"
    })
  }, [])

  return (
    <Fragment>
      <div className="faq__footer">
        <div className="faq__footer__title">
          Entre em contato pelos canais:
        </div>
        <div className="faq__footer__list">
          <a
            href={ROUTES.HELP_SUPERBID}
            target="_blank"
            rel="noreferrer"
          >
            <div className="faq__footer__list__single">
              <div className="faq__footer__list__single__icon">
                <i
                  data-eva="question-mark-circle-outline"
                />
              </div>
              <div className="faq__footer__list__single__text">
                Ajuda
              </div>
            </div>
          </a>
          <a
            href={ROUTES.OMBUDSMAN}
            target="_blank"
            rel="noreferrer"
          >
            <div className="faq__footer__list__single">
              <div className="faq__footer__list__single__icon">
                <i
                  data-eva="headphones-outline"
                />
              </div>
              <div className="faq__footer__list__single__text">
                Ouvidoria
              </div>
            </div>
          </a>
        </div>
      </div>
    </Fragment>
  )
}

const mapDispatchToProps = dispatch => ({
  setTitleDoubt: (doubtTitle) => dispatch(UtilsActions.setTitleDoubt(doubtTitle)),
});

export default connect(null, mapDispatchToProps)(FaqFooter);
