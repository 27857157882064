import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SubmitButton from './SubmitButton';
import ROUTES from '../../data/routes';
import Visibility from '../../svg/login/ic_visibility.svg';
import VisibilityOff from '../../svg/login/ic_visibility_off.svg';
import LoginCard from './LoginCard';
import TwoFactorAuthenticate from '../../data/enumerators/TwoFactorAuthenticate';
import { AuthActions } from '../../app/store/actions';
import LogoSbPay from '../../svg/logo/LogoSbPay';

export class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      username: '',
      password: ''
    };
  }

  onEmailChange = (e) => {
    const username = e.target.value;
    this.setState(() => ({ username }));
  };

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => ({ password }));
  };

  formIsValid = () => {
    const { username, password } = this.state;
    if (!username || username.length == 0) { throw new Error(I18n.t('error.emailPasswordEmpty')); }
    if (!password || password.length == 0) { throw new Error(I18n.t('error.emailPasswordEmpty')); }
  }

  login = async (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    try {

      this.formIsValid();

      await this.props.authenticate(username, password, TwoFactorAuthenticate.SMS);
      this.props.history.push(ROUTES.CONFIRM_IDENTITY);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500
      });
    }
  }

  toggleShow() {
    this.setState(prevState => ({ hidden: !prevState.hidden }));
  }

  render() {
    const { hidden, password, username } = this.state;
    return (
      <LoginCard>
        <ToastContainer />
        <div className="row">
          <div className="col-12">
            <div className="login__logo ">
              <LogoSbPay
                width={270}
                height={84}
              />
              {/* <Logo className="icon__login" /> */}
            </div>
          </div>
        </div>
        <div className="row login__container__description">
          <div className="col-12">
            <span className="login__container__description__text">{I18n.t('login.formTitle')}</span>
          </div>
        </div>

        <form
          className="login__container__form justify-content-center"
          onSubmit={this.login}
        >
          <input
            type="text"
            placeholder={I18n.t('login.inputEmail')}
            className="login__container__form__input"
            value={username}
            onChange={this.onEmailChange}
            required
          />
          <div className="login__container__form__password">
            <div className="row">
              <div className="col-12">
                <input
                  placeholder={I18n.t('login.inputPassword')}
                  type={hidden ? 'password' : 'text'}
                  className="login__container__form__password__input"
                  value={password}
                  onChange={this.onPasswordChange}
                  required
                />
                {hidden ? (
                  <Visibility
                    className="login__container__form__ic-visibility"
                    onClick={() => this.toggleShow()}
                  />
                ) :
                  (
                    <VisibilityOff
                      className="login__container__form__ic-visibility"
                      onClick={() => this.toggleShow()}
                    />
                  )}
              </div>
            </div>
          </div>

          <div className="row login__container__form__links">
            <div className="col-6 d-flex justify-content-start align-items-center pl-0">
              <a href={ROUTES.FORGOT_PASSWORD}>{I18n.t('login.rememberPassword')}</a>
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center pr-0">
              <a href={ROUTES.CREATE_ACCOUNT}>{I18n.t('login.creatAccount')}</a>
            </div>
          </div>
          <SubmitButton
            name={I18n.t('login.buttonText')}
          />
        </form>
      </LoginCard>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.authenticated,
})

const mapDispatchToProps = dispatch => ({
  authenticate: (email, password, secondFactorType) =>
    dispatch(AuthActions.authenticate(email, password, secondFactorType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LoginForm));
