import React, { Component } from 'react';
import CreateAccountBanner from '../../components/institutional/CreateAccountBanner';
import AdvantagesSection from '../../components/institutional/home/AdvantagesSection';
import UtilizationSection from '../../components/institutional/home/UtilizationSection';

class InstitutionalHome extends Component {
  componentDidMount = () => {
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <main className="main">
        <CreateAccountBanner />
        <AdvantagesSection />
        <UtilizationSection />
      </main>
    )
  }
}

export default InstitutionalHome;
