import { BankTransferActions } from "../actions";

const defaultState = {
  bankTransferList: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case BankTransferActions.ACTION_LIST_BANK_TRANSFER:
      return {
        ...state,
        bankTransferList: action.bankTransferList,
      };
    default:
      return state;
  }
};

export function getBankTransferList(state) {
  return state.bankTransfer.bankTransferList;
}
