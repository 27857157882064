import * as AuthSelectors from './auth';
import * as UserSelectors from './user';
import * as AccountSelectors from './account';
import * as LoadingSelectors from './loading';
import * as CashInSelectors from './cashin';
import * as UtilsSelectors from './utils';
import * as BankTransferSelectors from './bank-transfer';
import * as PaymentSelectors from './paymentOrder';
import * as CreditCardSelectors from './credit-card';
import * as ConnectedDevicesSelectors from './connected-devices';
import * as SettingsSelector from './settings'
import * as CreditOfferSelector from './credit-data';
import * as UserInformIncomeSelectors from './inform-income';

export {
  AuthSelectors,
  UtilsSelectors,
  UserSelectors,
  AccountSelectors,
  LoadingSelectors,
  CashInSelectors,
  BankTransferSelectors,
  PaymentSelectors,
  CreditCardSelectors,
  ConnectedDevicesSelectors,
  SettingsSelector,
  CreditOfferSelector,
  UserInformIncomeSelectors
};
