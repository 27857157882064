/* eslint-disable max-len */
import * as React from "react"

const IcTransfer = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 14.612H2v1.146h4v-1.146Z"
      fill="currentColor"
    />
    <path
      d="M14 28.943a.462.462 0 0 1-.311-.124l-9.5-8.598a.627.627 0 0 1 0-.9l9.5-8.599a.45.45 0 0 1 .527-.066.588.588 0 0 1 .282.516v3.439h3.5a.467.467 0 0 1 .353.167.616.616 0 0 1 .147.405v3.44l8.666-7.452-8.666-7.452v3.439a.54.54 0 0 1-.5.573H5.5V6.585h12V2.572a.587.587 0 0 1 .277-.513.447.447 0 0 1 .521.055l10 8.599a.627.627 0 0 1 0 .917l-10 8.598a.448.448 0 0 1-.521.054.587.587 0 0 1-.277-.513v-4.011H14a.47.47 0 0 1-.354-.168.62.62 0 0 1-.146-.405v-2.834l-8.193 7.415 8.194 7.416v-3.403a.54.54 0 0 1 .5-.573h11.5v1.146h-11v4.012a.586.586 0 0 1-.282.515.444.444 0 0 1-.219.064Z"
      fill="currentColor"
    />
    <path
      d="M29.5 15.185H26v1.147h3.5v-1.147ZM10.5 10.026H0v1.146h10.5v-1.146ZM32 20.344H22v1.147h10v-1.147Z"
      fill="currentColor"
    />
  </svg>
)

export default IcTransfer
