import { addLoading, removeLoading } from './loading';
import UserRequests from '../../server/user';
import * as AuthActions from './auth';

export const ACTION_SAVE_USER = 'SAVE_USER';
export const ACTION_CLEAN_USER = 'CLEAN_USER';
export const ACTION_UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const ACTION_UPDATE_PHONE = 'UPDATE_PHONE';
export const ACTION_UPDATE_EMAIL = 'UPDATE_EMAIL';
export const ACTION_UPDATE_DOCUMENT_USER = 'UPDATE_DOCUMENT_USER';

export const saveUser = user => ({
  type: ACTION_SAVE_USER,
  user,
});

export const cleanUser = () => ({
  type: ACTION_CLEAN_USER,
  user: null,
});

export const updateAddress = address => ({
  type: ACTION_UPDATE_ADDRESS,
  address,
});

export const updatePhone = user => ({
  type: ACTION_UPDATE_PHONE,
  user,
});

export const updateEmail = user => ({
  type: ACTION_UPDATE_EMAIL,
  user,
});

export const updateDocumentUser = user => ({
  type: ACTION_UPDATE_DOCUMENT_USER,
  user,
});


export const getUser = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const user = await UserRequests.getUser();
    dispatch(saveUser(user));
  } catch (err) {
    dispatch(AuthActions.cleanAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const changeAddressUser = (address) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await UserRequests.updateAddressUser(address);
    dispatch(updateAddress(address));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

export const changePhoneNumber = (phone) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await UserRequests.updateCellphoneUser(phone);
    const cellphone = { cellphone: phone.ddd + phone.phone };
    dispatch(updatePhone(cellphone));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

export const changeEmail = (emailUser) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await UserRequests.updateEmailUser(emailUser);
    const email = { email: emailUser.email };
    dispatch(updatePhone(email));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

export const uploadFile = (file) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const formData = new FormData();
    formData.append('file', file)
    await UserRequests.updateFileUser(formData);
    const documentValidation = { verifiedStatus: 2 };
    dispatch(updateDocumentUser(documentValidation));
  } catch (err) {
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};

// export const updateUser = user => async (dispatch) => {
//   dispatch(addLoading());

//   try {
//     await UserRequests.upd
//   } catch (error) {
//     throw error;
//   } finally {
//     dispatch(removeLoading());
//   }

// }
