import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { AuthActions } from '../app/store/actions';

class ExternalAuth extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      redirectUrl: '',
    }
  }

  componentDidMount = async () => {
    try {
      const { location, authenticateSDK } = this.props;
      let params = queryString.parse(location.search);
      await authenticateSDK(params.sessionToken);
      let redirectUrl = params.redirectUrl;
      this.setState({ redirectUrl });
    } catch (err) {
      this.setState({ redirectUrl: '/Login' });
    }
  }

  returnUrl = () => {
    if (this.state.redirectUrl) {
      return <Redirect to={this.state.redirectUrl} />;
    }
  }

  render() {
    return (
      <div>
        {this.returnUrl()}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  authenticateSDK: (id) =>
    dispatch(AuthActions.authenticateSDK(id))
});

export default connect(null, mapDispatchToProps)(withRouter(ExternalAuth));
