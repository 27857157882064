import { ConnectedDevicesAction } from "../actions";

const defaultState = {
  connectedDeviceList: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case ConnectedDevicesAction.ACTION_LIST_CONNECTED_DEVICES:
      return {
        ...state,
        connectedDeviceList: action.connectedDeviceList,
      };
    default:
      return state;
  }
};

export function getDevicesList(state) {
  return state.connectedDevices.connectedDeviceList;
}
