import React from 'react';
import Select, { components } from 'react-select';
import IconArrowDown from '../../svg/ic_arrow_drop_down.svg';

/* DropdownIndicator that uses the material icon IconArrowDropDown */
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconArrowDown />
    </components.DropdownIndicator>
  )
}

/* Dropdown Props
  - items: array of objects to be displayed
  - label: label to be displayed on the left side of the dropdown
  - placeholder: string -> dropdown placeholder, if user does not provide one, items[0] is selected
  - defaultValue: boolean -> selects the first element of the array, OVERRIDES PLACEHOLDER
  - isSearchable: boolean -> enables search
  - isLoading: boolean -> shows a loading spinner if true
  - itemValue: string -> property name mapped to VALUE
  - itemLabel: string -> property name mapped to LABEL
  - handleChange: function -> triggered when the select value changes
  - selectedValue: current selected value, (state from parent to be updated)
  - secondaryColor: boolean -> if true, the select value has the secondary color, otherwise it's black
  - noMaxHeight: boolean -> if true, the dropdown container does not have a max height for the options (no scroll)
  - labelHidden: boolean -> if true, hides label
  - mobileHidden: boolean -> if true, hides on mobile by adding the mobile-hidden class
*/

const Dropdown = ({
  items,
  label,
  placeholder,
  isLoading,
  isSearchable,
  defaultValue,
  itemValue,
  itemLabel,
  handleChange,
  selectedValue,
  secondaryColor,
  mobileHidden,
  noMaxHeight,
  menuIsOpen,
  onClick }) => {

  /* construct the array of objects = {value: string, label: string } 
     if an element does not contain of the listed properties (itemValue || itemLabel), the object is removed
     from the displayed options to remove inconsistencies and error when selecting */
  const mapProperties = (items) => {
    const cleanArray = items.filter((item) =>
      (Object.keys(item).includes(itemValue) && Object.keys(item).includes(itemLabel)));
    return cleanArray.map((item) => ({ value: item[`${itemValue}`], label: item[`${itemLabel}`] }));
  }

  const parsedItems = mapProperties(items);

  /* react-select component uses emotion to further style it with ease
     to use the provided styles and add more, put provided */
  const customStyles = {
    container: (provided) => ({
      ...provided,
      padding: '10px 10px 5px 10px',
    }),
    control: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      borderRadius: '22px',
      boxShadow: '0px 8px 12px #0000001A',
      border: state.isSelected ? 'rgb(0, 0, 0, 0)' : 'rgb(0, 0, 0, 0)',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      fill: '#BE00FF',
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: '10px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: secondaryColor ? '#9300C5' : '#000',
      paddingLeft: '10px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '5px',
      borderColor: 'rgb(0, 0, 0, 0)',
      boxShadow: '0px 8px 12px #0000001A',
    }),
    menuList: (provided) => ({
      ...provided,
      color: '#28334A',
      fontWeight: 'bold',
      maxHeight: noMaxHeight ? 'auto' : '200px',
      borderRadius: '5px',
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid rgb(228, 228, 228, 0.78)',
      color: state.isSelected ? '#9300C5' : '#28334A',
      background: state.isSelected ? '#fff' : '#fff',
      cursor: 'pointer',
      "&:last-of-type": {
        borderBottom: '0',
      },
    }),
  }

  return (
    <React.Fragment>
      <div className={`dropdown ${mobileHidden ? 'mobile-hidden' : ''}`}>
        {label && (
          <div className="dropdown__label">{label}:</div>
        )}
        <div className="dropdown__select">
          <Select
            styles={customStyles}
            defaultValue={(defaultValue || !placeholder) && parsedItems[0]}
            isLoading={isLoading}
            isSearchable={isSearchable}
            placeholder={placeholder}
            options={parsedItems}
            components={{ DropdownIndicator }}
            value={selectedValue}
            onChange={(selectedValue) => handleChange(selectedValue)}
            menuIsOpen={menuIsOpen}
            openMenuOnClick={menuIsOpen}
            onClick={onClick}
          />
        </div>
      </div>
    </React.Fragment>

  );
}

export default Dropdown;
