import React, { useCallback } from "react";
import Button from "../elements/Button";
import EmptyAccount from "../wallet/EmptyAccount";
import SelectAccountCard from "./SelectAccountCard";

const AccountListSelect = (
  { accounts = [],
    selectedAccount,
    setAccount,
    onClickAddAccount,
    onSelectAccount,
    onClickBack
  }) => {
  const handleSelectAccount = useCallback((account) => {
    setAccount(account)
  }, []);

  return (
    <div className="account-list">
      <div className="account-list__content">
        {accounts.map(account => (
          <SelectAccountCard
            key={account.id}
            onSelect={() => handleSelectAccount(account)}
            checked={selectedAccount.id === account.id}
            bankName={account.bankName}
            name={account.name}
            agency={account.branchDestination}
            account={account.accountDestination}
            accountType={Number(account.accountTypeDestination)}
          />
        ))}
        {!accounts.length && (
          <EmptyAccount />
        )}
      </div>

      <div
        className="account-list__add"
        onClick={onClickAddAccount}
        aria-hidden
      >
        <div className="account-list__add__icon">
          <i className="fa fa-plus" />
        </div>

        <p
          type="button"
        >
          Cadastrar nova conta bancária
        </p>
      </div>

      <div className="account-list__footer">
        <Button
          variant="secondary"
          onClick={onClickBack}
        >
          Voltar
        </Button>
        <Button
          onClick={onSelectAccount}
        >
          Selecionar conta
        </Button>
      </div>
    </div>
  )
}

export default AccountListSelect;