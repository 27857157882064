/* eslint-disable max-len */
import * as React from "react"

const IcArrowFoward = ({ size = 24, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.04171 9.70836H12.4425L9.87129 12.7967C9.81176 12.8683 9.76692 12.951 9.73932 13.0399C9.71173 13.1289 9.70193 13.2224 9.71048 13.3151C9.72775 13.5024 9.81872 13.6752 9.96337 13.7954C10.108 13.9157 10.2945 13.9735 10.4818 13.9562C10.6691 13.939 10.8419 13.848 10.9621 13.7034L14.5038 9.45336C14.5276 9.41955 14.5489 9.38404 14.5675 9.34711C14.5675 9.31169 14.603 9.29044 14.6171 9.25502C14.6492 9.17381 14.666 9.08735 14.6667 9.00002C14.666 8.91269 14.6492 8.82624 14.6171 8.74502C14.6171 8.70961 14.5817 8.68836 14.5675 8.65294C14.5489 8.61601 14.5276 8.58049 14.5038 8.54669L10.9621 4.29669C10.8955 4.21673 10.8121 4.15243 10.7179 4.10835C10.6236 4.06428 10.5208 4.04152 10.4167 4.04169C10.2512 4.04137 10.0908 4.09901 9.96337 4.20461C9.89165 4.26407 9.83236 4.3371 9.7889 4.41951C9.74544 4.50192 9.71867 4.5921 9.71012 4.68488C9.70156 4.77765 9.7114 4.8712 9.73905 4.96017C9.76671 5.04914 9.81165 5.13178 9.87129 5.20335L12.4425 8.29169H4.04171C3.85385 8.29169 3.67368 8.36632 3.54084 8.49915C3.408 8.63199 3.33337 8.81216 3.33337 9.00002C3.33337 9.18788 3.408 9.36805 3.54084 9.50089C3.67368 9.63373 3.85385 9.70836 4.04171 9.70836Z"
        fill="currentColor"
      />
      <title>Transferido</title>
    </svg>
  )
}
export default IcArrowFoward;
