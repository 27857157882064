import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../../data/routes';

const OuvidoriaStepOne = ({ currentStep, nextStep }) => {
  return (
    <main>
      {currentStep === 1 && (
        <Fragment>
          <div className="ouvidoria__wrapper">
            <div className="ouvidoria__text">
              Acesse a Ouvidoria somente se você realizou uma consulta anterior e não obteve a resposta dentro
              do prazo ou se a resposta não foi satisfatória. Para realizar uma consulta,
              acesse a nossa seção <Link to={ROUTES.PANEL_FAQ}>Ajuda</Link>.
              <br />
              <br />
              O nosso prazo de resposta na Ouvidoria é de até
              <br />
              <strong>5 dias úteis.</strong>
            </div>
            <button
              type="button"
              className="support-email__button"
              onClick={() => { nextStep() }}
            >Acessar ouvidoria
            </button>
          </div>
        </Fragment>
      )}
    </main>
  )
}

export default OuvidoriaStepOne;
