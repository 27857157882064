/* eslint-disable no-useless-escape */
import { I18n } from 'react-redux-i18n';

const keepOnlyNumber = value => value.replace(/\D/g, '');

const countDecimals = (value) => {
  if (Math.floor(value) === value) return 0;
  return value ? value.toString().split('.')[1].length : 0;
};

function cepMask(cep) {
  const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

  if (re.test(cep)) {
    return cep.replace(re, '$1.$2-$3');
  }

  return I18n.t('invalidCep');
}

const validateCep = cep => {
  return /^[0-9]{5}-[0-9]{3}$/.test(cep);
}

const boletoMask = (boleto) => {
  var boletoNumberRegex = /^([\d]{5})([\d]{5})([\d]{5})([\d]{6})([\d]{5})([\d]{6})([\d])([\d]{14})/;
  return boleto.replace(boletoNumberRegex, '$1.$2 $3.$4 $5.$6 $7 $8');
}

const cpfMask = (cpf = '') => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

const cnpjMask = (cnpj = '') => {
  return cnpj.replace(/(^\d{2})(\d{3})(\d{3})(\d{1,4})(\d{2}$)/, "$1.$2.$3/$4-$5");
}

const cellphoneMask = (celphone = '') => {
  celphone = celphone.replace(/\D/g, '');
  celphone = celphone.replace(/(\d{2})(\d)/, "($1) $2");
  celphone = celphone.replace(/(\d)(\d{4})$/, "$1-$2");

  return celphone
}

const documentMask = (document = '') => {
  document = keepOnlyNumber(document);

  if (document.length <= 11) {
    document = cpfMask(document);
  } else {
    document = cpfMask(document);
  }

  return document;
}

export default {
  keepOnlyNumber,
  countDecimals,
  cepMask,
  cpfMask,
  boletoMask,
  cnpjMask,
  cellphoneMask,
  validateCep,
  documentMask
};
