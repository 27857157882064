/* eslint-disable react/no-danger */
import React, { Fragment } from 'react';

const FaqQuestion = ({ selectedQuestion }) => {
  return (
    <Fragment>
      {selectedQuestion && (
        <div className="faq__topic__answer">
          <div className="faq__topic__answer__title">
            {selectedQuestion.title}
          </div>
          <div className="faq__topic__answer__description">
            <div dangerouslySetInnerHTML={{ __html: selectedQuestion.description }} />
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default FaqQuestion;
