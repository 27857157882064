import React, { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import Flex from "../elements/Flex"
import Icon from "../elements/Icon";
import Typography from "../elements/Typography";

const UploadButton = styled(Flex)`
  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.brand.primary};
    
    svg, p {
      color: ${({ theme }) => theme.colors.brand.primary};
    }
  }
`;

const ReportOmbudsman = () => {
  const theme = useTheme();

  const reports = useMemo(() => ([
    {
      title: "1º Semestre/2023",
      file: "/assets/reports/RelatorioOuvidoria_2023.1.pdf",
      fileName: "RelatorioOuvidoria_2023.1.pdf"
    },
    {
      title: "2º Semestre/2023",
      file: "/assets/reports/RelatorioOuvidoria_2023.2.pptx",
      fileName: "RelatorioOuvidoria_2023.2.pptx"
    },
    {
      title: "1º Semestre/2024",
      file: "/assets/reports/RelatorioOuvidoria_2024.1.pdf",
      fileName: "RelatorioOuvidoria_2024.1.pdf"
    },
  ]), []);

  return (
    <Flex
      width="100%"
      flexDirection="column"
      alignItems="center"
    >
      <Typography
        mt={3}
        variant="title"
        fontSize="title.xxl"
        fontWeight="semibold"
      >
        Relatórios da ouvidoria
      </Typography>

      <Flex
        width="100%"
        alignSelf="center"
        maxWidth={{
          default: "300px",
          sm: "600px"
        }}
        mt={2}
        flexDirection="column"
        gap="16px"
      >
        {reports.map((report, index) => (
          <a
            key={index}
            href={report.file}
            download={report.fileName}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", width: "100%" }}
          >
            <UploadButton
              alignItems="center"
              border="1px solid"
              borderColor="text.lightest"
              borderRadius="xs"
              px={2}
              py={0}
            >
              <Icon
                icon="download-outline"
                size={20}
                color={theme.colors.brand.primary}
              />

              <Typography
                color="text.dark"
                fontSize="title.sm"
                lineHeight="title.sm"
                fontWeight="semibold"
                ml={0}
              >
                Download – Relatório da Ouvidoria – {report.title}
              </Typography>
            </UploadButton>
          </a>
        ))}
      </Flex>
    </Flex>
  )
}

export default ReportOmbudsman;