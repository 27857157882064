const parseMethods = (method) => {
  const methods = {
    'BOLETO': () => 'Boleto',
    'SALDO': () => 'Saldo',
    'CARTAO_CREDITO': () => 'Cartão de crédito',
    'BOLETO_PARCELADO': () => 'Boleto parcelado',
    'DEPOSITO': () => 'Depósito',
    'default': () => 'Inválido',
  };
  return (methods[method] || methods['default'])();
}

export const humanizePaymentMethods = (methods) => (methods.split(',').map((m) => (m = parseMethods(m)))).join(', ');

export default humanizePaymentMethods;

