import axios from 'axios';
import moment from 'moment-timezone';
import jstz from 'jstimezonedetect';
import * as Yup from 'yup';

import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import i18nDictionary from '../i18n';

import PreferencesData from '../data/preferences';

import {
  AuthActions,
  UserActions
} from './store/actions';
import { isValidCardExpiration, isValidCardCVV, isValidCardNumber } from '../helpers/card'
import pageTitleService from './services/pageTitle';
import AuthServices from './services/auth';

export const axiosInstance = axios.create();

function addAxiosinterceptor(store) {
  axiosInstance.interceptors.response.use(response => response, async (err) => {
    if (err.response.status == 401 && !(err.response.config.url.includes('card'))) {
      store.dispatch(AuthActions.cleanAuth());
    }
    return Promise.reject(err);
  });
}

async function authenticated(store) {
  if (AuthServices.isAuthenticated()) {
    const auth = AuthServices.get();
    await store.dispatch(AuthActions.saveAuthentication(auth));
    await store.dispatch(UserActions.getUser());

  }
}

export default async (store) => {
  addAxiosinterceptor(store);
  try {
    const tz = jstz.determine();
    const timezone = tz.name() || PreferencesData.timezone;
    let language = PreferencesData.language;
    if (!i18nDictionary[language]) ({ language } = PreferencesData);

    // initialize react-redux-i18n
    syncTranslationWithStore(store);
    store.dispatch(loadTranslations(i18nDictionary));
    store.dispatch(setLocale(language));

    moment.tz.setDefault(timezone);
    moment.locale(language);

    Yup.addMethod(Yup.string, 'isValidCardExpiration', isValidCardExpiration);
    Yup.addMethod(Yup.string, 'isValidCardCVV', isValidCardCVV);
    Yup.addMethod(Yup.string, 'isValidCardNumber', isValidCardNumber);

    pageTitleService.setTitle(`Superbid Pay`);

    authenticated(store);
  } catch (err) {
    // Redirect to error page
  } finally {
    const launchLoader = document.getElementById('launch');
    launchLoader.classList.add('hide');
  }
};
