import React from 'react';
import { Helmet } from 'react-helmet';
import { I18n } from 'react-redux-i18n';
import LoginForm from '../components/login/LoginForm';
import LoginFooter from '../components/login/LoginFooter';

const LoginPage = () => (
  <main className="login">
    <Helmet>
      <title>{I18n.t('login.pageTitle')} - Superbid Pay</title>
    </Helmet>
    <LoginForm />
    <LoginFooter />
  </main>
);

export default LoginPage;
