import React from 'react';
import { Link } from 'react-router-dom';
import KeyboardArrowRight from '../../svg/operations/ic_keyboard_arrow_right.svg';

// eslint-disable-next-line no-unused-vars
const DepositBanks = ({code, icon, description, route }) => {
  return (
    <Link
      to={`${route}${code}`}
      className="link-no-style"
    >
      <div className="deposit__wrapper">
        <div className="row">
          <div className="col-3  d-flex align-items-center pr-0">
            {icon}
          </div>
          <div className="col-7 d-flex align-items-center pl-0">
            <div className="deposit__description">
              {description}
            </div>
          </div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            <div className="deposit__arrow">
              <KeyboardArrowRight onClick={() => { }} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default DepositBanks;
