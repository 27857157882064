/* eslint-disable max-len */
export default {
  // application definitions
  application: {
    projectName: 'Superbid Pay',
  },
  faq: {
    description: 'Ficamos felizes em responder a sua dúvida.',

  },
  mobile: {
    filter: 'filtrar por',
    filterSearch: 'buscar',
  },

  toastMessage: {
    copy: 'Copiado para área de transferência',
    insuficientbalance: 'Confirme seu saldo e dados da conta para concluir essa operação',
    changeEmail: 'E-mail alterado com sucesso!',
    changeTelefone: 'Telefone alterado com sucesso!',
    changeAddress: 'Endereço alterado com sucesso!',
    saveCreditCard: 'Cartão de crédito salvo com sucesso!',
    validationExclusionError: 'Contas em processo de validação não podem ser excluídas da carteira.',
    invalidCashout: 'Realize a solicitação de transferência no período entre 10h e 15h. Temporariamente as transações serão processadas nesse período.'
  },
  hourWarningModal: {
    modalTitle: 'Agendamento',
    modalTextOnePt1: 'Para enviar saldo para a sua conta bancária, é necessário solicitar a retirada até às ',
    modalTextOnePt2: ' do mesmo dia útil.',
    modalTextTwo: 'Caso queira prosseguir com a solicitação, você fará um agendamento para o próximo dia útil.'
  },
  error: {
    userInvalid: 'Usuário ou senha inválida.',
    loginUnexpectedError: 'Ocorreu um erro inesperado. Tente novamente mais tarde',
    codeInvalid: 'Código de confirmação inválido. Digite novamente.',
    codeExpired: 'Código vencido. Solicite um novo código.',
    codeEmpty: 'Preencha código de confirmação.',
    emailPasswordEmpty: 'Preencha o campo de e-mail e senha.',
    generateBoleto: 'Falha ao gerar boleto.',
    addressChange: 'Falha ao alterar endereço.',
    creditCard: 'Cartão de crédito inválido.',
    requiredFields: 'Preencha os todos os campos.',
    invalidExpiration: 'Data de expiração inválida.',
    invalidDocument: 'CPF inválido.',
    invalidFields: 'Por favor preencha os campos com valores válidos.',
    loginManyRequestError: 'Login temporariamente bloqueado, tente novamente em alguns minutos',
    notAccountCpf: "CPF deve ser o mesmo que do titular da conta"
  },

  schemaValidation: {
    mixed: {
      required: "Este campo é necessário"
    }
  },

  public: {
    extractDetail: 'Extrato',
    reportOmbudsman: 'Relatórios da ouvidoria',
  },

  // page not found
  pageNotFound: {
    pageTitle: '404 - Página não encontrada',
    title: 'Página não encontrada',
    description: 'Parece que a página que você está tentando acessar não existe ou foi removida.',
    descriptionTwo: 'Verifique a URL na barra de endereços do navegador e tente novamente.',
  },

  // access denied
  accessDenied: {
    pageTitle: '403 - Acesso negado',
    title: 'Acesso negado',
    description: 'Parece que você não pode acessar esta página.',
    descriptionTwo: 'Verifique se você digitou o endereço corretamente na barra de endereços ou procure um administrador.',
  },
  paymentDescription: {
    commision_auctioner: 'COMISSÃO DO LEILOEIRO',
    administrative_rate: 'ENCARGOS DE ADMINISTRAÇÃO',
    fee_platform: 'FEE PLATAFORMA',
    buyers_premium: 'BUYERS PREMIUM'
  },

  // login
  login: {
    pageTitle: 'Entrar',
    formTitle: 'Acessar minha conta',
    rememberPassword: 'Esqueci a senha',
    creatAccount: 'Criar conta',
    inputEmail: 'Usuário',
    inputPassword: 'Senha',
    buttonText: 'Avançar',
    help: 'Ajuda',
    terms: 'Termos & Políticas',
    about: 'Sobre',
    ombudsman: "Ouvidoria",
    reportOmbudsman: "Relatórios da ouvidoria",
  },

  //intitucional
  institucional: {

    aboutUs: 'Quem somos',

    advantagesSection: {
      benefits: 'Benefícios',
      contentWallet: 'Múltiplas formas de pagar suas compras no Superbid Exchange.',
      contentCellphone: 'Nova forma de acompanhar a sua agenda de pagamentos e administrar o seu dinheiro com autonomia, segurança e praticidade.',
      contentWalletSecurity: 'O seu dinheiro depositado em sua conta digital e transferido para sua conta bancária quando quiser.',
    },

    utilizationSection: {
      title: 'Como utilizar',
      stepOne: 'Crie sua conta no Superbid Exchange e ganhe uma conta grátis na Superbid Pay.',
      stepTwo: 'Adicione dinheiro e utilize o saldo para pagar suas compras.',
      stepThree: 'Receba o pagamento de suas vendas e transfira o dinheiro para sua conta bancária.',
    },

    pillarSection: {
      title: 'Nossos pilares',
      titleSecurity: 'Segurança',
      descriptionSecurity: 'Seu dinheiro guardado em contas de pagamento, protegido e livre de fraudes.',
      practicality: 'Praticidade',
      descriptionPracticality: 'Plataforma completa, com soluções simples e inteligentes para pagar e receber.',
      transparency: 'Transparência',
      descriptionTransparency: 'Serviços eficientes e de qualidade, sem burocracia e cobranças abusivas.',
    },

    header: {
      signUp: 'Cadastre-se',
      login: 'Login'
    },

    terms: {
      title: 'Veja também',
    },

    footer: {
      help: 'Ajuda',
      terms: 'Termos & Políticas',
      ombudsman: 'Ouvidoria',
      about: 'Sobre',
      content: 'Faça o seu cadastro no Superbid Exchange e comece a usar a sua conta digital.',
      signUp: 'Cadastrar',
      rightsReserved: 'Todos os direitos reservados',
      disclaimerOmbudsman: 'Já falou conosco e não conseguiu resolver o que precisava? Fale com nossa ouvidoria através do 0800 800 0044 Atendemos em dias úteis das 09h às 17h.'
    },
  },

  // validation
  validation: {
    pageTitle: 'Confirmar identidade',
    cardTitle: 'Confirme sua identidade',
    cardDescription: 'Digite o código que você recebeu no',
    inputTitle: 'Código',
    smallText: 'A mensagem pode demorar alguns segundos para chegar',
    buttonText: 'Validar Código',
    resubmit: 'Reenviar código',
    changeSendType: 'Enviar pelo',
    cell: 'celular',
    email: 'e-mail',
    number: 'número',
    bankAccount: {
      modalTitles: {
        failed: 'Validação falhou...',
        waiting: 'Conta cadastrada!'
      },
      description: {
        title: 'Descrição: '
      },
      modalMessages: {
        failed: 'A conta cadastrada não pôde ser validada. Certifique-se de que os dados de titularidade estão corretos.',
        waiting: 'A conta cadastrada está sendo validada junto ao banco informado.\nO processo pode levar até 1 dia útil para finalização.'
      },
      mainMessages: {
        failed: 'Conta inválida.',
        waiting: 'Aguardando validação da conta',
        valid: 'Conta validada'
      },
      tipMessages: {
        waiting: 'A conta cadastrada está sendo validada junto ao banco de destino. O processo de validação pode levar até 1 dia útil.'
      }
    }
  },

  // home
  home: {
    pageTitle: 'Bem vindo(a)',
  },

  credit: {
    pageTitle: 'Meus empréstimos',
    disclaimer_1: 'O art. 49 do CDC prevê que a pessoa consumidora tem sete dias para desistir da contratação de serviços realizada fora do estabelecimento físico. O prazo é contado a partir da assinatura do contrato ou recebimento do serviço.',
    disclaimer_2: '• A Superbid pay não é instituição financeira e atua exclusivamente como parceira  e não tem responsabilidade sobre as regras de análise, concesssão de crédito, taxas e juros aplicados pela empresa parceira.',
    disclaimer_3: '• A contratação do financiamento está sujeita à análise e aprovação de crédito. A taxa de juros, o valor das parcelas e as outras condições do financiamento variam de acordo com o perfil do cliente, do prazo, valor de entrada e data de pagamento da primeira parcela.',
    disclaimer_4: '• A realização da simulação do financiamento é condicionada ao contato do gerente de relacionamento da instituição parceira',
    cancel: 'Cancelar contratação',
    approved_value: 'Valor aprovado:',
    installments: 'Parcelas:',
    status: 'Status:',
  },
  // wallet page
  walletPage: {
    pageTitle: 'Carteira',

    accountItem: {
      removeButton: 'remover',
      agency: 'Agência',
      currentAccount: 'Conta',
    },

    removeAccountModal: {
      title: 'Remover conta',
      titleCard: 'Remover método de pagamento',
      subtitleCard: 'Tem certeza que deseja remover este cartão?',
      subtitleBank: 'Tem certeza que deseja remover esta conta?',
      statusError: 'Contas em processo de validação não podem ser excluídas da carteira.',
      buttonCancel: 'Cancelar',
      buttonConfirm: 'Sim',
    },

    buttonName: 'Ver outras instituições',


    accountList: {
      empty: 'Cadastre sua conta bancária e transfira o seu dinheiro com apenas um clique.',
    },

    cardItem: {
      removeButton: 'remover',
    },

    cardList: {
      empty: 'Cadastre um cartão de crédito e facilite seus pagamentos.',
    },

    addAccountModal: {
      title: 'Adicione uma conta bancária:',
      name: 'Nome',
      account: 'Conta',
      bank: 'Instituição',
      agency: 'Agência',
      type: 'Tipo de conta',
      cancelButton: 'Cancelar',
      confirmButton: 'confirmar',
      document: 'CPF',
      main: 'Instituição'
    },

    addCardModal: {
      title: 'Adicione um cartão:',
      holderDefault: 'NOME DO TITULAR',
      numberPlaceholder: 'Número do cartão',
      holderPlaceholder: 'Nome do titular',
      expirationPlaceholder: 'Vencimento',
      cvvPlaceholder: 'CVV',
      documentPlaceholder: 'CPF do titular',
      cancelButton: 'cancelar',
      confirmButton: 'Confirmar',
    },

    animatedCard: {
      expires: 'VALIDADE:',
      cvv: 'CVV',
    },
  },

  cashoutPage: {
    title: 'Transferir saldo',
    description: 'Selecione o tipo de transferência que você deseja fazer hoje',
    cashoutQuestion: 'Quanto você deseja retirar?',
    nowBalance: 'Seu saldo atual é',
    backButton: 'Voltar',
    totalValue: 'Valor',
    fee: 'Tarifa de retirada ao favorecido',
    amount: 'Valor creditado ao favorecido:',
    bank: 'Banco',
    agency: 'Agência',
    account: 'Conta',
    type: 'Tipo',
    name: 'Nome do favorecido',
    cpf: 'Cpf/Cnpj',
    changeAccount: 'Alterar',
    changeValue: 'Alterar valor',
    confirm: 'Confirmar transferência',
    continue: 'Continuar',
    addAccount: 'Cadastrar conta',
    note: {
      title: "Observação",
      description1: "Transferências realizadas após as",
      description2: "(horário de Brasília), serão agendadas para o próximo dia útil"
    },
    receipt: {
      title: "Detalhes da Operação",
      operationTime: {
        now: {
          one: "Operação realizada em",
          two: "ás"
        },
        schedule: "Operação agendada para",
        scheduleWhenAccountPending: "Estamos validando os dados da conta conta bancária que você acabou de cadastrar. Em breve seu saldo será reservado e sua transferência será processada durante o expediente bancário, até as 17hrs.",
        scheduleWhenAccountTwo: "Reservamos seu saldo e sua solicitação será processada durante o expediente bancário, até as 17hrs.",
        scheduleWhenAccountThree: "Estamos processando sua solicitação. Em breve seu saldo será reservado e sua transferência será processada durante o expediente bancário, até as 17hrs.",
        scheduleWhenAccountFour: "Estamos processando sua solicitação. Em breve seu saldo será reservado e sua transferência será processada durante o expediente bancário do próximo dia útil, até as 17hrs."
      },
      nowTitle: "Transferência realizada",
      scheduleTitle: "Transferência agendada",
      value: "Valor da retirada",
      from: "De:",
      scheduleTo: "Agendado para:",
      operationNumber: "Número da operação",
      type: "Tipo de operação:",
      fee: "Tarifa",
      backToHome: "Voltar para home"
    },
    cashoutLimitTimeModal: {
      title: "Confirmação de agendamento",
      text1: "A partir das",
      text2: "todas as transferências são agendadas para o próximo dia útil. Deseja agendar sua transação?",
      confirm: "Continuar",
      cancel: "Voltar"
    }
  },

  date: {
    today: 'Hoje'
  },

  // extract page
  extractPage: {
    pageTitle: 'Extrato',
    contentTitle: 'Extrato',
    containerTitle: 'Detalhes da operação',
    operationNumber: 'Número da operação',
    amountWithdrawn: 'VALOR DA RETIRADA',
    receive: 'DE',
    to: 'PARA',
    account: 'CONTA',
    description: 'DESCRIÇÃO',
    like: 'às',
    textFooter: 'Crédito realizado em',
    textCredit: 'Caso a retirada do dinheiro seja solicitada até 12h00, o valor é creditado na conta bancária no mesmo dia da solicitação. Após este horário, o dinheiro é creditado no próximo dia útil.',
    textOperation: 'Operação realizada em',
    accountBank: 'CONTA',
    bank: 'BANCO',
    agency: 'AGÊNCIA',
    descriptionBank: 'Descrição',
    valueTransaction: 'Valor da transação',
    operationType: 'TIPO DE OPERAÇÃO',
    fee: 'TARIFA',
    nav: {
      all: 'Todos',
      incoming: 'Entrada',
      outcoming: 'Saída',
      new: 'Mais recentes...',
      old: 'Mais antigos...',
      selectDate: 'Definir data',
    },
    incoming: 'Entrada de dinheiro',
    outcoming: 'Saída de dinheiro'
  },

  // date picker modal
  datePickerModal: {
    selectFirst: 'Selecione o primeiro dia.',
    selectLast: 'Selecione o último dia.',
    from: 'De',
    to: 'até',
    reset: 'Limpar',
    cancel: 'cancelar',
    confirm: 'Confirmar',
  },

  // operation type enum
  operationTypeEnum: {
    all: 'Todas as operações',
    receipts: 'Recebimentos',
    deposit: 'Entrada de dinheiro',
    withdrawal: 'Retirada de dinheiro',
  },

  // operation status enum
  operationStatusEnum: {
    all: 'Todos os status',
    finished: 'Concluído',
    pending: 'Pendente',
    canceled: 'Cancelado',
    analysis: 'Em análise',
    returned: 'Devolvido',
  },

  configuration: {
    pageTitle: 'Configurações',
    contentTitle: 'Configurações',
    devicesTitle: 'Dispositivos conectados',
    devicesButtonRemove: 'Remover todos dispositivos',
    devicesList: {
      nameComputer: 'Dispositivo',
      browser: 'Browser',
      operationSystem: 'Sistema Operacional',
      buttonRemove: 'Remover',
    },
  },

  support: {
    pageTitle: 'Ajuda',
    contentTitle: 'Configurações',
  },

  // account type enum
  accountTypeEnum: {
    current: 'Conta corrente',
    savings: 'Conta poupança',
  },

  // phone type enum
  phoneTypeEnum: {
    residential: 'Residencial',
    cellphone: 'Celular',
  },

  emptyState: {
    title: 'Você não possui registros neste período',
    description: 'Verifique os filtros e tente novamente',
  },

  // account type enum
  extractTypeEnum: {
    all: 'Todos',
    entry: 'Entrada',
    output: 'Saída',
    blocked: 'Bloqueados',
    future: 'Futuros'
  },

  // payment type enum
  paymentTypeEnum: {
    all: 'Todos',
    waitingPayment: 'Aguardando pagamento',
    inProgress: 'Em andamento',
    authorized: 'Autorizado',
    paid: 'Pago',
    canceled: 'Cancelado',
    release: 'A liberar',
    cancelInProgress: 'Cancelamento em progresso',
  },

  paymentOrderMethodEnum: {
    boleto: 'Boleto',
    boletoParcelado: 'Boleto parcelado',
    creditCard: 'Cartão de crédito',
    balance: 'Saldo',
    deposit: 'Depósito',
  },

  emptyAccount: {
    title: "Ganhe mais agilidade",
    description: "Cadastre sua conta para transferir dinheiro com apenas um clique",
    addButton: "Adicionar conta"
  },

  // panel
  panel: {
    // start page
    startPage: {
      pageTitle: 'Início',
      seeAll: 'ver todos',
      loan: 'Empréstimo',
      loanDescription: 'Até R$ 30.000,00 disponíveis na sua conta digital no empréstimo com ou sem garantia.',
      loanSimulator: 'Simulador de empréstimo',
      addMoney: 'Adicionar saldo em conta',
      transfer: 'Transferir para mesma titularidade',
      balance: 'Saldo disponivel',
      history: 'Histórico',
      mobileMenu: {
        extract: 'Extrato',
        transfer: 'Transferir',
        addMoney: 'Adicionar',
        wallet: 'Carteira'
      },
      emptyTransactions: 'Nenhuma transação feita recentemente',
      extract: 'Ver extrato'
    },

    // wallet page
    walletPage: {
      outsideCardList: 'Meus cartões',
      outsideAccountList: 'Minhas contas bancárias',
      pageTitle: 'Carteira',
      cardListTitle: 'Seus cartões',
      cardListButton: 'Adicionar cartão',
      accountListTitle: 'Suas contas bancárias',
      accountListButton: 'Adicionar conta',
      emptyCreditCard: {
        title: "Ganhe mais agilidade",
        webDescription: "Cadastre seu cartão de crédito para pagamentos",
        mobileDescription: "Cadastre um cartão de crédito e facilite seus pagamentos",
        addButton: "Adicionar cartão"
      },
      emptyAccount: {
        title: "Ganhe mais agilidade",
        description: "Cadastre sua conta para transferir dinheiro com apenas um clique",
        addButton: "Adicionar conta"
      },
      addCreditCardModal: {
        title: "Adicionar cartão",
        description: "Preencha os dados abaixo exatamente como está no seu cartão.",

        form: {
          number: "Número do cartão de crédito",
          holder: "Nome impresso no cartão",
          expiration: "Vencimento",
          cvv: "CVV",
          cpf: "CPF",
          cnpj: "CNPJ",
          submitButton: "Salvar dados do cartão"
        }
      },
      addAccountModal: {
        title: "Adicionar conta bancária:",
        description: "Em até um dia útil, enviaremos uma validação  junto ao seu banco para confirmação de dados.",

        form: {
          name: "Nome do titular",
          taxIdentifier: {
            cpf: "CPF",
            cnpj: "CNPJ"
          },
          bankDestination: "Instituição",
          branchDestination: "Agência (Sem dígito)",
          accountDestination: "Conta",
          accountDigitDestination: "Dígito",
          accountType: "Tipo de conta"
        },

        button: {
          cancel: "Cancelar",
          submit: "Continuar"
        },

        toast: {
          sucess: "Conta bancária adicionada com sucesso",
          error: "Alguma coisa de errado aconteceu, tente novamente mais tarde.",
          alreadyCreated: "Conta ja existente"
        }
      },
      removeModal: {
        account: {
          title: "Remover conta bancária",
          description: "Você precisará inserir os dados da sua conta bancária novamente caso queira utilizar",
        },
        card: {
          title: "Remover cartão",
          description: "Você precisará inserir os dados do seu cartão novamente caso queira utilizar"
        },
        button: {
          cancel: "Cancelar",
          remove: "Remover"
        }
      }
    },

    // history page
    historyDetailsPage: {
      pageTitle: 'Histórico',
      backPageText: 'Voltar para histórico',
    },

    // history page
    historyPage: {
      pageTitle: 'Histórico',

      filter: {
        periodLabel: 'Período',
        statusLabel: 'Status',
        operationsLabel: 'Operações',
        dropdownOrder: 'Ordenar por',
      }
    },

    depositPage: {
      pageTitle: 'Adicionar saldo',
      description: 'Selecione um dos métodos abaixo para adicionar dinheiro à sua conta:',
      descriptionBank: 'Selecione um dos bancos abaixo:',
      depositDescription: 'Gere um boleto de depósito e receba o valor em até um dia útil.',
      transferDeposit: 'Disponível em até um dia útil dentro do horário comercial.'
    },

    extractDetailsPage: {
      contentTitle: 'Extrato',
      backPageText: 'Voltar para extrato',
    },

    withdrawalPage: {
      pageTitle: 'Retirar dinheiro',
    },

    informIncome: {
      pageTitle: "Informe de rendimentos",
      description: "Selecione o ano que você deseja baixar o informe de rendimentos",
      calendarYear: "Ano calendário",
      recipient: {
        title: "Beneficiário",
        client: "Cliente",
        document: "CPF/CNPJ",
        agency: "Agência",
        account: "Conta corrente"
      },
      company: {
        title: "Fonte pagadora",
        name: "Nome empresarial",
        document: "CNPJ"
      },
      payment: {
        title: "Conta de pagamento",
        balance: "Saldo em"
      },
      obs: "Com a Superbid Pay ficou mais fácil preencher sua declaração de imposto de renda! Estes dados são utilizados para preencher o item 6 da sua declaração anual de imposto de renda.",
      copyright: "2024 Superbid © • Rua Henrique Monteiro, nº 125, 2º andar - Pinheiros - São Paulo - SP Atendimento de segunda à sexta das 9:00 às 18:00, exceto feriados : (11) 4950 9400",
      fileName: "InformeRendimento"
    }
  },

  cashOut: {
    title: 'Retirar dinheiro',
    goBack: 'Voltar',
    accountSelection: 'Selecione ou adicione uma conta para a retirada:',

    value: {
      title: 'Quanto você deseja',
      titleStrong: ' retirar',
      balance: 'Seu saldo atual é ',
      continueButton: 'continuar',
    },

    confirmation: {
      title: 'Você está retirando:',
      agency: 'AGÊNCIA',
      account: 'CONTA',
      type: 'TIPO',
      bank: 'BANCO',
      name: 'NOME DO FAVORECIDO',
      documentPerson: 'CPF',
      documentCorporate: 'CNPJ',
      changeButton: 'alterar conta',
      confirmButton: 'confirmar retirada',
      valueCreditText: 'Valor creditado ao favorecido',
      withdrawFeeText: 'Tarifa de retirada',
      withdrawText: 'Valor'
    }
  },

  cashIn: {
    billetDescription: 'Boleto de depósito',
    transferDescription: 'Transferência Bancária',

    value: {
      title: "Quanto você deseja depositar?",
      description: "Sua saldo atual é"
    },

    modalCashIn: {
      modalTitle: 'Siga as instruções abaixo para realizar a transferência',
      modalDescriptionOne: '1. Serão fornecidos os dados bancários da Superbid Pay no banco parceiro. Utilize esses dados para realizar transfêrencias e adicionar dinheiro na sua conta digital.',
      modalDescriptionTwo: '2. Esta transferência deve ser realizada de uma conta bancária de mesma titularidade, ou seja, o titular da conta digital Superbid Pay e o titular da conta bancária precisam ser a mesma pessoa (ter o mesmo CPF ou CNPJ).',
      modalButton: 'Entendi',
    },

    transfer: {
      goBack: 'Voltar para home',
      title: 'Transferência bancária',
      titleOne: 'Utilize os dados abaixo para a transferência bancária (TED, TEF ou DOC):',
      titleThree: 'Boleto de depósito',
      description: 'Informe o valor que deseja adicionar à sua conta.',
      descriptionTwo: 'Confirme seus dados e o valor do boleto a ser pago.',
      bank: 'banco',
      agency: 'agência',
      account: 'conta',
      type: 'tipo',
      name: 'favorecido',
      document: 'cnpj',
      attention: 'Importante',
      attentionDescription: 'Esta transferência deve ser realizada de uma conta bancária de mesma titularidade (mesmo CPF ou CNPJ).',
      attentionDescriptionTwo: 'O dinheiro estará disponível na conta digital em até 1 dia útil após a confirmação da transferência',
    },

    billet: {
      goBack: 'Voltar',
      goBackTwo: 'Alterar Valor',
      stepOne: {
        title: 'Quanto você deseja',
        titleStrong: ' adicionar',
        balance: 'Seu saldo atual é ',
        continueButton: 'continuar',
      },

      stepTwo: {
        title: 'Confirme as informações',
        addingText: 'Valor',
        formOfPayment: 'forma de pagamento',
        invoice: 'Boleto',
        fullName: 'nome do favorecido',
        document: 'cpf',
        generateButton: 'gerar boleto',
        securityTips: 'três dicas importantes para pagar seu boleto \n com segurança',
        security: 'Antes de confirmar o pagamento deste boleto em seu banco, confira estas dicas:',
        observation: 'OBSERVAÇÃO',
        obeservationText: 'Quando ocorrer a compensação do pagamento deste boleto, o valor será adicionado à sua conta e você poderá seguir com o pagamento das suas compras normalmente. ',
        secM0: ' Os três primeiros números do seu código de barras correspondem ao banco que emitiu o boleto. nós emitimos apenas boletos do Itaú (341) e Santander (033);',
        secM1: ' No seu boleto deve constar como beneficiário SUPERBID PAYMENTS INSTITUIÇÃO DE PAGAMENTO LTDA - CNPJ 35.084.163/0001-84. verifique se esses dados estão corretos;',
        secM2: ' Confirme se o valor exibido no boleto corresponde ao valor da sua compra.',
        changeValue: "Alterar valor",
        confirm: "Confirmar"
      },

      stepThree: {
        title: 'Boleto emitido',
        barCodeTitle: 'Boleto de depósito gerado.',
        codeNumber: 'Número do código de barras:',
        barCode: 'Utilize o número do código de barras para realizar o depósito em sua conta Superbid Pay:',
        codeBars: 'Escaneie o código de barras',
        tip: 'Importante:',
        tipText: 'O pagamento deste boleto não quita quaisquer débitos de compras realizadas no Superbid Exchange. O seu objetivo é adicionar saldo na Conta Digital Superbid pay do usuário do Superbid Exchange.',
        tipTextTwo: 'Para emissão de boleto para pagamento de bens adquiridos, acesse sua conta no Superbid Exchange e selecione a opção “Minhas Compras”.',
        paymentOptions: 'Ou utilize as opções abaixo para realizar o pagamento:',
        downloadCode: 'Baixar o boleto',
        copyCode: 'Copiar o código de barras',
        shareCode: 'compartilhar',
        goToExtract: 'veja seu extrato',
        tipChecklistTitle: 'Antes de confirmar o pagamento deste boleto em seu banco, confira estas dicas:',
        tipChecklistOne: {
          text1: 'Verifique se os três primeiros números do código de barras correspondem ao banco que emitiu o boleto. Nós emitimos apenas boletos do',
          text2: 'Itaú (341)',
          text3: 'ou',
          text4: 'Santander (033);'
        },
        tipChecklistTwo: {
          text1: 'Valide sempre os dados do beneficiário. Neste pagamento deve constar como beneficiário:',
          text2: 'SUPERBID PAYMENTS INSTITUICAO DE PAGAMENTO LTDA - CNPJ 35.084.163/0001-84;'
        },
        tipChecklistThree: 'Confirme se o valor do documento corresponde ao valor da sua compra.',
        footer: 'O dinheiro estará disponível na conta digital em até 1 dia útil após a confirmação do pagamento.'
      }
    }
  },

  balanceOfDay: {
    balance: 'Saldo do dia',
    emptyBalance: 'Não calculado',
  },

  smallCard: {
    balance: 'Saldo Total',
    available: 'Disponível',
    release: 'A Liberar',
  },

  whiteCard: {
    availableBalance: 'saldo disponível',
    notAvailableBalance: 'saldo não disponível',
    deposit: 'adicionar',
    withdrawal: 'retirar',
    money: 'dinheiro',
  },

  documentCard: {
    greeting: 'Olá',
    description: 'Para aproveitar os benefícios da Superbid Pay é necessário validar sua identidade. Aproveite o momento e envie seus documentos em apenas 3 minutinhos.',
    validateButton: 'VALIDAR IDENTIDADE',
  },

  profile: {
    indicator: 'principal',
    addButton: 'adicionar',
    changeButton: 'Editar',
    termsLink: 'Termos & Políticas',
    about: 'Sobre',
    addDocumentButton: 'Adicionar documento',

    phoneCard: {
      title: 'Telefone',
      modal: {
        title: 'Insira um novo número de contato',
        button: 'ok',
        codePlaceholder: 'DDD',
        phonePlaceholder: 'TELEFONE',
      }
    },

    addressCard: {
      title: 'Endereço',
      zipCode: 'CEP',
      modal: {
        title: 'Insira um novo endereço',
        button: 'confirmar',
        streetPlaceholder: 'RUA',
        numberPlaceholder: 'N°',
        neighborhoodPlaceholder: 'BAIRRO',
        cityPlaceholder: 'CIDADE',
        statePlaceholder: 'ESTADO',
        postcodePlaceholder: 'CEP',
        additionalInfoPlaceholder: 'COMPLEMENTO',
      },
    },

    userInformation: {
      changePhoto: 'alterar foto',
      denied: 'CPF negado',
      verified: 'CPF verificado',
      notVerified: 'CPF não verificado',
      inProgress: 'CPF em avaliação'
    },

    emailCard: {
      title: 'E-mail',
      modal: {
        title: 'Insira um novo e-mail',
        button: 'ok',
        emailPlaceholder: 'E-MAIL',
      }
    }
  },

  secondFactorModal: {
    title: 'Enviamos um código de verificação para',
    validate: 'validar',
    resendCode: 'reenviar código',
  },

  header: {
    balance: "Saldo disponível",
    available: "a liberar",
    menu: {
      settings: "Configurações",
      ombudsman: "Ouvidoria",
      help: "Ajuda",
      informIncome: "Informe de rendimentos",
      logout: "Sair"
    }
  },
}