import React from 'react';

import IcHome from '../components/icons/IcHome';
import IcExtractColored from '../components/icons/IcExtractColored';
import IcWalletColored from '../components/icons/IcWalletColored';
import IcMoneyAddColored from '../components/icons/IcMoneyAddColored';
import IcWithdrawColored from '../components/icons/IcWithdrawColored';
import IcHistoryColored from '../components/icons/IcHistoryColored';
// import IcTransfer from '../svg/ic_transfer.svg';

import ROUTES from './routes';

export default {
  links: [
    {
      url: ROUTES.PANEL_START,
      name: 'Início',
      icon: <IcHome size={24} />,
    },
    {
      url: ROUTES.PANEL_HISTORY,
      name: 'Histórico',
      icon: <IcHistoryColored size={24} />,
    },
    {
      url: ROUTES.PANEL_EXTRACT,
      name: 'Extrato',
      icon: <IcExtractColored size={24} />,
    },
    {
      url: ROUTES.PANEL_WALLET,
      name: 'Carteira',
      icon: <IcWalletColored size={24} />,
    },
    {
      url: ROUTES.PANEL_ADD_MONEY,
      name: 'Adicionar',
      icon: <IcMoneyAddColored size={24} />,
    },
    {
      url: ROUTES.PANEL_WITHDRAW_MONEY,
      name: 'Transferir',
      icon: <IcWithdrawColored size={24} />,
    },
    // {
    //   url: ROUTES.PANEL_CREDIT,
    //   name: 'Crédito',
    //   icon: <IcTransfer size={24} />
    // },
  ]
};
