import React, { Component } from 'react';
import Modal from 'react-modal';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

Modal.setAppElement('#app');

const customStyles = {
  overlay: {
    background: 'rgb(40, 51, 74, 0.5)',
  },
  content: {
    top: 'calc(50% - (448px / 2))',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    height: '448px',
    width: '424px',
    background: '#F7F5F6',
    borderRadius: '5px',
    border: '0px',
  },
  months: {
    fontWeight: 'bold',
  }
}

const modifiersStyles = {
  today: {
    color: '#28334a',
  },
}

class DatePickerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      from: null,
      to: null,
      enteredTo: null,
      valueFrom: null,
      valueTo: null,
    };
  }

  isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  focusFromDate = () => {
    const { from } = this.state;
    this.setState({
      valueFrom: moment(from).format('DD/MM/YYYY'),
    });
  }

  focusToDate = () => {
    const { to } = this.state;
    this.setState({
      valueTo: moment(to).format('DD/MM/YYYY')
    });
  }

  blurFromDate = () => {
    const { from, valueFrom, to } = this.state;
    let day = moment(valueFrom, 'DD/MM/YYYY', true).isValid() ?
      moment(valueFrom, 'DD/MM/YYYY').format() : from;
    if (moment(day).isSameOrAfter(to)) {
      day = to;
    }
    this.setState({
      from: new Date(day),
      valueFrom: moment(day).format('ddd, DD MMM, YYYY')
    });
  }

  blurToDate = () => {
    const { to, valueTo, from } = this.state;
    let day = moment(valueTo, 'DD/MM/YYYY', true).isValid() ?
      moment(valueTo, 'DD/MM/YYYY').format() : to;
    if (moment(from).isSameOrAfter(day)) {
      day = from;
    }
    this.setState({
      to: new Date(day),
      enteredTo: new Date(day),
      valueTo: moment(day).format('ddd, DD MMM, YYYY')
    });
  }

  changeFromDate = (day) => {
    this.setState({
      valueFrom: day,
    });
  }

  changeToDate = (day) => {
    this.setState({
      valueTo: day,
    });
  }

  handleResetClick = () => {
    this.setState({
      from: null,
      to: null,
      enteredTo: null,
      valueFrom: null,
      valueTo: null,
    });
  }

  handleDayMouseEnter = (day) => {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  }

  handleDayClick = (day) => {
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      this.handleResetClick();
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
        valueFrom: moment(from).format('ddd, DD MMM, YYYY'),
        valueTo: null,
      });
    } else {
      this.setState({
        to: day,
        enteredTo: day,
        valueFrom: moment(from).format('ddd, DD MMM, YYYY'),
        valueTo: moment(day).format('ddd, DD MMM, YYYY'),
      });
    }
  }

  render() {
    const { from, to, enteredTo, valueFrom, valueTo } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const disabledDays = { before: this.state.from };
    const selectedDays = [from, { from, to: enteredTo }]
    const { isOpen, onClose, locale, onSelectDate } = this.props;

    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          onRequestClose={() => onClose()}
          style={customStyles}
        >
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <DayPicker
                className="Range"
                fromMonth={from}
                selectedDays={selectedDays}
                disabledDays={disabledDays}
                modifiers={modifiers}
                modifiersStyles={modifiersStyles}
                onDayClick={this.handleDayClick}
                onDayMouseEnter={this.handleDayMouseEnter}
                localeUtils={MomentLocaleUtils}
                locale={locale || 'moment/locale/pt-br'}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              {!from && !to && I18n.t('datePickerModal.selectFirst')}
              {from && !to && I18n.t('datePickerModal.selectLast')}
            </div>
          </div>
          {valueFrom && valueTo && (
            <React.Fragment>
              <div className="date-picker__selected__wrapper">
                <input
                  size='14'
                  className="date-picker__selected"
                  onFocus={this.focusFromDate}
                  onBlur={this.blurFromDate}
                  onChange={e => this.changeFromDate(e.target.value)}
                  value={valueFrom}
                />

                <input
                  size='14'
                  className="date-picker__selected"
                  onFocus={this.focusToDate}
                  onBlur={this.blurToDate}
                  onChange={e => this.changeToDate(e.target.value)}
                  value={valueTo}
                />
                <button
                  type="button"
                  className="link"
                  onClick={this.handleResetClick}
                >
                  {I18n.t('datePickerModal.reset')}
                </button>
              </div>
            </React.Fragment>
          )}

          <div className="row action__buttons">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <button
                className="btn__default btn__default__cancel"
                type="button"
                onClick={() => onClose()}
              >{I18n.t('datePickerModal.cancel').toUpperCase()}
              </button>
              <button
                className="btn__default btn__default__confirm"
                type="button"
                onClick={() => onSelectDate(selectedDays)}
              >{I18n.t('datePickerModal.confirm').toUpperCase()}
              </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default DatePickerModal;
