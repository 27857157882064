import styled, { css } from "styled-components";
import { space, width } from "styled-system";

export const Wrapper = styled.div`
  ${width};
  ${space}
`;

const filledBorder = css`
  border-color: ${({ theme }) => theme.colors.text.regular};
`;

export const Container = styled.section`
  display: flex;
  align-items: center;
  height: 42px;
  width: 100%;

  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.text.light};

  ${({ isFocused }) => isFocused && filledBorder};
  ${({ isFilled }) => isFilled && filledBorder};
  ${({ hasError }) => hasError && css`
    border-color: ${({ theme }) => theme.colors.feedback.error};
  `};
`;

export const filled = css`
    input {
      border-color: ${({ theme }) => theme.colors.text.regular};
      color: ${({ theme }) => theme.colors.text.regular};
    }

    span {
      top: -8px;
      left: 12px;
      font-size: 10px;
      padding: 0 8px;
      color: ${({ theme }) => theme.colors.text.regular};
      background: ${({ theme }) => theme.colors.background.default};
    }

    &:last-of-type {
      border-left: 1px solid ${({ theme }) => theme.colors.text.regular};
    }
`;

export const Component = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  input {
    border: none;
    background: transparent;
    padding: 0 16px;
    width: 100%;
    height: 100%;
  }

  &:first-of-type {
    width: 65%;

    input {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }

  }

  &:last-of-type {
    border-left: 1px solid ${({ theme }) => theme.colors.text.light};
    width: 35%;

    input {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
  }
  
  span {
    color: #BEC5CB;
    position: absolute;
    top: 10px;
    left: 16px;
    font-size: 14px;
    letter-spacing: 0.05em;
    pointer-events: none;
    transition: .2s;
  }

  ${({ isFocused }) => isFocused && filled};
  ${({ isFilled }) => isFilled && filled};
  ${({ hasError }) => hasError && css`
      span {
        color: ${({ theme }) => theme.colors.feedback.error};
      }

      &:last-of-type {
        border-left: 1px solid ${({ theme }) => theme.colors.feedback.error};
      }
  `};
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.feedback.error};
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  margin-top: 6px;
  margin-bottom: 0;
`;