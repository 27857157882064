import React from 'react';
import { Link } from 'react-router-dom';
import ArrowFoward from '../icons/IcArrowFoward';

// eslint-disable-next-line no-unused-vars
const DepositMethod = ({ icon, description, title, route, code }) => {
  return (
    <Link
      to={code ? `${route}${code}` : route}
      className="link-no-style"
    >
      <div className="deposit__wrapper" title={title}>
        <div className="deposit__content">
          <p className="deposit__content__title">
            {title}
          </p>
          <span className="deposit__content__description">
            {description}
          </span>
        </div>
        <div className="deposit__arrow">
          <ArrowFoward />
        </div>
      </div>
    </Link>
  )
}

export default DepositMethod;
