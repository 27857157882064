/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import ROUTES from '../../data/routes';
import CardWhite from "../card/CardWhite";
import { PaymentActions } from '../../app/store/actions';
import { PaymentSelectors } from '../../app/store/reducers';
import { parseHistoryId } from '../../app/services/parseUrlId';
import OrderItem from './OrderItem';
import MoneyValue from '../moneyValue/MoneyValue';
import BackPage from '../../svg/panel/panelExtract/ic_keyboard_backspace.svg';
import { formatDate } from '../../app/pipe/dateFormatter';
import statusColor from '../../app/pipe/statusColor';
import humanizePaymentMethods from '../../app/pipe/humanizePaymentMethods';
import getPaymentOrderStatus from '../../app/pipe/paymentOrderStatus';
import PaymentOrderStatus from '../../data/enumerators/paymentOrderStatus';
import PaymentMethodIcon from './PaymentMethodIcon';
import IconQuestion from '../../svg/elements/ic_help_outline.svg';

class HistoryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0
    }
  }


  handleCursor = event => {
    this.setState({
      x: event.clientX,
      y: event.clientY,
    });
  }
  componentDidMount = async () => {
    const { getPaymentOrder } = this.props;
    const orderId = parseHistoryId(location.pathname);
    try {
      await getPaymentOrder(orderId);
    } catch (err) {
      // console.log(err);
    }
  }

  getNextExpirationDate() {
    const { paymentOrder } = this.props;
    const transaction = paymentOrder.transactions.find(data => data.status === 1);
    if (!paymentOrder.selectPaymentMethod) {
      return null;
    }

    if (transaction && paymentOrder.selectPaymentMethod === 'BOLETO') {
      return moment(transaction.billets.expirationDate, 'YYYY-MM_DD').format('DD/MM/YYYY');
    }

    if (transaction && paymentOrder.selectPaymentMethod === 'BOLETO_PARCELADO') {
      return moment(transaction.billets.expirationDate, 'YYYY-MM_DD').format('DD/MM/YYYY');
    }

    return null;
  }

  getNextBilletUrl() {
    const { paymentOrder } = this.props;
    const transaction = paymentOrder.transactions.find(data => data.status === 1);

    if (!paymentOrder.selectPaymentMethod) {
      return null;
    }

    if (paymentOrder.selectPaymentMethod === 'BOLETO' ||
      paymentOrder.selectPaymentMethod === 'BOLETO_PARCELADO') {
      return transaction.billets.billUrl;
    }

    return null;
  }

  validateDueDate() {
    const { paymentOrder } = this.props;
    if (paymentOrder.selectPaymentMethod === 'BOLETO' ||
      paymentOrder.selectPaymentMethod === 'BOLETO_PARCELADO') {
      const currentDate = moment().format('YYYY-MM-DD');
      const transaction = paymentOrder.transactions.find(data => data.status === 1);
      if (transaction) {
        const dateExpiration = moment(transaction.billets.expirationDate);
        const diffDate = dateExpiration.diff(currentDate, 'days');
        if (diffDate >= 0) {
          return true;
        }
      }
    }

    return false;
  }

  render() {

    const { x, y } = this.state;
    const { paymentOrder } = this.props;

    return (
      <CardWhite
        noShadow
      >
        {paymentOrder && (
          <div className="history__card">
            <div className="row mobile mb-1">
              <div className="col-12">
                <Link
                  to={ROUTES.PANEL_HISTORY}
                  className="panel__head__back link-no-style d-flex"
                >
                  <BackPage className="panel__head__ic_keyboard_backspace" />
                  <span className="panel__head__back__text">Voltar para histórico</span>
                </Link>
              </div>
            </div>
            <div className="history__card__title">
              {paymentOrder.message}
            </div>
            <div className="history__card__header">
              <div className="history__card__header__number">
                Número da operação: {paymentOrder.code || paymentOrder.externalIdentifier}
              </div>
            </div>
            <div className="history__card__content">
              <div className="history__card__content__items">
                {paymentOrder.items && paymentOrder.items.length > 0 &&
                  (
                    paymentOrder.items.map(item => (
                      <OrderItem
                        key={item.id}
                        description={item.description}
                        price={item.price}
                      />
                    ))
                  )}
              </div>
              <div className="history__card__content__total">
                <div className="history__card__content__total__text">
                  Valor total
                  {(paymentOrder.selectPaymentMethod && paymentOrder.selectPaymentMethod.includes("CARTAO_CREDITO")) && (
                    <span className="small__card__invisible">
                      <IconQuestion
                        className="small__card__invisible__question"
                        onMouseMove={this.handleCursor}
                      />
                      <span className="small__card__invisible__info">
                        {x && y
                          && (
                            <p className="small__card__invisible__info__text">
                              Na sua fatura, esta transação aparecerá com a descrição SUPERBID*PAY
                            </p>
                          )
                        }
                      </span>
                    </span>
                  )}
                </div>
                <div className="history__card__content__total__value">
                  <MoneyValue value={paymentOrder.totalAmount} />
                </div>
              </div>
            </div>
            <div className="history__card__payment">
              {paymentOrder.config && paymentOrder.config.acceptedPaymentMethods
                && !paymentOrder.selectPaymentMethod && (
                  <div className="history__card__payment__accepted-methods">
                    {/* eslint-disable-next-line max-len */}
                    <strong>Forma de pagamento:</strong> {humanizePaymentMethods(paymentOrder.config.acceptedPaymentMethods)}
                  </div>
                )}
              {paymentOrder.selectPaymentMethod &&
                (
                  <div className="history__card__payment__boleto">
                    <div className="history__card__payment__icon">
                      {(paymentOrder.selectPaymentMethod === 'BOLETO'
                        || paymentOrder.selectPaymentMethod === 'BOLETO_PARCELADO') && this.validateDueDate() &&
                        (
                          <Link
                            to={this.getNextBilletUrl()}
                            className="panel__head__back link-no-style d-flex"
                            target="_blank"
                            onClick={(event) => { event.preventDefault(); window.open(this.getNextBilletUrl()); }}
                          >
                            <PaymentMethodIcon method={paymentOrder.selectPaymentMethod} />
                          </Link>
                        )}
                      {(paymentOrder.selectPaymentMethod !== 'BOLETO'
                        && paymentOrder.selectPaymentMethod !== 'BOLETO_PARCELADO') &&
                        (
                          <PaymentMethodIcon method={paymentOrder.selectPaymentMethod} />
                        )}
                    </div>
                    <div className={`history__card__payment__status 
                  history__card__payment__status--${statusColor(paymentOrder.status)}`}
                    >
                      {/* eslint-disable-next-line max-len */}
                      Pagamento via: {humanizePaymentMethods(paymentOrder.selectPaymentMethod)} - {getPaymentOrderStatus(paymentOrder.status)}
                    </div>
                    <div className="history__card__payment__creation">
                      <div className="history__card__payment__creation__text">
                        {(
                          (paymentOrder.selectPaymentMethod === 'BOLETO'
                            || paymentOrder.selectPaymentMethod === 'BOLETO_PARCELADO')
                          && 'emissão')
                          || 'pago'}
                      </div>
                      <div className="history__card__payment__creation__date">
                        {formatDate(paymentOrder.config.createdAt)}
                      </div>
                    </div>
                    {this.getNextExpirationDate() && (
                      <div className="history__card__payment__creation">
                        <div className="history__card__payment__creation__text">
                          vencimento
                        </div>
                        <div className="history__card__payment__creation__date">
                          {this.getNextExpirationDate()}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              {paymentOrder.status === PaymentOrderStatus.CANCELED && !paymentOrder.selectPaymentMethod && (
                <div className="history__card__canceled">
                  Ordem de pagamento cancelada
                </div>
              )}
            </div>
          </div>
        )}
      </CardWhite>
    )
  }
}

const mapStateToProps = state => ({
  paymentOrder: PaymentSelectors.getPaymentOrder(state),
});

const mapDispatchToProps = dispatch => ({
  getPaymentOrder: (id) => dispatch(PaymentActions.getPaymentOrder(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryDetails);

