import ROUTES from '../../data/routes';

export const parseExtractId = (pathname) => {
  return (pathname.slice(ROUTES.PANEL_EXTRACT.length + 1, pathname.length));
}

export const parseHistoryId = (pathname) => {
  return (pathname.slice(ROUTES.PANEL_HISTORY.length + 1, pathname.length));
}

export default { parseExtractId, parseHistoryId };

