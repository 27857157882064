import { axiosInstance } from '../initialization';
import ServerUtilAdiq from '../utils/serverAdiq';
import AuthService from './auth';

async function request(method, uri, data = {}, headers = {}, extras = {}) {
  if (!headers['Content-Type']) headers['Content-Type'] = 'application/json';
  try {
    const response = await axiosInstance({
      method,
      url: `${ServerUtilAdiq.getBaseUrlAdiq()}${uri}`,
      data,
      headers,
      ...extras,
    });
    if (response && response.data) {
      if (response.data) return response.data;
      return response.data;
    }
    return response;
  } catch (err) {
    throw err;
  }
}

function authticatedHeader(header = {}) {
  const auth = AuthService.get();
  if (auth) header.Authorization = `Bearer ${auth.token}`;
  return header;
}

async function postAuthenticated(uri, data, header) {
  return request('post', uri, data, authticatedHeader(header));
}

export default {
  postAuthenticated,
};
