import React from 'react';
import Modal from 'react-modal';
import { I18n, Translate } from 'react-redux-i18n';
import ROUTES from '../../data/routes';
import DepositMethod from '../../components/elements/DepositMethod';
import Billet from '../../svg/operations/ic_billet.svg';
import Transfer from '../../svg/operations/ic_transfer.svg';
import Bank from '../../data/enumerators/bank';

const depositMethods = [
  {
    id: Bank.SANTANDER,
    icon: <Transfer />,
    title: <Translate value="cashIn.transferDescription" />,
    description: <Translate value="panel.depositPage.transferDeposit" />,
    route: ROUTES.PANEL_ADD_MONEY_TRANSFER,
  },
]

Modal.setAppElement('#app');

const PanelDepositPage = () => {
  return (
    <React.Fragment>
      <div className="content-container">
        <div className="content-container__view">
          <div className="content-container__head">
            <div className="content-container__head__title">
              {I18n.t('panel.depositPage.pageTitle')}
            </div>
            <div className="content-container__head__description">
              {I18n.t('panel.depositPage.description')}
            </div>
          </div>


          {depositMethods.map((method) => (
            <DepositMethod
              key={method.route}
              icon={method.icon}
              title={method.title}
              description={method.description}
              route={method.route}
              code={method.id}
            />
          ))}

        </div>
      </div>
    </React.Fragment>
  )
}

export default PanelDepositPage;
