/* eslint-disable max-len */
import React from 'react';
import DoneIcon from '../../svg/ic_done.svg';

const MobileSingleFitler = ({ title, options, selected, onSelect }) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 d-flex align-items-center justify-content-start">
          <div className="mobile-filters__subtitle">
            {title}
          </div>
        </div>
      </div>
      {options && options.length && (
        options.map(option => (
          <div
            key={option.id}
            className="row"
          >
            <div className="col-12">
              <button
                type="button"
                onClick={() => { onSelect(option.id) }}
                className={`mobile-filters__option ${(selected === option.id) ? 'mobile-filters__option--selected' : ''}`}
              >
                {option.description}
                {(selected === option.id) && (
                  <DoneIcon className="mobile-filters__option__icon" />
                )}
              </button>
            </div>
          </div>
        ))
      )}
    </React.Fragment>
  )
}

export default MobileSingleFitler;
