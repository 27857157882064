import React, { useCallback, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';

import 'react-toastify/dist/ReactToastify.css';

import helpers from '../../helpers/currency';

const { formatCurrency, parseCurrency } = helpers


const CashOutValue = ({ amount, setAmount, onContinue, balance }) => {
  const [isDisabled, setIsDisable] = useState(true);

  const handleAddAmount = useCallback(value => {
    const newValue = (parseCurrency(amount || '0') + parseCurrency(value))
      .toLocaleString('pt-br', { minimumFractionDigits: 2 })

    setAmount(newValue)
  }, [amount]);

  useEffect(() => {
    if (amount) {
      setIsDisable(parseCurrency(amount) > balance || parseCurrency(amount) === 0);
    }
    else {
      setIsDisable(true)
    }
  }, [amount])

  return (
    <div className="cashout_page__content">
      <h3 className="cashout_page__content__title">{I18n.t('cashoutPage.cashoutQuestion')}</h3>
      <p className="cashout_page__content__description">{I18n.t('cashoutPage.nowBalance')} {' '}
        <b>{balance && balance.toLocaleString('pt-br', { style: 'currency', currency: "BRL" })}</b>
      </p>

      <div className="cashout_page__content__cashout_form">
        <input
          className="cashout_page__content__cashout_form__input"
          value={`R$ ${amount}`}
          onChange={(e) => {
            setAmount(formatCurrency(e.target.value))
          }}
        />

        <div className="cashout_page__content__cashout_form__shortcuts">
          <button
            type='button'
            className="cashout_page__content__cashout_form__shortcuts__button"
            onClick={() => handleAddAmount('50,00')}
          >+ {Number(50).toLocaleString('pt-br', { style: 'currency', currency: "BRL" })}
          </button>
          <button
            type='button'
            className="cashout_page__content__cashout_form__shortcuts__button"
            onClick={() => handleAddAmount('200,00')}
          >+ {Number(200).toLocaleString('pt-br', { style: 'currency', currency: "BRL" })}
          </button>
          <button
            type='button'
            className="cashout_page__content__cashout_form__shortcuts__button"
            onClick={() => handleAddAmount('500,00')}
          >+ {Number(500).toLocaleString('pt-br', { style: 'currency', currency: "BRL" })}
          </button>
        </div>
      </div>

      <button
        type="button"
        className="cashout_page__content__button"
        onClick={onContinue}
        disabled={isDisabled}
      >{I18n.t("cashoutPage.continue")}
      </button>
    </div>
  )
}

export default CashOutValue;
