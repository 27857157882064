import React, { useMemo } from "react";
import { I18n } from "react-redux-i18n";
import AccountValidationStatus from "../../data/enumerators/accountValidation";

import IcTrash from "../../svg/ic_trash-outline.svg"

const AccountCard = (
  {
    type,
    accountNumber,
    accountType,
    name,
    bankCode,
    bankName,
    agency,
    account,
    onClickRemove
  }) => {
  const typeColors = useMemo(() => {
    switch (type) {
      case AccountValidationStatus.OK:
        return {
          message: I18n.t("validation.bankAccount.mainMessages.valid"),
          color: "#59AC7C"
        }
      case AccountValidationStatus.PENDING:
        return {
          message: I18n.t("validation.bankAccount.mainMessages.waiting"),
          color: "#EDBB0C"
        }
      case AccountValidationStatus.VALIDATION_IN_PROGRESS:
        return {
          message: I18n.t("validation.bankAccount.mainMessages.waiting"),
          color: "#EC660B",
        }
      case AccountValidationStatus.FAILED:
        return {
          message: I18n.t("validation.bankAccount.mainMessages.failed"),
          color: "#BD3939",
        }
      default:
        return {
          message: I18n.t("validation.bankAccount.mainMessages.failed"),
          color: "#BD3939",
        }
    }
  }, [type]);

  // Todo - Fazer conta poupança

  return (
    <div className="account-card">
      <div className="account-card__header">
        <h3>Conta {accountNumber || 1}</h3>
        <div title="Excluir conta bancária">
          <IcTrash onClick={onClickRemove} />
        </div>
      </div>
      <hr className="account-card__divider" />
      <p className="account-card__name">{name}</p>
      <p className="account-card__text">{bankCode} - {bankName}</p>
      <p className="account-card__text">Agencia: {agency}</p>
      <p className="account-card__text">{accountType === 1 ? "Conta corrente" : "Conta poupança"}: {account}</p>
      <p
        className="account-card__text"
        style={{ color: typeColors.color }}
      >
        {typeColors.message}
      </p>
    </div>
  )
}

export default AccountCard;