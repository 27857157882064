import { connect } from 'react-redux';
import React, { useCallback, useEffect } from 'react';
import * as eva from 'eva-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { UserActions, UtilsActions } from '../../../app/store/actions';
import Input from '../../elements/Input';
import InputMask from '../../elements/InputMask';
import Button from '../../elements/Button';
import numberHelpers from '../../../helpers/number';

const AddressModal = ({ getByCep, onCancel, onClose, onConfirm, changeAddressUser, accountId }) => {
  const handleGetAddressByCep = useCallback(async (value) => {
    const cepIsValid = numberHelpers.validateCep(value);

    if (cepIsValid) {
      const { city, neighboorhood, state, street } = await getByCep(numberHelpers.keepOnlyNumber(value));

      await formik.setValues({
        zipCode: value,
        city,
        district: neighboorhood,
        uf: state,
        street
      }, true);
    }
  }, [formik])

  const handleSubmit = useCallback(async (data) => {
    const { zipCode, number, street, district, uf, city, complement } = data;

    try {
      await changeAddressUser({
        id: accountId,
        zipCode,
        number,
        street,
        complement,
        district,
        city,
        uf
      });

      onConfirm();
    } catch (error) {
      toast.error("Alguma coisa deu errado", error)
    }
  }, [accountId]);

  const validationSchema = Yup.object().shape({
    zipCode: Yup.string().min(7, 'Digite seu CEP').required('Este campo é necessário'),
    street: Yup.string().required('Este campo é necessário'),
    district: Yup.string().required(),
    complement: Yup.string().optional('Este campo é necessário'),
    city: Yup.string().required('Este campo é necessário'),
    uf: Yup.string().required('Este campo é necessário'),
    number: Yup.string().required('Este campo é necessário')
  });

  const formik = useFormik({
    initialValues: {
      zipCode: "",
      street: "",
      district: "",
      complement: "",
      city: "",
      uf: "",
      number: ""
    },
    onSubmit: handleSubmit,
    validationSchema
  });

  useEffect(() => {
    eva.replace();
  }, []);

  return (
    <div className="update-address">
      <header className="update-address__header">
        <h5>Alterar endereço</h5>
        <button
          type="button"
          onClick={onClose}
        >
          <i
            data-eva="close-outline"
          />
        </button>
      </header>

      <form
        className="update-address__form"
        onSubmit={formik.handleSubmit}
      >
        <div className="update-address__form__flex">
          <InputMask
            name="zipCode"
            placeholder="CEP"
            mask="99999-999"
            className="update-address__form__flex__cep"
            onChange={e => {
              handleGetAddressByCep(e.target.value);
              formik.handleChange(e);
            }}
            value={formik.values.zipCode}
            error={formik.errors.zipCode}
          />

          <Input
            name="district"
            placeholder="Bairro"
            className="update-address__form__flex__district"
            onChange={formik.handleChange}
            value={formik.values.district}
            error={formik.errors.district}
          />
        </div>

        <div className="update-address__form__flex">
          <Input
            name="street"
            placeholder="Endereço"
            mask="99999-999"
            className="update-address__form__flex__street"
            onChange={formik.handleChange}
            value={formik.values.street}
            error={formik.errors.street}
          />
          <Input
            name="number"
            placeholder="Nº"
            className="update-address__form__flex__number"
            onChange={formik.handleChange}
            value={formik.values.number}
            error={formik.errors.number}
          />
        </div>

        <Input
          name="complement"
          placeholder="Complemento"
          onChange={formik.handleChange}
          value={formik.values.complement}
          error={formik.errors.complement}
        />

        <Input
          name="city"
          placeholder="Cidade"
          onChange={formik.handleChange}
          value={formik.values.city}
          error={formik.errors.city}
        />

        <Input
          name="uf"
          placeholder="UF"
          onChange={formik.handleChange}
          value={formik.values.uf}
          error={formik.errors.uf}
        />

        <div className="update-address__form__flex">
          <Button
            variant="secondary"
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button
            onClick={formik.handleSubmit}
          >
            Confirmar
          </Button>
        </div>
      </form>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  getByCep: (zipcode) => dispatch(UtilsActions.getAddressViaCep(zipcode)),
  changeAddressUser: (address) => dispatch(UserActions.changeAddressUser(address)),
});

export default connect(
  null,
  mapDispatchToProps,
)(AddressModal);