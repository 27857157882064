import React from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../svg/ic_loading_spinner.svg';
import { LoadingSelectors } from '../../app/store/reducers';

const Loading = ({ isLoading }) => (
  <div>
    {isLoading && (
      <div className="loading">
        <div className="loading__inner">
          <LoadingSpinner className="loading__inner__spinner" />
        </div>
      </div>
    )}
  </div>
);

const mapStateToProps = state => ({
  isLoading: (LoadingSelectors.getLoading(state) > 0),
});

export default connect(mapStateToProps)(Loading);
