import React, { Fragment } from 'react';
import CheckIcon from '../../../svg/institutional/ic_check.svg';

const AboutSection = () => {
  return (
    <Fragment>
      <div className="institutional__section institutional__section--blue">
        <div className="institutional__section__title">
          Nossas Soluções
        </div>
        <div className="about__section">
          <div className="about__section__container mobile-hidden">
            <img
              alt=""
              className="about__section__container__image"
              src="/assets/images/mockup_iphone.png"
            />
          </div>
          <div className="about__section__list">
            <div className="about__section__list__single">
              <div className="d-flex align-center justify-content-center">
                <div className="about__section__list__single__icon">
                  <CheckIcon />
                </div>
              </div>
              <div className="about__section__list__single__content">
                <div className="about__section__list__single__content__description">
                  Carteira digital para adicionar ou transferir dinheiro e realizar pagamentos com saldo em conta.
                </div>
              </div>
            </div>
            <div className="about__section__list__single__divider" />
            <div className="about__section__list__single">
              <div className="d-flex align-center justify-content-center">
                <div className="about__section__list__single__icon">
                  <CheckIcon />
                </div>
              </div>
              <div className="about__section__list__single__content">
                <div className="about__section__list__single__content__description">
                  Extrato e agenda de pagamentos, recebimentos e transferências, acessível em uma plataforma digital.
                </div>
              </div>
            </div>
            <div className="about__section__list__single__divider" />
            <div className="about__section__list__single">
              <div className="d-flex align-center justify-content-center">
                <div className="about__section__list__single__icon">
                  <CheckIcon />
                </div>
              </div>
              <div className="about__section__list__single__content">
                <div className="about__section__list__single__content__description">
                  Checkout transparente com múltiplas formas de pagamento.
                </div>
              </div>
            </div>
            <div className="about__section__list__single__divider" />
            <div className="about__section__list__single">
              <div className="d-flex align-center justify-content-center">
                <div className="about__section__list__single__icon">
                  <CheckIcon />
                </div>
              </div>
              <div className="about__section__list__single__content">
                <div className="about__section__list__single__content__description">
                  Sistema com dados criptografados e medidas de segurança contra fraudes.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AboutSection;
