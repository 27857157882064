import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ROUTES from '../data/routes';

import Header from '../components/header/Header';
import { AuthSelectors } from '../app/store/reducers';

const PublicRoute = ({
  component: Component,
  noHeader,
  isAuthenticated,
  title,
  ...rest
}) => {
  return (
    <React.Fragment>
      {!noHeader && <Header />}

      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Route
        {...rest}
        component={props => (ROUTES.CONFIRM_IDENTITY ||
          !isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to="/painel" />
          )
        )}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: AuthSelectors.getIsAuthenticated(state),
});

export default connect(mapStateToProps)(PublicRoute);
